import React, { useEffect } from "react";
import homepage from "../image/homepage.jpg";
import { useDispatch, useSelector } from "react-redux";
import { loadDataSecurity } from "../redux/action/StaticAction";
import Loader from "../component/Shared/Loader";
import Message from "../component/share/Message";

export default function DataSecurity() {
  const dispatch = useDispatch();

  useEffect(() => {
    document.getElementById("maintittle").innerHTML = "data security | iRobot India";
    document.getElementById("description").setAttribute("content", "data security | iRobot India");

    dispatch(loadDataSecurity());
  }, [dispatch]);

  const CovidData = useSelector((state) => state.Static);

  const { loading, error, dataSecurityData } = CovidData;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section className="section " >
      <div className="container">
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : (
          <div className="data_security page_content">
            <img
              src={homepage}
              style={{ margin: "0 auto 30px", display: "block" }}
            />
            {dataSecurityData &&
              dataSecurityData.map((item, i) => (
                <div className="rte" key={i}>
                  <h3> {item.heading}</h3>
                  <p>{item.content}</p>
                </div>
              ))}
          </div>
        )}
      </div>
    </section>
  );
}
