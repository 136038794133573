import {
  FIND_ALL_CATEGORY_FAIL,
  FIND_ALL_CATEGORY_REQUEST,
  FIND_ALL_CATEGORY_SUCCESS,
  FIND_ONE_CATEGORY_FAIL,
  FIND_ONE_CATEGORY_REQUEST,
  FIND_ONE_CATEGORY_SUCCESS,
} from "../constants/CategoryConstant";

const initialState = {
  loading: false,
  action: "Main Category",
  result: [],
  response: {},
  singledata: [],
  msg: "",
  error: "",
};
const CategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case FIND_ALL_CATEGORY_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case FIND_ALL_CATEGORY_SUCCESS:
      return {
        ...state,
        result: action.result.data,
        loading: action.payload,
        msg: action.msg,
      };
    case FIND_ALL_CATEGORY_FAIL:
      return {
        ...state,
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };

    case FIND_ONE_CATEGORY_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case FIND_ONE_CATEGORY_SUCCESS:
      return {
        ...state,
        singledata: action.result.data,
        loading: action.payload,
        msg: action.msg,
      };
    case FIND_ONE_CATEGORY_FAIL:
      return {
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };
    default:
      return state;
  }
};
export default CategoryReducer;
