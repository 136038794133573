import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import dummyUser from "../../image/dummyUser.jpg";
// import { Link } from "react-router-dom";
import { fetchUserProfile } from "../../redux/action/AuthAction";
import Loader from "../Shared/Loader";
import OrderInvoice from "./OrderInvoice";
import { fetchOrderHistoryRequest } from "../../redux/action/OrderAction";

export default function DashboardProfile() {
  const [order_id, setOrder_id] = useState("");

  const [showInvoice, setshowInvoice] = useState(false);

  const dispatch = useDispatch();

  const UserInfo = useSelector((state) => state.AuthReducer);

  const { userResult } = UserInfo;

  let id = userResult.id;

  useEffect(() => {
    if (id) {
      dispatch(fetchOrderHistoryRequest(id));
      dispatch(fetchUserProfile(id));
    }
  }, [dispatch, id]);

  const Orderinfo = useSelector((state) => state.Order);

  const userData = useSelector((state) => state.AuthReducer.result);

  let { loading, error, result } = Orderinfo;

  let formatter = new Intl.DateTimeFormat("en-GB", {
    year: "numeric",
    month: "numeric",
    day: "2-digit",
  });

  const handleInvoice = (id) => {
    setOrder_id(id);
    setshowInvoice(!showInvoice);
  };

  return (




    <div className="orderHistory">
      <div className="user_profile">
        <h2 className="title">
          Hi,
          {userResult.first_name ? (
            <>
              <span>{userResult.first_name + " " + userResult.last_name}</span>
            </>
          ) : (
            <>{userResult.email}</>
          )}
        </h2>
      </div>

      {result.length > 0 ? <><div className="recentPurchase">

        <div className="product_cart">
          <div className="">
            <div className="regrobot">
              {loading == true ? (
                <>
                  <Loader />
                </>
              ) : (
                <>
                  <table>
                    <thead>
                      <tr>
                        <th className="section-header">Order ID</th>
                        <th className="section-header">Total</th>
                        <th className="section-header Purchase">
                          Final Total
                        </th>
                        <th className="section-header">Order Status</th>
                        <th className="section-header Purchase">
                          Date of Purchase
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {result &&
                        result.slice(0, 1).map((item, i) => (
                          <tr key={i}>
                            <td>
                              <button
                                onClick={() => handleInvoice(item.order_id)}
                              >
                                {item.order_id}
                              </button>
                            </td>
                            <td>

                              <span className="sale">{item.total}</span>

                            </td>
                            <td>

                              <span className="sale">
                                {item.final_total}
                              </span>

                            </td>
                            <td>

                              <span className="sale">
                                {item.order_status.name}
                              </span>

                            </td>
                            <td className="purchase_date">
                              {formatter.format(Date.parse(item.created_at))}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </>
              )}
            </div>
          </div>
        </div>
      </div></> : ""}


      {/* <div className="about_user">
        {userData.image !== null ? (
          <>
            <img src={userData.image} alt="" />
          </>
        ) : (
          <>
            <img src={dummyUser} alt="" />
          </>
        )}
        <img src={userData.image} alt="" />
      </div> */}
      {showInvoice ? <OrderInvoice order_id={order_id} /> : null}
    </div >
  );
}
