import {
  ADD_CART_FAIL,
  ADD_CART_REQUEST,
  ADD_CART_SUCCESS,
  DELETE_CART_FAIL,
  DELETE_CART_REQUEST,
  DELETE_CART_SUCCESS,
  GET_ALL_CART_FAIL,
  GET_ALL_CART_REQUEST,
  GET_ALL_CART_SUCCESS,
  UPDATE_CART_FAIL,
  UPDATE_CART_REQUEST,
  UPDATE_CART_SUCCESS,
} from "../constants/CartLiveConstant";
import uuid from "uuid";
import axios from "axios";
import { toast } from "react-toastify";

// action from get all the Cart

const loadAllCart = (id) => {


  return function (dispatch) {
    dispatch({
      type: GET_ALL_CART_REQUEST,
      payload: true,
    });

    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/cart/${id}/`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };

    axios(OPTION)
      .then((res) => {
        dispatch(loadAllCartPre(res.data));
      })
      .catch((error) => {
        dispatch({
          type: GET_ALL_CART_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
      });
  };
};
export const loadAllCartPre = (data) => {


  if (data.data[0] != null & data.data[0] != undefined && data.data[0] != "") {

    return {
      type: GET_ALL_CART_SUCCESS,
      result: data,
      payload: false,
      msg: "SUCCESS",
      tmsg: "",
    };

  }
  else {
    //window.location.href = "/";
    return {
      type: GET_ALL_CART_SUCCESS,
      result: data,
      payload: false,
      msg: "SUCCESS",
      tmsg: "home",
    };
  }

};

// add New Vehicle
const createCart = (user_id, id, qty, ftype,price,discount, selling_price ) => {
  let code = uuid.v4().toString();
  return function (dispatch) {
    dispatch({
      type: ADD_CART_REQUEST,
      payload: true,
    });

    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/cart-create/`,
      method: "POST",
      data: {
        user_id: user_id,
        product_id: id,
        quantity: qty,
        price: price,
        discount: discount,
        selling_price: selling_price,
        code,
      },
      headers: {
        "content-type": "application/json",
      },
    };

    axios(OPTION)
      .then((res) => {
        dispatch(createCartPre(res.data, ftype));
        localStorage.setItem("userCode", JSON.stringify(res.data.data.code));
      })

      .catch((error) => {
        dispatch({
          type: ADD_CART_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
        toast.danger(error.msg);
      });
  };
};
export const createCartPre = (data, ftype) => {

  toast.success(data.msg);
  if (ftype == 2) {
    window.location.href = "/cart";
    //window.location.href = "/shipping";

  }

  return {
    type: ADD_CART_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

// update Vehicle OWNER
const updateCart = (id, data) => {
  return function (dispatch) {
    dispatch({
      type: UPDATE_CART_REQUEST,
      payload: true,
    });
    const token = JSON.parse(localStorage.getItem("jwt"))
      ? JSON.parse(localStorage.getItem("jwt"))
      : "";
    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/admin-cart/`,
      method: "POST",
      data: data,
      headers: {
        "content-type": "application/json",
        token: token,
      },
    };

    axios(OPTION)
      .then((res) => {
        dispatch(updateCartPre(res.data));
        // dispatch(loadAllCart());
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_CART_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
        toast.danger("Failed To Update The Best Uses!");
      });
  };
};
export const updateCartPre = (data) => {
  toast.success("Cart Updated Successfully!");

  return {
    type: UPDATE_CART_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

const deleteCart = (data) => {
  return function (dispatch) {
    dispatch({
      type: DELETE_CART_REQUEST,
      payload: true,
    });
    // const token = JSON.parse(localStorage.getItem("jwt"))
    //   ? JSON.parse(localStorage.getItem("jwt"))
    //   : "";
    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/cart-remove/`,
      method: "POST",
      data: data,
      headers: {
        "content-type": "application/json",
      },
    };

    axios(OPTION)
      .then((res) => {
        dispatch(deleteCartPre(res.data));
        //dispatch(loadAllCart());
      })
      .catch((error) => {
        dispatch({
          type: DELETE_CART_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
      });
  };
};
export const deleteCartPre = (data) => {
  toast.error("Cart Deleted Successfully!");
  return {
    type: DELETE_CART_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

export { loadAllCart, createCart, updateCart, deleteCart };
