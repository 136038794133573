import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import genius from "../../image/genius.jpg";

import { loadAllGeniusContent } from "../../redux/action/StaticAction";

export default function InnerBanner() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadAllGeniusContent());
  }, []);
  const banners = useSelector((state) => state.Static.genius);

  // console.log("banners",banners)
  let bannerData = banners[0]

  if (bannerData) {
    var banner_id = bannerData.id
    var banner_image = bannerData.banner_image
    var banner_heading = bannerData.banner_heading
    var banner_content = bannerData.banner_content


    // console.log('banner_id',banner_id);
  }

  return (
    <>
      <section className="inner_banner">
        {banner_image == null ? (
          <img src={genius} alt="" />
        ) : (
          <img src={`${process.env.REACT_APP_API_PATH}${banner_image}`} />
        )}
        <div className="container-fluid">
          {/* 
          <div className="inner">
            <h2 className="title">{banner_heading}</h2>
            {<p>{banner_content}</p>}
          </div>
          */}
        </div>
      </section>
    </>
  );
}
