import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createAppointment } from "../../redux/action/AdditionalAction";
import { loadAllCategory } from "../../redux/action/CategoryAction";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../Shared/Loader";
import Calendar from 'react-calendar'
import Message from "../share/Message";
import ReCAPTCHA from "react-google-recaptcha";
import spinner1 from "../../image/spinner1.gif";


export default function SelectLocation({ onchange }) {


  const [captchaResult, setCaptchaResult] = useState();

  const queryParams = new URLSearchParams(window.location.search);


  const [utm_source, setutm_source] = useState('');
  const [utm_campaign, setutm_campaign] = useState('');

  var utm_sourcercv = queryParams.get('utm_source');
  var utm_campaignrcv = queryParams.get('utm_campaign');

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    if (utm_sourcercv != null && utm_sourcercv != '' && utm_sourcercv != undefined) {

      console.log("utm source1");
      setutm_source(utm_sourcercv);
      setutm_campaign(utm_campaignrcv);

      localStorage.setItem('utm_source', utm_sourcercv);
      localStorage.setItem('utm_campaign', utm_campaignrcv);
      // setState({ ...state, ['source']: utm_sourcercv });
      //setState({ ...state, ['campaign']: utm_campaignrcv });
    }
    else {
      console.log("utm source2");

      var item1 = localStorage.getItem('utm_source');
      var item2 = localStorage.getItem('utm_campaign');
      if (item1 != null && item1 != undefined && item1 != '') {
        setutm_source(item1);
        setutm_campaign(item2);

        setState({ ...state, ['source']: item1 });
        setState({ ...state, ['campaign']: item2 });
      }

    }
    dispatch(loadAllCategory());
  }, [dispatch]);

  const [state, setState] = useState({
    city: "",
    landmark: "video",
    category: "",
    name: "",
    email: "",
    mobile: "",
    pincode: "",
    location: "",
    address: "",
    message: "",
    pe: "",
    reqtyop: "",
    source: utm_source,
    campaign: utm_campaign,

  });

  const {
    city,
    category,
    name,
    email,
    mobile,
    landmark,
    pincode,
    location,
    address,
    message,
    pe,
    reqtyop,
    source,
    campaign

  } = state;

  const Category = useSelector((state) => state.MainCategory);

  const { error, result } = Category;



  const handleInputChange = (e) => {


    let { name, value } = e.target;
    console.log("handleInputChange" + name);


    if (name == "pincode") {
      value = value.slice(0, 6);
    }
    if (name == "mobile") {
      value = value.slice(0, 10);
    }
    setState({ ...state, [name]: value });


  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (captchaResult == undefined || captchaResult == '' || captchaResult == null) {
      toast.warning("Invalid Captcha");

      //return false;

    }
    state.campaign = utm_campaign;
    state.source = utm_source;
    if (!category || !email || !mobile || !name) {
      toast.warning("Please input all input Field!");
    } else {
      dispatch(createAppointment(state));
    }
    //  navigate("/all-thank-you");
  };



  const { response, loading } = useSelector((state) => state.Additional);

  useEffect(() => {

    if (Object.keys(response).length > 0) {
      console.log('Object is NOT empty');

      //navigate('/thanks-you', { state: { messagefor: "contact", heading: "Thank You For Choosing iRobot and taking the first step", message: "We will get in touch with you shortly. For quick response connect on 18002003355. " } });
      navigate('/thanks-you-video-demo', { state: { messagefor: "contact", heading: "Thank You For Choosing iRobot and taking the first step", message: "We will get in touch with you shortly. For quick response connect on 18002003355. " } });

    }

  }, [response]);
  const key = process.env.REACT_APP_SITE_KEY;
  const url = process.env.REACT_APP_SITE_URL;

  const handleRecaptcha = (value) => {
    setCaptchaResult(value)
    /*
    fetch(url, {
      method: "POST",
      body: JSON.stringify({ captcha_value: value }),
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.captcha.success);
        setCaptchaResult(data.captcha.success);
      });
      */
  };
  return (
    <section  id="divform" className="book-onlie" style={{ background: "#fff" }}>
      {loading ? (<div id="loading">
        <img id="loading-image" src={spinner1} alt="Loading..." />
      </div>) : ""}
      <ToastContainer />
      <div className="container" >
        <div className="account_form register">

          <div className="acc_form">
            {loading ? (
              <Loader />
            ) : error ? (
              <Message variant="danger">{error}</Message>
            ) : (
              <form autoComplete="off" onSubmit={handleSubmit}>
                <div className="top-content-form">
                  <h2 className="title">Try before you buy:</h2>
                  <p className="subheading">
                    Fill in your details below or Call now{" "}
                    <button
                      onClick={(e) => (window.location = "tel:1800-200-3355")}
                    >
                      1800-200-3355
                    </button>
                  </p>
                </div>

                {/* <div className="input_field">
                  <label>
                    Select Location <span>*</span>
                  </label>
                  <input
                    type="text"
                    required
                    placeholder=""
                    name="location"
                    value={location}
                    onChange={handleInputChange}
                  />
                </div> */}
                <div className="input_field">
                  <label>
                    Product Type <span>*</span>
                  </label>
                  <select
                    name="category"
                    onChange={handleInputChange}
                    value={category || ""}
                  >
                    <option>Select Product</option>
                    {result.map((item, i) => (
                      <option key={i} value={item.alias}>
                        {item.name} {item.alias}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="input_field">
                  <label>
                    Name <span>*</span>
                  </label>
                  <input
                    type="text"
                    placeholder=""
                    name="name"
                    value={name}
                    onChange={handleInputChange}
                    required
                  />
                </div>

                <div className="input_field">
                  <label>
                    Email  <span>*</span>
                  </label>
                  <input
                    type="email"
                    required
                    name="email"
                    placeholder=""
                    value={email}
                    onChange={handleInputChange}

                  />
                </div>
                <div className="input_field">
                  <label>
                    Contact Number <span>*</span>
                  </label>
                  <input
                    type="number"
                    required
                    minLength="10" 
                    maxLength="10"
                    placeholder=""
                    name="mobile"
                    value={mobile}
                    //onChange={handleInputChange}
                    onChange={(e) => {
                      if (e.target.value.length < 11) {
                        handleInputChange(e);
                      }
                    }}

                  />
                </div>
                <div className="input_field">
                  <label>
                    Address
                  </label>
                  <input
                    type="text"
                    placeholder=""
                    name="address"
                    value={address}
                    onChange={handleInputChange}

                  />
                </div>
                <div className="input_field">
                  <label>
                    City
                  </label>
                  <input
                    type="text"
                    placeholder=""
                    name="city"
                    value={city}
                    onChange={handleInputChange}

                  />
                </div>
                <div className="input_field">
                  <label>
                    Pincode
                  </label>
                  <input
                    type="text"
                    minLength="6" 
                    maxLength="6"
                    placeholder=""
                    name="pincode"
                    value={pincode}
                    onChange={(e) => {
                      if (e.target.value.length < 7) {
                        handleInputChange(e);
                      }
                    }}

                  />
                </div>
                {/* 
                <div className="input_field">
                  <label>
                    Source <span>*</span>
                  </label>
                  <input
                    type="text"
                    placeholder=""
                    name="source"
                    value={source}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="input_field">
                  <label>
                    Campaign<span>*</span>
                  </label>
                  <input
                    type="text"
                    placeholder=""
                    name="campaign"
                    value={campaign}
                    onChange={handleInputChange}
                  />
                </div>
                */}
                {/* <div className="input_field">
                  <label>
                    Date <span>*</span>
                  </label>
                  <input
                    type="number"
                    placeholder=""
                    name="date"
                    value={date}
                    onChange={handleInputChange}
                  />
                </div> */}
                <ReCAPTCHA
                  sitekey={key}
                  onChange={handleRecaptcha}
                  className="captcha"
                />
                <div className="input_field_btn">
                  {/* <ReCAPTCHA sitekey={key} onChange={handleRecaptcha} className="captcha"/> */}

                  {/* {captchaResult && ( <input type="submit" value="Book Online" />)} */}
                  <input type="submit" value="Book Online" />
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
