import {
  GET_EARN_LOYALTY_POINTS_FAIL,
  GET_EARN_LOYALTY_POINTS_REQUEST,
  GET_EARN_LOYALTY_POINTS_SUCCESS,
  GET_LOYALTY_POINTS_FAIL,
  GET_LOYALTY_POINTS_REQUEST,
  GET_LOYALTY_POINTS_SUCCESS,
  GET_SPEND_LOYALTY_POINTS_FAIL,
  GET_SPEND_LOYALTY_POINTS_REQUEST,
  GET_SPEND_LOYALTY_POINTS_SUCCESS,
  GET_LOYALTY_POINTS_HISTORY_FAIL,
  GET_LOYALTY_POINTS_HISTORY_REQUEST,
  GET_LOYALTY_POINTS_HISTORY_SUCCESS,
} from "../constants/LoyaltypointsConstant";

const initialState = {
  loading: false,
  action: "Additonal-Data",
  response: {},
  loyaltyPoints: [],
  pointsHistory: [],
  earnHistory: [],
  spendHistory: [],
  msg: "",
  error: "",
};

const LoyaltyPointsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LOYALTY_POINTS_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case GET_LOYALTY_POINTS_SUCCESS:
      return {
        ...state,
        loyaltyPoints: action.result.data,
        loading: action.payload,
        msg: action.msg,
      };
    case GET_LOYALTY_POINTS_FAIL:
      return {
        ...state,
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };

    case GET_LOYALTY_POINTS_HISTORY_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case GET_LOYALTY_POINTS_HISTORY_SUCCESS:
      return {
        ...state,
        pointsHistory: action.result.data,
        loading: action.payload,
        msg: action.msg,
      };
    case GET_LOYALTY_POINTS_HISTORY_FAIL:
      return {
        ...state,
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };

    case GET_SPEND_LOYALTY_POINTS_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case GET_SPEND_LOYALTY_POINTS_SUCCESS:
      return {
        ...state,
        spendHistory: action.result.data,
        loading: action.payload,
        msg: action.msg,
      };
    case GET_SPEND_LOYALTY_POINTS_FAIL:
      return {
        ...state,
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };

    case GET_EARN_LOYALTY_POINTS_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case GET_EARN_LOYALTY_POINTS_SUCCESS:
      return {
        ...state,
        earnHistory: action.result.data,
        loading: action.payload,
        msg: action.msg,
      };
    case GET_EARN_LOYALTY_POINTS_FAIL:
      return {
        ...state,
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };

    default:
      return state;
  }
};

export default LoyaltyPointsReducer;
