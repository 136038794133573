import {
  FIND_ALL_SUBCATEGORY_FAIL,
  FIND_ALL_SUBCATEGORY_REQUEST,
  FIND_ALL_SUBCATEGORY_SUCCESS,
  FIND_ONE_SUBCATEGORY_FAIL,
  FIND_ONE_SUBCATEGORY_REQUEST,
  FIND_ONE_SUBCATEGORY_SUCCESS,
  FIND_ONE_SUBCATEGORY_FAIL2,
  FIND_ONE_SUBCATEGORY_REQUEST2,
  FIND_ONE_SUBCATEGORY_SUCCESS2,
} from "../constants/SubCategoryConstant";
const initialState = {
  loading: false,
  action: "Sub Category",
  result: [],
  response: {},
  singledata: [],
  singledata2: [],
  msg: "",
  error: "",
};
const SubCategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case FIND_ALL_SUBCATEGORY_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case FIND_ALL_SUBCATEGORY_SUCCESS:
      return {
        ...state,
        result: action.result.data,
        loading: action.payload,
        msg: action.msg,
      };
    case FIND_ALL_SUBCATEGORY_FAIL:
      return {
        ...state,
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };

    case FIND_ONE_SUBCATEGORY_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case FIND_ONE_SUBCATEGORY_SUCCESS:
      return {
        ...state,
        singledata: action.result.data,
        loading: action.payload,
        msg: action.msg,
      };
    case FIND_ONE_SUBCATEGORY_FAIL:
      return {
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };

      case FIND_ONE_SUBCATEGORY_REQUEST2:
      return {
        ...state,
        loading: action.payload,
      };
    case FIND_ONE_SUBCATEGORY_SUCCESS2:
      return {
        ...state,
        singledata2: action.result.data,
        loading: action.payload,
        msg: action.msg,
      };
    case FIND_ONE_SUBCATEGORY_FAIL2:
      return {
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };
    default:
      return state;
  }
};
export default SubCategoryReducer;
