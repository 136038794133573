import axios from "axios";
import {
  FIND_SERIES_FAIL,
  FIND_SERIES_REQUEST,
  FIND_SERIES_SUCCESS,
  FIND_PROUDLIST_FAIL,
  FIND_PROUDLIST_REQUEST,
  FIND_PROUDLIST_SUCCESS,
  FIND_GENUIS_FAIL,
  FIND_GENUIS_REQUEST,
  FIND_GENUIS_SUCCESS,
  FIND_OFFER_CONTENT_FAIL,
  FIND_OFFER_CONTENT_REQUEST,
  FIND_OFFER_CONTENT_SUCCESS,
  FIND_ABOUTUS_FAIL,
  FIND_ABOUTUS_REQUEST,
  FIND_ABOUTUS_SUCCESS,
  FIND_COVID_UPDATE_FAIL,
  FIND_COVID_UPDATE_REQUEST,
  FIND_COVID_UPDATE_SUCCESS,
  FIND_DATA_SECURITY_FAIL,
  FIND_DATA_SECURITY_REQUEST,
  FIND_DATA_SECURITY_SUCCESS,
  FIND_PRIVACY_POLICY_FAIL,
  FIND_PRIVACY_POLICY_REQUEST,
  FIND_PRIVACY_POLICY_SUCCESS,
  FIND_RETURN_POLICY_FAIL,
  FIND_RETURN_POLICY_REQUEST,
  FIND_RETURN_POLICY_SUCCESS,
  FIND_TERM_CONDITION_FAIL,
  FIND_TERM_CONDITION_REQUEST,
  FIND_TERM_CONDITION_SUCCESS,
  FIND_CATEGORY_FAIL,
  FIND_CATEGORY_REQUEST,
  FIND_CATEGORY_SUCCESS,
  FIND_CATEGORY_TAB_FAIL,
  FIND_CATEGORY_TAB_REQUEST,
  FIND_CATEGORY_TAB_SUCCESS,
  FIND_SUBCATEGORY_FAIL,
  FIND_SUBCATEGORY_REQUEST,
  FIND_SUBCATEGORY_SUCCESS,
  FIND_SUBCATEGORY_TAB_FAIL,
  FIND_SUBCATEGORY_TAB_REQUEST,
  FIND_SUBCATEGORY_TAB_SUCCESS,
  FIND_GENIUS_FAIL,
  FIND_GENIUS_REQUEST,
  FIND_GENIUS_SUCCESS,
  FIND_GENIUS_TAB_FAIL,
  FIND_GENIUS_TAB_REQUEST,
  FIND_GENIUS_TAB_SUCCESS,

} from "../constants/StaticConstant";

const loadAllcategoriesContent = (id) => {
  return function (dispatch) {
    dispatch({
      type: FIND_SERIES_REQUEST,
      payload: true,
    });
    let OPTION = {
      //url: `${process.env.REACT_APP_API_PATH}/api/v1/category-static-content-catid/${id}/`,
      url: `${process.env.REACT_APP_API_PATH}/api/v1/category-static-content-catid-n/${id}/`,
      method: "GET",
      headers: {
        "content-type": "application/json",
        // token: token,
      },
    };
    axios(OPTION)
      .then((res) => {
        console.log("res", res.data);
        dispatch(loadAllcategoriesContentPre(res.data));
      })
      .catch((error) => {
        console.log("error", error);
        dispatch({
          type: FIND_SERIES_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
      });
  };
};
export const loadAllcategoriesContentPre = (data) => {
  return {
    type: FIND_SERIES_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

const loadCategoryImages = (banner_id) => {
  return function (dispatch) {
    dispatch({
      type: FIND_CATEGORY_REQUEST,
      payload: true,
    });
    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/static-banner-catid/${banner_id}/`,
      // url: `${process.env.REACT_APP_API_PATH}/api/v1/static-banner-catid/20/`,
      method: "GET",
      headers: {
        "content-type": "application/json",
        // token: token,
      },
    };
    axios(OPTION)
      .then((res) => {
        // console.log('res',res.data);
        dispatch(loadCategoryImagesPre(res.data));
      })
      .catch((error) => {
        // console.log("error",error);
        dispatch({
          type: FIND_CATEGORY_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
      });
  };
};
export const loadCategoryImagesPre = (data) => {
  return {
    type: FIND_CATEGORY_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

const loadCategoryTab = (banner_id) => {

  return function (dispatch) {
    dispatch({
      type: FIND_CATEGORY_TAB_REQUEST,
      payload: true,
    });
    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/dynamic-tab-catid/${banner_id}/`,
      // url: `${process.env.REACT_APP_API_PATH}/api/v1/static-banner-catid/20/`,
      method: "GET",
      headers: {
        "content-type": "application/json",
        // token: token,
      },
    };
    axios(OPTION)
      .then((res) => {
        // console.log('res',res.data);
        dispatch(loadCategoryTabPre(res.data));
      })
      .catch((error) => {
        // console.log("error",error);
        dispatch({
          type: FIND_CATEGORY_TAB_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
      });
  };
};
export const loadCategoryTabPre = (data) => {
  return {
    type: FIND_CATEGORY_TAB_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

const loadSubCategoryImages = (banner_id) => {

  return function (dispatch) {
    dispatch({
      type: FIND_SUBCATEGORY_REQUEST,
      payload: true,
    });
    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/static-banner-subcat/${banner_id}/`,
      // url: `${process.env.REACT_APP_API_PATH}/api/v1/static-banner-catid/20/`,
      method: "GET",
      headers: {
        "content-type": "application/json",
        // token: token,
      },
    };
    axios(OPTION)
      .then((res) => {
        // console.log('res',res.data);
        dispatch(loadSubCategoryImagesPre(res.data));
      })
      .catch((error) => {
        // console.log("error",error);
        dispatch({
          type: FIND_SUBCATEGORY_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
      });
  };
};

export const loadSubCategoryImagesPre = (data) => {
  return {
    type: FIND_SUBCATEGORY_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

const loadSubCategoryTab = (banner_id) => {
  return function (dispatch) {
    dispatch({
      type: FIND_SUBCATEGORY_TAB_REQUEST,
      payload: true,
    });
    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/dynamic-tab-subcat/${banner_id}/`,
      // url: `${process.env.REACT_APP_API_PATH}/api/v1/static-banner-catid/20/`,
      method: "GET",
      headers: {
        "content-type": "application/json",
        // token: token,
      },
    };
    axios(OPTION)
      .then((res) => {
        // console.log('res',res.data);
        dispatch(loadSubCategoryTabPre(res.data));
      })
      .catch((error) => {
        // console.log("error",error);
        dispatch({
          type: FIND_SUBCATEGORY_TAB_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
      });
  };
};
export const loadSubCategoryTabPre = (data) => {
  return {
    type: FIND_SUBCATEGORY_TAB_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

const loadGeniusImages = (banner_id) => {
  return function (dispatch) {
    dispatch({
      type: FIND_GENIUS_REQUEST,
      payload: true,
    });
    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/static-banner-genius/${banner_id}/`,
      // url: `${process.env.REACT_APP_API_PATH}/api/v1/static-banner-catid/20/`,
      method: "GET",
      headers: {
        "content-type": "application/json",
        // token: token,
      },
    };
    axios(OPTION)
      .then((res) => {
        // console.log('res',res.data);
        dispatch(loadGeniusImagesPre(res.data));
      })
      .catch((error) => {
        // console.log("error",error);
        dispatch({
          type: FIND_GENIUS_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
      });
  };
};
export const loadGeniusImagesPre = (data) => {
  return {
    type: FIND_GENIUS_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

const loadGeniusTab = (banner_id) => {
  return function (dispatch) {
    dispatch({
      type: FIND_GENIUS_TAB_REQUEST,
      payload: true,
    });
    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/dynamic-tab-genius/${banner_id}/`,
      // url: `${process.env.REACT_APP_API_PATH}/api/v1/static-banner-catid/20/`,
      method: "GET",
      headers: {
        "content-type": "application/json",
        // token: token,
      },
    };
    axios(OPTION)
      .then((res) => {
        // console.log('res',res.data);
        dispatch(loadGeniusTabPre(res.data));
      })
      .catch((error) => {
        // console.log("error",error);
        dispatch({
          type: FIND_GENIUS_TAB_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
      });
  };
};
export const loadGeniusTabPre = (data) => {
  return {
    type: FIND_GENIUS_TAB_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

const loadAllProdtList = (id) => {
  return function (dispatch) {
    dispatch({
      type: FIND_PROUDLIST_REQUEST,
      payload: true,
    });
    let OPTION = {
      //url: `${process.env.REACT_APP_API_PATH}/api/v1/subcategory-static-content-subcatid/${id}/`,
      url: `${process.env.REACT_APP_API_PATH}/api/v1/subcategory-static-content-subcatid-n/${id}/`,
      method: "GET",
      headers: {
        "content-type": "application/json",
        // token: token,
      },
    };
    axios(OPTION)
      .then((res) => {
        dispatch(loadAllProdtListPre(res.data));
      })
      .catch((error) => {
        dispatch({
          type: FIND_PROUDLIST_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
      });
  };
};
export const loadAllProdtListPre = (data) => {
  return {
    type: FIND_PROUDLIST_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

const loadAllGeniusContent = () => {
  return function (dispatch) {
    dispatch({
      type: FIND_GENUIS_REQUEST,
      payload: true,
    });
    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/genius-product-static-content/`,
      method: "GET",
      headers: {
        "content-type": "application/json",
        // token: token,
      },
    };
    axios(OPTION)
      .then((res) => {
        dispatch(loadAllGeniusContentPre(res.data));
      })
      .catch((error) => {
        dispatch({
          type: FIND_GENUIS_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
      });
  };
};
export const loadAllGeniusContentPre = (data) => {
  return {
    type: FIND_GENUIS_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

const loadAllOfferContent = () => {
  return function (dispatch) {
    dispatch({
      type: FIND_OFFER_CONTENT_REQUEST,
      payload: true,
    });
    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/offer-product-static-content/`,
      method: "GET",
      headers: {
        "content-type": "application/json",
        // token: token,
      },
    };
    axios(OPTION)
      .then((res) => {
        dispatch(loadAllOfferContentPre(res.data));
      })
      .catch((error) => {
        dispatch({
          type: FIND_OFFER_CONTENT_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
      });
  };
};
export const loadAllOfferContentPre = (data) => {
  return {
    type: FIND_OFFER_CONTENT_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

const loadAllAboutContent = () => {
  return function (dispatch) {
    dispatch({
      type: FIND_ABOUTUS_REQUEST,
      payload: true,
    });
    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/aboutus-static-content/`,
      method: "GET",
      headers: {
        "content-type": "application/json",
        // token: token,
      },
    };
    axios(OPTION)
      .then((res) => {
        dispatch(loadAllAboutContentPre(res.data));
      })
      .catch((error) => {
        dispatch({
          type: FIND_ABOUTUS_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
      });
  };
};
export const loadAllAboutContentPre = (data) => {
  return {
    type: FIND_ABOUTUS_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

const loadCividUpdate = () => {
  return function (dispatch) {
    dispatch({
      type: FIND_COVID_UPDATE_REQUEST,
      payload: true,
    });
    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/covid-update-static-content/`,
      method: "GET",
      headers: {
        "content-type": "application/json",
        // token: token,
      },
    };
    axios(OPTION)
      .then((res) => {
        dispatch(loadCividUpdatePre(res.data));
      })
      .catch((error) => {
        dispatch({
          type: FIND_COVID_UPDATE_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
      });
  };
};
export const loadCividUpdatePre = (data) => {
  return {
    type: FIND_COVID_UPDATE_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

const loadDataSecurity = () => {
  return function (dispatch) {
    dispatch({
      type: FIND_DATA_SECURITY_REQUEST,
      payload: true,
    });
    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/data-security-static-content/`,
      method: "GET",
      headers: {
        "content-type": "application/json",
        // token: token,
      },
    };
    axios(OPTION)
      .then((res) => {
        dispatch(loadDataSecurityPre(res.data));
      })
      .catch((error) => {
        dispatch({
          type: FIND_DATA_SECURITY_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
      });
  };
};
export const loadDataSecurityPre = (data) => {
  return {
    type: FIND_DATA_SECURITY_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

const loadPrivacyPolicy = () => {
  return function (dispatch) {
    dispatch({
      type: FIND_PRIVACY_POLICY_REQUEST,
      payload: true,
    });
    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/privacy-policy-static-content/`,
      method: "GET",
      headers: {
        "content-type": "application/json",
        // token: token,
      },
    };
    axios(OPTION)
      .then((res) => {
        dispatch(loadPrivacyPolicyPre(res.data));
      })
      .catch((error) => {
        dispatch({
          type: FIND_PRIVACY_POLICY_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
      });
  };
};
export const loadPrivacyPolicyPre = (data) => {
  return {
    type: FIND_PRIVACY_POLICY_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

const loadReturnPolicy = () => {
  return function (dispatch) {
    dispatch({
      type: FIND_RETURN_POLICY_REQUEST,
      payload: true,
    });
    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/return-policy-static-content/`,
      method: "GET",
      headers: {
        "content-type": "application/json",
        // token: token,
      },
    };
    axios(OPTION)
      .then((res) => {
        dispatch(loadReturnPolicyPre(res.data));
      })
      .catch((error) => {
        dispatch({
          type: FIND_RETURN_POLICY_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
      });
  };
};
export const loadReturnPolicyPre = (data) => {
  return {
    type: FIND_RETURN_POLICY_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

const loadTermCondition = () => {
  return function (dispatch) {
    dispatch({
      type: FIND_TERM_CONDITION_REQUEST,
      payload: true,
    });
    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/term-condition-static-content/`,
      method: "GET",
      headers: {
        "content-type": "application/json",
        // token: token,
      },
    };
    axios(OPTION)
      .then((res) => {
        dispatch(loadTermConditionPre(res.data));
      })
      .catch((error) => {
        dispatch({
          type: FIND_TERM_CONDITION_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
      });
  };
};
export const loadTermConditionPre = (data) => {
  return {
    type: FIND_TERM_CONDITION_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

export {
  loadAllcategoriesContent,
  loadAllProdtList,
  loadAllGeniusContent,
  loadAllOfferContent,
  loadAllAboutContent,
  loadCividUpdate,
  loadDataSecurity,
  loadPrivacyPolicy,
  loadReturnPolicy,
  loadTermCondition,
  loadCategoryImages,
  loadCategoryTab,
  loadSubCategoryImages,
  loadSubCategoryTab,
  loadGeniusTab,
  loadGeniusImages,
};
