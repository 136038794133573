import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DashboardSideBar from "../component/dashboard/DashboardSideBar";
export default function Dashboard() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="profile_dashboard section">
      <div className="container">
        <DashboardSideBar />
      </div>
    </div>
  );
}
