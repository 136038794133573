import axios from "axios";
import { toast } from "react-toastify";
import {
  FIND_ALL_SUBACCESSORIES_REQUEST,
  FIND_ALL_SUBACCESSORIES_SUCCESS,
  FIND_ALL_SUBACCESSORIES_FAIL,
  FIND_SINGLE_SUBACCESSORIES_REQUEST,
  FIND_SINGLE_SUBACCESSORIES_SUCCESS,
  FIND_SINGLE_SUBACCESSORIES_FAIL,
} from "../constants/SubAccessoriesConstant";

// Find All SUBACCESSORIES
const loadAllSubAccesssories = (id) => {
  return function (dispatch) {
    dispatch({
      type: FIND_ALL_SUBACCESSORIES_REQUEST,
      payload: true,
    });
    // const token = JSON.parse(localStorage.getItem("jwt"))
    //   ? JSON.parse(localStorage.getItem("jwt"))
    //   : "";
    let OPTION = {
      //url: `${process.env.REACT_APP_API_PATH}/api/v1/user-subcategory-catid/${id}/`,
      url: `${process.env.REACT_APP_API_PATH}/api/v1/user-subcategory-catid-n/${id}/`,
      method: "GET",
      headers: {
        "content-type": "application/json",
        // token: token,
      },
    };
    axios(OPTION)
      .then((res) => {
        dispatch(loadAllSubAccesssoriesPre(res.data));
      })
      .catch((error) => {
        dispatch({
          type: FIND_ALL_SUBACCESSORIES_FAIL,
          preload: false,
          error: error,
          msg: "Failed to load the information",
        });
      });
  };
};
export const loadAllSubAccesssoriesPre = (data) => {
  // console.log("data",data)
  return {
    type: FIND_ALL_SUBACCESSORIES_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

// Find single SUBACCESSORIES

const loadSingleSubAccesssories = (id, result) => {
  return function (dispatch) {
    dispatch({
      type: FIND_SINGLE_SUBACCESSORIES_REQUEST,
      payload: true,
    });
    const token = JSON.parse(localStorage.getItem("jwt"))
      ? JSON.parse(localStorage.getItem("jwt"))
      : "";
    let OPTION = {
      url: process.env.REACT_APP_API_PATH + "/api/v1/user-category/1/",
      method: "GET",
      headers: {
        "content-type": "application/json",
        token: token,
      },
    };
    axios(OPTION)
      .then((res) => {
        dispatch(loadSingleSubAccesssoriesPre(res.data));
      })
      .catch((error) => {
        dispatch({
          type: FIND_SINGLE_SUBACCESSORIES_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
      });
  };
};
export const loadSingleSubAccesssoriesPre = (data) => {
  return {
    type: FIND_SINGLE_SUBACCESSORIES_SUCCESS,
    payload: false,
    result: data,
    msg: "SUCESS",
  };
};

export { loadAllSubAccesssories, loadSingleSubAccesssories };
