// constant files

export const ADD_PRODUCT_REVIEW_REQUEST = "ADD_PRODUCT_REVIEW_REQUEST";
export const ADD_PRODUCT_REVIEW_SUCCESS = "ADD_PRODUCT_REVIEW_SUCCESS";
export const ADD_PRODUCT_REVIEW_FAIL = "ADD_PRODUCT_REVIEW_FAIL";

export const UPDATE_PRODUCT_REVIEW_REQUEST = "UPDATE_PRODUCT_REVIEW_REQUEST";
export const UPDATE_PRODUCT_REVIEW_SUCCESS = "UPDATE_PRODUCT_REVIEW_SUCCESS";
export const UPDATE_PRODUCT_REVIEW_FAIL = "UPDATE_PRODUCT_REVIEW_FAIL";

export const DELETE_PRODUCT_REVIEW_REQUEST = "DELETE_PRODUCT_REVIEW_REQUEST";
export const DELETE_PRODUCT_REVIEW_SUCCESS = "DELETE_PRODUCT_REVIEW_SUCCESS";
export const DELETE_PRODUCT_REVIEW_FAIL = "DELETE_PRODUCT_REVIEW_FAIL";

export const GET_ALL_PRODUCT_REVIEW_REQUEST = "GET_ALL_PRODUCT_REVIEW_REQUEST";
export const GET_ALL_PRODUCT_REVIEW_SUCCESS = "GET_ALL_PRODUCT_REVIEW_SUCCESS";
export const GET_ALL_PRODUCT_REVIEW_FAIL = "GET_ALL_PRODUCT_REVIEW_FAIL";

export const GET_SINGLE_PRODUCT_REVIEW_REQUEST =
  "GET_SINGLE_PRODUCT_REVIEW_REQUEST";
export const GET_SINGLE_PRODUCT_REVIEW_SUCCESS =
  "GET_SINGLE_PRODUCT_REVIEW_SUCCESS";
export const GET_SINGLE_PRODUCT_REVIEW_FAIL = "GET_SINGLE_PRODUCT_REVIEW_FAIL";


export const GET_ALL_PRODUCT_REVIEW_IMAGE_REQUEST = "GET_ALL_PRODUCT_REVIEW_IMAGE_REQUEST";
export const GET_ALL_PRODUCT_REVIEW_IMAGE_SUCCESS = "GET_ALL_PRODUCT_REVIEW_IMAGE_SUCCESS";
export const GET_ALL_PRODUCT_REVIEW_IMAGE_FAIL = "GET_ALL_PRODUCT_REVIEW_IMAGE_FAIL";