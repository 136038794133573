import React, { useState, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import ProductSpec from "./ProductSpec";
import ProductDetail from "./ProductDetails";
import ProductFaq from "./ProductFaq"
// import 'react-tabs/style/react-tabs.css';

import { useSelector, useDispatch } from "react-redux";
import { loadAllProductSpecification } from "../../redux/action/ProductAction";
import { loadAllProductAddDetails, loadAllProductFaq } from "../../redux/action/ProductAction";
import { useParams } from "react-router-dom";

export default function ProductTabs() {
  const dispatch = useDispatch();

  const { id } = useParams();

  useEffect(() => {

    dispatch(loadAllProductSpecification(id));
    dispatch(loadAllProductAddDetails(id));
    dispatch(loadAllProductFaq(id));



  }, [id]);

  let productData = useSelector((state) => state.MainProduct);



  let { loading, error, productSpec, productAddDetails, productFaq } = productData;
   productAddDetails = productAddDetails.map(item => item).reverse();

  {/* 
          productAddDetails.reverse().map((de, i) => {
          
          */}
  return (
    <>
      {/* {productAddDetails !== "" && productData.productSpec !== "" ? "yes" :"no"} */}
      <Tabs className="Product_tab">
        <TabList>
          {productAddDetails == [] || productAddDetails == "" ? (
            ""
          ) : (
            <Tab>Details</Tab>
          )}

          {productData.productSpec == [] || productData.productSpec == "" ? (
            ""
          ) : (
            <Tab>Specs</Tab>
          )}
          {productData.productFaq == [] || productData.productFaq == "" ? (
            ""
          ) : (
            <Tab>FAQ</Tab>

          )}

        </TabList>

        {productAddDetails == [] || productAddDetails == "" ? (
          ""
        ) : (

          < TabPanel >
            {productAddDetails &&
              productAddDetails.map((de, i) => {
                return (
                  <div key={de.id} className="proud-detail">
                    <div className="detail-conetnt">
                      <h2 className="subheading">{de.title}</h2>
                      <p>{de.short_description}</p>
                      <>
                        <p className="post__content" dangerouslySetInnerHTML={{ __html: de.description }}></p></>

                    </div>
                    <img src={`${process.env.REACT_APP_API_PATH}${de.image}`} alt="" />
                  </div>
                );
              })}
          </TabPanel>
        )}

        {productData.productSpec == [] || productData.productSpec == "" ? (
          ""
        ) : (
          <TabPanel>
            {productSpec &&
              productSpec
                .slice(0, 1)
                .map((spec, i) => {
                  return (
                    <>
                      <div key={spec.id} className="spec">
                        <h2 className="subheading">{spec.title}</h2>
                        {/* < className="subheading">What's in the Box:</  */}
                        <p>{spec.short_description}</p>
                        <>
                          <p className="post__content" dangerouslySetInnerHTML={{ __html: spec.description }}></p></>

                      </div>

                    </>
                  );
                })}
          </TabPanel>
        )}
        <TabPanel>
          {productFaq && productFaq.map((da) => {
            return (
              <div className='product-faq' key={da.id}>
                <h4 className='subheading'>{da.heading} </h4>
                <>
                  <p className="post__content" dangerouslySetInnerHTML={{ __html: da.description }}></p></>


              </div>
            )
          })}
        </TabPanel>
      </Tabs>
    </>
  );
}
