import React, { useEffect, useState } from "react";

//import pic from "../image/left.jpeg";

import hand from "../image/hand.png";
import whatsapp from "../image/whatsap.png";
import phone from "../image/phone.png";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Satisfaction from "../component/home/Satisfaction";
import SelectLocation from "../component/bookonline/SelectLocation";
import test from "../../src/image/test.xlsx"
import { loadSingleVideodemo } from "../redux/action/VideodemoAction";

import { useDispatch, useSelector } from "react-redux";
import Loader from "../component/Shared/Loader";
import Message from "../component/share/Message";

const VideoCallDemo = () => {
  const [book, setBook] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadSingleVideodemo());
  }, []);

  const Videodemo = useSelector((state) => state.Videodemo);

  let { error, loading, singledata } = Videodemo;
  let VideoCallDemodata = singledata[0];



  if (VideoCallDemodata) {
    var banner_image = VideoCallDemodata.image
    document.getElementById("maintittle").innerHTML = VideoCallDemodata.seo_tittle;
    document.getElementById("description").setAttribute("content", VideoCallDemodata.seo_description);


  }


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onchange = () => {
    setBook(!book);
  };

  return (
    <>
      <div className="container-fluid">
        <ToastContainer />

        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : (
          <div className="left">
            <div className="div60">
              <img src={`${process.env.REACT_APP_API_PATH}${banner_image}`} />

            </div>
            <div className="div40">
              <div className="right">

                {VideoCallDemodata ? <h3 className="title">{VideoCallDemodata.bannerheading}</h3> : <></>}
                {VideoCallDemodata ?
                  <p className="post__content" dangerouslySetInnerHTML={{ __html: VideoCallDemodata.bannercontent }}></p> : <></>}


              </div>
            </div>
          </div>
        )}

        <div className="para">

          {VideoCallDemodata ? <p>{VideoCallDemodata.pageheading}</p> : <></>}


          <div className="link">
            <div className="card">
              {/* <button onClick={() => setBook(true)}> */}
              <button onClick={onchange}>

                <img src={hand} alt="" />
                <h4>Book Online</h4>
              </button>
            </div>
            <div className="card">
              <button onClick={(e) => (window.location = "tel:18002003355")}>
                {/* <button onClick={(e) => (window.open = {test})}> */}
                {/* <button onClick={(event) => { event.preventDefault(); window.open(test); }}> */}
                <img src={phone} alt="" />
                <h4>Make Call</h4>
              </button>
            </div>
            <div className="card">
              <button
                onClick={(e) =>
                  (window.location = `https://wa.me/917022800561?text=I'm%20inquiring%20about%20the%20demo`)
                }
              >
                <img src={whatsapp} alt="" />
                <h4>WhatsApp</h4>
              </button>
            </div>
          </div>
          {book ? <SelectLocation /> : ""}

        </div>
        <Satisfaction />
      </div>

      {/*
      {book ? <SelectLocation onchange={onchange} /> : ""}
*/}

    </>
  );
};

export default VideoCallDemo;
