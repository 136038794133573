import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { loadSubCategoryTab } from "../../redux/action/StaticAction";
import zig_zag1 from '../../image/zig_zag1.jpg'
import { useParams } from 'react-router-dom';

export default function Left_zigzag44({ banner_id }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadSubCategoryTab(banner_id));
  }, [banner_id])

  // let {id} = useParams();

  const tab = useSelector((state) => state.Static.subCategoriesTab)

  return (
    <>
      {tab.map((da) => {
        return (
          <section className="section zigzag" key={da.id}>

            <div className="zigzag_content">
              <div className="leftt">
                <div className="container">

                  {da.tab4_heading == null ?
                    "":
                    <h3 className="title"><span>{da.tab4_heading}</span></h3>
                  }
                </div>
              </div>
              <div className="rightt">
                {da.tab4_image == null ?
                  "" :
                  <img src={`${process.env.REACT_APP_API_PATH}${da.tab4_image}`} alt="" />}
              </div>
            </div>

          </section>
        )
      })}
    </>


  )
}
