import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchOrderDetailsRequest,
  fetchOrderListDetailsRequest,
} from "../../redux/action/OrderAction";
import logo from "../../image/logo.png";
import { Link } from "react-router-dom";
import Loader from "../Shared/Loader";
import "./OrderInvoice.css";

export default function OrderInvoice({ order_id }) {
  const [hideInvoice, sethideInvoice] = useState(true);
  const [quantity, setQuantity] = useState(0);
  const [subTotalamount, setSubTotalamount] = useState(0);
  const [tax, setTax] = useState(0);
  const [amount, setAmount] = useState(0);
  const [shipping_price, setShipping_price] = useState(0);
  const [coupon_value, setCoupon_value] = useState(0);
  const [offer_value, setOffer_value] = useState(0);
  const [final_total, setFinal_total] = useState(0);




  const dispatch = useDispatch();

  const data = {
    id: order_id,
  };

  useEffect(() => {
    if (order_id) {
      dispatch(fetchOrderDetailsRequest(data));
      dispatch(fetchOrderListDetailsRequest(data));
    }
  }, []);

  const OrderList = useSelector((state) => state.Order);

  const { loading, error, orderlist, singledata } = OrderList;

  let orderDetails = singledata[0];

  let formatter = new Intl.DateTimeFormat("en-GB", {
    year: "numeric",
    month: "numeric",
    day: "2-digit",
  });

  useEffect(() => {
    if (orderlist != null && orderDetails != null) {

      console.log('orderDetails..', orderDetails);
      setSubTotalamount(orderDetails.total)
      /*
      setSubTotalamount(
        Number(
          orderlist
            .reduce(
              (acc, item) => acc + item.productid.sellprice * item.quantity,
              0
            )
            .toFixed(2)
        )
      );
      */

      setQuantity(orderlist.reduce((acc, item) => acc + item.quantity, 0));

      if (subTotalamount != null && subTotalamount != 0) {
        //setTax(Number((0.15 * subTotalamount).toFixed(2)));
        setTax(orderDetails.gst);

      }
      /*
      if (subTotalamount >= 500) {
        setShipping_price(0);
      } else {
        setShipping_price(100);
      }
      */
      setShipping_price(orderDetails.shipping_cost);

      if (orderDetails) {
        // setCoupon_value(orderDetails.coupan_id.discount_value);
        setCoupon_value(orderDetails.discount);



        setOffer_value(orderDetails.coupan_id.discount_value);
      }

      /*
      setAmount(
        Number(subTotalamount) +
        Number(tax) +
        Number(shipping_price) -
        Number(coupon_value) -
        Number(offer_value)
      ); */
      setAmount(orderDetails.final_total);
    }
  });

  const prindiv = () => {

    var divToPrint = document.getElementById('orderdetail');
    console.log(divToPrint.innerHTML);

    var newWin = window.open('', 'Print-Window');

    newWin.document.open();

    newWin.document.write('<html><body onload="window.print()">' + divToPrint.innerHTML + '</body></html>');

    newWin.document.close();

    setTimeout(function () { newWin.close(); }, 10);

  }
  return (
    <>
      {hideInvoice ? (
        <section className="order-invoice">
          {orderDetails ? (
            <>
              <div className="container" id="orderdetail">
                <div className="invoice-header">
                  <h2 className="title">
                    Order Id : <span> {order_id}</span>
                  </h2>
                  <span
                    className="close"
                    onClick={() => sethideInvoice(!hideInvoice)}
                  >
                    X
                  </span>
                </div>
                <div className="invoice-body">
                  <div className="date">
                    {formatter.format(Date.parse(orderDetails.created_at))}
                  </div>
                  <div className="invoice-logo">
                    <img src={logo} alt="" />
                  </div>
                  <div className="extra"></div>
                  <div className="incoice-to">
                    <h3 className="subheading">Invoice To:</h3>
                    <table className="incoice-tabel">
                      <tbody>
                        <tr>
                          <th>Name:</th>
                          <td>
                            {orderDetails.user_id.first_name +
                              " " +
                              orderDetails.user_id.last_name}
                          </td>
                        </tr>
                        <tr>
                          <th>Mobile:</th>
                          <td>{orderDetails.user_id.mobile}</td>
                        </tr>
                        <tr>
                          <th>Email:</th>
                          <td>{orderDetails.user_id.email}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="ship-to">
                    <h3 className="subheading">Ship To:</h3>
                    <table className="incoice-tabel">
                      <tbody>
                        <tr>
                          <th>Name:</th>
                          <td>
                            {orderDetails.deliver_address_Id.firstname +
                              " " +
                              orderDetails.deliver_address_Id.lastname}
                          </td>
                        </tr>
                        <tr>
                          <th>Mobile:</th>
                          <td>{orderDetails.deliver_address_Id.mobile}</td>
                        </tr>
                        <tr>
                          <th>Email:</th>
                          <td>{orderDetails.user_id.email}</td>
                        </tr>
                        <tr>
                          <th>Address:</th>
                          <td>{orderDetails.deliver_address_Id.address}</td>
                        </tr>
                        <tr>
                          <th>House Number:</th>
                          <td>{orderDetails.deliver_address_Id.house}</td>
                        </tr>
                        <tr>
                          <th>Sector:</th>
                          <td>{orderDetails.deliver_address_Id.sector}</td>
                        </tr>
                        <tr>
                          <th>Street:</th>
                          <td>{orderDetails.deliver_address_Id.street}</td>
                        </tr>
                        <tr>
                          <th>City:</th>
                          <td>{orderDetails.deliver_address_Id.city}</td>
                        </tr>
                        <tr>
                          <th>Landmark:</th>
                          <td>{orderDetails.deliver_address_Id.landmark}</td>
                        </tr>
                        <tr>
                          <th>State:</th>
                          <td>{orderDetails.deliver_address_Id.stateName}</td>
                        </tr>
                        <tr>
                          <th>Country:</th>
                          <td>{orderDetails.deliver_address_Id.country}</td>
                        </tr>
                        <tr>
                          <th>Postal Code:</th>
                          <td>{orderDetails.deliver_address_Id.postCode}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div className="main-detail">
                    {loading == true ? (
                      <>
                        <Loader />
                      </>
                    ) : (
                      <div className="cart_tabel">
                        <table>
                          <thead>
                            <tr>
                              <th className="section-header">Sr.No</th>
                              <th className="section-header">Product Name</th>
                              <th className="section-header">Price</th>
                              <th className="section-header">Qty</th>
                              <th className="section-header">Total</th>
                              <th className="section-header Purchase">
                                Date of Purchase
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {orderlist &&
                              orderlist.map((item, i) => (
                                <tr key={i}>
                                  <td>
                                    {/* <Link to={`/product/${item.productid.id}/`}> */}
                                    {i + 1}
                                    {/* </Link> */}
                                  </td>
                                  <td>
                                    <Link to={`/product/${item.productid.id}/`}>
                                      <h3>{item.productid.name}</h3>
                                      {item.productid.item_no == null ? (
                                        ""
                                      ) : (
                                        <span>
                                          Item No:{" "}
                                          {" " + item.productid.item_no}
                                        </span>
                                      )}
                                    </Link>
                                  </td>
                                  <td>
                                    <div className="series_price">
                                      <p className="regular">
                                        <span className="sale">
                                          ₹{" " + item.productid.sellprice}
                                        </span>
                                      </p>
                                    </div>
                                  </td>
                                  <td className="qty">
                                    <strong>{item.quantity}</strong>
                                  </td>
                                  <td className="cart_product_total">
                                    <strong>
                                      ₹
                                      {item.productid.sellprice * item.quantity}
                                    </strong>
                                  </td>
                                  <td className="purchase_date">
                                    {formatter.format(
                                      Date.parse(item.created_at)
                                    )}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                          <tfoot>
                            <tr>
                              <td></td>
                              <td></td>

                              <td className="cart_subcart" colSpan={4}>
                                <ul>
                                  <li>
                                    <span>Subtotal</span>
                                    <span>₹{subTotalamount}</span>
                                  </li>
                                  <li>
                                    <span> GST</span>
                                    <span>₹{" " + tax}</span>
                                  </li>
                                  <li>
                                    <span>Coupon Discount</span>
                                    <span>
                                      -
                                      {coupon_value === null ? (
                                        <> 0.00</>
                                      ) : (
                                        <> ₹{" " + coupon_value}</>
                                      )}
                                    </span>
                                  </li>
                                  <li>
                                    <span>Offer Discount</span>
                                    <span>
                                      - ₹
                                      {offer_value === null ? (
                                        <> 0.00</>
                                      ) : (
                                        <> ₹{" " + offer_value}</>

                                      )}
                                    </span>
                                  </li>


                                  <li>
                                    <span>Shipping Charges</span>
                                    <span>
                                      {shipping_price === 0 ? (
                                        <> ₹ 0.00</>
                                      ) : (
                                        <> ₹  {shipping_price}</>
                                      )}
                                    </span>
                                  </li>



                                  <li className="subtototal">
                                    <span>Total Amount</span>
                                    <span> ₹{" " + amount}</span>
                                    <p>
                                      <small>
                                        GST is included in the total amount
                                      </small>
                                    </p>
                                  </li>
                                </ul>
                              </td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    )}
                  </div>
                </div>


              </div>
              <div className="invoice-fotter">
                <h4 className="subheading">
                  Thanks For bussiness with irobot
                </h4>
                <div className="fotter-btn">
                  <button
                    className="btn cancel"
                    onClick={() => sethideInvoice(!hideInvoice)}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn print"
                    onClick={(e) => prindiv(e)}

                  >
                    Print
                  </button>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </section>
      ) : null}
    </>
  );
}
