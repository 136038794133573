import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadAllBlogsSingle } from "../../redux/action/AdditionalAction";
// import blog_1 from "../../image/blog_1.png";
import { useParams } from "react-router-dom";

export default function SingleBlog() {
  const dispatch = useDispatch();
  let { id } = useParams();
  useEffect(() => {
    if (id) {
      dispatch(loadAllBlogsSingle(id));
    }
  }, [dispatch, id]);

  const blogdata1 = useSelector((state) => state.Additional.singleblog);
  let blogdata = [];
  useEffect(() => {
    document.getElementById("maintittle").innerHTML = blogdata[0].seo_tittle;
    document.getElementById("description").setAttribute("content", blogdata[0].seo_description);

    window.scrollTo(0, 0);
  }, [blogdata]);

  blogdata.push(blogdata1);
  return (
    <>
      {blogdata &&
        blogdata.map((e, i) => {
          return (
            <aside className="left_sidebar" key={i}>
              <div className="title">
                <span>{e.title}</span>
              </div>
              {/* 
              <div className="blog_meta">
                <ul className="blog_author">
                  <li>
                    <strong>Author Name: </strong>
                    {e.author}
                  </li>
                  <li>
                    <strong>Category: </strong>
                    {e.category}
                  </li>
                </ul>
                <ul className="blog_author posted">
                  <li>
                    <strong>Posted: </strong>
                    {Date(e.created_at).substring(0, 15)}
                  </li>
                  {e.updated_at == null ? (
                    ""
                  ) : (
                    <li>
                      <strong>Updated:</strong> {Date(e.updated_at).substring(0, 15)}
                    </li>
                  )}
                </ul>
              </div>
              */}
              <div className="blog_img">
                <img src={`${process.env.REACT_APP_API_PATH}${e.image}`} alt="" />
              </div>
              <div className="blog_meta">


                <div className="blog_short_Desc">
                  <div key={i} className="post__content" dangerouslySetInnerHTML={{ __html: e.description }}></div>

                </div>
              </div>
            </aside>
          );
        })}
    </>
  );
}
