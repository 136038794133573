import React from "react";
import apply from "../../image/apply.jpg";

export default function InnerBanner() {
  return (
    <section className="inner_banner">
      <img src={apply} alt="" />
      <div className="container">
        <div className="inner">
          <h2 className="title"></h2>
        </div>
      </div>
    </section>
  );
}
