import {
  ADD_ORDER_FAIL,
  ADD_ORDER_REQUEST,
  ADD_ORDER_SUCCESS,
  CANCEL_ORDER_FAIL,
  CANCEL_ORDER_REQUEST,
  CANCEL_ORDER_SUCCESS,
  DELETE_ORDER_FAIL,
  DELETE_ORDER_REQUEST,
  DELETE_ORDER_SUCCESS,
  FIND_ORDER_DETAIL_FAIL,
  FIND_ORDER_DETAIL_REQUEST,
  FIND_ORDER_DETAIL_SUCCESS,
  FIND_ORDER_FAIL,
  FIND_ORDER_REQUEST,
  FIND_ORDER_SUCCESS,
  RETURN_ORDER_FAIL,
  RETURN_ORDER_REQUEST,
  RETURN_ORDER_SUCCESS,
  UPDATE_ORDER_FAIL,
  UPDATE_ORDER_REQUEST,
  UPDATE_ORDER_SUCCESS,
  FIND_ORDER_LIST_DETAIL_FAIL,
  FIND_ORDER_LIST_DETAIL_REQUEST,
  FIND_ORDER_LIST_DETAIL_SUCCESS,
} from "../constants/OrderConstant";

const initialState = {
  loading: false,
  action: "Order Action",
  result: [],
  orderlist: [],
  response: {},
  singledata: [],
  msg: "",
  error: "",
};

const OrderReduser = (state = initialState, action) => {
  switch (action.type) {
    case FIND_ORDER_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case FIND_ORDER_SUCCESS:
      return {
        ...state,
        result: action.result.data,
        loading: action.payload,
        msg: action.msg,
      };
    case FIND_ORDER_FAIL:
      return {
        ...state,
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };

    case FIND_ORDER_DETAIL_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case FIND_ORDER_DETAIL_SUCCESS:
      return {
        ...state,
        orderlist: action.result.data,
        loading: action.payload,
        msg: action.msg,
      };
    case FIND_ORDER_DETAIL_FAIL:
      return {
        ...state,
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };

    case FIND_ORDER_LIST_DETAIL_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case FIND_ORDER_LIST_DETAIL_SUCCESS:
      return {
        ...state,
        singledata: action.result.data,
        loading: action.payload,
        msg: action.msg,
      };
    case FIND_ORDER_LIST_DETAIL_FAIL:
      return {
        ...state,
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };

    case DELETE_ORDER_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case DELETE_ORDER_SUCCESS:
      return {
        ...state,
        result: state.result.filter(
          (item) => item.id !== action.result.data.id
        ),
        response: action.result.msg,
        loading: action.payload,
        msg: action.msg,
      };
    case DELETE_ORDER_FAIL:
      return {
        ...state,
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };

    case ADD_ORDER_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case ADD_ORDER_SUCCESS:
      return {
        ...state,
        result: state.result.concat(action.result.data),
        response: action.result.msg,
        loading: action.payload,
        msg: action.msg,
      };
    case ADD_ORDER_FAIL:
      return {
        ...state,
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };

    case UPDATE_ORDER_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case UPDATE_ORDER_SUCCESS:
      return {
        ...state,
        result: state.result.map((item) =>
          item.id === action.result.data.id ? action.result.data : item
        ),
        response: action.result.msg,
        singledata: [],
        loading: action.payload,
        msg: action.msg,
      };
    case UPDATE_ORDER_FAIL:
      return {
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };

    case RETURN_ORDER_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case RETURN_ORDER_SUCCESS:
      return {
        ...state,
        result: state.result.map((item) =>
          item.id === action.result.data.id ? action.result.data : item
        ),
        response: action.result.msg,
        singledata: [],
        loading: action.payload,
        msg: action.msg,
      };
    case RETURN_ORDER_FAIL:
      return {
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };

    case CANCEL_ORDER_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case CANCEL_ORDER_SUCCESS:
      return {
        ...state,
        result: state.result.map((item) =>
          item.id === action.result.data.id ? action.result.data : item
        ),
        response: action.result.msg,
        singledata: [],
        loading: action.payload,
        msg: action.msg,
      };
    case CANCEL_ORDER_FAIL:
      return {
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };

    default:
      return state;
  }
};

export default OrderReduser;
