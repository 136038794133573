import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadAllAccesssories } from "../../redux/action/AccessoriesAction";
import { Link, useParams, useNavigate } from "react-router-dom";



import { loadPromoCodeByCode } from "../../redux/action/AdditionalAction";

import { deleteCart, loadAllCart } from "../../redux/action/CartAction";
import { createCart } from "../../redux/action/CartAction";
export default function CartPopup({ showChange }) {

    let navigate = useNavigate();

    let dispatch = useDispatch();
    const [TotolGst, setGST] = useState(0.00);
    const [coupondicount, setcoupondicount] = useState(0.00);
    const [promocode, setPromocode] = useState("");
    const [promomessage, setPromomessage] = useState("");


    const [couponId, setCoupanId] = useState("");

    const [offerdicount, setofferdicount] = useState(0.00);

    const [shippingCharges, setshippingCharges] = useState(0.00);


    const [totalamount, settotalamount] = useState(0.00);
    const [subtotalamount, setsubtotalamount] = useState(0.00);






    let user = useSelector((state) => state.AuthReducer);

    let { isSigninIn, userResult } = user;

    var user_id = userResult.id;

    useEffect(() => {
        //localStorage.setItem("tempuser", "");

        if (isSigninIn != true) {
            //navigate("/login");
            var idrcv = localStorage.getItem("tempuser");
            dispatch(loadAllCart(idrcv));
        }

        if (user_id != null && user_id != undefined) {

            dispatch(loadAllCart(user_id));

        } else {
            var idrcv = localStorage.getItem("tempuser");
            dispatch(loadAllCart(idrcv));
        }
    }, [user_id]);

    let cartData = useSelector((state) => state.Cart);
    console.log(cartData, "cartData")

    let { loading, error, result, tmsg } = cartData;
    const removeToCartHandler = (id) => {


        console.log("removeToCartHandler");
        if (isSigninIn != true) {
            //navigate("/login");
            var idrcv = localStorage.getItem("tempuser");

            let data = {
                id: id,
                user_id: idrcv,
            };
            dispatch(deleteCart(data));
        }

        if (user_id != null) {
            let data = {
                id: id,
                user_id: user_id,
            };
            dispatch(deleteCart(data));
        }
    };

    useEffect(() => {

        // console.log('tmsg.. ', tmsg);
        // if (tmsg == "home") {
        //     window.location.href = "/";
        // }
    }, [tmsg]);


    useEffect(() => {


        if (result.length > 0) {

            console.log('cartData...', result);
            var tot = 0.00;
            result.forEach((data) => {
                tot = tot + data.quantity * data.product_id.sellprice
            })
            var gstamount = tot - [tot * (100 / (100 + 18))];

            //const [coupondicount, setcoupondicount] = useState(0.00);
            //const [offerdicount, setofferdicount] = useState(0.00);
            //const [shippingCharges, setshippingCharges] = useState(0.00);
            //const [totalamount, settotalamount] = useState(0.00);
            setsubtotalamount(tot.toFixed(2))
            setGST(gstamount.toFixed(2));
            // var finaltot = Number(tot) + Number(gstamount);
            var finaltot = Number(tot);

            settotalamount(finaltot.toFixed(2));

        }
        else {
            showChange();
        }

    });

    const PromocodehandleClick = (e) => {

        e.preventDefault();
        console.log('The link was clicked.', promocode);
        dispatch(loadPromoCodeByCode(promocode));
        //var code=
    }
    const { promoCodeSingle } = useSelector((state) => state.Additional);
    console.log('code..', promoCodeSingle)
    useEffect(() => {

        if (promoCodeSingle) {

            setCoupanId(promoCodeSingle.id);
            console.log('code us..', promoCodeSingle)

            var Rcoupan_type = promoCodeSingle.coupan_type;
            var Rcode = promoCodeSingle.code;
            var Rdiscount_type = promoCodeSingle.discount_type;
            //var coupan_type=promoCode.coupan_type;
            var Rdiscount_percent = promoCodeSingle.discount_percent;
            var Rdiscount_value = promoCodeSingle.discount_value;
            var RmaxdiscountAllowed = promoCodeSingle.maxdiscountAllowed;
            var Rmin_purchase = promoCodeSingle.min_purchase;
            var Rvalid_from = promoCodeSingle.valid_from;
            var valid_to = promoCodeSingle.valid_to;


            var discount_on = promoCodeSingle.discount_on;
            var type_id = promoCodeSingle.type_id;
            var cat_id = promoCodeSingle.cat_id;
            var subCategory_id = promoCodeSingle.subCategory_id;
            var product_id = promoCodeSingle.product_id;
            var usefor = promoCodeSingle.usefor;
            var user_id = promoCodeSingle.user_id;
            var use_time = promoCodeSingle.use_time;



            if (Number(subtotalamount) < Number(Rmin_purchase)) {
                setPromomessage("Minimum " + Rmin_purchase + " is required to use this code");
                return
            }
            var datetoday = new Date(new Date().toISOString().slice(0, 10)).getTime();
            var datevalidfrom = new Date(Rvalid_from).getTime();
            var datevalidto = new Date(valid_to).getTime();

            if (!(datetoday >= datevalidfrom && datetoday <= datevalidto)) {
                setPromomessage("Code is valid From " + Rvalid_from + " to " + valid_to);
                return
            }

            //discounall
            if (Number(discount_on) == 0) {

                if (usefor == 1) {
                    if (user_id != useremail) {
                        setPromomessage("Not applicable on your profile.");
                        return;
                    }

                }

                if (Rdiscount_type == 1) {
                    var disc = (Number(subtotalamount) / 100) * Number(Rdiscount_percent);
                    if (Number(disc) > Number(RmaxdiscountAllowed)) {
                        disc = RmaxdiscountAllowed;
                    }
                    setcoupondicount(disc);
                    var totamount = Number(subtotalamount) - Number(coupondicount);
                    var gstamount = totamount - [totamount * (100 / (100 + 18))];
                    setGST(gstamount.toFixed(2));
                    // var finaltot = Number(totamount) + Number(gstamount);
                    var finaltot = Number(totamount);
                    settotalamount(finaltot.toFixed(2));

                }
                if (Rdiscount_type == 2) {
                    var disc = Rdiscount_value;
                    setcoupondicount(disc);
                    var totamount = Number(subtotalamount) - Number(coupondicount);
                    var gstamount = totamount - [totamount * (100 / (100 + 18))];
                    setGST(gstamount.toFixed(2));
                    // var finaltot = Number(totamount) + Number(gstamount);
                    var finaltot = Number(totamount);
                    settotalamount(finaltot.toFixed(2));
                }

            }
            //cat type main catgeory
            if (Number(discount_on) == 1) {
                console.log("discount on CategoryType  ", type_id)
                var discountamount = 0.00;
                var flagd = true;
                var useremail = "";
                result.map((item, i) => {
                    useremail = item.user_id.email;
                    var rcvtypeid = item.product_id.type;
                    var rcvsellprice = item.product_id.sellprice
                    if (type_id == rcvtypeid) {
                        flagd = false;
                        discountamount = discountamount + Number(rcvsellprice);
                    }
                })
                console.log("Total discount subtotal", discountamount)

                if (flagd) {
                    setPromomessage("Not applicable on existing cart item.");
                    return;
                }
                if (usefor == 1) {
                    if (user_id != useremail) {
                        setPromomessage("Not applicable on your profile.");
                        return;
                    }

                }

                if (Rdiscount_type == 1) {
                    var disc = (Number(discountamount) / 100) * Number(Rdiscount_percent);
                    if (Number(disc) > Number(RmaxdiscountAllowed)) {
                        disc = RmaxdiscountAllowed;
                    }
                    setcoupondicount(disc);
                    var totamount = Number(subtotalamount) - Number(coupondicount);
                    var gstamount = totamount - [totamount * (100 / (100 + 18))];
                    setGST(gstamount.toFixed(2));
                    // var finaltot = Number(totamount) + Number(gstamount);
                    var finaltot = Number(totamount);
                    settotalamount(finaltot.toFixed(2));
                }
                if (Rdiscount_type == 2) {
                    var disc = Rdiscount_value;
                    setcoupondicount(disc);
                    var totamount = Number(subtotalamount) - Number(coupondicount);
                    var gstamount = totamount - [totamount * (100 / (100 + 18))];
                    setGST(gstamount.toFixed(2));
                    // var finaltot = Number(totamount) + Number(gstamount);
                    var finaltot = Number(totamount);
                    settotalamount(finaltot.toFixed(2));
                }

            }

            //category
            if (Number(discount_on) == 2) {
                console.log("discount on Category  ", cat_id)
                var discountamount = 0.00;
                var flagd = true;
                var useremail = "";
                result.map((item, i) => {
                    useremail = item.user_id.email;
                    var rcvcatid = item.product_id.catid;
                    var rcvsellprice = item.product_id.sellprice
                    if (cat_id == rcvcatid) {
                        flagd = false;
                        discountamount = discountamount + Number(rcvsellprice);
                    }
                })
                console.log("Total discount subtotal", discountamount)

                if (flagd) {
                    setPromomessage("Not applicable on existing cart item.");
                    return;
                }
                if (usefor == 1) {
                    if (user_id != useremail) {
                        setPromomessage("Not applicable on your profile.");
                        return;
                    }

                }

                if (Rdiscount_type == 1) {

                    var disc = (Number(discountamount) / 100) * Number(Rdiscount_percent);
                    if (Number(disc) > Number(RmaxdiscountAllowed)) {
                        disc = RmaxdiscountAllowed;
                    }
                    setcoupondicount(disc);
                    var totamount = Number(subtotalamount) - Number(coupondicount);
                    var gstamount = totamount - [totamount * (100 / (100 + 18))];
                    setGST(gstamount.toFixed(2));
                    // var finaltot = Number(totamount) + Number(gstamount);
                    var finaltot = Number(totamount);
                    settotalamount(finaltot.toFixed(2));
                }
                if (Rdiscount_type == 2) {
                    var disc = Rdiscount_value;
                    setcoupondicount(disc);
                    var totamount = Number(subtotalamount) - Number(coupondicount);
                    var gstamount = totamount - [totamount * (100 / (100 + 18))];
                    setGST(gstamount.toFixed(2));
                    // var finaltot = Number(totamount) + Number(gstamount);
                    var finaltot = Number(totamount);
                    settotalamount(finaltot.toFixed(2));
                }

            }

            //sub categor
            if (Number(discount_on) == 3) {
                console.log("discount on subCategory  ", subCategory_id)
                var discountamount = 0.00;
                var flagd = true;
                var useremail = "";
                result.map((item, i) => {
                    useremail = item.user_id.email;
                    var rcvtypeid = item.product_id.type;
                    var rcvcatid = item.product_id.catid;
                    var rcvsubcategory = item.product_id.subcategory.id;
                    var rcvproductid = item.product_id.id;
                    var rcvsellprice = item.product_id.sellprice
                    if (subCategory_id == rcvsubcategory) {
                        flagd = false;
                        discountamount = discountamount + Number(rcvsellprice);
                    }
                })


                console.log("Total discount subtotal", discountamount)

                if (flagd) {
                    setPromomessage("Not applicable on existing cart item.");
                    return;
                }
                if (usefor == 1) {
                    if (user_id != useremail) {
                        setPromomessage("Not applicable on your profile.");
                        return;
                    }

                }

                if (Rdiscount_type == 1) {
                    var disc = (Number(discountamount) / 100) * Number(Rdiscount_percent);
                    if (Number(disc) > Number(RmaxdiscountAllowed)) {
                        disc = RmaxdiscountAllowed;
                    }
                    setcoupondicount(disc);
                    var totamount = Number(subtotalamount) - Number(coupondicount);
                    var gstamount = totamount - [totamount * (100 / (100 + 18))];
                    setGST(gstamount.toFixed(2));
                    // var finaltot = Number(totamount) + Number(gstamount);
                    var finaltot = Number(totamount);
                    settotalamount(finaltot.toFixed(2));
                }
                if (Rdiscount_type == 2) {
                    var disc = Rdiscount_value;
                    setcoupondicount(disc);
                    var totamount = Number(subtotalamount) - Number(coupondicount);
                    var gstamount = totamount - [totamount * (100 / (100 + 18))];
                    setGST(gstamount.toFixed(2));
                    // var finaltot = Number(totamount) + Number(gstamount);
                    var finaltot = Number(totamount);
                    settotalamount(finaltot.toFixed(2));
                }

            }
            // Product
            if (Number(discount_on) == 4) {
                console.log("discount on subCategory  ", subCategory_id)
                var discountamount = 0.00;
                var flagd = true;
                var useremail = "";
                result.map((item, i) => {
                    useremail = item.user_id.email;
                    var rcvproductid = item.product_id.id;
                    var rcvsellprice = item.product_id.sellprice
                    if (product_id == rcvproductid) {
                        flagd = false;
                        discountamount = discountamount + Number(rcvsellprice);
                    }
                })


                console.log("Total discount subtotal", discountamount)

                if (flagd) {
                    setPromomessage("Not applicable on existing cart item.");
                    return;
                }
                if (usefor == 1) {
                    if (user_id != useremail) {
                        setPromomessage("Not applicable on your profile.");
                        return;
                    }

                }

                if (Rdiscount_type == 1) {
                    var disc = (Number(discountamount) / 100) * Number(Rdiscount_percent);
                    if (Number(disc) > Number(RmaxdiscountAllowed)) {
                        disc = RmaxdiscountAllowed;
                    }
                    setcoupondicount(disc);
                    var totamount = Number(subtotalamount) - Number(coupondicount);
                    var gstamount = totamount - [totamount * (100 / (100 + 18))];
                    setGST(gstamount.toFixed(2));
                    // var finaltot = Number(totamount) + Number(gstamount);
                    var finaltot = Number(totamount);
                    settotalamount(finaltot.toFixed(2));
                }
                if (Rdiscount_type == 2) {
                    var disc = Rdiscount_value;
                    setcoupondicount(disc);
                    var totamount = Number(subtotalamount) - Number(coupondicount);
                    var gstamount = totamount - [totamount * (100 / (100 + 18))];
                    setGST(gstamount.toFixed(2));
                    // var finaltot = Number(totamount) + Number(gstamount);
                    var finaltot = Number(totamount);
                    settotalamount(finaltot.toFixed(2));
                }

            }

        }




    });

    // const setQtyN = (qty, prodid) => {


    //     if (parseInt(qty) < 1) {
    //         //setQty(1);

    //     }

    //     if (parseInt(qty) > 4) {
    //         // setQty(4);
    //     }
    //     var useridrcv = '';
    //     if (isSigninIn != true) {
    //         //navigate("/login");
    //         var idrcv = localStorage.getItem("tempuser");
    //         useridrcv = idrcv;
    //     }

    //     if (user_id != null) {
    //         useridrcv = user_id;

    //     }
    //     if (parseInt(qty) >= 1 && parseInt(qty) <= 4) {
    //         // setQty(e);
    //         console.log('prodid...', prodid)
    //         console.log('qty..', qty);
    //         console.log('user_id..', useridrcv);
    //         cartData.result = [];
    //         dispatch(createCart(useridrcv, prodid, qty, 2));


    //     }
    // }



    return (



        <>

            <div className="product_cartpopup">

                <div className="popup_container">
                    <div className="cart_tabel">
                        {cartData.result.length == 0 ? (
                            ""
                        ) : (
                            <>
                                <div >

                                    <Link to="#" className="closepopup" onClick={showChange} >
                                        Close

                                    </Link>
                                </div>

                                <div className="popcardheader">
                                    <div className="cardhead">
                                        Your Items

                                    </div>
                                    <div className="cardlink">
                                        <Link to="/cart" className="" state={{ coupondicountvalue: coupondicount, couponid: couponId }} >
                                            View Cart

                                        </Link>
                                    </div>
                                </div>
                                <table>

                                    <tbody>
                                        {result &&
                                            result.map((item, i) => (
                                                <tr key={item.product_id.id}>
                                                    <td>
                                                        <img
                                                            src={`${process.env.REACT_APP_API_PATH}${item.product_id.image}`}
                                                            width="60"
                                                            height="60" />
                                                    </td>
                                                    <td className="popopprodname">
                                                        <Link to={`/product/${item.product_id.slug}`} onClick={showChange}>
                                                            <h3>{item.product_id.name}</h3>
                                                        </Link>
                                                    </td>

                                                    {item.product_id.available_inventory - item.quantity >
                                                        0 ? (
                                                        <td className="popopprodqty">

                                                            <span>

                                                                {item.quantity}

                                                            </span>
                                                        </td>

                                                    ) : (
                                                        <td>
                                                            <span className="stock_cart">Out Of Stock</span>
                                                            <Link to="/" onClick={showChange}>Continue Shoping</Link>
                                                            <br></br>
                                                            <button
                                                                onClick={() => removeToCartHandler(item.id)}
                                                            >
                                                                X
                                                            </button>
                                                        </td>

                                                    )}

                                                    <td className="popopprodprice">
                                                        <span className="sale">


                                                            {"₹ " + item.product_id.sellprice}
                                                        </span>

                                                    </td>

                                                    <td>
                                                        <button className="removebutton"
                                                            onClick={() => removeToCartHandler(item.id)}
                                                        >
                                                            X
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>

                                </table></>
                        )}
                    </div>
                </div>
                {/* <div>
                <Link to="/cart" className="btn btn_checkout-popup " state={{ coupondicountvalue: coupondicount, couponid: couponId }} >
                    Go To Cart

                </Link>

            </div> */}
                <div>
                    <Link to="/shipping" className="btn btn_checkout-popup " state={{ coupondicountvalue: coupondicount, couponid: couponId }}>
                        Checkout

                    </Link>

                </div>
            </div>
        </>

    );

}
