import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import icon_1 from "../../image/icon_1.png";
import icon_2 from "../../image/icon_2.png";
import icon_3 from "../../image/icon_3.png";
import icon_4 from "../../image/icon_4.png";
import icon_5 from "../../image/icon_5.png";
import { loadAllSatisfaction } from "../../redux/action/HomeAction";

export default function Satisfaction(satisifaction) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadAllSatisfaction());
  }, []);

  const satis = useSelector((state) => state.Home.result);
  // console.log("satis",satis)

  return (
    <section className="section satisfaction" id="Satisifaction">
      <div className="container">
        <div className="satisfaction_mnain">
          {satis.slice(0, 1).map((satis) => {
            return (
              <h2 className="title" key={satis.id}>
                <span>{satis.heding}</span>
              </h2>
            );
          })}
          <ul>
            {satis.map((satis) => {
              return (
                <li key={satis.id}>
                  <div className="imggrouph">
                    <img
                      src={process.env.REACT_APP_API_PATH + satis.icon}

                    />
                  </div>
                  <h4>{satis.content}</h4>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </section>
  );
}
