import React, { useEffect } from 'react';
import InnerOfferBanner from "../component/offer/InnerOfferBanner";
import OfferMain from "../component/offer/OfferMain";

export default function Offer() {
  useEffect(() => {
    document.getElementById("maintittle").innerHTML = "Roomba® Sale, Robot Vacuum and Robot Mop Deals | iRobot India";
    document.getElementById("description").setAttribute("content", "Save money with the iRobot sale and offer on Roomba robot vacuums and Braava mops. Check out our great deals on robot vacuums and robot mops.");
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <InnerOfferBanner />
      <OfferMain />
    </>
  )
}
