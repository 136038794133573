import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleLeft } from "@fortawesome/fontawesome-free-solid";
import series_p_1 from "../../image/series_p_1.png";
import Message from "../Shared/Message";
import { loadPromoCodeByCode } from "../../redux/action/AdditionalAction";

import { deleteCart, loadAllCart } from "../../redux/action/CartAction";
import { createCart } from "../../redux/action/CartAction";

import Select from 'react-select';

import {
  loadAllPoints
} from "../../redux/action/LoyaltyPointsAction";
import { calculateNewValue } from "@testing-library/user-event/dist/utils";

export default function ProductCart() {
  let navigate = useNavigate();

  let dispatch = useDispatch();
  const [TotolGst, setGST] = useState(0.00);
  const [coupondicount, setcoupondicount] = useState(0.00);
  const [promocode, setPromocode] = useState("");
  const [applieedpromocode, setapplieedpromocode] = useState("");

  const [promomessage, setPromomessage] = useState("");


  const [couponId, setCoupanId] = useState("");

  const [offerdicount, setofferdicount] = useState(0.00);

  const [shippingCharges, setshippingCharges] = useState(0.00);
  const [totalamount, settotalamount] = useState(0.00);
  const [subtotalamount, setsubtotalamount] = useState(0.00);

  const [temptotal, settemptotal] = useState(0.00);


  const [loyalitypointsrcv, setLoyalitypoints] = useState(0.00);
  const [loyalitypointused, setloyalitypointused] = useState(0.00);


  const [promoCodeSingleData, setpromoCodeSingleData] = useState();







  useEffect(() => {
    document.getElementById("maintittle").innerHTML = "Cart | iRobot® India";
    document.getElementById("description").setAttribute("content", "Your shopping cart part of our secure checkout process.");

    window.scrollTo(0, 0);
  }, []);

  






  let user = useSelector((state) => state.AuthReducer);

  let { isSigninIn, userResult } = user;

  var user_id = userResult.id;



  let pointsData = useSelector((state) => state.LoyaltyPoints);
  let { loyaltyPoints } = pointsData;




  let cartData = useSelector((state) => state.Cart);
  

  let { loading, error, result, tmsg } = cartData;
  const removeToCartHandler = (id) => {
    setshippingCharges(0);


    console.log("removeToCartHandler");
    if (isSigninIn != true) {
      //navigate("/login");
      var idrcv = localStorage.getItem("tempuser");

      let data = {
        id: id,
        user_id: idrcv,
      };
      dispatch(deleteCart(data));
    }

    if (user_id != null) {
      let data = {
        id: id,
        user_id: user_id,
      };
      dispatch(deleteCart(data));
    }
  };

  const addShippingCharges=()=>{
    var shipcharge = "400";

    setshippingCharges(0.00);
    console.log(result);
  if(result){
    result.forEach((data) => {
      console.log(data);
      console.log('fff' + data.product_id.type)
      if (data.product_id.type == 2) {
        setshippingCharges(shipcharge);
        console.log(shippingCharges);

      }
    })
  }
  };

  useEffect(() => {

    var shipcharge = "400";

    setshippingCharges(0.00);
    console.log(result);
  if(result){
    result.forEach((data) => {
      console.log(data);
      console.log('fff' + data.product_id.type)
      if (data.product_id.type == 2) {
        setshippingCharges(shipcharge);
        console.log(shippingCharges);

      }
    })
  }

  }, []);

  const usepointclick = (e) => {


    var perc = (5 / 100) * totalamount;
    if (perc < loyalitypointsrcv) {
      setloyalitypointused(perc);
      var totfinal = temptotal - perc;
      settotalamount(totfinal.toFixed(2));
    }
    else {
      setloyalitypointused(loyalitypointsrcv);
      var totfinal = temptotal - loyalitypointsrcv;
      settotalamount(totfinal.toFixed(2));
    }

    console.log('usepointclick...');


  }

  const removepoints = (e) => {

    console.log('removepoints...' + temptotal);

    setloyalitypointused(0);
    settotalamount(temptotal);

  }




  const PromocodehandleClick = (e) => {

    setpromoCodeSingleData(false);
    var promocodercv = promocode;
    dispatch(loadPromoCodeByCode(promocodercv));
    setPromocode('');
  }

  const removepromocode = (e) => {
    setpromoCodeSingleData(false);
  }


  useEffect(() => {

    console.log('tmsg.. ', tmsg);
    if (tmsg == "home") {
      window.location.href = "/";
    }
  }, [tmsg]);


  const { promoCodeSingle } = useSelector((state) => state.Additional);



  useEffect(() => {


    setcoupondicount(0.00);
    setPromomessage('');
    setapplieedpromocode('');

    if (isSigninIn != true) {
      //navigate("/login");
      var idrcv = localStorage.getItem("tempuser");
      dispatch(loadAllCart(idrcv));
    }

    if (user_id != null && user_id != undefined) {

      dispatch(loadAllCart(user_id));
      dispatch(loadAllPoints(user_id));

    } else {
      var idrcv = localStorage.getItem("tempuser");
      dispatch(loadAllCart(idrcv));
    }

  }, [user_id]);

  useEffect(() => {

    //setpromoCodeSingleData(false);
    cartcalculate();


  }, [result]);


  useEffect(() => {

    console.log("promoCodeSingle");

    setpromoCodeSingleData(promoCodeSingle);

  }, [promoCodeSingle]);

  useEffect(() => {

    console.log("promoCodeSingleData");

    cartcalculate();


  }, [promoCodeSingleData]);


  useEffect(() => {

    console.log("loyaltyPoints")
    if (loyaltyPoints.cr != null && loyaltyPoints.cr != '') {
      var rcvp = loyaltyPoints.cr.points__sum - loyaltyPoints.db.points__sum;
      setLoyalitypoints(rcvp);
    }


  }, [loyaltyPoints]);



  const setQtyN = (e, prodid,price,  sellprice) => {
    const p = price - sellprice;
    const discount = ((p/sellprice)*100).toFixed(2);
    const finalP = price == ""? sellprice : price;



    // console.log('qty' + e.value);
    var qty = e.value;
    setSelectedOption(e);
    // setQty(e.value);





    var useridrcv = '';
    if (isSigninIn != true) {
      //navigate("/login");
      var idrcv = localStorage.getItem("tempuser");
      useridrcv = idrcv;
    }

    if (user_id != null) {
      useridrcv = user_id;

    }
    if (parseInt(qty) >= 1 && parseInt(qty) <= 4) {
      // setQty(e);
      console.log('prodid...', prodid)
      console.log('qty..', qty);
      console.log('user_id..', useridrcv);
      cartData.result = [];
      dispatch(createCart(useridrcv, prodid, qty, 2,finalP,discount, sellprice));

    }
  }
  const data = [
    {
      value: 1,
      label: "1"
    },
    {
      value: 2,
      label: "2"
    },
    {
      value: 3,
      label: "3"
    },
    {
      value: 4,
      label: "4"
    },

  ];


  function cartcalculate() {

    setloyalitypointused(0);

    if (result.length > 0) {

      var tot = 0.00;
      result.forEach((data) => {
        tot = tot + data.quantity * data.product_id.sellprice
      })
      var gstamount = tot - [tot * (100 / (100 + 18))];
      addShippingCharges();
      setsubtotalamount(tot.toFixed(2))
      setGST(gstamount.toFixed(2));
      // var finaltot = Number(tot) + Number(gstamount);
      var finaltot = Number(tot) + Number(shippingCharges);

      settemptotal(finaltot.toFixed(2));
      settotalamount(finaltot.toFixed(2));

      setcoupondicount(0.00);
      setPromomessage('');
      setapplieedpromocode('');

      //setPromocode('')
      console.log('cartcalculate.');

      



      if (promoCodeSingleData) {



        setCoupanId(promoCodeSingleData.id);
        setapplieedpromocode(promoCodeSingleData.code);

        console.log('code us..', promoCodeSingleData)

        var Rcoupan_type = promoCodeSingleData.coupan_type;
        var Rcode = promoCodeSingleData.code;
        var Rdiscount_type = promoCodeSingleData.discount_type;
        //var coupan_type=promoCode.coupan_type;
        var Rdiscount_percent = promoCodeSingleData.discount_percent;
        var Rdiscount_value = promoCodeSingleData.discount_value;
        var RmaxdiscountAllowed = promoCodeSingleData.maxdiscountAllowed;
        var Rmin_purchase = promoCodeSingleData.min_purchase;
        var Rvalid_from = promoCodeSingleData.valid_from;
        var valid_to = promoCodeSingleData.valid_to;


        var discount_on = promoCodeSingleData.discount_on;
        var type_id = promoCodeSingleData.type_id;
        var cat_id = promoCodeSingleData.cat_id;
        var subCategory_id = promoCodeSingleData.subCategory_id;
        var product_id = promoCodeSingleData.product_id;
        var usefor = promoCodeSingleData.usefor;
        var user_id = promoCodeSingleData.user_id;
        var use_time = promoCodeSingleData.use_time;



        if (Number(subtotalamount) < Number(Rmin_purchase)) {
          setPromomessage("Minimum " + Rmin_purchase + " is required to use this code");
          return
        }
        var datetoday = new Date(new Date().toISOString().slice(0, 10)).getTime();
        var datevalidfrom = new Date(Rvalid_from).getTime();
        var datevalidto = new Date(valid_to).getTime();

        if (!(datetoday >= datevalidfrom && datetoday <= datevalidto)) {
          setPromomessage("Code is valid From " + Rvalid_from + " to " + valid_to);
          return
        }

        //discounall
        if (Number(discount_on) == 0) {
          console.log("discount on all  ")

          if (usefor == 1) {
            if (user_id != useremail) {
              setPromomessage("Not applicable on your profile.");
              return;
            }

          }

          if (Rdiscount_type == 1) {
            var disc = (Number(subtotalamount) / 100) * Number(Rdiscount_percent);
            if (Number(disc) > Number(RmaxdiscountAllowed)) {
              disc = RmaxdiscountAllowed;
            }
            setcoupondicount(disc);
            //var totamount = Number(subtotalamount) - Number(coupondicount);
            var totamount = Number(subtotalamount) - Number(disc);
            var gstamount = totamount - [totamount * (100 / (100 + 18))];
            setGST(gstamount.toFixed(2));
            // var finaltot = Number(totamount) + Number(gstamount);
            var finaltot = Number(totamount);
            console.log("discount..  " + finaltot)

            settemptotal(finaltot.toFixed(2));
            settotalamount(finaltot.toFixed(2));

          }
          if (Rdiscount_type == 2) {
            var disc = Rdiscount_value;
            setcoupondicount(disc);
            //var totamount = Number(subtotalamount) - Number(coupondicount);
            var totamount = Number(subtotalamount) - Number(disc);
            var gstamount = totamount - [totamount * (100 / (100 + 18))];
            setGST(gstamount.toFixed(2));
            // var finaltot = Number(totamount) + Number(gstamount);
            var finaltot = Number(totamount);
            settemptotal(finaltot.toFixed(2));

            settotalamount(finaltot.toFixed(2));
          }

        }
        //cat type main catgeory
        if (Number(discount_on) == 1) {
          console.log("discount on CategoryType  ", type_id)
          var discountamount = 0.00;
          var flagd = true;
          var useremail = "";
          result.map((item, i) => {
            useremail = item.user_id.email;
            var rcvtypeid = item.product_id.type;
            var rcvsellprice = item.product_id.sellprice
            console.log("discount on rcvtypeid  ", rcvtypeid)

            if (type_id == rcvtypeid) {
              console.log("discount on flag ")

              flagd = false;
              discountamount = discountamount + Number(rcvsellprice);
            }

            // if (type_id == rcvtypeid) {
            //   console.log("discount on flag ")

            //   flagd = false;
            //   discountamount = discountamount + Number(rcvsellprice);
            // }

          })


          if (flagd || (discountamount == 0)) {

            setPromomessage("Not applicable on existing cart item.");
            return;
          }
          if (usefor == 1) {
            if (user_id != useremail) {
              setPromomessage("Not applicable on your profile.");
              return;
            }

          }

          if (Rdiscount_type == 1) {
            var disc = (Number(discountamount) / 100) * Number(Rdiscount_percent);
            if (Number(disc) > Number(RmaxdiscountAllowed)) {
              disc = RmaxdiscountAllowed;
            }
            setcoupondicount(disc);
            //var totamount = Number(subtotalamount) - Number(coupondicount);
            var totamount = Number(subtotalamount) - Number(disc);

            var gstamount = totamount - [totamount * (100 / (100 + 18))];
            setGST(gstamount.toFixed(2));
            // var finaltot = Number(totamount) + Number(gstamount);
            var finaltot = Number(totamount);
            settemptotal(finaltot.toFixed(2));

            settotalamount(finaltot.toFixed(2));
          }
          if (Rdiscount_type == 2) {
            var disc = Rdiscount_value;
            setcoupondicount(disc);
            //var totamount = Number(subtotalamount) - Number(coupondicount);
            var totamount = Number(subtotalamount) - Number(disc);

            var gstamount = totamount - [totamount * (100 / (100 + 18))];
            setGST(gstamount.toFixed(2));
            // var finaltot = Number(totamount) + Number(gstamount);
            var finaltot = Number(totamount);
            settemptotal(finaltot.toFixed(2));

            settotalamount(finaltot.toFixed(2));
          }

        }

        //category
        if (Number(discount_on) == 2) {
          console.log("discount on Category  ", cat_id)
          var discountamount = 0.00;
          var flagd = false;
          var flagdnon = false;

          var useremail = "";

          
          result.map((item, i) => {
            useremail = item.user_id.email;
            var rcvcatid = item.product_id.catid;
            var rcvsellprice = item.product_id.sellprice
            if (cat_id == rcvcatid) {
              flagd = false;
              console.log("Total discount flagd", discountamount)

              discountamount = discountamount + Number(rcvsellprice);
            }
            // if (cat_id != rcvcatid) {
            //   flagdnon = true;
            // }
          })
          console.log("Total discount subtotal", discountamount)

          if (flagd || (discountamount == 0)) {
            setPromomessage("Not applicable on existing cart item.");
            return;
          }
          if (usefor == 1) {
            if (user_id != useremail) {
              setPromomessage("Not applicable on your profile.");
              return;
            }

          }

          if (Rdiscount_type == 1) {

            var disc = (Number(discountamount) / 100) * Number(Rdiscount_percent);
            if (Number(disc) > Number(RmaxdiscountAllowed)) {
              disc = RmaxdiscountAllowed;
            }
            setcoupondicount(disc);
            // var totamount = Number(subtotalamount) - Number(coupondicount);
            var totamount = Number(subtotalamount) - Number(disc);

            var gstamount = totamount - [totamount * (100 / (100 + 18))];
            setGST(gstamount.toFixed(2));
            // var finaltot = Number(totamount) + Number(gstamount);
            var finaltot = Number(totamount);
            settemptotal(finaltot.toFixed(2));
            settotalamount(finaltot.toFixed(2));
          }
          if (Rdiscount_type == 2) {
            var disc = Rdiscount_value;
            setcoupondicount(disc);
            // var totamount = Number(subtotalamount) - Number(coupondicount);
            var totamount = Number(subtotalamount) - Number(disc);

            var gstamount = totamount - [totamount * (100 / (100 + 18))];
            setGST(gstamount.toFixed(2));
            // var finaltot = Number(totamount) + Number(gstamount);
            var finaltot = Number(totamount);
            settemptotal(finaltot.toFixed(2));
            settotalamount(finaltot.toFixed(2));
          }

        }

        //sub categor
        if (Number(discount_on) == 3) {
          console.log("discount on subCategory  ", subCategory_id)
          var discountamount = 0.00;
          var flagd = true;
          var flagdnon = false;

          var useremail = "";
          result.map((item, i) => {
            useremail = item.user_id.email;
            var rcvtypeid = item.product_id.type;
            var rcvcatid = item.product_id.catid;
            var rcvsubcategory = item.product_id.subcategory.id;
            var rcvproductid = item.product_id.id;
            var rcvsellprice = item.product_id.sellprice
            if (subCategory_id == rcvsubcategory) {
              flagd = false;
              discountamount = discountamount + Number(rcvsellprice);
            }
            // if (subCategory_id != rcvsubcategory) {
            //   flagdnon = true;
            // }
          })


          console.log("Total discount subtotal", discountamount)

          if (flagd || (discountamount == 0)) {
            setPromomessage("Not applicable on existing cart item.");
            return;
          }
          if (usefor == 1) {
            if (user_id != useremail) {
              setPromomessage("Not applicable on your profile.");
              return;
            }

          }

          if (Rdiscount_type == 1) {
            var disc = (Number(discountamount) / 100) * Number(Rdiscount_percent);
            if (Number(disc) > Number(RmaxdiscountAllowed)) {
              disc = RmaxdiscountAllowed;
            }
            setcoupondicount(disc);
            // var totamount = Number(subtotalamount) - Number(coupondicount);
            var totamount = Number(subtotalamount) - Number(disc);

            var gstamount = totamount - [totamount * (100 / (100 + 18))];
            setGST(gstamount.toFixed(2));
            // var finaltot = Number(totamount) + Number(gstamount);
            var finaltot = Number(totamount);
            settemptotal(finaltot.toFixed(2));
            settotalamount(finaltot.toFixed(2));
          }
          if (Rdiscount_type == 2) {
            var disc = Rdiscount_value;
            setcoupondicount(disc);
            // var totamount = Number(subtotalamount) - Number(coupondicount);
            var totamount = Number(subtotalamount) - Number(disc);

            var gstamount = totamount - [totamount * (100 / (100 + 18))];
            setGST(gstamount.toFixed(2));
            // var finaltot = Number(totamount) + Number(gstamount);
            var finaltot = Number(totamount);
            settemptotal(finaltot.toFixed(2));
            settotalamount(finaltot.toFixed(2));
          }

        }
        // Product
        if (Number(discount_on) == 4) {
          console.log("discount on product  ", product_id)

          var discountamount = 0.00;
          var flagd = true;
          var flagdnon = false;

          var useremail = "";
          result.map((item, i) => {
            useremail = item.user_id.email;
            var rcvproductid = item.product_id.id;
            var rcvsellprice = item.product_id.sellprice
            console.log("discount on ...  ", product_id + "," + rcvproductid);

            if (product_id == rcvproductid) {
              flagd = false;
              //setPromomessage("Not applicable on existing cart item.");
              discountamount = discountamount + Number(rcvsellprice);
            }
            if (product_id != rcvproductid) {
              flagdnon = true;
            }
          })


          console.log("Total discount subtotal", discountamount)

          if (flagd || (discountamount == 0)) {
            setPromomessage("Not applicable on existing cart item.");
            return;
          }
          if (usefor == 1) {
            if (user_id != useremail) {
              setPromomessage("Not applicable on your profile.");
              return;
            }

          }

          if (Rdiscount_type == 1) {
            var disc = (Number(discountamount) / 100) * Number(Rdiscount_percent);
            if (Number(disc) > Number(RmaxdiscountAllowed)) {
              disc = RmaxdiscountAllowed;
            }
            setcoupondicount(disc);
            //var totamount = Number(subtotalamount) - Number(coupondicount);
            var totamount = Number(subtotalamount) - Number(disc);

            var gstamount = totamount - [totamount * (100 / (100 + 18))];
            setGST(gstamount.toFixed(2));
            // var finaltot = Number(totamount) + Number(gstamount);
            var finaltot = Number(totamount);
            settemptotal(finaltot.toFixed(2));
            settotalamount(finaltot.toFixed(2));
          }
          if (Rdiscount_type == 2) {
            var disc = Rdiscount_value;
            setcoupondicount(disc);
            // var totamount = Number(subtotalamount) - Number(coupondicount);
            var totamount = Number(subtotalamount) - Number(disc);
            var gstamount = totamount - [totamount * (100 / (100 + 18))];
            setGST(gstamount.toFixed(2));
            // var finaltot = Number(totamount) + Number(gstamount);
            var finaltot = Number(totamount);
            settemptotal(finaltot.toFixed(2));
            settotalamount(finaltot.toFixed(2));
          }

        }

      }

    }

  }
  const [selectedOption, setSelectedOption] = useState(data[0]);

  return (
    <section
      className="section"
      style={{ paddingBottom: "70px", background: "#fff" }}
    >
      <div className="empty_cart">
        <div className="container">
          <div className="freeShipping">
            <p className="subheading">Free Shipping</p>
            <p>
              <small>on all products except accessories</small>
            </p>
          </div>

          <div className="back_to_shop">
            <Link to="/home">
              <FontAwesomeIcon icon={faAngleDoubleLeft} /> Continue Shopping
            </Link>
          </div>
        </div>
      </div>

      <div className="product_cart">
        <div className="container">
          <div className="cart_tabel">
            {cartData.result.length == 0 ? (
              ""
            ) : (
              <>
                <div className="cart_wrap">

                  <div className="cartitemwrap">
                    <div className="wrapcartheading">
                      <p className="yourcart"> Your Cart</p>
                    </div>
                    {result &&
                      result.map((item, i) => (
                        <div className="cart-iitem-row" key={item.product_id.id}>
                          <div className="cart-image">
                            <img
                              src={`${process.env.REACT_APP_API_PATH}${item.product_id.image}`}
                              width="100"
                              height="100" />
                          </div>
                          <div className="cart-product">
                            <Link to={`/product/${item.product_id.slug}`}>
                              <h3>{item.product_id.name}</h3>
                              {/* 
                              <span>{item.product_id.item_no}</span>
                              */}
                            </Link>
                          </div>
                          <div className="series_price">
                            <p className="regular">
                              Price <br></br>
                              <br></br>
                              {item.product_id.price != null && item.product_id.price != "" ? (
                                <strike>{"₹" + item.product_id.price}</strike>) : ("")}
                              <span className="sale">


                                {"₹" + item.product_id.sellprice}
                              </span>
                            </p>
                          </div>

                          {item.product_id.available_inventory - item.quantity >
                            0 ? (
                            <div className="cart-qty">

                              {/*
                              <input
                                required
                                className="form-control quantity"
                                min="1"
                                type="number"
                                name="qty"
                                value={item.quantity}

                                onChange={(e) => setQtyN(e.target.value, item.product_id.id)} />
                            */ }
                              Qty <br></br>
                              <br></br>
                              <Select isSearchable={false}
                                className="quantitydropdown"
                                placeholder="Qty"
                                value={data[item.quantity - 1]} // set selected value
                                options={data} // set list of the data
                                onChange={(e) => setQtyN(e, item.product_id.id,item.product_id.price,item.product_id.sellprice)}// assign onChange function
                              />



                            </div>


                          ) : (
                            <div className="cart-qty">

                              <span className="stock_cart">Out Of Stock</span>
                              <Link to="/">Continue Shoping</Link>
                              <br></br>
                              <button
                                onClick={() => removeToCartHandler(item.id)}
                              >
                                x
                              </button>
                            </div>

                          )}

                          <div className="cart_product_total">
                            Total <br></br>
                            <br></br>
                            <strong>
                              {"₹" + item.product_id.sellprice * item.quantity}{" "}
                            </strong>
                          </div>
                          <div className="cartremove">
                            <br></br>
                            <br></br>
                            <button className="removebutton"
                              onClick={() => removeToCartHandler(item.id)}
                            >
                              x
                            </button>
                          </div>
                        </div>
                      ))}
                  </div>
                  <div className="cartdetailsmwrap">
                    <div className="wrapcartheading">
                      <p className="ordersummerytext"> Need help? Call <span className="numbergreen">1800-200-3355</span></p>
                    </div>


                    <div className="cartdetailsdiv">

                      <div className="promocode">
                        <div className="promo">

                          {loyalitypointsrcv != '' && loyalitypointsrcv > 0 ? (
                            <><label className="applieedpromocode">Rewards Point: {loyalitypointsrcv}</label><button type="submit" className="btn aply_promo" onClick={usepointclick}>
                              Use  Point
                            </button></>
                          ) : ('')}
                        </div>





                        <div className="promo">
                          <input
                            type="text"
                            laceholder="Enter Promo Code"
                            required
                            value={promocode}
                            name="promocode"
                            onChange={(e) => setPromocode(e.target.value)} />
                          <button type="submit" className="btn aply_promo" onClick={PromocodehandleClick}>
                            Apply Coupon
                          </button>
                          <br></br>
                          <br></br>

                          <label> {promomessage}</label>
                        </div>
                        <br></br>
                        {applieedpromocode != '' ? (<div className="promo">
                          <label className="applieedpromocode"> {applieedpromocode}</label>

                          <button type="submit" className="btn aply_promo" onClick={removepromocode}>
                            Remove Coupon
                          </button>
                          <br></br>
                        </div>) : ('')}

                        {loyalitypointused != '' && loyalitypointused > 0 ? (<div className="promo">
                          <label className="applieedpromocode"> {loyalitypointused} Point using in order</label>

                          <button type="submit" className="btn aply_promo" onClick={removepoints}>
                            Remove Points
                          </button>
                          <br></br>
                        </div>) : ('')}

                      </div>
                      <div className="carcalculation">
                        <table>


                          <tfoot>

                            <tr>

                              <td colSpan={3} className="cart_subcart">

                                <ul>
                                  <li>
                                    <span>Order Value</span>
                                    <span>
                                      <strong>

                                        ₹ {subtotalamount}
                                      </strong>
                                    </span>
                                  </li>
                                  <li>
                                    <span>GST <span className="gsttext">(included in the total amount)</span> </span>


                                    <span>₹ {TotolGst}</span>
                                  </li>
                                  <li>
                                    <span>Coupon Discount</span>
                                    <span>- ₹ {coupondicount}</span>
                                  </li>
                                  <li>
                                    <span>Offer Discount</span>
                                    <span>- ₹ {offerdicount}</span>
                                  </li>
                                  <li>
                                    <span>Rewards Point</span>
                                    <span>- ₹ {loyalitypointused}</span>
                                  </li>




                                  <li>
                                    <span>Shipping Charges</span>
                                    <span>₹ {shippingCharges}</span>
                                  </li>
                                  {/* <li>
<span>Sales Tax</span>
<span>(₹ 0.00)*</span>
</li> */}
                                  <li className="subtototal">
                                    <span>Total Amount</span>
                                    <span>
                                      ₹
                                      {totalamount}
                                    </span>
                                    {/* 
                                    <p>
                                      <small>
                                        GST is included in the total amount
                                      </small>
                                    </p>
                                    */}
                                  </li>
                                </ul>
                                <Link to="/shipping" className="btn btn_checkout" state={{ coupondicountvalue: coupondicount, couponid: couponId, loyalitypointused: loyalitypointused }}>
                                  Checkout

                                </Link>

                              </td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

              </>

            )}
          </div>

        </div>
      </div>
    </section>
  );
}
