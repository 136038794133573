import {
  Find_ALL_PRODUCTLIST_FAIL,
  Find_ALL_PRODUCTLIST_REQUEST,
  Find_ALL_PRODUCTLIST_SUCCESS,
  Find_ALL_OFFER_PRODUCTLIST_FAIL,
  Find_ALL_OFFER_PRODUCTLIST_REQUEST,
  Find_ALL_OFFER_PRODUCTLIST_SUCCESS,
} from "../constants/ProductListingConstant";
import axios from "axios";
import { toast } from "react-toastify";

const loadAllProductList = (id) => {
  return function (dispatch) {
    dispatch({
      type: Find_ALL_PRODUCTLIST_REQUEST,
      payload: true,
    });
    let OPTION = {
      //url: `${process.env.REACT_APP_API_PATH}/api/v1/product-subcatid/${id}/`,
      url: `${process.env.REACT_APP_API_PATH}/api/v1/product-subcatid-n/${id}/`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    axios(OPTION)
      .then((res) => {
        dispatch(loadAllProductListPre(res.data));
      })
      .catch((error) => {
        dispatch({
          type: Find_ALL_PRODUCTLIST_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
      });
  };
};
export const loadAllProductListPre = (data) => {
  return {
    type: Find_ALL_PRODUCTLIST_SUCCESS,
    result: data,
    payload: false,
    mas: "SUCCESS",
  };
};

const loadAllOfferProductList = (id) => {
  return function (dispatch) {
    dispatch({
      type: Find_ALL_OFFER_PRODUCTLIST_REQUEST,
      payload: true,
    });
    let OPTION = {
      // url:`${process.env.REACT_APP_API_PATH}/api/v1/admin-product/${id}/`,
      url: `${process.env.REACT_APP_API_PATH}/api/v1/offer-product/`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    axios(OPTION)
      .then((res) => {
        dispatch(loadAllOfferProductListPre(res.data));
      })
      .catch((error) => {
        dispatch({
          type: Find_ALL_OFFER_PRODUCTLIST_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
      });
  };
};
export const loadAllOfferProductListPre = (data) => {
  return {
    type: Find_ALL_OFFER_PRODUCTLIST_SUCCESS,
    result: data,
    payload: false,
    mas: "SUCCESS",
  };
};

export { loadAllProductList, loadAllOfferProductList };
