import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadAllcategoriesContent } from "../../redux/action/StaticAction";
import { useParams } from "react-router-dom";
import caregory_banner from "../../image/caregory_banner.jpg";
import { isEmptyObject } from "jquery";

export default function Inner_banner() {
  const [catproduct, setCatproduct] = useState('');
  const [bannerData, setbannerData] = useState([]);


  const dispatch = useDispatch();
  let { id } = useParams();


  const banner = useSelector((state) => state.Static.categoriesdata);
  //let bannerData = [];
  //console.log('bannern..', banner)

  useEffect(() => {

    dispatch(loadAllcategoriesContent(id));
    if (banner != null) {
      //console.log('banner..', banner)
      //console.log('bannernn..', banner)

      // setCatproduct(banner[0]?.id);
      //bannerData = banner[0];
      //setbannerData(banner[0]);

    }
  }, id);
  useEffect(() => {

    if (banner != null && !isEmptyObject(banner)) {
      console.log('bannern..', banner)

      setCatproduct(banner[0]?.id);
      //bannerData = banner[0];
      setbannerData(banner[0]);

    }
  },);

  // [banner, id]);


  // console.log("bannerData",bannerData)

  if (bannerData) {
    var banner_id = bannerData.id
    var banner_image = bannerData.top_banner
    var banner_heading = bannerData.top_banner_heading
    var banner_content = bannerData.top_banner_content

    // console.log('banner_id',banner_id);
  }
  //{/*<img src={caregory_banner} alt="" /> */ } 
  return (
    <>
      <section className="inner_banner" key={banner_id}>
        {banner_image == null ? (
          ""
        ) : (
          <img src={`${process.env.REACT_APP_API_PATH}${banner_image}`} />
        )}
        <div className="container-fluid">
          <div className="inner">
            <h2 className="title">{banner_heading}</h2>
            <p className="inner_p">{banner_content}</p>
          </div>
        </div>
      </section>
    </>
  );

}
