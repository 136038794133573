import {
  CREATE_REGISTER_ROBOT_FAIL,
  CREATE_REGISTER_ROBOT_REQUEST,
  CREATE_REGISTER_ROBOT_SUCCESS,
  Find_ALL_REGISTER_ROBOT_FAIL,
  Find_ALL_REGISTER_ROBOT_REQUEST,
  Find_ALL_REGISTER_ROBOT_SUCCESS,
  Find_REGISTER_ROBOT_DETAIL_FAIL,
  Find_REGISTER_ROBOT_DETAIL_REQUEST,
  Find_REGISTER_ROBOT_DETAIL_SUCCESS,
  REMOVE_REGISTER_ROBOT_FAIL,
  REMOVE_REGISTER_ROBOT_REQUEST,
  REMOVE_REGISTER_ROBOT_SUCCESS,
  UPDATE_REGISTER_ROBOT_FAIL,
  UPDATE_REGISTER_ROBOT_REQUEST,
  UPDATE_REGISTER_ROBOT_SUCCESS,
} from "../constants/RegisterRobotConstant";

const initialState = {
  loading: false,
  action: "Register Robot",
  result: [],
  response: {},
  singledata: [],
  msg: "",
  error: "",
};

const RegisterRobotReduser = (state = initialState, action) => {
  switch (action.type) {
    case Find_ALL_REGISTER_ROBOT_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case Find_ALL_REGISTER_ROBOT_SUCCESS:
      return {
        ...state,
        result: action.result.data,
        loading: action.payload,
        msg: action.msg,
      };
    case Find_ALL_REGISTER_ROBOT_FAIL:
      return {
        ...state,
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };

    case Find_REGISTER_ROBOT_DETAIL_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case Find_REGISTER_ROBOT_DETAIL_SUCCESS:
      return {
        ...state,
        singledata: action.result.data,
        loading: action.payload,
        msg: action.msg,
      };
    case Find_REGISTER_ROBOT_DETAIL_FAIL:
      return {
        ...state,
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };

    case REMOVE_REGISTER_ROBOT_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case REMOVE_REGISTER_ROBOT_SUCCESS:
      return {
        ...state,
        result: state.result.filter(
          (item) => item.id !== action.result.data.id
        ),
        response: action.result.msg,
        loading: action.payload,
        msg: action.msg,
      };
    case REMOVE_REGISTER_ROBOT_FAIL:
      return {
        ...state,
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };

    case CREATE_REGISTER_ROBOT_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case CREATE_REGISTER_ROBOT_SUCCESS:
      return {
        ...state,
        result: state.result.concat(action.result.data),
        response: action.result.msg,
        loading: action.payload,
        msg: action.msg,
      };
    case CREATE_REGISTER_ROBOT_FAIL:
      return {
        ...state,
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };

    case UPDATE_REGISTER_ROBOT_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case UPDATE_REGISTER_ROBOT_SUCCESS:
      return {
        ...state,
        result: state.result.map((item) =>
          item.id === action.result.data.id ? action.result.data : item
        ),
        response: action.result.msg,
        singledata: [],
        loading: action.payload,
        msg: action.msg,
      };
    case UPDATE_REGISTER_ROBOT_FAIL:
      return {
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };

    default:
      return state;
  }
};

export default RegisterRobotReduser;
