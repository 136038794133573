import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createAppointment } from "../redux/action/AdditionalAction";
import { loadAllCategory } from "../redux/action/CategoryAction";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../src/component/Shared/Loader";
import Calendar from 'react-calendar'
import Message from "../../src/component/share/Message";
import ReCAPTCHA from "react-google-recaptcha";
import spinner1 from "../image/spinner1.gif"
import Satisfaction from "../component/home/Satisfaction";
import Hard_work from "../component/home/Hard_work";




import ReactDOM from 'react-dom';
import $ from 'jquery';

$("#visible-part").hide();
$("#visible-part-one").hide();
$("#shom-more").on("click", function () {
    var txt = $("#visible-part").is(':visible') ? 'Read More' : 'Read Less';
    $("#shom-more").text(txt);
    $(this).prev('#visible-part').slideToggle(200);
});

$("#shom-more-one").on("click", function () {
    var txt = $("#visible-part-one").is(':visible') ? 'Read More' : 'Read Less';
    $("#shom-more-one").text(txt);
    $(this).prev('#visible-part-one').slideToggle(200);
});

function LandingPageTwo() {



    const queryParams = new URLSearchParams(window.location.search);


    const [utm_source, setutm_source] = useState('');
    const [utm_campaign, setutm_campaign] = useState('');

    const [checkboxval, setPe] = useState(false);
     const [Loading, setLoading]=useState(false)
    var utm_sourcercv = queryParams.get('utm_source');
    var utm_campaignrcv = queryParams.get('utm_campaign');

    const dispatch = useDispatch();

    const navigate = useNavigate();

    useEffect(() => {
        if (utm_sourcercv != null && utm_sourcercv != '' && utm_sourcercv != undefined) {

            console.log("utm source1");
            setutm_source(utm_sourcercv);
            setutm_campaign(utm_campaignrcv);

            localStorage.setItem('utm_source', utm_sourcercv);
            localStorage.setItem('utm_campaign', utm_campaignrcv);
            // setState({ ...state, ['source']: utm_sourcercv });
            //setState({ ...state, ['campaign']: utm_campaignrcv });
        }
        else {
            console.log("utm source2");

            var item1 = localStorage.getItem('utm_source');
            var item2 = localStorage.getItem('utm_campaign');
            if (item1 != null && item1 != undefined && item1 != '') {
                setutm_source(item1);
                setutm_campaign(item2);

                setState({ ...state, ['source']: item1 });
                setState({ ...state, ['campaign']: item2 });
            }

        }
        dispatch(loadAllCategory());
    }, [dispatch]);

    const [state, setState] = useState({
        city: "",
        landmark: "landingpage",
        category: "",
        name: "",
        email: "",
        mobile: "",
        pincode: "",
        location: "",
        address: "",
        message: "",
        pe: "",
        reqtyop: "",
        source: utm_source,
        campaign: utm_campaign,

    });

    const {
        city,
        category,
        name,
        email,
        mobile,
        landmark,
        pincode,
        location,
        address,
        message,
        pe,
        reqtyop,
        source,
        campaign

    } = state;

    const Category = useSelector((state) => state.MainCategory);

    const { error, result } = Category;



    const handleInputChange = (e) => {


        let { name, value } = e.target;
        console.log("handleInputChange" + name);


        if (name == "pincode") {
            value = value.slice(0, 6);
        }
        if (name == "mobile") {
            value = value.slice(0, 10);
        }
        setState({ ...state, [name]: value });


    };

    const key = process.env.REACT_APP_SITE_KEY;
    const url = process.env.REACT_APP_SITE_URL;
    const [captchaResult, setCaptchaResult] = useState();

    const handleRecaptcha = (value) => {
        setCaptchaResult(value)
        /*
        fetch(url, {
          method: "POST",
          body: JSON.stringify({ captcha_value: value }),
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
          .then((data) => {
            console.log(data.captcha.success);
            setCaptchaResult(data.captcha.success);
          });
          */
    };

    const handleSubmit = (e) => {

        setLoading(true);
        // alert(checkboxval);
        state.campaign = utm_campaign;
        state.source = utm_source;
        e.preventDefault();
        if (captchaResult == undefined || captchaResult == '' || captchaResult == null) {
            toast.warning("Invalid Captcha");

            //return false;
        }
        // if (checkboxval) {
        //     toast.warning("Check condition");
        //     //return false;

        // }





        if (!category || !email || !mobile || !name) {
            toast.warning("Please input all input Field!");
        } else {
            dispatch(createAppointment(state));
            setLoading(false)

        }
        return;

        //  navigate("/all-thank-you");
    };



    const { response, loading } = useSelector((state) => state.Additional);

    useEffect(() => {

        if (Object.keys(response).length > 0) {
            console.log('Object is NOT empty');
            //navigate('/thanks-you', { state: { messagefor: "contact", heading: "Thank You For Choosing iRobot and taking the first step", message: "We will get in touch with you shortly. For quick response connect on 18002003355. " } });
            navigate('/thanks-you-landingpage-one', { state: { messagefor: "contact", heading: "Thank You For Choosing iRobot and taking the first step", message: "We will get in touch with you shortly. For quick response connect on 18002003355. " } });
        }

    }, [response]);


    if(loading){

       return (<div id="loading">
        <img id="loading-image" src={spinner1} alt="Loading..." />
      </div>)
    }



    return (
        <>
        
            <div className="bodybackground">
            <headerdm class="main-header">
                    <div class="container-html">
                        <div class="row align-items-center">
                            <div class="col-lg-1 col-md-1 col-3">
                                <div class="logohtml">
                                    <a href="/">
                                        <img class="img-fluiddm" src={require("../../src/images/logo.png")} alt="iRobot" height="50" />
                                    </a>
                                </div>
                            </div>

                            <div class="col-lg-2 ms-auto col-7">
                                <div class="call_us text-end">
                                    <a href="tel:18002003355" target="_blank">Call Us : 18002003355</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </headerdm>
                <section id="BookADemo" class="bookdemo_formtwo">
                    <div class="container-html">
                        <div class="row">
                            <div class="col-lg-4 col-md-6 ms-auto">
                                <div class="book-demo-user">
                                    <h3 class="text-center">Try before you buy</h3>
                                    <p class="text-center">Fill in your details below or <img class="img-fluiddm phn-icon"
                                        src={require("../../src/images/phone.png")} /> <a target="_blank" href="tel:18002003355">Call now</a>
                                        <span>18002003355</span>
                                    </p>

                                    <div class="form-book">
                                        <form autoComplete="off" onSubmit={handleSubmit}>

                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <label>Full name*</label>
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        placeholder=""
                                                        name="name"
                                                        value={name}
                                                        onChange={handleInputChange}
                                                        required
                                                    />
                                                </div>
                                            </div>

                                            <div class="row mt-2 mb-2">
                                                <div class="col-lg-6 col-md-6">
                                                    <label>E-mail*</label>
                                                    <input
                                                        class="form-control"
                                                        type="email"
                                                        required
                                                        name="email"
                                                        placeholder=""
                                                        value={email}
                                                        onChange={handleInputChange}

                                                    />
                                                </div>

                                                <div class="col-lg-6 col-md-6 mob-mt-2">
                                                    <label>Phone</label>
                                                    <input
                                                        class="form-control"
                                                        type="number"
                                                        required
                                                        minLength="10" 
                                                        maxLength="10"
                                                        placeholder=""
                                                        name="mobile"
                                                        value={mobile}
                                                        //onChange={handleInputChange}
                                                        onChange={(e) => {
                                                            if (e.target.value.length < 11) {
                                                                handleInputChange(e);
                                                            }
                                                        }}

                                                    />
                                                </div>
                                            </div>

                                            <div class="row mb-2">
                                                <div class="col-lg-6 col-md-6">
                                                    <label>Select Product*</label>
                                                    <select class="form-control"
                                                        name="category"
                                                        onChange={handleInputChange}
                                                        value={category || ""}
                                                    >
                                                        <option>Select </option>
                                                        
                                                        {result.map((item, i) => (
                                                            <option key={i} value={item.alias}>
                                                                {item.name} {item.alias}
                                                            </option>
                                                        ))}
                                                    </select>

                                                </div>

                                                <div class="col-lg-6 col-md-6 mob-mt-2">
                                                    <label>City*</label>
                                                    <input
                                                        class="form-control"
                                                        type="text"
                                                        placeholder=""
                                                        name="city"
                                                        value={city}
                                                        onChange={handleInputChange}

                                                    />
                                                </div>
                                            </div>


                                            <div class="row">
                                                <div class="col-lg-12 agree ps-4">
                                                    <input
                                                        type="checkbox"
                                                        id="receive_news"
                                                        name="news"
                                                        value={checkboxval}
                                                        onChange={(e) => setPe(!checkboxval)}
                                                    />
                                                    &nbsp;
                                                    I agree to iRobot
                                                    <a href="https://irobot.in/terms-and-conditions.aspx" target="_blank" class="condition_link">Terms of
                                                        Use,</a>
                                                    <a href="https://irobot.in/privacy-policy.aspx" target="_blank" class="condition_link">Privacy
                                                        Policy</a>
                                                </div>
                                            </div>


                                            <div class="row">
                                                <ReCAPTCHA
                                                    sitekey={key}
                                                    onChange={handleRecaptcha}
                                                    className="captcha"
                                                />
                                                <div class="col-lg-12">
                                                    <input type="submit" value="Book a demo" class="container-html-fluid submit-demo">
                                                    </input>
                                                </div>
                                            </div>

                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

      <Satisfaction />
      {/* <section class="intro_site">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-6">
                    <img class="img-fluid" src={require("../../src/images/wel_come.jpg")} />
                </div> 
                <div class="col-md-6">
                    <div class="welcome_txt">
                        <h3>Why do we use it?</h3>
                        <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).
                        <br /><br />
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section> */}
    <section class="intro_site">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-6">
                    <img class="img-fluid" src={require("../../src/images/RoombaCombo.jpg")} />
                </div> 
                <div class="col-md-6">
                    <div class="welcome_txt">
                        <h3>About Us</h3>
                        <p>
                        iRobot has over 30 years of robotics expertise and innovation with over 40 million home robots sold worldwide. iRobot India is the exclusive distributor of iRobot home robots, the world’s leading robotic floor cleaners. Whether you need a smart Robotic vacuum, a mopping robot, or both 2 in 1 Robot vacuum with mop, iRobot has the perfect solution for you. iRobot products are designed to make your everyday cleaning easier and smarter, so you can enjoy more time doing what you love.
                        <br /><br />
                        If you ever need help, we make it easy with specially trained Customer Care agents available by phone or email to answer your questions.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <Hard_work />
    <section id="advantage" class="irobot-adv mt-4">
                    <div class="container-html-two">
                        <div class="row mt-5 mb-5">
                            <div class="col-lg-3 col-md-3 text-center">
                                <div class="adv-bx">
                                    <img src={require("../../src/images/year_logo.webp")} />
                                </div>
                            </div>

                            <div class="col-lg-3 col-md-3 text-center">
                                <div class="adv-bx">
                                    <img src={require("../../src/images/warranty_logo.webp")} />
                                </div>
                            </div>

                            <div class="col-lg-3 col-md-3 text-center">
                                <div class="adv-bx">
                                    <img src={require("../../src/images/robot_logo.webp")} />
                                </div>
                            </div>

                            <div class="col-lg-3 col-md-3 text-center">
                                <div class="adv-bx">
                                    <img src={require("../../src/images/million_logo.webp")} />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <footer class="copyrighttwo">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <p>© 2022Copyright iRobot.in</p>
                </div>
            </div>
        </div>
    </footer>
    
            </div>

        </>
    );
}

export default LandingPageTwo;
