// constant files

export const ADD_ORDER_REQUEST = "ADD_ORDER_REQUEST";
export const ADD_ORDER_SUCCESS = "ADD_ORDER_SUCCESS";
export const ADD_ORDER_FAIL = "ADD_ORDER_FAIL";

export const UPDATE_ORDER_REQUEST = "UPDATE_ORDER_REQUEST";
export const UPDATE_ORDER_SUCCESS = "UPDATE_ORDER_SUCCESS";
export const UPDATE_ORDER_FAIL = "UPDATE_ORDER_FAIL";

export const DELETE_ORDER_REQUEST = "DELETE_ORDER_REQUEST";
export const DELETE_ORDER_SUCCESS = "DELETE_ORDER_SUCCESS";
export const DELETE_ORDER_FAIL = "DELETE_ORDER_FAIL";

export const CANCEL_ORDER_REQUEST = "CANCEL_ORDER_REQUEST";
export const CANCEL_ORDER_SUCCESS = "CANCEL_ORDER_SUCCESS";
export const CANCEL_ORDER_FAIL = "CANCEL_ORDER_FAIL";

export const RETURN_ORDER_REQUEST = "RETURN_ORDER_REQUEST";
export const RETURN_ORDER_SUCCESS = "RETURN_ORDER_SUCCESS";
export const RETURN_ORDER_FAIL = "RETURN_ORDER_FAIL";

export const FIND_ORDER_REQUEST = "FIND_ORDER_REQUEST";
export const FIND_ORDER_SUCCESS = "FIND_ORDER_SUCCESS";
export const FIND_ORDER_FAIL = "FIND_ORDER_FAIL";

export const FIND_ORDER_DETAIL_REQUEST = "FIND_ORDER_DETAIL_REQUEST";
export const FIND_ORDER_DETAIL_SUCCESS = "FIND_ORDER_DETAIL_SUCCESS";
export const FIND_ORDER_DETAIL_FAIL = "FIND_ORDER_DETAIL_FAIL";

export const FIND_ORDER_LIST_DETAIL_REQUEST = "FIND_ORDER_LIST_DETAIL_REQUEST";
export const FIND_ORDER_LIST_DETAIL_SUCCESS = "FIND_ORDER_LIST_DETAIL_SUCCESS";
export const FIND_ORDER_LIST_DETAIL_FAIL = "FIND_ORDER_LIST_DETAIL_FAIL";
