import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { createPromotionEmail } from "../../redux/action/AdditionalAction";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/fontawesome-free-solid";
import logo from "../../image/footer-logo.png";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Footer() {
  const [email, setEmail] = useState("");

  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!email) {
      toast.warning("Please input all input Field!");
    } else {
      dispatch(createPromotionEmail(email));
      setEmail("");
    }
  };

  return (
    <footer className="section-foot">
      <ToastContainer />

      <div className="container">
        <div className="footer-top">



          <div className="m-footer__iconcontainer diviconfoot">


            <a
              href="https://www.facebook.com/irobotindia/"
              target="_blank"
              aria-label="To make this website accessible to screen reader, press combination of alt and 1 keys.
  To stop getting this message, press the combination of alt and 2 keys."
            >
              <img
                src="data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMDAwIDEwMDAiPjx0aXRsZT5mYWNlYm9vazwvdGl0bGU+PHBhdGggZD0iTTExNiw2OEg4ODRjMjcsMCw0OCwyMSw0OCw0OFY4ODRjMCwyNy0yMSw0OC00OCw0OEg2NjRWNTk3SDc3NmwxNy0xMzBINjY0VjM4NGMwLTM4LDEwLTYzLDY1LTYzbDY5LTFWMjA0Yy0xMi0yLTUzLTYtMTAxLTYtOTksMC0xNjgsNjEtMTY4LDE3M3Y5Nkg0MTdWNTk3SDUyOVY5MzJIMTE2Yy0yNywwLTQ4LTIxLTQ4LTQ4VjExNkM2OCw4OSw4OSw2OCwxMTYsNjhaIiBmaWxsPSIjZmZmIi8+PC9zdmc+"
                className="a-icon a-icon--small"
              />
            </a>
            <a
              href="https://www.instagram.com/irobot_india/"
              target="_blank"
              aria-label="To make this website accessible to screen reader, press combination of alt and 1 keys.
  To stop getting this message, press the combination of alt and 2 keys."
            >
              <img
                src="data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMDAwIDEwMDAiPjx0aXRsZT5pbnN0YWdyYW08L3RpdGxlPjxwYXRoIGQ9Ik05MzIsNTAwYzAsNjAsMCwxMTktMywxNzgtMyw2OS0xOSwxMzEtNzAsMTgxLTUwLDUxLTExMiw2Ny0xODEsNzAtNTksMy0xMTgsMy0xNzgsM3MtMTE5LDAtMTc4LTNjLTY5LTMtMTMxLTE5LTE4MS03MEM5MCw4MDksNzQsNzQ3LDcxLDY3OGMtMy01OS0zLTExOC0zLTE3OHMwLTExOSwzLTE3OGMzLTY5LDE5LTEzMSw3MC0xODEsNTAtNTEsMTEyLTY3LDE4MS03MCw1OS0zLDExOC0zLDE3OC0zczExOSwwLDE3OCwzYzY5LDMsMTMxLDE5LDE4MSw3MCw1MSw1MCw2NywxMTIsNzAsMTgxQzkzMiwzODEsOTMyLDQ0MCw5MzIsNTAwWk01MDAsMTQ2Yy02MywwLTE5OC01LTI1NSwxNy0yMCw4LTM0LDE4LTQ5LDMzcy0yNSwyOS0zMyw0OWMtMjIsNTctMTcsMTkyLTE3LDI1NXMtNSwxOTgsMTcsMjU1YzgsMjAsMTgsMzQsMzMsNDlzMjksMjUsNDksMzNjNTcsMjIsMTkyLDE3LDI1NSwxN3MxOTgsNSwyNTUtMTdjMjAtOCwzNC0xOCw0OS0zMywxNi0xNSwyNS0yOSwzMy00OSwyMi01NywxNy0xOTIsMTctMjU1czUtMTk4LTE3LTI1NWMtOC0yMC0xNy0zNC0zMy00OS0xNS0xNS0yOS0yNS00OS0zM0M2OTgsMTQxLDU2MywxNDYsNTAwLDE0NlpNNzgyLDI2OWMwLDI5LTIzLDUyLTUxLDUyYTUxLjY0NDE5LDUxLjY0NDE5LDAsMCwxLTUyLTUyYzAtMjgsMjMtNTEsNTItNTFBNTEuMjE0NzMsNTEuMjE0NzMsMCwwLDEsNzgyLDI2OVpNNzIyLDUwMEEyMjIsMjIyLDAsMSwxLDUwMCwyNzgsMjIxLjQ5OSwyMjEuNDk5LDAsMCwxLDcyMiw1MDBabS03OCwwYzAtNzktNjUtMTQ0LTE0NC0xNDRTMzU2LDQyMSwzNTYsNTAwczY1LDE0NCwxNDQsMTQ0UzY0NCw1NzksNjQ0LDUwMFoiIGZpbGw9IiNmZmYiLz48L3N2Zz4="
                className="a-icon a-icon--small"
              />
            </a>
            <a
              href="https://www.linkedin.com/company/irobot-india/
"
              target="_blank"
              aria-label="To make this website accessible to screen reader, press combination of alt and 1 keys.
  To stop getting this message, press the combination of alt and 2 keys."
            >
              <img
                src="data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMDAwIDEwMDAiPjx0aXRsZT5saW5rZWRpbjwvdGl0bGU+PHBhdGggZD0iTTkzMiw1OTNWOTEzSDc0N1Y2MTVjMC03NS0yNy0xMjYtOTQtMTI2LTUxLDAtODIsMzQtOTUsNjctNSwxMy02LDI5LTYsNDZWOTEzSDM2N2MyLTUwNSwwLTU1NywwLTU1N0g1NTJ2ODFoLTFjMjQtMzksNjgtOTQsMTY4LTk0Qzg0MSwzNDMsOTMyLDQyMiw5MzIsNTkzWk0yNzYsMTgzYzEsNTQtNDAsOTctMTA0LDk3aC0yYy02MiwwLTEwMi00My0xMDItOTdzNDItOTYsMTA1LTk2UzI3NiwxMjksMjc2LDE4M1pNNzksMzU2SDI2NFY5MTNINzlaIiBmaWxsPSIjZmZmIi8+PC9zdmc+"
                className="a-icon a-icon--small"
              />
            </a>
            <a
              href="https://twitter.com/iRobotIndia"
              target="_blank"
              aria-label="To make this website accessible to screen reader, press combination of alt and 1 keys.
  To stop getting this message, press the combination of alt and 2 keys."
            >
              <img
                src="data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMDAwIDEwMDAiPjx0aXRsZT50d2l0dGVyPC90aXRsZT48cGF0aCBkPSJNOTQzLDI1Ni40Mzk1NWMtMjUsMzUtNTUsNjgtOTEsOTNhMTkyLjY4NzIzLDE5Mi42ODcyMywwLDAsMSwxLDI0YzAsMjQwLTE4Myw1MTctNTE3LDUxNy0xMDMsMC0xOTktMzAtMjc5LTgyYTMwNC4yNzYwNywzMDQuMjc2MDcsMCwwLDAsNDQsMywzNjEuMTQ1MDcsMzYxLjE0NTA3LDAsMCwwLDIyNS03OGMtODAtMi0xNDctNTQtMTcwLTEyNmEyMDYuNzkxNjksMjA2Ljc5MTY5LDAsMCwwLDM1LDMsMTgzLjUyNTU4LDE4My41MjU1OCwwLDAsMCw0Ny02Yy04My0xNy0xNDUtOTAtMTQ1LTE3OXYtMmMyNCwxNCw1MiwyMiw4MiwyMy00OS0zMi04MS04OC04MS0xNTFhMTc4LjU0MjI5LDE3OC41NDIyOSwwLDAsMSwyNS05MmM4OSwxMTAsMjI0LDE4MiwzNzQsMTkwLTMtMTMtNC0yNy00LTQxLDAtMTAwLDgxLTE4MiwxODEtMTgyYTE4MS42OTA1NywxODEuNjkwNTcsMCwwLDEsMTMzLDU3YzQxLTgsODEtMjMsMTE1LTQ0LTEzLDQzLTQyLDc4LTc5LDEwMUEzNjMuNDgyLDM2My40ODIsMCwwLDAsOTQzLDI1Ni40Mzk1NVoiIGZpbGw9IiNmZmYiLz48L3N2Zz4="
                className="a-icon a-icon--small"
              />
            </a>
            <a
              href="https://www.youtube.com/channel/UCNVFHDIW750ulX21DwYeprg"
              target="_blank"
              aria-label="To make this website accessible to screen reader, press combination of alt and 1 keys.
  To stop getting this message, press the combination of alt and 2 keys."
            >
              <img
                src="data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMDAwIDEwMDAiPjx0aXRsZT55b3V0dWJlPC90aXRsZT48cGF0aCBkPSJNNDAxLjcwNDQ0LDM2Mi4yMDU0MlY2NDcuMjYyNTZMNjUyLjgwNDkyLDUwNS4xODA3OVptNTU5LjM5MTEyLTkxLjE0NjhjMTguNzY1NTIsNzUuOTU1NjcsMTguNzY1NTIsMjMyLjMzNSwxOC43NjU1MiwyMzIuMzM1czAsMTU2LjM3OTMtMTguNzY1NTIsMjMyLjMzNWExMTUuNzU5NTUsMTE1Ljc1OTU1LDAsMCwxLTg0Ljg5MTYzLDgzLjk5OEM4MDEuMTQxODcsODM5LjM4NTcxLDUwMCw4MzkuMzg1NzEsNTAwLDgzOS4zODU3MXMtMzAxLjE0MTg3LDAtMzc2LjIwMzkzLTE5LjY1OTExYTExNS43NTk1NSwxMTUuNzU5NTUsMCwwLDEtODQuODkxNjMtODMuOTk4Yy0xOC43NjU1Mi03NS45NTU2Ny0xOC43NjU1Mi0yMzIuMzM1LTE4Ljc2NTUyLTIzMi4zMzVzMC0xNTYuMzc5MzEsMTguNzY1NTItMjMyLjMzNWMxMC43MjMxNS00MS45OTksNDMuNzg2MjEtNzUuOTU1NjYsODQuODkxNjMtODUuNzg1MjJDMTk4Ljg1ODEzLDE2NS42MTQyOSw1MDAsMTY1LjYxNDI5LDUwMCwxNjUuNjE0MjlzMzAxLjE0MTg3LDAsMzc2LjIwMzkzLDE5LjY1OTExQzkxNy4zMDkzNSwxOTUuMTAzLDk1MC4zNzI0MSwyMjkuMDU5NjEsOTYxLjA5NTU2LDI3MS4wNTg2MloiIGZpbGw9IiNmZmYiLz48L3N2Zz4="
                className="a-icon a-icon--small"
              />
            </a>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="footer_menu">
          <div className="box">
            <div className="footer-logo">
              <a href="/">
                <img src={logo} alt="" />
              </a>
              <h3 className="title">Be the first to know</h3>
            </div>
            <div className="newsletter">
              <p>
                Enter your email address below for the latest offers, new
                products and more from iRobot.
              </p>
            </div>
            <div className="diviconfoot newsletter">
              <form autoComplete="off" onSubmit={handleSubmit}>
                <div className="input_field">
                  <label>
                    Join our mailing list<span> * </span>
                  </label>
                  <input
                    type="email"
                    name="email"
                    value={email}
                    required
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <button type="submit">
                    <FontAwesomeIcon icon={faAngleRight} />
                  </button>
                </div>
                <div className="m-icon__section diviconfoot ">
                  <i className="a-icon fa fa-phone" aria-hidden="true"></i>
                  <a href="tel:1800-200-3355" className="colorfootcontect">
                    1800-200-3355
                  </a>
                </div>
              </form>


            </div>
          </div>
          <div className="box">
            <h4 className="footer-title">My Account</h4>
            <ul>
              <li>
                <Link to="/login">Login</Link>
              </li>
              <li>
                <Link to="/register">Create Account</Link>
              </li>
              <li>
                <Link to="/dashboard">Returning customer</Link>
              </li>
              <li>
                <Link to="/dashboard">Order status</Link>
              </li>
              {/* <li>
                <Link to="/spin-wheel">Customer Loyalty Program</Link>
              </li> */}
              <li>
                <Link to="/cart">Shopping Cart</Link>
              </li>
            </ul>
          </div>
          <div className="box">
            <h4 className="footer-title">Support</h4>
            <ul>
              <li>
                <Link to="/faq">Find Answers</Link>
              </li>
              <li>
                <Link to="/register-robot">Register Robots</Link>
              </li>
              <li>
                <Link to="/storelocator">Where to buy</Link>
              </li>
              <li>
                <Link to="/contact-us">Contact</Link>
              </li>
            </ul>
          </div>
          <div className="box">
            <h4 className="footer-title">Information</h4>
            <ul>
              {/*
              <li>
                <Link to="/covid19-updates"> COVID 19 Update</Link>
              </li>
               */}
              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/terms-condition">Terms &amp; Conditions</Link>
              </li>
              <li>
                <Link to="/data-security">Data Security</Link>
              </li>
              <li>
                <Link to="/return-warranty">Returns and Warranty</Link>
              </li>
              <li>
                <Link to="/no-cost-emi">No Cost EMI</Link>
              </li>
            </ul>
          </div>
          <div className="box">
            <h4 className="footer-title">About</h4>
            <ul>
              <li>
                <Link to="/company-information">Overview</Link>
              </li>
              <li>
                <Link to="/news">News</Link>
              </li>
              <li>
                <Link to="/testimonial">Testimonials</Link>
              </li>
              <li>
                <Link to="/apply-now">Apply Now</Link>
              </li>
              <li>
                <Link to="/blog">Blogs</Link>
              </li>
              <li>
                <Link to="/sitemap">Sitemap</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="copyright-box">
            <div className="copyright-box-1">
              <Link to="#!">Cookie Preferences</Link>
            </div>
          </div>
          <div className="copyright">
            <p className="">
              © 2022 All rights reserved by Puresight Systems Pvt. Ltd. aka
              iRobot India.
            </p>
          </div>
        </div>
      </div>
      <div className="footeraction">

        <a href="tel:1800-200-3355" className="footeractionleft">
          <i className="a-icon fa fa-phone colorfootericon" aria-hidden="true"></i>

          <span className="coloraction">
            Call

          </span>


        </a>
        <a href="https://wa.me/917022800561" className="footeractionleft">
          <i className="a-icon fa fa-whatsapp colorfootericon" aria-hidden="true"></i>

          <span className="coloraction">Whatsapp</span>
        </a>
      </div>

    </footer>
  );
}
