import {
  Find_ALL_PRODUCT_DETAILS_FAIL,
  Find_ALL_PRODUCT_DETAILS_REQUEST,
  Find_ALL_PRODUCT_DETAILS_SUCCESS,
  Find_ALL_PRODUCT_IMAGES_FAIL,
  Find_ALL_PRODUCT_IMAGES_REQUEST,
  Find_ALL_PRODUCT_IMAGES_SUCCESS,
  Find_ALL_PRODUCT_SPECIFICATION_FAIL,
  Find_ALL_PRODUCT_SPECIFICATION_REQUEST,
  Find_ALL_PRODUCT_SPECIFICATION_SUCCESS,
  Find_ALL_PRODUCT_ADD_DETAIL_FAIL,
  Find_ALL_PRODUCT_ADD_DETAIL_REQUEST,
  Find_ALL_PRODUCT_ADD_DETAIL_SUCCESS,
  Find_ACCESSORY_PRODUCT_LIST_FAIL,
  Find_ACCESSORY_PRODUCT_LIST_REQUEST,
  Find_ACCESSORY_PRODUCT_LIST_SUCCESS,
  Find_PRODUCT_LIST_FAIL,
  Find_PRODUCT_LIST_REQUEST,
  Find_PRODUCT_LIST_SUCCESS,
  SEARCH_PRODUCT_LIST_FAIL,
  SEARCH_PRODUCT_LIST_REQUEST,
  SEARCH_PRODUCT_LIST_SUCCESS,
  FIND_ALL_PRODUCT_DESC_FAIL,
  FIND_ALL_PRODUCT_DESC_REQUEST,
  FIND_ALL_PRODUCT_DESC_SUCCESS,
  FIND_ALL_PRODUCT_FAQ_FAIL,
  FIND_ALL_PRODUCT_FAQ_REQUEST,
  FIND_ALL_PRODUCT_FAQ_SUCCESS,
  LOAD_ALL_MAIN_PRODUCT_LIST_FAIL,
  LOAD_ALL_MAIN_PRODUCT_LIST_REQUEST,
  LOAD_ALL_MAIN_PRODUCT_LIST_SUCCESS,
} from "../constants/ProductConstant";
import axios from "axios";

const loadAllProduct = (id) => {
  return function (dispatch) {
    dispatch({
      type: Find_ALL_PRODUCT_DETAILS_REQUEST,
      payload: true,
    });
    let OPTION = {
      //url: `${process.env.REACT_APP_API_PATH}/api/v1/admin-product/${id}/`,
      url: `${process.env.REACT_APP_API_PATH}/api/v1/admin-product-n/${id}/`,

      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    axios(OPTION)
      .then((res) => {
        // console.log("res", res.data);
        dispatch(loadAllProductPre(res.data));
      })
      .catch((error) => {
        // console.log("error", error);
        dispatch({
          type: Find_ALL_PRODUCT_DETAILS_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
      });
  };
};
export const loadAllProductPre = (data) => {
  // console.log("djncjus",data)
  return {
    type: Find_ALL_PRODUCT_DETAILS_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

const ProductSearch = (inputData) => {
  return function (dispatch) {
    dispatch({
      type: SEARCH_PRODUCT_LIST_REQUEST,
      payload: true,
    });
    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/product-search/`,
      method: "POST",
      data: {
        inputData,
      },
      headers: {
        "content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };
    axios(OPTION)
      .then((res) => {
        dispatch(ProductSearchPre(res.data));
      })
      .catch((error) => {
        dispatch({
          type: SEARCH_PRODUCT_LIST_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
      });
  };
};
export const ProductSearchPre = (data) => {
  return {
    type: SEARCH_PRODUCT_LIST_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

const loadCompareProductList = () => {
  return function (dispatch) {
    dispatch({
      type: Find_PRODUCT_LIST_REQUEST,
      payload: true,
    });
    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/compare-product-list/`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    axios(OPTION)
      .then((res) => {
        dispatch(loadCompareProductListPre(res.data));
      })
      .catch((error) => {
        dispatch({
          type: Find_PRODUCT_LIST_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
      });
  };
};
export const loadCompareProductListPre = (data) => {
  console.log("djncjus", data);
  return {
    type: Find_PRODUCT_LIST_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

const loadProductAccessoryList = (id) => {
  return function (dispatch) {
    dispatch({
      type: Find_ACCESSORY_PRODUCT_LIST_REQUEST,
      payload: true,
    });
    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/accessory-product-list/${id}/`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    axios(OPTION)
      .then((res) => {
        console.log("res", res.data);
        dispatch(loadProductAccessoryListPre(res.data));
      })
      .catch((error) => {
        console.log("error", error);
        dispatch({
          type: Find_ACCESSORY_PRODUCT_LIST_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
      });
  };
};
export const loadProductAccessoryListPre = (data) => {
  return {
    type: Find_ACCESSORY_PRODUCT_LIST_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

const loadAllProductImage = (id) => {
  return function (dispatch) {
    dispatch({
      type: Find_ALL_PRODUCT_IMAGES_REQUEST,
      payload: true,
    });
    let OPTION = {
      //url: `${process.env.REACT_APP_API_PATH}/api/v1/product-Images/${id}/`,
      url: `${process.env.REACT_APP_API_PATH}/api/v1/product-Images-n/${id}/`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    axios(OPTION)
      .then((res) => {
        dispatch(loadAllProductImagePre(res.data));
      })
      .catch((error) => {
        dispatch({
          type: Find_ALL_PRODUCT_IMAGES_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
      });
  };
};
export const loadAllProductImagePre = (data) => {
  // console.log("djncjus", data);
  return {
    type: Find_ALL_PRODUCT_IMAGES_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};
const loadAllProductSpecification = (id) => {
  return function (dispatch) {
    dispatch({
      type: Find_ALL_PRODUCT_SPECIFICATION_REQUEST,
      payload: true,
    });
    let OPTION = {
      // url:`${process.env.REACT_APP_API_PATH}/api/v1/admin-product/${id}/`,
      //url: `${process.env.REACT_APP_API_PATH}/api/v1/product-specification-prodid/${id}/`,
      url: `${process.env.REACT_APP_API_PATH}/api/v1/product-specification-prodid-n/${id}/`,

      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    axios(OPTION)
      .then((res) => {
        dispatch(loadAllProductSpecificationPre(res.data));
      })
      .catch((error) => {
        dispatch({
          type: Find_ALL_PRODUCT_SPECIFICATION_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
      });
  };
};
export const loadAllProductSpecificationPre = (data) => {
  // console.log("djncjus",data)
  return {
    type: Find_ALL_PRODUCT_SPECIFICATION_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};
const loadAllProductAddDetails = (id) => {
  return function (dispatch) {
    dispatch({
      type: Find_ALL_PRODUCT_ADD_DETAIL_REQUEST,
      payload: true,
    });
    let OPTION = {
      // url:`${process.env.REACT_APP_API_PATH}/api/v1/admin-product/${id}/`,
      //url: `${process.env.REACT_APP_API_PATH}/api/v1/product-details-prodid/${id}/`,
      url: `${process.env.REACT_APP_API_PATH}/api/v1/product-details-prodid-n/${id}/`,

      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    axios(OPTION)
      .then((res) => {
        dispatch(loadAllProductAddDetailsPre(res.data));
      })
      .catch((error) => {
        //   console.log("loadAllProductAddDetails",error)

        dispatch({
          type: Find_ALL_PRODUCT_ADD_DETAIL_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
      });
  };
};
export const loadAllProductAddDetailsPre = (data) => {
  //   console.log("loadAllProductAddDetails",data)
  return {
    type: Find_ALL_PRODUCT_ADD_DETAIL_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

const loadAllProductDesc = (id) => {
  return function (dispatch) {
    dispatch({
      type: FIND_ALL_PRODUCT_DESC_REQUEST,
      payload: true,
    });
    let OPTION = {
      // url:`${process.env.REACT_APP_API_PATH}/api/v1/admin-product/${id}/`,
      //url: `${process.env.REACT_APP_API_PATH}/api/v1/product-description/${id}/`,
      url: `${process.env.REACT_APP_API_PATH}/api/v1/product-description-n/${id}/`,

      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    axios(OPTION)
      .then((res) => {
        // console.log("res",res )
        dispatch(loadAllProductDescPre(res.data));
      })
      .catch((error) => {
        // console.log("errr",error)

        dispatch({
          type: FIND_ALL_PRODUCT_DESC_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
      });
  };
};
export const loadAllProductDescPre = (data) => {
  //   console.log("loadAllProductAddDetails",data)
  return {
    type: FIND_ALL_PRODUCT_DESC_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

const loadAllProductFaq = (id) => {
  return function (dispatch) {
    dispatch({
      type: FIND_ALL_PRODUCT_FAQ_REQUEST,
      payload: true,
    });

    let OPTION = {
      // url:`${process.env.REACT_APP_API_PATH}/api/v1/admin-product/${id}/`,
      //url: `${process.env.REACT_APP_API_PATH}/api/v1/product-description/${id}/`,
      url: `${process.env.REACT_APP_API_PATH}/api/v1/product-description-n/${id}/`,

      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    axios(OPTION)
      .then((res) => {
        // console.log("res",res )
        dispatch(loadAllProductFaqPre(res.data));
      })
      .catch((error) => {
        // console.log("errr",error)

        dispatch({
          type: FIND_ALL_PRODUCT_FAQ_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
      });
  };
};
export const loadAllProductFaqPre = (data) => {
  //   console.log("loadAllProductAddDetails",data)
  return {
    type: FIND_ALL_PRODUCT_FAQ_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

const loadAllMainProductList = () => {
  return function (dispatch) {
    dispatch({
      type: LOAD_ALL_MAIN_PRODUCT_LIST_REQUEST,
      payload: true,
    });
    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/product-list/`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    axios(OPTION)
      .then((res) => {
        console.log("res", res.data);
        dispatch(loadAllMainProductListPre(res.data));
      })
      .catch((error) => {
        console.log("error", error);
        dispatch({
          type: LOAD_ALL_MAIN_PRODUCT_LIST_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
      });
  };
};
export const loadAllMainProductListPre = (data) => {
  return {
    type: LOAD_ALL_MAIN_PRODUCT_LIST_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

export {
  loadAllProduct,
  loadAllProductImage,
  loadAllProductSpecification,
  loadAllProductAddDetails,
  loadProductAccessoryList,
  loadCompareProductList,
  ProductSearch,
  loadAllProductDesc,
  loadAllProductFaq,
  loadAllMainProductList,
};
