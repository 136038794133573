import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store";
import "./App.css";
import "../src/Style.css";
import "../src/css/landingstyle.css";



import Header from "./component/share/Header";
import Top_header from "./component/share/Top_header";
import Footer from "./component/share/Footer";
import Home from "./screen/Home";
import Series from "./screen/Series";
import Category from "./screen/Category";
import ProductDetail from "./screen/ProductDetail";
import IRobotGenius from "./screen/iRobotGenius";
import Accesories from "./screen/Accesories";
import PrivacyPolicy from "./screen/PrivacyPolicy";
import DataSecurity from "./screen/DataSecurity";
import ReturnWarranty from "./screen/ReturnWarranty";
import Covid19Updates from "./screen/Covid19Updates";
import SiteMap from "./screen/SiteMap";
import ContactUs from "./screen/ContactUs";
import Register from "./screen/Register";
import Login from "./screen/Login";
import ForgotPassword from "./screen/ForgotPassword";
import CompanyINformation from "./screen/CompanyInformation";
import Blog from "./screen/Blog";
import SinglePost from "./screen/SinglePost";
import Enquiry from "./screen/Enquiry";
import News from "./screen/News";
import Testimonial from "./screen/Testimonial";
import ApplyNow from "./screen/ApplyNow";
import Offer from "./screen/Offer";
import Cart from "./screen/Cart";
import Faq from "./screen/Faq";
import StoreLocation from "./screen/StoreLocation";
import Storedetail from "./screen/Storedetail";

import CheckOut from "./screen/CheckOut";
import Shipping from "./screen/Shipping";
import TermCondition from "./screen/TermCondition";
import RightReviews from "./screen/RightReviews";
import ThanksYou from "./screen/ThanksYou";
import ThanksYouNew from "./screen/ThanksYouNew";
import ThanksYouNewOrder from "./screen/ThanksYouNewOrder";
import NocostEmi from "./screen/NocostEmi";


import AllthankU from "./component/Common/AllthankU"
// import BookOnline from "./screen/BookOnline";
import EditShippingAddress from "./component/shipping/EditShippingAddress";
import StepOne from "./component/shipping/Step-1";
import VideoCallDemo from "./screen/VideoCallDemo";
import CompareProduct from "./screen/CompareProduct";
import Return from "./component/dashboard/Return";
import OrderCancel from "./component/dashboard/OrderCancel";
import SpinWheel from "./component/spinWheel.js/SpinWheel";
import RegisterRobot from "./screen/RegisterRobot";
import Dashboard from "./screen/Dashboard";
import setAuthorizationToken from "./redux/action/setAuthorization";
import jwtDecode from "jwt-decode";
import { signOut, setCurrentUser } from "./redux/action/AuthAction";
import ChangenewPassword from "./screen/ChangenewPassword";
import Notfound from "./screen/Notfound";
import LandingPageOne from "./component/landingpage1";
import LandingPageTwo from "./component/LandingPageTwo";
import { clarity } from 'react-microsoft-clarity';




import ReactGA from "react-ga4";
// import { v4 as uuidv4 } from 'uuid';

console.log(process.env.REACT_APP_API_PATH)


function App() {

  ReactGA.initialize(process.env.REACT_APP_G_A_4_ID);
  clarity.init(process.env.REACT_APP_CLARITY_ID);

  if (window.location.pathname !== "/homenew") {
    // require('./Style.css');
    // require('../src/css/landingstyle.css');

  }
  else {

    // require('../src/css/landingstyle.css');
  }
  if (
    localStorage.token !== null &&
    localStorage.token !== undefined &&
    localStorage.token !== ""
  ) {
    setAuthorizationToken(localStorage.token);
    let token = jwtDecode(localStorage.token);

    if (token !== null || token !== undefined || token !== "") {
      store.dispatch(setCurrentUser(token));
    } else {
      store.dispatch(signOut());
    }
  }

  return (
    <div className="App">

      <Provider store={store}>
        <BrowserRouter>

          {(window.location.pathname == "/homenew" || window.location.pathname == "/lp_ads") ? (
            null

          ) : <><Top_header /><Header /></>}



          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/homenew" element={<LandingPageOne />} />
            <Route path="/lp_ads" element={<LandingPageTwo />} />
            <Route path="/no-cost-emi" element={<NocostEmi />} />

            <Route exact path="/series/:id" element={<Series />} />
            <Route exact path="/category/:id" element={<Category />} />
            <Route exact path="/product/:id" element={<ProductDetail />} />
            <Route path="/irobot-os" element={<IRobotGenius />} />
            <Route path="/accessories/:id" element={<Accesories />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/data-security" element={<DataSecurity />} />
            <Route path="/return-warranty" element={<ReturnWarranty />} />
            <Route path="/covid19-updates" element={<Covid19Updates />} />
            <Route path="/sitemap" element={<SiteMap />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/register" element={<Register />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route
              path="/company-information"
              element={<CompanyINformation />}
            />
            <Route path="/blog" element={<Blog />} />
            <Route path="/post/:id" element={<SinglePost />} />
            <Route path="/enquiry" element={<Enquiry />} />
            <Route path="/news" element={<News />} />
            <Route path="/testimonial" element={<Testimonial />} />
            <Route path="/apply-now" element={<ApplyNow />} />
            <Route path="/offer" element={<Offer />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/storelocator" element={<StoreLocation />} />
            <Route path="/storedetails/:id" element={<Storedetail />} />
            <Route path="/checkout" element={<CheckOut />} />
            <Route path="/shipping" element={<Shipping />} />
            <Route path="/terms-condition" element={<TermCondition />} />
            <Route path="/dashboard" element={<Dashboard />} />

            <Route path="/thank-you" element={<ThanksYou />} />

            <Route path="/Thanks-you" element={<ThanksYouNew />} />

            <Route path="/thanks-you-order" element={<ThanksYouNewOrder />} />
            <Route path="/thank-you-apply" element={<ThanksYouNew />} />
            <Route path="/thanks-you-enquiry" element={<ThanksYouNew />} />
            <Route path="/thanks-you-location" element={<ThanksYouNew />} />
            <Route path="/thanks-you-video-demo" element={<ThanksYouNew />} />
            <Route path="/thanks-you-store" element={<ThanksYouNew />} />

            <Route path="/thanks-you-contact" element={<ThanksYouNew />} />


            <Route path="/thanks-you-landingpage-one" element={<ThanksYouNew />} />


            <Route path="/all-thank-you" element={<AllthankU />} />
            <Route path="/edit-address/:id" element={<EditShippingAddress />} />
            <Route path="/new-address" element={<StepOne />} />
            <Route path="/video-demo" element={<VideoCallDemo />} />
            {/* <Route path="/book-online" element={<BookOnline />} /> */}
            <Route path="/spin-wheel" element={<SpinWheel />} />
            <Route path="/compare-product" element={<CompareProduct />} />
            <Route path="/write-review/:id" element={<RightReviews />} />
            <Route path="/return/:order_id" element={<Return />} />
            <Route path="/cancel/:order_id" element={<OrderCancel />} />
            <Route path="/register-robot" element={<RegisterRobot />} />
            <Route path="/updatepassword/:id" element={<ChangenewPassword />} />

            <Route path="*" element={<Home />} />

          </Routes>
          {window.location.pathname !== "/homenew" ? (
            <Footer />
          ) : null}
        </BrowserRouter>
      </Provider>
    </div>
  );
}

export default App;