import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { fetchOrderHistoryRequest } from "../../redux/action/OrderAction";
import Loader from "../Shared/Loader";
import OrderInvoice from "./OrderInvoice";

export default function OrderHistory() {
  const [showInvoice, setshowInvoice] = useState(false);
  const [order_id, setOrder_id] = useState("");

  const [currentPage, setcurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [pageNumberLimit] = useState(5);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0);
  const dispatch = useDispatch();

  const Orderinfo = useSelector((state) => state.Order);

  let { loading, error, result } = Orderinfo;

  let formatter = new Intl.DateTimeFormat("en-GB", {
    year: "numeric",
    month: "numeric",
    day: "2-digit",
  });

  const handleClick = (e) => {
    setcurrentPage(Number(e.target.id));
  };

  const pages = [];
  for (let i = 1; i <= Math.ceil(result.length / itemsPerPage); i++) {
    pages.push(i);
  }

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = result.slice(indexOfFirstItem, indexOfLastItem);

  const renderPageNumbers = pages.map((number) => {
    if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
      return (
        <li
          key={number}
          id={number}
          onClick={handleClick}
          className={currentPage === number ? "active" : null}
        >
          {number}
        </li>
      );
    } else {
      return null;
    }
  });

  const handleNextbtn = () => {
    setcurrentPage(currentPage + 1);

    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };

  const handlePrevbtn = () => {
    setcurrentPage(currentPage - 1);

    if ((currentPage - 1) % pageNumberLimit === 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };

  let pageIncrementBtn = null;
  if (pages.length > maxPageNumberLimit) {
    pageIncrementBtn = <li onClick={handleNextbtn}> &hellip; </li>;
  }

  let pageDecrementBtn = null;
  if (minPageNumberLimit >= 1) {
    pageDecrementBtn = <li onClick={handlePrevbtn}> &hellip; </li>;
  }

  let j = currentPage * 10 + 1 - 10;

  const handleInvoice = (id) => {
    setOrder_id(id);
    setshowInvoice(!showInvoice);
  };

  var date = new Date();
  date.setDate(date.getDate() - 13);

  console.log(date);
  return (
    <div className="orderHistory">
      <h2 className="title">Order History</h2>
      <div className="product_cart">
        <div className="">
          <div className="regrobot">
            {loading == true ? (
              <>
                <Loader />
              </>
            ) : (
              <>
                {result.length > 0 ? (
                  <>
                    <table>
                      <thead>
                        <tr>
                          <th className="section-header">Sr.No</th>
                          <th className="section-header">Order ID</th>
                          <th className="section-header">Total</th>
                          <th className="section-header Purchase">
                            Final Total
                          </th>
                          <th className="section-header">Order Status</th>
                          <th className="section-header Purchase">
                            Date of Purchase
                          </th>
                          <th className="section-header return-head">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentItems &&
                          currentItems.map((item, i) => (
                            <tr key={i}>
                              <td>{j++}</td>
                              <td>
                                <button
                                  onClick={() => handleInvoice(item.order_id)}
                                >
                                  {item.order_id} / {item.id}
                                </button>
                              </td>

                              <td>

                                <span className="sale">{item.total}</span>

                              </td>
                              <td>

                                <span className="sale">
                                  {item.final_total}
                                </span>

                              </td>
                              <td>

                                <span className="sale">
                                  {item.order_status.name}
                                </span>

                              </td>
                              <td className="purchase_date">
                                {formatter.format(Date.parse(item.created_at))}
                              </td>
                              <td className="return-product">
                                {item.order_status.id == 1 ||
                                  item.order_status.id == 2 ||
                                  item.order_status.id == 3 ||
                                  item.order_status.id == 4 ? (
                                  <Link to={`/cancel/${item.order_id}`}>
                                    Cancel
                                  </Link>
                                ) : item.order_status.id == 5 ? (
                                  <Link to={`/return/${item.order_id}`}>
                                    Return
                                  </Link>
                                ) : item.order_status.id == 6 ? (
                                  <strong>Canceled</strong>
                                ) : item.order_status.id == 7 ? (
                                  <strong>Returned</strong>
                                ) : (
                                  item.order_status.name
                                )}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                    {result.length > 10 ? (
                      <div className="mt-4">
                        <ul className="pageNumbers">
                          <li>
                            <button
                              onClick={handlePrevbtn}
                              disabled={currentPage === pages[0] ? true : false}
                            >
                              Prev
                            </button>
                          </li>
                          {pageDecrementBtn}
                          {renderPageNumbers}
                          {pageIncrementBtn}

                          <li>
                            <button
                              onClick={handleNextbtn}
                              disabled={
                                currentPage === pages[pages.length - 1]
                                  ? true
                                  : false
                              }
                            >
                              Next
                            </button>
                          </li>
                        </ul>
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  "No Order History"
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {showInvoice ? <OrderInvoice order_id={order_id} /> : null}
    </div>
  );
}
