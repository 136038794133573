import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelope } from "@fortawesome/fontawesome-free-solid";
import ContactForm from "./ContactForm";
import { useEffect } from "react";
export default function Contact() {
  const [message, setMesssage] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    //navigate('/thanks-you', { state: { messagefor: "contact", heading: "Booking successful", message: "Thank you for taking the first step We will get in touch with you shortly." } });

  });


  return (
    <>

      <section className="section " >
        <div className="container" >
          <div className="contact_us">
            <h2 className="title center"> </h2>
            <div className="contact_us-box">

              <p className="page-title">Contact iRobot Customer Care </p>
              <p className="paragragh">

                Please use the following information to contact iRobot Customer Care for additional assistance.
              </p>
              <h2 className="font-weight-bolder"> Technical Support  </h2>
              <p className="paragraghN"> For Technical Support, please call:</p>

              <p className="paragraghN">


                <i class="fa fa-phone" aria-hidden="true"></i>&nbsp;<a href="tel:18002003355" class="text-green">1800-200-33-55</a>
              </p>

              <p className="paragraghN">

                <i class="fa fa-envelope" aria-hidden="true"></i>&nbsp;Contact <a href="mailto:service@puresight.co.in" class="text-button text-green">iRobot Customer Care</a> via email.
              </p>
              <br></br>
              <br></br>

              <h2 className="font-weight-bolder"> Retailers  </h2>
              <p className="paragraghN">
                For Distribution inquires, please contact: </p>
              <a href="mailto:contact@puresight.co.in" class="text-green">contact@puresight.co.in</a>
              <br></br>
              Please include the country of interest in the memo line.
              <br></br>
              <br></br>

              <h2 class="h2 font-weight-bolder">Hours of Operation</h2>
              <p class="h2 font-weight-bolder">All Day, <span class="font-weight-normal font-italic">10:30 am - 7:30 pm</span></p>

            </div>
            <button className="btn send_msg btncontact" onClick={() => setMesssage(true)}>
              Send Us a Message
            </button>
          </div>
        </div>
      </section>

      {message ? <ContactForm /> : ""}
    </>
  );
}
