import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadAllNews } from "../redux/action/AdditionalAction";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import NewsTab from "../component/media_coverge/NewsTab";
import MediaTab from "../component/media_coverge/MediaTab";

export default function News() {
  const dispatch = useDispatch();

  useEffect(() => {
    document.getElementById("maintittle").innerHTML = "iRobot India - Latest News - Product Launch Stories & Tech Upgrade";
    document.getElementById("description").setAttribute("content", "iRobot latest news, product launch stories, pictures, special product reviews, news, technology upgradation etc. on the official iRobot India website.");

    dispatch(loadAllNews());
  }, []);

  const blogData = useSelector((state) => state.Additional);

  const { loading, error, news } = blogData;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section className="section" style={{ background: "#fff" }}>
      <div className="container">
        <Tabs className="news_media">
          <TabList>
            <Tab>News</Tab>
            <Tab>Media Coverage</Tab>
          </TabList>


          <TabPanel>
            <NewsTab loading={loading} error={error} news={news} />
          </TabPanel>

          <TabPanel>
            <MediaTab loading={loading} error={error} news={news} />
          </TabPanel>
        </Tabs>
      </div>
    </section>
  );
}
