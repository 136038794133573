import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { loadAllProductList } from "../../redux/action/ProductListingAction";
import { Link, useParams } from "react-router-dom";
import series_bundel_1 from "../../image/series_bundel_1.png";

export default function SeriesBundel(props) {
  const dispatch = useDispatch();
  const [Productlist, setProductlist] = useState();
  const [ProductListing, setProductListing] = useState([]);

  //let { id } = useParams();
  useEffect(() => {
    console.log("useEffect..SeriesBundel..", props.ProductListing)

    setProductListing(props.ProductListing);
    setProductlist(props.ProductListing[0]?.catid.alias);
  });

  return (
    <section className="section shop_now">
      <div className="container">

        {ProductListing.slice(0, 1).map((shopnow) => {
          return (
            <><h2 className="title" key={shopnow.id}>
              {shopnow.subcategory.catid.name}
              <span className="subheadseries">{shopnow.subcategory.name}</span>
            </h2>
              <div>


                <h2 className="serieshtext">
                  <span className="catsubheadgreen"> {shopnow.subcategory.metatag3 != 'null' ? shopnow.subcategory.metatag3 : ''} </span>
                </h2>
              </div>
            </>
          );
        })}

        <ul>
          {ProductListing.map((shopnow) => {
            return (
              <li key={shopnow.id}>
                <div className="shop_now_content">
                  <div className="product_main">
                    {shopnow.tag ? (
                      // <span className="savving">{shopnow.tag}</span>
                      ""
                    ) : (
                      " "
                    )}
                    <h3>{shopnow.name}</h3>
                    {/*
                    <p>{shopnow.subcategory.catid.alias}</p>
                    */}

                  </div>
                  <div className="product_imagew">
                    <Link to={`/product/${shopnow.slug}`}>
                      <img src={`${process.env.REACT_APP_API_PATH}${shopnow.image}`} />
                    </Link>
                  </div>
                  <div className="product_descripton">
                    <div className="series_price">
                      <p className="regular">
                        {shopnow.price != null && shopnow.price != "" ? (
                          <strike>₹{shopnow.price}</strike>
                        ) : (
                          " "
                        )}

                        <span className="sale">₹{shopnow.sellprice}</span>
                      </p>
                      {shopnow.price != null && shopnow.sellprice != null && shopnow.price != "" && shopnow.sellprice != "" && (shopnow.price - shopnow.sellprice) != 0 ? (
                        <p className="saving_price">


                          Save: ₹{shopnow.price - shopnow.sellprice} (
                          {Math.ceil(
                            (shopnow.price - shopnow.sellprice) /
                            (shopnow.price / 100)
                          )}
                          %)
                        </p>
                      ) : (
                        " "
                      )}
                    </div>
                    <Link
                      to={`/product/${shopnow.slug}`}
                      className="btn shop_now"
                    >
                      Shop Now
                    </Link>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </section>
  );
}
