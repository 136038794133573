import {
  Find_ALL_PRODUCT_DETAILS_FAIL,
  Find_ALL_PRODUCT_DETAILS_REQUEST,
  Find_ALL_PRODUCT_DETAILS_SUCCESS,
  Find_ALL_PRODUCT_IMAGES_FAIL,
  Find_ALL_PRODUCT_IMAGES_REQUEST,
  Find_ALL_PRODUCT_IMAGES_SUCCESS,
  Find_ALL_PRODUCT_SPECIFICATION_FAIL,
  Find_ALL_PRODUCT_SPECIFICATION_REQUEST,
  Find_ALL_PRODUCT_SPECIFICATION_SUCCESS,
  Find_ALL_PRODUCT_ADD_DETAIL_FAIL,
  Find_ALL_PRODUCT_ADD_DETAIL_REQUEST,
  Find_ALL_PRODUCT_ADD_DETAIL_SUCCESS,
  Find_ACCESSORY_PRODUCT_LIST_FAIL,
  Find_ACCESSORY_PRODUCT_LIST_REQUEST,
  Find_ACCESSORY_PRODUCT_LIST_SUCCESS,
  Find_PRODUCT_LIST_FAIL,
  Find_PRODUCT_LIST_REQUEST,
  Find_PRODUCT_LIST_SUCCESS,
  SEARCH_PRODUCT_LIST_FAIL,
  SEARCH_PRODUCT_LIST_REQUEST,
  SEARCH_PRODUCT_LIST_SUCCESS,
  FIND_ALL_PRODUCT_DESC_FAIL,
  FIND_ALL_PRODUCT_DESC_REQUEST,
  FIND_ALL_PRODUCT_DESC_SUCCESS,
  FIND_ALL_PRODUCT_FAQ_FAIL,
  FIND_ALL_PRODUCT_FAQ_REQUEST,
  FIND_ALL_PRODUCT_FAQ_SUCCESS,
  LOAD_ALL_MAIN_PRODUCT_LIST_FAIL,
  LOAD_ALL_MAIN_PRODUCT_LIST_REQUEST,
  LOAD_ALL_MAIN_PRODUCT_LIST_SUCCESS,
} from "../constants/ProductConstant";

const initialState = {
  loading: false,
  action: "Main Product",
  result: [],
  result2: [],
  result3: [],
  search: [],
  response: {},
  singledata: [],
  productImages: [],
  productDesc: [],
  productFaq: [],
  productAddDetails: [],
  productSpec: [],
  allProducts: [],
  msg: "",
  error: "",
};

const ProductReducer = (state = initialState, action) => {
  switch (action.type) {
    case Find_ALL_PRODUCT_DETAILS_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case Find_ALL_PRODUCT_DETAILS_SUCCESS:
      return {
        ...state,
        singledata: action.result.data,
        loading: action.payload,
        msg: action.msg,
      };
    case Find_ALL_PRODUCT_DETAILS_FAIL:
      return {
        ...state,
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };

    case Find_PRODUCT_LIST_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case Find_PRODUCT_LIST_SUCCESS:
      return {
        ...state,
        result3: action.result.data,
        loading: action.payload,
        msg: action.msg,
      };
    case Find_PRODUCT_LIST_FAIL:
      return {
        ...state,
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };

    case SEARCH_PRODUCT_LIST_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case SEARCH_PRODUCT_LIST_SUCCESS:
      return {
        ...state,
        search: action.result.data,
        loading: action.payload,
        msg: action.msg,
      };
    case SEARCH_PRODUCT_LIST_FAIL:
      return {
        ...state,
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };

    case Find_ACCESSORY_PRODUCT_LIST_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case Find_ACCESSORY_PRODUCT_LIST_SUCCESS:
      return {
        ...state,
        result2: action.result.data,
        loading: action.payload,
        msg: action.msg,
      };
    case Find_ACCESSORY_PRODUCT_LIST_FAIL:
      return {
        ...state,
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };

    case Find_ALL_PRODUCT_SPECIFICATION_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case Find_ALL_PRODUCT_SPECIFICATION_SUCCESS:
      return {
        ...state,
        productSpec: action.result.data,
        loading: action.payload,
        msg: action.msg,
      };
    case Find_ALL_PRODUCT_SPECIFICATION_FAIL:
      return {
        ...state,
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };

    case Find_ALL_PRODUCT_IMAGES_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case Find_ALL_PRODUCT_IMAGES_SUCCESS:
      return {
        ...state,
        productImages: action.result.data,
        loading: action.payload,
        msg: action.msg,
      };
    case Find_ALL_PRODUCT_IMAGES_FAIL:
      return {
        ...state,
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };

    case Find_ALL_PRODUCT_IMAGES_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case Find_ALL_PRODUCT_IMAGES_SUCCESS:
      return {
        ...state,
        productImages: action.result.data,
        loading: action.payload,
        msg: action.msg,
      };
    case Find_ALL_PRODUCT_IMAGES_FAIL:
      return {
        ...state,
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };

    case FIND_ALL_PRODUCT_DESC_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case FIND_ALL_PRODUCT_DESC_SUCCESS:
      return {
        ...state,
        productDesc: action.result.data,
        loading: action.payload,
        msg: action.msg,
      };
    case FIND_ALL_PRODUCT_DESC_FAIL:
      return {
        ...state,
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };

    case FIND_ALL_PRODUCT_FAQ_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case FIND_ALL_PRODUCT_FAQ_SUCCESS:
      return {
        ...state,
        productFaq: action.result.data,
        loading: action.payload,
        msg: action.msg,
      };
    case FIND_ALL_PRODUCT_FAQ_FAIL:
      return {
        ...state,
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };

    case Find_ALL_PRODUCT_ADD_DETAIL_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case Find_ALL_PRODUCT_ADD_DETAIL_SUCCESS:
      return {
        ...state,
        productAddDetails: action.result.data,
        loading: action.payload,
        msg: action.msg,
      };
    case Find_ALL_PRODUCT_ADD_DETAIL_FAIL:
      return {
        ...state,
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };

    case LOAD_ALL_MAIN_PRODUCT_LIST_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case LOAD_ALL_MAIN_PRODUCT_LIST_SUCCESS:
      return {
        ...state,
        allProducts: action.result.data,
        loading: action.payload,
        msg: action.msg,
      };
    case LOAD_ALL_MAIN_PRODUCT_LIST_FAIL:
      return {
        ...state,
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };

    default:
      return state;
  }
};

export default ProductReducer;
