export const FIND_SERIES_REQUEST = "FIND_SERIES_REQUEST";
export const FIND_SERIES_SUCCESS = "FIND_SERIES_SUCCESS";
export const FIND_SERIES_FAIL = "FIND_SERIES_FAIL";

export const FIND_PROUDLIST_REQUEST = "FIND_PROUDLIST_REQUEST";
export const FIND_PROUDLIST_SUCCESS = "FIND_PROUDLIST_SUCCESS";
export const FIND_PROUDLIST_FAIL = "FIND_PROUDLIST_FAIL";

export const FIND_GENUIS_REQUEST = "FIND_GENUIS_REQUEST";
export const FIND_GENUIS_SUCCESS = "FIND_GENUIS_SUCCESS";
export const FIND_GENUIS_FAIL = "FIND_GENUIS_FAIL";

export const FIND_OFFER_CONTENT_REQUEST = "FIND_OFFER_CONTENT_REQUEST";
export const FIND_OFFER_CONTENT_SUCCESS = "FIND_OFFER_CONTENT_SUCCESS";
export const FIND_OFFER_CONTENT_FAIL = "FIND_OFFER_CONTENT_FAIL";

export const FIND_ABOUTUS_REQUEST = "FIND_ABOUTUS_REQUEST";
export const FIND_ABOUTUS_SUCCESS = "FIND_ABOUTUS_SUCCESS";
export const FIND_ABOUTUS_FAIL = "FIND_ABOUTUS_FAIL";

export const FIND_PRIVACY_POLICY_REQUEST = "FIND_PRIVACY_POLICY_REQUEST";
export const FIND_PRIVACY_POLICY_SUCCESS = "FIND_PRIVACY_POLICY_SUCCESS";
export const FIND_PRIVACY_POLICY_FAIL = "FIND_PRIVACY_POLICY_FAIL";

export const FIND_TERM_CONDITION_REQUEST = "FIND_TERM_CONDITION_REQUEST";
export const FIND_TERM_CONDITION_SUCCESS = "FIND_TERM_CONDITION_SUCCESS";
export const FIND_TERM_CONDITION_FAIL = "FIND_TERM_CONDITION_FAIL";

export const FIND_DATA_SECURITY_REQUEST = "FIND_DATA_SECURITY_REQUEST";
export const FIND_DATA_SECURITY_SUCCESS = "FIND_DATA_SECURITY_SUCCESS";
export const FIND_DATA_SECURITY_FAIL = "FIND_DATA_SECURITY_FAIL";

export const FIND_RETURN_POLICY_REQUEST = "FIND_RETURN_POLICY_REQUEST";
export const FIND_RETURN_POLICY_SUCCESS = "FIND_RETURN_POLICY_SUCCESS";
export const FIND_RETURN_POLICY_FAIL = "FIND_RETURN_POLICY_FAIL";

export const FIND_COVID_UPDATE_REQUEST = "FIND_COVID_UPDATE_REQUEST";
export const FIND_COVID_UPDATE_SUCCESS = "FIND_COVID_UPDATE_SUCCESS";
export const FIND_COVID_UPDATE_FAIL = "FIND_COVID_UPDATE_FAIL";


export const FIND_CATEGORY_REQUEST = "FIND_CATEGORY_REQUEST";
export const FIND_CATEGORY_SUCCESS = "FIND_CATEGORY_SUCCESS";
export const FIND_CATEGORY_FAIL = "FIND_CATEGORY_FAIL";

export const FIND_CATEGORY_TAB_REQUEST = "FIND_CATEGORY_TAB_REQUEST";
export const FIND_CATEGORY_TAB_SUCCESS = "FIND_CATEGORY_TAB_SUCCESS";
export const FIND_CATEGORY_TAB_FAIL = "FIND_CATEGORY_TAB_FAIL";

export const FIND_SUBCATEGORY_REQUEST = "FIND_SUBCATEGORY_REQUEST";
export const FIND_SUBCATEGORY_SUCCESS = "FIND_SUBCATEGORY_SUCCESS";
export const FIND_SUBCATEGORY_FAIL = "FIND_SUBCATEGORY_FAIL";

export const FIND_SUBCATEGORY_TAB_REQUEST = "FIND_SUBCATEGORY_TAB_REQUEST";
export const FIND_SUBCATEGORY_TAB_SUCCESS = "FIND_SUBCATEGORY_TAB_SUCCESS";
export const FIND_SUBCATEGORY_TAB_FAIL = "FIND_SUBCATEGORY_TAB_FAIL";

export const FIND_GENIUS_REQUEST = "FIND_GENIUS_REQUEST";
export const FIND_GENIUS_SUCCESS = "FIND_GENIUS_SUCCESS";
export const FIND_GENIUS_FAIL = "FIND_GENIUS_FAIL";

export const FIND_GENIUS_TAB_REQUEST = "FIND_GENIUS_TAB_REQUEST";
export const FIND_GENIUS_TAB_SUCCESS = "FIND_GENIUS_TAB_SUCCESS";
export const FIND_GENIUS_TAB_FAIL = "FIND_GENIUS_TAB_FAIL";