import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Step1 from "./Step-1";
import EditShippingAddress from "./EditShippingAddress";
import { loadAllUserAddress } from "../../redux/action/deliveryAddressAction";
import Loader from "../Shared/Loader";
import { loadAllCart } from "../../redux/action/CartAction";
import Message from "../Shared/Message";
import Axios from "axios";
import useRazorpay from "react-razorpay";
import { isEdge } from "react-device-detect";
import { isEmptyObject } from "jquery";

export default function ShippingAddress() {
  const Razorpay = useRazorpay();
  const [genorderid, setgenorderid] = useState("");

  document.getElementById("maintittle").innerHTML =
    "Checkout - Shipping details";
  document
    .getElementById("description")
    .setAttribute(
      "content",
      "Checkout - Enter your shipping address details and shipping method."
    );

  const [showNew, setshowNew] = useState(false);
  // const onClickNew = () => setshowNew(!showNew);
  const [showEdit, setshowEdit] = useState(false);
  const [addressId, setAddressId] = useState(0);
  const [setName] = useState("");

  const [amount, setAmount] = useState(0);

  const [subTotalamount, setSubTotalamount] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [shipping_price, setShipping_price] = useState(0);
  const [tax, setTax] = useState(0);
  // const [total_amount, setTotal_amount] = useState(0);
  const [setProductId] = useState("");
  const [discount, setDiscount] = useState(0);
  const [coupan_id, setCoupan_id] = useState();
  const [coupondiscount, setcoupondiscount] = useState(0.0);
  const [loyalitypointused, setloyalitypointused] = useState(0.0);

  const [offerdicount, setofferdicount] = useState(0.0);
  const [selectedaddressobj, setselectedaddressobj] = useState();

  //const [action, setaction] = useState("https://test.ccavenue.com/transaction/transaction.do?command=initiateTransaction");
  //const [encrpty, setencrpty] = useState("2ae153dd71da723edfc831bf0399cbfb643909472ad93e403473ec4c56ae4458fb6a03ea1c21512196d920c58392a38bb7e5b57ed6abbd041a008016f110133f43f371d03c514eee60787af7903aba9b5e0cbca5b14ac25d8fcd9c68a9389545afbb88f6e80367d4d783ce84f29be4fc4d43aac02d1e518592e7c7e85ee38354814419854d9712df40e95920c31c2ff1956a540a02c3086668d362cdc7e2e8613f19b8b458c7bdd81efc502c9d2fe1ea9ec0588036f6e46120187f6c9d69ad2da21b797d5cb6830fe159a8ba4aacade22991d3add3306116ce95047766c4baf755e7f818f5c6764d27ba92b9a68603889cfff38f955dcca9b9362d72d4464f9b5d5db1a807038163244a7dc29241787b21dd728541ab412a37c23ba97ba1243e0d367537a175911a60a20bd368433b44b46b7dcd4ad3145d0e6d3fe6bad67c5314fe4a6aaf18d0fb33cbe6a2f0153a0c373d9bee3d0d86d9a536985f8a59a50f32dee35470c58260bc6e9efa585275b5764337ecf3d5012075639f59976c6992903418b2392114b4144f5c232515e013b707bc95c930d08a7125384e30490f170aad72007305b178cbe79aae67f1055cdbc94850679060fa58b2a703e6e37f8bd39ffa036f40d136d3d1f809ed60675157321d3f068203217180b6ebaafae6e7ccd3e3aa8888783af880a6fc4b43d13bab223de56e9f584a52869e8b52ef41a25de49d862a5f7192808f88d21f543876aca7fbc35a4a4b60ea5d14cb557ac90fca83dac38c6f6a2ebc8463d4ebb5831625e22ca9f7e15abec83c8b5ec9478c54f3c23327c96cc8ab8e740f3f81d7671b364749697177c83177826137f0369e08b12eff1afdfb78b30d7eb79ba0d338e46bd5a0a43c3b63c4b4c45a8a2c1f05798de6f98944971d83f2936437d4396d41");
  //const [access_code, setaccess_code] = useState("AVWN04JH40BY45NWYB");

  const [action, setaction] = useState("");
  const [encrpty, setencrpty] = useState("");
  const [access_code, setaccess_code] = useState("");

  // const [payment_method, setPayment_method] = useState();
  // const [payment_status, setPayment_status] = useState();
  // const [transaction_id, setTransaction_id] = useState();
  // const [order_status, setOrder_status] = useState();

  const location = useLocation();

  const dispatch = useDispatch();
  const [gstNumber, setGstNumber] = useState("");

  // let user = useSelector((state) => state.AuthReducer.userResult);

  let navigate = useNavigate();

  let user = useSelector((state) => state.AuthReducer);

  let { isSigninIn, userResult } = user;

  var user_id = userResult.id;
  var user_email = userResult.email;

  const userAddress = useSelector((state) => state.DeliveryAddress);

  let { error, loading, result } = userAddress;

  console.log("genorderid..", genorderid);
  console.log("email.....", user_email);

  let cartData = useSelector((state) => state.Cart.result);
  let tmsg = useSelector((state) => state.Cart.tmsg);
  useEffect(() => {
    if (result != undefined && !isEmptyObject(result)) {
      console.log("useEffect result " + result[0]);
      setAddressId(result[0].id);

      //var shipcharge = "400";
      // var shipcharge = 0.0;

      setselectedaddressobj(result[0]);
      //setShipping_price(shipcharge);

      var totamount = Number(subTotalamount) - Number(coupondiscount);
      var gstamount = totamount - [totamount * (100 / (100 + 18))];
      setTax(gstamount.toFixed(0));
      var finaltot = Number(totamount) + Number(shipping_price);
      var finaltot =
        Number(totamount) + Number(shipping_price) - Number(loyalitypointused);

      setAmount(finaltot.toFixed(2));
    }
  }, [result]);

  useEffect(() => {
    var shipcharge = "400";

    setShipping_price(0.0);

    cartData.forEach((data) => {
      console.log(data);
      console.log("fff" + data.product_id.type);
      if (data.product_id.type == 2) {
        setShipping_price(shipcharge);
      }
    });
  }, [selectedaddressobj]);

  useEffect(() => {
    //setCoupan_id(couponid);
    if (isSigninIn != true) {
      console.log("useEffect isSigninIn " + isSigninIn);

      navigate("/login");
    }
    if (location.state != null) {
      const { coupondicountvalue, couponid, loyalitypointused } =
        location.state;
      //console.log('..coupondicountvalue..', coupondicountvalue)
      //console.log('..couponid..', couponid)
      setcoupondiscount(coupondicountvalue);
      setCoupan_id(couponid);
      setloyalitypointused(loyalitypointused);
    }
    if (user_id != null) {
      console.log("loadAllCart(user_id)", user_id);
      if (user_id != null && user_id != undefined) {
        dispatch(loadAllCart(user_id));
      }
      dispatch(loadAllUserAddress(user_id));

      var dn = new Date();
      var genorderidrcv = "order_" + dn.getTime().toString();
      setgenorderid("order_9A33XWu170gUtm");
    }
  }, [user_id]);

  useEffect(() => {
    console.log("tmsg.. ", tmsg);
    if (tmsg == "home") {
      window.location.href = "/";
    }
  }, [tmsg]);

  const addDecimal = (num) => {
    return (Math.round(num * 100) / 100).toFixed(2);
  };

  const setaddressPostcode = (e, addressid, postcode, itemobj) => {
    var shipcharge = "400";

    if (e.target.checked) {
      setShipping_price(0.0);

      cartData.forEach((data) => {
        console.log("fff" + data.product_id.type);
        if (data.product_id.type == 2) {
          setShipping_price(shipcharge);
        }
      });

      setAddressId(addressid);
      setselectedaddressobj(itemobj);

      console.log("...itemobj..", itemobj);

      var totamount = Number(subTotalamount) - Number(coupondiscount);
      var gstamount = totamount - [totamount * (100 / (100 + 18))];
      setTax(gstamount.toFixed(0));
      console.log(
        addressid +
          "....." +
          postcode +
          ".." +
          e.target.checked +
          "..." +
          shipping_price
      );

      // var finaltot = Number(totamount) + Number(gstamount) + Number(shipcharge);
      // var finaltot = Number(totamount) + Number(shipcharge);
      var finaltot =
        Number(totamount) + Number(shipcharge) - Number(loyalitypointused);
      setAmount(finaltot.toFixed(2));
    } else {
      setselectedaddressobj(null);
      setAddressId("");

      setShipping_price(0.0);

      var totamount = Number(subTotalamount) - Number(coupondiscount);
      var gstamount = totamount - [totamount * (100 / (100 + 18))];
      setTax(gstamount.toFixed(2));
      console.log(
        addressid +
          "....." +
          postcode +
          ".." +
          e.target.checked +
          "..." +
          shipping_price
      );

      //var finaltot = Number(totamount) + Number(gstamount);
      //var finaltot = Number(totamount);
      var finaltot = Number(totamount) - Number(loyalitypointused);

      setAmount(finaltot.toFixed(2));
    }
  };
  useEffect(() => {
    if (cartData.length > 0) {
      var tot = 0.0;
      cartData.forEach((data) => {
        tot = tot + data.quantity * data.product_id.sellprice;
      });
      var gstamount = tot - [tot * (100 / (100 + 18))];

      console.log("cartData...", cartData);
      //setcoupondiscount(coupondicountvalue);

      setSubTotalamount(tot.toFixed(2));

      var coupondiscountrcv = "0";
      var couponidrcv = "";
      var loyalitypointusedrcv = "0";

      if (location.state != null) {
        const { coupondicountvalue, couponid, loyalitypointused } =
          location.state;
        coupondiscountrcv = 0.0;

        loyalitypointusedrcv = 0.0;
        if (
          loyalitypointused == null ||
          loyalitypointused == undefined ||
          loyalitypointused == ""
        ) {
          setloyalitypointused(0.0);
        } else {
          setloyalitypointused(coupondicountvalue);
          loyalitypointusedrcv = loyalitypointused;
        }

        if (
          coupondicountvalue == null ||
          coupondicountvalue == undefined ||
          coupondicountvalue == ""
        ) {
          setcoupondiscount(0.0);
        } else {
          setcoupondiscount(coupondicountvalue);
          coupondiscountrcv = coupondicountvalue;
        }
        if (couponid == null || couponid == undefined || couponid == "") {
        } else {
          setCoupan_id(couponid);
          couponidrcv = couponid;
        }
      }

      var totamount = Number(tot.toFixed(2)) - Number(coupondiscountrcv);
      var totamount = Number(tot.toFixed(2)) - Number(coupondiscountrcv);

      var gstamount = totamount - [totamount * (100 / (100 + 18))];
      setTax(gstamount.toFixed(2));
      //var finaltot = Number(totamount) + Number(gstamount) + Number(shipping_price);
      //var finaltot = Number(totamount) + Number(shipping_price);
      var finaltot =
        Number(totamount) + Number(shipping_price) - Number(loyalitypointused);
      console.log(totamount);
      console.log(shipping_price);
      console.log(loyalitypointused);

      setAmount(finaltot.toFixed(2));

      console.log("gstamount...", gstamount);
      console.log("subTotalamount...", tot.toFixed(2));
      console.log("coupondiscount...", coupondiscountrcv);
    }

    if (cartData) {
      /*
      setSubTotalamount(
        Number(
          cartData
            .reduce(
              (acc, item) => acc + item.quantity * item.product_id.sellprice,
              0
            )
            .toFixed(2)
        )
      ); 
      setQuantity(cartData.reduce((acc, item) => acc + item.quantity, 0));
      if (subTotalamount != null && subTotalamount != 0) {
        setTax(Number((0.15 * subTotalamount).toFixed(2)));
        if (subTotalamount >= 500) {
          setShipping_price(0);
        } else {
          setShipping_price(100);
        }
      }
      setAmount(Number(subTotalamount) + Number(tax) + Number(shipping_price));
      */
    }
  }, [cartData]);

  const handlePaymentSuccess = async (response) => {
    try {
      let bodyData = new FormData();

      bodyData.append("response", JSON.stringify(response));

      console.log("bodyData", bodyData);

      await Axios({
        url: `${process.env.REACT_APP_API_PATH}/api/v1/razorpay/payment/success/`,
        method: "POST",
        data: bodyData,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          console.log("Everything is OK!");
          setName("");
          setSubTotalamount("");
          setAddressId("");
          setProductId("");
          setAmount("");
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(console.error());
    }
  };

  const loadScript = () => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    document.body.appendChild(script);
  };

  const showRazorpay_old = async () => {
    //const order = await createOrder(params);

    //const res = await loadScript();

    if (addressId == "" || addressId == null || addressId == undefined) {
      alert("Please select address");
      return;
    }

    let mainDataordid = {
      user_id: user_id,
      final_total: Number(amount),
    };

    const datrcv = await Axios({
      url: `${process.env.REACT_APP_API_PATH}/api/v1/create-orderid/`,
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: mainDataordid,
    }).then((res) => {
      console.log("dataRes", res.data.data);
      return res.data.data;
    });

    var options = {
      key: `rzp_test_TOkIbpFJx43vty`,
      amount: Number(amount),
      currency: "INR",
      name: "I-Robot",
      description: "Pyament For the Item Purchase",
      image: "",
      order_id: datrcv,
      handler: function (response) {
        // handlePaymentSuccess(response);

        let mainData = {
          user_id: user_id,
          total: Number(subTotalamount),
          final_total: Number(amount),
          gst: Number(tax),
          shipping_cost: Number(shipping_price),
          discount: coupondiscount,
          coupan_id: coupan_id,
          deliver_address_Id: Number(addressId),
          order_status: 1,
          payment_method: 1,
          payment_status: 3,
          razorpay_payment_id: response.razorpay_payment_id,
          paymentid: datrcv,
        };

        const datrcvord = Axios({
          url: `${process.env.REACT_APP_API_PATH}/api/v1/create-order/`,
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          data: mainData,
        }).then((res) => {
          console.log("datrcvord.,.", res.data);
          console.log("datrcvord.,.", res.data.data.order_id);

          //alert(response.razorpay_payment_id);
          // alert(response.razorpay_order_id);
          //alert(response.razorpay_signature);

          console.log("Object is NOT empty", response);

          // navigate('/thanks-you', { state: { messagefor: "contact", orderid: res.data.data.order_id, heading: "For Shopping with us... ", message: "Your Order placed successfully" } });
          navigate("/thanks-you-order", {
            state: {
              messagefor: "contact",
              orderid: res.data.data.order_id,
              heading: "Thank you For Shopping with us... ",
              message: "Your Order placed successfully",
            },
          });
        });
      },
      prefill: {
        name: userResult.firstname,
        email: userResult.email,
        contact: userResult.mobile,
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#3399cc",
      },
    };

    var rzp1 = new Razorpay(options);
    rzp1.on("payment.failed", function (response) {
      alert(response.error.code);
      alert(response.error.description);
      alert(response.error.source);
      alert(response.error.step);
      alert(response.error.reason);
      alert(response.error.metadata.order_id);
      alert(response.error.metadata.payment_id);
    });

    rzp1.open();

    //return res;
  };
  const productIdList = [];

  function extractProductIdsFromMap(dataMap) {
    // Initialize an empty array to store product IDs

    // Iterate over the values of the map
    for (const item of dataMap.values()) {
      // Extract the product ID from the item
      const productId = item.id;

      // Add the product ID to the list
      productIdList.push(productId);
    }
    console.log(productIdList);

    return productIdList;
  }

  const showRazorpay = async () => {
    //const order = await createOrder(params);

    //const res = await loadScript();

    if (addressId == "" || addressId == null || addressId == undefined) {
      alert("Please select address");
      return;
    }

    extractProductIdsFromMap(cartData);

    // handlePaymentSuccess(response);

    let mainData = {
      user_id: user_id,
      total: Number(subTotalamount),
      final_total: Number(amount),
      // gst: Number(tax),
      gst:gstNumber ??"",
      shipping_cost: Number(shipping_price),
      discount: coupondiscount,
      coupan_id: coupan_id ??"",
      deliver_address_Id: Number(addressId),
      order_status: 1,
      payment_method: 1,
      payment_status: 0,
      currency: "INR",
      language: "en",
      billing_name: selectedaddressobj.firstname,
      billing_address: selectedaddressobj.address,
      billing_city: selectedaddressobj.city,
      billing_state: selectedaddressobj.stateName,
      billing_zip: selectedaddressobj.postCode,
      billing_country: selectedaddressobj.country,
      billing_tel: selectedaddressobj.mobile,
      // billing_email: userResult.email,
      delivery_name: selectedaddressobj.firstname,
      delivery_address: selectedaddressobj.address,
      delivery_city: selectedaddressobj.city,
      delivery_state: selectedaddressobj.stateName,
      delivery_zip: selectedaddressobj.postCode,
      delivery_country: selectedaddressobj.country,
      delivery_tel: selectedaddressobj.mobile,
      billing_email: user_email,
      merchant_param1: "",
      merchant_param2: "",
      merchant_param3: "",
      merchant_param4: "",
      merchant_param5: "",
      cart: productIdList,
    };

    const datrcvord = Axios({
      url: `${process.env.REACT_APP_API_PATH}/api/v1/create-order-cc/`,
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: mainData,
    }).then((res) => {
      console.log("res  .,.", res);
      console.log("formaction.,.", res.data.formaction);
      console.log("accessCode.,.", res.data.accessCode);
      console.log("encryption.,.", res.data.encryption);
      setaction(res.data.formaction);
      setencrpty(res.data.encryption);
      setaccess_code(res.data.accessCode);
      document.getElementById("nameForm").submit();
    });
  };
  return (
    <>
      <form id="nameForm" action={action} method="POST">
        <input
          type="hidden"
          id="encRequest"
          name="encRequest"
          value={encrpty}
        />
        <input
          type="hidden"
          name="access_code"
          id="access_code"
          value={access_code}
        />
        <button type="submit" className="ccavform">
          Submit
        </button>
      </form>
      <section className="step">
        <div className="shipiing-address section">
          <div className="checkout_title">
            <h2 className="title">
              <span>Shipping Information</span>
            </h2>
            <p className="subheading">Select or Enter a Shipping Address</p>
          </div>
          {loading == true ? (
            <>
              <Loader />
            </>
          ) : (
            <>
              {result &&
                result.map((item, i) => (
                  <ul className="address-list" key={item.id}>
                    {i == 0 ? (
                      <li>
                        <div className="select-address">
                          <input
                            type="checkbox"
                            id={i}
                            checked={addressId === item.id}
                            onClick={(e) =>
                              setaddressPostcode(
                                e,
                                item.id,
                                item.postCode,
                                item
                              )
                            }
                          />
                          <label>Address {i + 1}</label>
                        </div>
                        <div className="addresslist">
                          {/*
                        <p>
                          <strong>
                            {item.firstname + " " + item.lastname}
                          </strong>
                          <span className="edit">
                            <Link to={`/edit-address/${item.id}`}>Edit</Link>
                          </span>
                        </p>
                        <p>{item.user_id.email}</p>
                        */}

                          <p>
                            {item.first_name}-{item.mobile}
                          </p>
                          <p>
                            {item.address} ,{item.house},Landmark-{" "}
                            {item.landmark}
                          </p>
                          <p>
                            {item.city} ,{item.stateName}- {item.postCode}
                          </p>

                          {/* <p>
                          {item.house +
                            ", " +
                            item.sector +
                            ", " +
                            item.street +
                            ", " +
                            item.city +
                            ", " +
                            item.stateName +
                            ", " +
                            item.country +
                            ", " +
                            item.postCode}
                        </p> */}
                        </div>
                      </li>
                    ) : (
                      <li>
                        <div className="select-address">
                          <input
                            type="checkbox"
                            id={i}
                            checked={addressId === item.id}
                            onClick={(e) =>
                              setaddressPostcode(
                                e,
                                item.id,
                                item.postCode,
                                item
                              )
                            }
                          />
                          <label>Address {i + 1}</label>
                        </div>
                        <div className="addresslist">
                          {/*
                        <p>
                          <strong>
                            {item.firstname + " " + item.lastname}
                          </strong>
                          <span className="edit">
                            <Link to={`/edit-address/${item.id}`}>Edit</Link>
                          </span>
                        </p>
                        <p>{item.user_id.email}</p>

                        */}
                          <p>
                            {item.first_name}-{item.mobile}
                          </p>
                          <p>
                            {item.address} ,{item.house},Landmark- {item.house}
                          </p>
                          <p>
                            {item.city} ,{item.stateName},- {item.postCode}
                          </p>

                          {/* <p>
                          {item.house +
                            ", " +
                            item.sector +
                            ", " +
                            item.street +
                            ", " +
                            item.city +
                            ", " +
                            item.stateName +
                            ", " +
                            item.country +
                            ", " +
                            item.postCode}
                        </p> */}
                        </div>
                      </li>
                    )}

                    {showEdit ? <EditShippingAddress id={item.id} /> : null}
                    {showNew ? <Step1 /> : null}
                  </ul>
                ))}
            </>
          )}

          <Link to={`/new-address`} className="btn update_address">
            Add New Address
          </Link>
          {showNew ? <Step1 /> : null}
          {/* <div className="line"></div> */}
          <div className="gstno">
            <h3>Do you have GST number? </h3>
            <label className="labelgst" htmlFor="gst">
              GST Number:{" "}
            </label>
            <input
              className="inputgst"
              type="text"
              id="gst"
              name="gst"
              value={gstNumber}
              onChange={(e) => {
                console.log(e.target.value); setGstNumber(e.target.value);
              }}
            />
          </div>
        </div>
      </section>

      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <aside className="section shipping_aside">
          {/* 
          <div className="freeShipping">
            <p className="subheading">Free Shipping</p>
            <p>
              <on all orders small>over ₹ 50 </on>
            </p>
          </div>
          */}
          <div className="ouder_summarry">
            <h2 className="subheading">
              Order Summary <Link to="/cart">Edit</Link>
            </h2>
            <ul className="product_summary">
              {cartData &&
                cartData.map((item, i) => (
                  <li key={item.product_id.id}>
                    <img
                      src={`${process.env.REACT_APP_API_PATH}${item.product_id.image}`}
                      width="50"
                      height="50"
                    />
                    <Link to={`/product/${item.product_id.slug}`}>
                      {item.product_id.name}
                    </Link>
                  </li>
                ))}
            </ul>
            <ul className="subtotal_summary">
              <li>
                <span>Subtotal</span>
                <span>₹ {subTotalamount}</span>
              </li>

              <li>
                <span>GST(included in the total amount)</span>
                <span>₹ {tax}</span>
              </li>
              <li>
                <span>Coupon Discount</span>
                <span>- ₹ {coupondiscount}</span>
              </li>
              <li>
                <span>Offer Discount</span>
                <span>- ₹ {offerdicount}</span>
              </li>

              <li>
                <span>Rewards Point</span>
                <span>- ₹ {loyalitypointused}</span>
              </li>

              <li>
                <span>Shipping Charges</span>
                <span>₹ {shipping_price}</span>
              </li>
            </ul>
            <div className="total_summary">
              <span>Order Total</span>
              <span>₹ {amount}</span>
            </div>
          </div>
          <div className="step_btn">
            <button
              onClick={
                // console.log(productIdList)
                showRazorpay
              }
              className="btn"
            >
              Continue
            </button>
          </div>
        </aside>
      )}
    </>
  );
}
