import { toast } from "react-toastify";
import axios from "axios";
import {
  CREATE_REGISTER_ROBOT_FAIL,
  CREATE_REGISTER_ROBOT_REQUEST,
  CREATE_REGISTER_ROBOT_SUCCESS,
  Find_ALL_REGISTER_ROBOT_FAIL,
  Find_ALL_REGISTER_ROBOT_REQUEST,
  Find_ALL_REGISTER_ROBOT_SUCCESS,
  Find_REGISTER_ROBOT_DETAIL_FAIL,
  Find_REGISTER_ROBOT_DETAIL_REQUEST,
  Find_REGISTER_ROBOT_DETAIL_SUCCESS,
  REMOVE_REGISTER_ROBOT_FAIL,
  REMOVE_REGISTER_ROBOT_REQUEST,
  REMOVE_REGISTER_ROBOT_SUCCESS,
  UPDATE_REGISTER_ROBOT_FAIL,
  UPDATE_REGISTER_ROBOT_REQUEST,
  UPDATE_REGISTER_ROBOT_SUCCESS,
} from "../constants/RegisterRobotConstant";

// action from get all the Register Robots
const loadAllRegisterRobots = (id) => {
  return function (dispatch) {
    dispatch({
      type: Find_ALL_REGISTER_ROBOT_REQUEST,
      payload: true,
    });

    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/register-robot-userid/${id}/`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };

    axios(OPTION)
      .then((res) => {
        console.log("res", res.data);
        dispatch(loadAllRegisterRobotsPre(res.data));
      })
      .catch((error) => {
        console.log("error", error);
        dispatch({
          type: Find_ALL_REGISTER_ROBOT_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
      });
  };
};
export const loadAllRegisterRobotsPre = (data) => {
  return {
    type: Find_ALL_REGISTER_ROBOT_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

// action from get the Register Robots Details

const loadRegisterRobotDetails = (id) => {
  return function (dispatch) {
    dispatch({
      type: Find_REGISTER_ROBOT_DETAIL_REQUEST,
      payload: true,
    });

    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/register-robot/${id}/`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };

    axios(OPTION)
      .then((res) => {
        dispatch(loadRegisterRobotDetailsPre(res.data));
      })
      .catch((error) => {
        dispatch({
          type: Find_REGISTER_ROBOT_DETAIL_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
      });
  };
};
export const loadRegisterRobotDetailsPre = (data) => {
  return {
    type: Find_REGISTER_ROBOT_DETAIL_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

// Register New Robot
const RegisterNewRobot = (data) => {
  return function (dispatch) {
    dispatch({
      type: CREATE_REGISTER_ROBOT_REQUEST,
      payload: true,
    });

    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/register-robot/`,
      method: "POST",
      data: data,
      headers: {
        "content-type": "application/json",
      },
    };

    axios(OPTION)
      .then((res) => {
        console.log("dara", res.data);
        dispatch(RegisterNewRobotPre(res.data));
      })
      .catch((error) => {
        console.log("error-data", error);
        dispatch({
          type: CREATE_REGISTER_ROBOT_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
        toast.danger(error.msg);
      });
  };
};
export const RegisterNewRobotPre = (data) => {
  toast.success(data.msg);
  if (data.success == 1) {
    window.location.href = "/dashboard";
  }

  return {
    type: CREATE_REGISTER_ROBOT_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

// Update Registered Robot
const updateRegisteredRobot = (data) => {
  return function (dispatch) {
    dispatch({
      type: UPDATE_REGISTER_ROBOT_REQUEST,
      payload: true,
    });

    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/register-robot/`,
      method: "POST",
      data: data,
      headers: {
        "content-type": "application/json",
      },
    };

    axios(OPTION)
      .then((res) => {
        console.log("dara", res.data);
        dispatch(updateRegisteredRobotPre(res.data));
      })
      .catch((error) => {
        console.log("error-data", error);
        dispatch({
          type: UPDATE_REGISTER_ROBOT_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
        toast.danger(error.msg);
      });
  };
};
export const updateRegisteredRobotPre = (data) => {
  toast.success(data.msg);
  return {
    type: UPDATE_REGISTER_ROBOT_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

// Remove Registered Robot
const removeRegisteredRobot = (data) => {
  return function (dispatch) {
    dispatch({
      type: REMOVE_REGISTER_ROBOT_REQUEST,
      payload: true,
    });

    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/register-robot-delete/`,
      method: "POST",
      data: data,
      headers: {
        "content-type": "application/json",
      },
    };

    axios(OPTION)
      .then((res) => {
        console.log("dara", res.data);
        dispatch(removeRegisteredRobotPre(res.data));
      })
      .catch((error) => {
        console.log("error-data", error);
        dispatch({
          type: REMOVE_REGISTER_ROBOT_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
        toast.danger(error.msg);
      });
  };
};
export const removeRegisteredRobotPre = (data) => {
  toast.success(data.msg);
  return {
    type: REMOVE_REGISTER_ROBOT_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

export {
  removeRegisteredRobot,
  updateRegisteredRobot,
  RegisterNewRobot,
  loadRegisterRobotDetails,
  loadAllRegisterRobots,
};
