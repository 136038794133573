import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Wheel } from "react-custom-roulette";
import { loadAllPromoCode } from "../../redux/action/AdditionalAction";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createLoyaltyReward, createEnquiryHome } from "../../redux/action/AdditionalAction";


var data = [];
var flagct = 0;
const SpinWheel = () => {


  const queryParams = new URLSearchParams(window.location.search);
  const [utm_source, setutm_source] = useState('');
  const [utm_campaign, setutm_campaign] = useState('');
  var utm_sourcercv = queryParams.get('utm_source');
  var utm_campaignrcv = queryParams.get('utm_campaign');

  useEffect(() => {
    if (utm_sourcercv != null && utm_sourcercv != '' && utm_sourcercv != undefined) {

      setutm_source(utm_sourcercv);
      setutm_campaign(utm_campaignrcv);
      localStorage.setItem('utm_source', utm_sourcercv);
      localStorage.setItem('utm_campaign', utm_campaignrcv);
    }
    else {
      var item1 = localStorage.getItem('utm_source');
      var item2 = localStorage.getItem('utm_campaign');
      if (item1 != null && item1 != undefined && item1 != '') {
        setutm_source(item1);
        setutm_campaign(item2);
      }

    }
  }, []);
  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(0);
  const [newData, setNewData] = useState("");
  const [code, setCode] = useState("");
  const [codeId, setCodeId] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");






  const [city, setCity] = useState("");
  const [category, setCategory] = useState("");
  const [first_name, setName] = useState("");
  const [last_name, setLastname] = useState("");
  const [landmark, setLandmark] = useState("");
  const [pincode, setPincode] = useState("");
  const [location, setLocation] = useState("");
  const [address, setAddress] = useState("");
  const [message, setMessage] = useState("");
  const [pe, setPe] = useState(0);
  const [reqtyop, setReqtyop] = useState("");
  const [title, settitle] = useState("SPIN  Enquiry");
  const [company, setcompany] = useState("");
  const [company_website, setcompany_website] = useState("");
  const [area_of_focus, setarea_of_focus] = useState("");
  const [area_of_expertise, setarea_of_expertise] = useState("");
  const [promotional_email, setpromotional_email] = useState("1");
  const [source, setsource] = useState(utm_source);
  const [campaign, setcampaign] = useState(utm_campaign);
  const dispatch = useDispatch();

  useEffect(() => {
    document.getElementById("maintittle").innerHTML = "Spin the Wheel";
    document.getElementById("description").setAttribute("content", "Spin the Wheel");
    dispatch(loadAllPromoCode());
  }, []);

  useEffect(() => {
    //window.scrollTo(0, 0);
  }, []);


  const Code = useSelector((state) => state.Additional);
  const { loading, error, promoCode } = Code;
  const userInfo = useSelector((state) => state.AuthReducer);
  const { userResult } = userInfo;
  let user_id = userResult.id;

  data = [];

  for (var i = 0; i < promoCode.length; i++) {
    data.push({ id: promoCode[i].id, option: promoCode[i].code });
  }

  const handleSpinClick = () => {
    const newPrizeNumber = Math.floor(Math.random() * data.length);
    setPrizeNumber(newPrizeNumber);
    flagct = 1;
    setMustSpin(true);
  };

  let data11 = data[prizeNumber];
  const handleSubmit = (e) => {


    /*
        if (
          data11 != null &&
          data11 != undefined &&
          mustSpin == false &&
          flagct == 1
        ) {
          flagct = 0;
    
          var codeData = data11.option;
          var codeIdData = data11.id;
          if (codeIdData !== null && codeIdData !== "") {
            setCode(codeIdData);
          }
     */
    e.preventDefault();
    if (!email || !mobile || !first_name) {
      toast.warning("Please input all input Field!");
    } else {
      const newPrizeNumber = Math.floor(Math.random() * data.length);
      setPrizeNumber(newPrizeNumber);
      flagct = 1;
      setMustSpin(true);

      let dataa = {
        email: email,
        name: first_name,
        mobile: mobile,
        // code: codeData,
        code: '',

        //code_id: codeIdData,
        code_id: '',

        user_id: user_id,
      };
      // dispatch(createLoyaltyReward(dataa));
      dispatch(createEnquiryHome(first_name, last_name, email, mobile, title, company, city, company_website, area_of_focus, area_of_expertise, message, promotional_email, source, campaign));





      // } // close for spin
    }
  };

  return (
    <>
      <div className="section spin-wheel">
        <div className="container">
          <div className="spin-wheel-form">
            <div className="acc_form">
              <form >
                <div className="input_field">
                  <label>
                    Name <span>*</span>
                  </label>
                  <input
                    required
                    type="text"
                    name="name"
                    value={first_name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="input_field">
                  <label>
                    Email Address <span>*</span>
                  </label>
                  <input
                    type="email"
                    required
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="input_field">
                  <label>
                    Mobile Number <span>*</span>
                  </label>
                  <input
                    type="number"
                    required
                    name="mobile"
                    minLength="10" 
                    maxLength="10"
                    value={mobile}
                    //onChange={(e) => setMobile(e.target.value)}
                    onChange={(e) => {
                      if (e.target.value.length < 11) {
                        setMobile(e.target.value);
                      }
                    }}
                  />
                </div>

                <div className="input_field_btn">
                  <input type="button" value="Submit" onClick={handleSubmit} />
                </div>
              </form>
            </div>
          </div>

          <div align="center" className="wheel-spin-right">
            <Wheel
              mustStartSpinning={mustSpin}
              prizeNumber={prizeNumber}
              data={data}
              outerBorderColor={["#f2f2f2"]}
              outerBorderWidth={[25]}
              innerBorderColor={["#f2f2f2"]}
              radiusLineColor={["#dedede"]}
              radiusLineWidth={[10]}
              textColors={["#ffffff"]}
              fontSize={[10]}
              perpendicularText={[true]}
              backgroundColors={[
                "#F22B35",
                "#F99533",
                "#24CA69",
                "#514E50",
                "#46AEFF",
                "#9145B7",
              ]}
              onStopSpinning={() => {
                setMustSpin(false);
              }}
            />
            {/* 
            <button className="button2" onClick={handleSpinClick}>
              SPIN
            </button>
            */}
          </div>
        </div>
      </div>
    </>
  );
};

export default SpinWheel;
