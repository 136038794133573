import React, { useEffect } from "react";
import Indicator from "../component/shipping/Indicator";

import ShippingAddress from "../component/shipping/ShippingAddress";
// import ShippingAside from "../component/shipping/ShippingAside";

export default function Shipping() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Indicator />
      <section className="step_shipping" style={{ background: "#fff" }}>
        <div className="container">
          <ShippingAddress />

          {/* <ShippingAside /> */}
        </div>
      </section>
    </>
  );
}
