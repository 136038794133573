import React from "react";

import ProductSlider from "../component/productDetail/ProductSlider";
import ProductDescription from "../component/productDetail/ProductDescription";
import ProductTabs from "../component/productDetail/ProductTabs";
import Review from "../component/productDetail/Review";
import WriteReview from "../component/productDetail/WriteReview";
import RecommendedForYou from "../component/productDetail/RecommendedForYou";
import SliderAccessories from "../component/productDetail/SliderAccessories";
import $ from "jquery";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { useState, useEffect } from "react";
import { loadAllProduct } from "../redux/action/ProductAction";

export default function ProductDetail() {
  const dispatch = useDispatch();

  // $(function () {
  //   $(".write_review_btn").click(function () {
  //     $(".write_review").show();
  //     $(".overlay_review").show();
  //   });
  //   $(".overlay_review").click(function () {
  //     $(".write_review").hide();
  //     $(this).hide();
  //   });
  //   $(".submt_comment").click(function () {
  //     setTimeout(function () {
  //       $(".review").hide();
  //       $(".overlay_review").hide();
  //     }, 2000);
  //   });
  // });
  useEffect(() => {
    window.scrollTo(0, 0);
    console.log("useEffect...1")
  }, []);



  return (
    <div className="container">
      <div className="single_product">
        <ProductSlider />
        {/* <MyComponent/> */}
        <ProductDescription />
      </div>
      {/* <RecommendedForYou /> */}
      <ProductTabs />
      <SliderAccessories />
      <Review />
      {/* <WriteReview /> */}
    </div>
  );
}
