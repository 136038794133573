import React from "react";
import { Link } from "react-router-dom";
import Loader from "../Shared/Loader";
import Message from "../share/Message";

export default function MediaTab({ loading, error, news }) {
  let formatter = new Intl.DateTimeFormat("en-GB", {
    year: "numeric",
    month: "numeric",
    day: "2-digit",
  });
  return (
    <div className="news_b">
      {/*  
      <ul className="newss">
        <li>
          <Link to="#!">All</Link>
        </li>
        <li>
          <Link to="#!">2022</Link>
        </li>
        <li>
          <Link to="#!">2021</Link>
        </li>
        <li>
          <Link to="#!">2020</Link>
        </li>
        <li>
          <Link to="#!">2019</Link>
        </li>
        <li>
          <Link to="#!">2018</Link>
        </li>
        <li>
          <Link to="#!">2017</Link>
        </li>
        <li>
          <Link to="#!">2016</Link>
        </li>
      </ul> */}
      <div className="news_coverage">
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : (
          <ul>
            {news &&
              news.map((item, i) => (
                <li key={i}>
                  <div className="inner_coverage">
                    <img
                      src={process.env.REACT_APP_API_PATH + item.image}
                      alt=""
                    />
                    <a href={item.url} target="_blank" >

                      <h3 className="subheading">{item.title}</h3>
                    </a>
                    <ul className="blog_author ">
                      <li> {formatter.format(Date.parse(item.created_at))}</li>
                    </ul>
                  </div>
                </li>
              ))}
          </ul>
        )}
      </div>
    </div>
  );
}
