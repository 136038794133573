import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/fontawesome-free-solid";

export default function Indicator() {
  return (
    <section className="section checkout-progress-indicator">
      <div className="container">
        <div className="step-1 active">
          <span>1.</span> Shipping <FontAwesomeIcon icon={faAngleRight} />
        </div>

        <div className="step-2 inactive">
          <span>2.</span> Billing <FontAwesomeIcon icon={faAngleRight} />
        </div>

        <div className="step-3 inactive">
          <span>3.</span> Order <FontAwesomeIcon icon={faAngleRight} />{" "}
        </div>
      </div>
    </section>
  );
}
