import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { loadAllCategory } from "../../redux/action/CategoryAction";
import { loadAllHome } from "../../redux/action/HomeAction";
import { Link } from "react-router-dom";

export default function Hard_work() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadAllCategory());
    dispatch(loadAllHome());
  }, []);

  const category = useSelector((state) => state.MainCategory.result);

  const homeContent = useSelector((state) => state.Home.singledata);
  console.log("homContent", category);
  return (
    <section className="section work_hard">
      <div className="container">
        <div className="play_hard">
          {homeContent.slice(0, 1).map((cont) => {
            return (
              <div key={cont.id}>
                <h2 className="title homeTitle">{cont.banner_bottom_heding}</h2>
                <h4 className="subheading">{cont.banner_bottom_content}</h4>
              </div>
            );
          })}
        </div>
        <ul>
          {category.slice(0, 5).map((e) => {
            return (
              <li key={e.id}>
                <div className="bundeel">
                  <Link
                    to={"/series/" + e.slug}
                    style={{
                      textDecoration: "none",
                      padding: 0,
                      margin: 0,
                      border: 0,
                      display: "inline-block",
                    }}
                  >
                    <img
                      src={process.env.REACT_APP_API_PATH + e.image}
                      alt={e.name}
                    />
                    <div className="cattext">
                      <h3 className="blackcolor">{e.name} &nbsp;</h3>
                      <h3>{e.alias}</h3>
                    </div>
                  </Link>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </section>
  );
}
