import setAuthorizationToken from "../../utils/setAutorization";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import jwtDecode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import {
  SIGNIN_USER_SUCCESS,
  SIGNIN_USER_REQUEST,
  SIGNIN_USER_ERROR,
  SET_CURRENT_USER,
  SIGNOUT_USER,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_PASSWORD_UPDATE_FAIL,
  USER_PASSWORD_UPDATE_REQUEST,
  USER_PASSWORD_UPDATE_SUCCESS,
  USER_PROFILE_UPDATE_FAIL,
  USER_PROFILE_UPDATE_REQUEST,
  USER_PROFILE_UPDATE_SUCCESS,
  FETCH_USER_FAIL,
  FETCH_USER_REQUEST,
  FETCH_USER_SUCCESS,
  USER_PASSWORD_FORGET_REQUEST,
  USER_PASSWORD_FORGET_SUCCESS,
  USER_PASSWORD_FORGET_FAIL,
  USER_PASSWORD_CHANGE_REQUEST,
  USER_PASSWORD_CHANGE_SUCCESS,
  USER_PASSWORD_CHANGE_FAIL

} from "../constants/AuthConstant";
const axios = require("axios");

// USER AUTH ACTION
export const signinUser = (email, password) => {
  // const navigate = useNavigate();
  return function (dispatch) {
    dispatch({
      type: SIGNIN_USER_REQUEST,
      payload: true,
    });

    let OPTIONS = {
      url: process.env.REACT_APP_API_PATH + "/api/v1/login/",
      method: "POST",
      data: {
        email: email,
        password: password,
      },
      headers: {
        "content-type": "application/json",
      },
    };

    axios(OPTIONS)
      .then((res) => {
        const msg = res.data.msg;
        if (msg === "User Login Successfully") {
          window.location.href = "/cart";
          const token = res.data.data.token;
          localStorage.setItem("token", token);
          setAuthorizationToken(token);
          dispatch(setCurrentUser(jwtDecode(token)));
          dispatch({
            type: SIGNIN_USER_SUCCESS,
            payload: false,
            isSigninIn: true,
            msg: res.data.msg,
          });
          toast.success("User Logged In!");
        } else {
          dispatch({
            type: SIGNIN_USER_ERROR,
            payload: false,
            isSigninIn: false,
            msg: res.data.msg,
          });
          toast.error(res.data.msg);
        }
      })
      .catch((error) => {
        dispatch({
          type: SIGNIN_USER_ERROR,
          loading: false,
          payload: false,
          msg: error.msg,
        });
      });
  };
};

export const forgetPasswordsend = (email, code) => {
  // const navigate = useNavigate();
  return function (dispatch) {
    dispatch({
      type: USER_PASSWORD_FORGET_REQUEST,
      payload: true,
    });

    let OPTIONS = {
      url: process.env.REACT_APP_API_PATH + "/api/v1/get_forgetpassword/",
      method: "POST",
      data: {
        email: email,
        code: code,
      },
      headers: {
        "content-type": "application/json",
      },
    };

    axios(OPTIONS)
      .then((res) => {
        const msg = res.data.msg;
        if (res.data.isvalid === "1") {

          dispatch({
            type: USER_PASSWORD_FORGET_SUCCESS,
            payload: false,
            isSigninIn: true,
            msg: res.data.msg,
          });
          toast.success("Password reset link has been sent to email!");
        } else {
          dispatch({
            type: USER_PASSWORD_FORGET_FAIL,
            payload: false,
            isSigninIn: false,
            msg: res.data.msg,
          });
          toast.error(res.data.msg);
        }

      })
      .catch((error) => {
        dispatch({
          type: SIGNIN_USER_ERROR,
          loading: false,
          payload: false,
          msg: error.msg,
        });
      });
  };
};

export const changexPasswordsend = (password, code) => {
  // const navigate = useNavigate();
  return function (dispatch) {
    dispatch({
      type: USER_PASSWORD_CHANGE_REQUEST,
      payload: true,
    });

    let OPTIONS = {
      url: process.env.REACT_APP_API_PATH + "/api/v1/changeuserpassword/",
      method: "POST",
      data: {
        password: password,
        code: code,
      },
      headers: {
        "content-type": "application/json",
      },
    };

    axios(OPTIONS)
      .then((res) => {
        const msg = res.data.msg;
        if (res.data.isvalid === "1") {

          dispatch({
            type: USER_PASSWORD_CHANGE_SUCCESS,
            payload: false,
            isSigninIn: true,
            msg: res.data.msg,
          });
          toast.success("Password reset link has been sent to email!");
        } else {
          dispatch({
            type: USER_PASSWORD_CHANGE_FAIL,
            payload: false,
            isSigninIn: false,
            msg: res.data.msg,
          });
          toast.error(res.data.msg);
        }

      })
      .catch((error) => {
        dispatch({
          type: SIGNIN_USER_ERROR,
          loading: false,
          payload: false,
          msg: error.msg,
        });
      });
  };
};

export const signOut = () => {
  return function (dispatch) {
    dispatch({
      type: SIGNIN_USER_REQUEST,
      payload: true,
    });
    localStorage.removeItem("token");
    setAuthorizationToken(false);
    dispatch(setCurrentUser({}));
    dispatch({
      type: SIGNOUT_USER,
      payload: false,
    });
    toast.success("Logout!");

    dispatch({
      type: SIGNIN_USER_ERROR,
      loading: false,
      payload: "",
    });
  };
};
// SET CURRENT USER ACTION
export const setCurrentUser = (result) => {
  return {
    type: SET_CURRENT_USER,
    payload: false,
    result: result,
  };
};

// Register User
export const register = (
  first_name,
  last_name,
  email,
  password,
  confirm_password,
  mobile,
  terms_conditions,
  licence_agreement,
  news,
  refer_by
) => {
  return function (dispatch) {
    dispatch({
      type: USER_REGISTER_REQUEST,
      payload: true,
    });

    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/register/`,

      method: "POST",
      data: {
        first_name,
        last_name,
        email,
        password,
        confirm_password,
        mobile,
        terms_conditions,
        licence_agreement,
        news,
        refer_by,
      },

      headers: {
        "content-type": "application/json",
      },
    };

    axios(OPTION)
      .then((res) => {
        dispatch(createBannerPre(res.data));
        //localStorage.setItem("userInfo", JSON.stringify(res.data));
        //window.location.href = "/login";
      })

      .catch((error) => {
        dispatch({
          type: USER_REGISTER_FAIL,
          payload: false,
          error: error,
          isSignUp: error.success,
          msg: "Failed to create user registration",
        });
        toast.error(error.msg);
      });
  };
};
export const createBannerPre = (data) => {
  if (data.success) {
    alert(data.msg);

    //localStorage.setItem("userInfo", JSON.stringify(data.data));
    window.location.href = "/login";
    //toast.success(data.msg);
  }
  else {
    toast.success(data.msg);
  }

  return {
    type: USER_REGISTER_SUCCESS,
    isSignUp: data.success,
    result: data,
    payload: false,
    msg: data.msg,
  };
};

export const fetchUserProfile = (id) => {
  return function (dispatch) {
    dispatch({
      type: FETCH_USER_REQUEST,
      payload: true,
    });

    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/user/${id}/`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };

    axios(OPTION)
      .then((res) => {
        dispatch(fetchUserProfilePre(res.data));
      })
      .catch((error) => {
        dispatch({
          type: FETCH_USER_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
      });
  };
};
export const fetchUserProfilePre = (data) => {
  return {
    type: FETCH_USER_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

// Register User
export const UpdateProfile = (data) => {
  return function (dispatch) {
    dispatch({
      type: USER_PROFILE_UPDATE_REQUEST,
      payload: true,
    });

    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/user/`,
      method: "POST",
      data: data,
      headers: {
        "content-type": "application/json",
      },
    };

    axios(OPTION)
      .then((res) => {
        // console.log("res", res.data);
        dispatch(UpdateProfilePre(res.data));
      })
      .catch((error) => {
        console.log("error", error);
        dispatch({
          type: USER_PROFILE_UPDATE_FAIL,
          payload: false,
          error: error,
          msg: "Failed to create user registration",
        });
        toast.error(error.msg);
      });
  };
};
export const UpdateProfilePre = (data) => {
  console.log("data", data);
  toast.success(data.msg);

  return {
    type: USER_PROFILE_UPDATE_SUCCESS,
    result: data,
    payload: false,
    msg: data.msg,
  };
};

// Register User
export const UpdatePassword = (id, password, confirm_password) => {
  return function (dispatch) {
    dispatch({
      type: USER_PASSWORD_UPDATE_REQUEST,
      payload: true,
    });

    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/register/`,
      method: "POST",
      data: {
        id,
        password,
        confirm_password,
      },

      headers: {
        "content-type": "application/json",
      },
    };

    axios(OPTION)
      .then((res) => {
        console.log("res", res.data);
        dispatch(UpdatePasswordPre(res.data));
        //localStorage.setItem("userInfo", JSON.stringify(res.data));
        window.location.href = "/login";
      })

      .catch((error) => {
        console.log("error", error);
        dispatch({
          type: USER_PASSWORD_UPDATE_FAIL,
          payload: false,
          error: error,
          isSignUp: error.success,
          msg: "Failed to create user registration",
        });
        toast.error(error.msg);
      });
  };
};
export const UpdatePasswordPre = (data) => {
  toast.success(data.msg);
  return {
    type: USER_PASSWORD_UPDATE_SUCCESS,
    isSignUp: data.success,
    result: data,
    payload: false,
    msg: data.msg,
  };
};


export const forgetPassword = (email, password) => {
  // const navigate = useNavigate();
  return function (dispatch) {
    dispatch({
      type: SIGNIN_USER_REQUEST,
      payload: true,
    });

    let OPTIONS = {
      url: process.env.REACT_APP_API_PATH + "/api/v1/login/",
      method: "POST",
      data: {
        email: email,
        password: password,
      },
      headers: {
        "content-type": "application/json",
      },
    };

    axios(OPTIONS)
      .then((res) => {
        const msg = res.data.msg;
        if (msg === "User Login Successfully") {
          const token = res.data.data.token;
          localStorage.setItem("token", token);
          setAuthorizationToken(token);
          dispatch(setCurrentUser(jwtDecode(token)));
          dispatch({
            type: SIGNIN_USER_SUCCESS,
            payload: false,
            isSigninIn: true,
            msg: res.data.msg,
          });
          toast.success("User Logged In!");
        } else {
          dispatch({
            type: SIGNIN_USER_ERROR,
            payload: false,
            isSigninIn: false,
            msg: res.data.msg,
          });
          toast.error(res.data.msg);
        }
        window.location.href = "/cart";
      })
      .catch((error) => {
        dispatch({
          type: SIGNIN_USER_ERROR,
          loading: false,
          payload: false,
          msg: error.msg,
        });
      });
  };
};