import { data } from "jquery";
import {
    FIND_ALL_SUBACCESSORIES_REQUEST,
    FIND_ALL_SUBACCESSORIES_SUCCESS,
    FIND_ALL_SUBACCESSORIES_FAIL,
    FIND_SINGLE_SUBACCESSORIES_REQUEST,
    FIND_SINGLE_SUBACCESSORIES_SUCCESS,
    FIND_SINGLE_SUBACCESSORIES_FAIL,
  } from "../constants/SubAccessoriesConstant";

const initialState = {
    loading:false,
    action:"SubAccessories",
    result:[],
    response: {},
    singledata:[],
    msg:"",
    error:"",
};
const SubAccessoriesReducer = (state = initialState, action)=>{
    switch(action.type){
        case FIND_ALL_SUBACCESSORIES_REQUEST:
            return{
                ...state,
                loading:action.payload,
            };
        case FIND_ALL_SUBACCESSORIES_SUCCESS:
            return{
                ...state,
                result: action.result,
                loading:action.payload,
                msg:action.msg,
            };
        case FIND_ALL_SUBACCESSORIES_FAIL:
            return{
                ...state,
                error:action.error,
                loading:action.payload,
                msg:action.msg,
            };
            case FIND_SINGLE_SUBACCESSORIES_REQUEST:
                return{
                    ...state,
                    loading:action.payload,
                };
            case FIND_SINGLE_SUBACCESSORIES_SUCCESS:
                return{
                    ...state,
                    result: action.result.result.data,
                    loading:action.payload,
                    msg:action.msg,
                };
            case FIND_SINGLE_SUBACCESSORIES_FAIL:
                return{
                    ...state,
                    error:action.error,
                    loading:action.payload,
                    msg:action.msg,
                };
            default:
                return state;
    }
};
export default SubAccessoriesReducer;