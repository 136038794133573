import {
  ADD_DELIVERY_ADDRESS_FAIL,
  ADD_DELIVERY_ADDRESS_REQUEST,
  ADD_DELIVERY_ADDRESS_SUCCESS,
  DELETE_DELIVERY_ADDRESS_FAIL,
  DELETE_DELIVERY_ADDRESS_REQUEST,
  DELETE_DELIVERY_ADDRESS_SUCCESS,
  GET_ALL_DELIVERY_ADDRESS_FAIL,
  GET_ALL_DELIVERY_ADDRESS_REQUEST,
  GET_ALL_DELIVERY_ADDRESS_SUCCESS,
  GET_SINGLE_DELIVERY_ADDRESS_FAIL,
  GET_SINGLE_DELIVERY_ADDRESS_REQUEST,
  GET_SINGLE_DELIVERY_ADDRESS_SUCCESS,
  UPDATE_DELIVERY_ADDRESS_FAIL,
  UPDATE_DELIVERY_ADDRESS_REQUEST,
  UPDATE_DELIVERY_ADDRESS_SUCCESS,
} from "../constants/deliveryAddressConstant";

import axios from "axios";
import { toast } from "react-toastify";

// action from get all the User Address

const loadAllUserAddress = (id) => {
  return function (dispatch) {
    dispatch({
      type: GET_ALL_DELIVERY_ADDRESS_REQUEST,
      payload: true,
    });

    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/user-address/${id}/`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };

    axios(OPTION)
      .then((res) => {
        dispatch(loadAllUserAddressPre(res.data));
      })
      .catch((error) => {
        dispatch({
          type: GET_ALL_DELIVERY_ADDRESS_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
      });
  };
};
export const loadAllUserAddressPre = (data) => {
  return {
    type: GET_ALL_DELIVERY_ADDRESS_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

const loadSingleUserAddress = (id) => {
  return function (dispatch) {
    dispatch({
      type: GET_SINGLE_DELIVERY_ADDRESS_REQUEST,
      payload: true,
    });
    // const token = JSON.parse(localStorage.getItem("jwt"))
    //   ? JSON.parse(localStorage.getItem("jwt"))
    //   : "";
    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/admin-user-address-detaile/${id}/`,
      method: "GET",
      headers: {
        "content-type": "application/json",
        // token: token,
      },
    };

    axios(OPTION)
      .then((res) => {
        dispatch(loadSingleUserAddressPre(res.data));
      })
      .catch((error) => {
        dispatch({
          type: GET_SINGLE_DELIVERY_ADDRESS_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
      });
  };
};
export const loadSingleUserAddressPre = (data) => {
  return {
    type: GET_SINGLE_DELIVERY_ADDRESS_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

// add New Vehicle
const createUserAddress = (
  user_id,
  email,
  firstname,
  lastname,
  mobile,
  address,
  house,
  sector,
  street,
  district,
  city,
  landmark,
  stateName,
  country,
  postCode
) => {
  let data = {
    user_id,
    email,
    firstname,
    lastname,
    mobile,
    address,
    house,
    sector,
    street,
    district,
    city,
    landmark,
    stateName,
    country,
    postCode,
  };
  return function (dispatch) {
    dispatch({
      type: ADD_DELIVERY_ADDRESS_REQUEST,
      payload: true,
    });

    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/user-address/`,
      method: "POST",
      data: data,
      headers: {
        "content-type": "application/json",
      },
    };

    axios(OPTION)
      .then((res) => {
        dispatch(createUserAddressPre(res.data));
        // dispatch(loadAllUser Address());
      })
      .catch((error) => {
        dispatch({
          type: ADD_DELIVERY_ADDRESS_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
        toast.danger("Failed To Create The Best Uses!");
      });
  };
};
export const createUserAddressPre = (data) => {
  toast.success("UserAddress Created Successfully!");

  return {
    type: ADD_DELIVERY_ADDRESS_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

// update Vehicle OWNER
const updateUserAddress = (
  id,
  user_id,
  email,
  firstname,
  lastname,
  mobile,
  address,
  house,
  sector,
  street,
  district,
  city,
  landmark,
  stateName,
  country,
  postCode
) => {
  return function (dispatch) {
    dispatch({
      type: UPDATE_DELIVERY_ADDRESS_REQUEST,
      payload: true,
    });
    // const token = JSON.parse(localStorage.getItem("jwt"))
    //   ? JSON.parse(localStorage.getItem("jwt"))
    //   : "";
    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/user-address/`,
      method: "POST",
      data: {
        id,
        user_id,
        email,
        firstname,
        lastname,
        mobile,
        address,
        house,
        sector,
        street,
        district,
        city,
        landmark,
        stateName,
        country,
        postCode,
      },
      headers: {
        "content-type": "application/json",
      },
    };

    axios(OPTION)
      .then((res) => {
        dispatch(updateUserAddressPre(res.data));
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_DELIVERY_ADDRESS_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
        toast.error(error.msg);
      });
  };
};
export const updateUserAddressPre = (data) => {
  toast.success(data.msg);

  return {
    type: UPDATE_DELIVERY_ADDRESS_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

// delete assign subject to class
const deleteUserAddress = (id, result) => {
  return function (dispatch) {
    dispatch({
      type: DELETE_DELIVERY_ADDRESS_REQUEST,
      payload: result,
    });
    const token = JSON.parse(localStorage.getItem("jwt"))
      ? JSON.parse(localStorage.getItem("jwt"))
      : "";
    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/admin-user-address-delete/`,
      method: "POST",
      data: {
        "content-type": "application/json",
        // token: token,
      },
    };

    axios(OPTION)
      .then((res) => {
        dispatch(deleteUserAddressPre(res.data));
        // dispatch(loadAllUser Address());
      })
      .catch((error) => {
        dispatch({
          type: DELETE_DELIVERY_ADDRESS_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
      });
  };
};
export const deleteUserAddressPre = (data) => {
  toast.success("User Address Deleted Successfully!");
  return {
    type: DELETE_DELIVERY_ADDRESS_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

export {
  loadAllUserAddress,
  loadSingleUserAddress,
  createUserAddress,
  updateUserAddress,
  deleteUserAddress,
};
