export const FIND_FAQ_REQUEST = "FIND_FAQ_REQUEST";
export const FIND_FAQ_SUCCESS = "FIND_FAQ_SUCCESS";
export const FIND_FAQ_FAIL = "FIND_FAQ_FAIL";

export const SEARCH_FAQ_REQUEST = "SEARCH_FAQ_REQUEST";
export const SEARCH_FAQ_SUCCESS = "SEARCH_FAQ_SUCCESS";
export const SEARCH_FAQ_FAIL = "SEARCH_FAQ_FAIL";


export const SEARCH_ORDERTRACK_REQUEST = "SEARCH_ORDERTRACK_REQUEST";
export const SEARCH_ORDERTRACK_SUCCESS = "SEARCH_ORDERTRACK_SUCCESS";
export const SEARCH_ORDERTRACK_FAIL = "SEARCH_ORDERTRACK_FAIL";


export const FIND_PROMO_CODE_REQUEST = "FIND_PROMO_CODE_REQUEST";
export const FIND_PROMO_CODE_SUCCESS = "FIND_PROMO_CODE_SUCCESS";
export const FIND_PROMO_CODE_FAIL = "FIND_PROMO_CODE_FAIL";

export const FIND_BLOGS_REQUEST = "FIND_BLOGS_REQUEST";
export const FIND_BLOGS_SUCCESS = "FIND_BLOGS_SUCCESS";
export const FIND_BLOGS_FAIL = "FIND_BLOGS_FAIL";

export const FIND_SINGLE_BLOGS_REQUEST = "FIND_SINGLE_BLOGS_REQUEST";
export const FIND_SINGLE_BLOGS_SUCCESS = "FIND_SINGLE_BLOGS_SUCCESS";
export const FIND_SINGLE_BLOGS_FAIL = "FIND_SINGLE_BLOGS_FAIL";

export const FIND_NEWS_REQUEST = "FIND_NEWS_REQUEST";
export const FIND_NEWS_SUCCESS = "FIND_NEWS_SUCCESS";
export const FIND_NEWS_FAIL = "FIND_NEWS_FAIL";

export const FIND_TESTIMONIAL_REQUEST = "FIND_TESTIMONIAL_REQUEST";
export const FIND_TESTIMONIAL_SUCCESS = "FIND_TESTIMONIAL_SUCCESS";
export const FIND_TESTIMONIAL_FAIL = "FIND_TESTIMONIAL_FAIL";

export const ADD_APPOINTMENT_REQUEST = "ADD_APPOINTMENT_REQUEST";
export const ADD_APPOINTMENT_SUCCESS = "ADD_APPOINTMENT_SUCCESS";
export const ADD_APPOINTMENT_FAIL = "ADD_APPOINTMENT_FAIL";

export const ADD_APPOINTMENTS_REQUEST = "ADD_APPOINTMENTS_REQUEST";
export const ADD_APPOINTMENTS_SUCCESS = "ADD_APPOINTMENTS_SUCCESS";
export const ADD_APPOINTMENTS_FAIL = "ADD_APPOINTMENTS_FAIL";

export const ADD_ENQUIRY_REQUEST = "ADD_ENQUIRY_REQUEST";
export const ADD_ENQUIRY_SUCCESS = "ADD_ENQUIRY_SUCCESS";
export const ADD_ENQUIRY_FAIL = "ADD_ENQUIRY_FAIL";

export const ADD_LOYALTY_REWARDS_REQUEST = "ADD_LOYALTY_REWARDS_REQUEST";
export const ADD_LOYALTY_REWARDS_SUCCESS = "ADD_LOYALTY_REWARDS_SUCCESS";
export const ADD_LOYALTY_REWARDS_FAIL = "ADD_LOYALTY_REWARDS_FAIL";

export const ADD_CUSTOMER_SUPPORT_REQUEST = "ADD_CUSTOMER_SUPPORT_REQUEST";
export const ADD_CUSTOMER_SUPPORT_SUCCESS = "ADD_CUSTOMER_SUPPORT_SUCCESS";
export const ADD_CUSTOMER_SUPPORT_FAIL = "ADD_CUSTOMER_SUPPORT_FAIL";

export const ADD_CAREER_REQUEST = "ADD_CAREER_REQUEST";
export const ADD_CAREER_SUCCESS = "ADD_CAREER_SUCCESS";
export const ADD_CAREER_FAIL = "ADD_CAREER_FAIL";

export const ADD_PROMOTION_EMAIL_REQUEST = "ADD_PROMOTION_EMAIL_REQUEST";
export const ADD_PROMOTION_EMAIL_SUCCESS = "ADD_PROMOTION_EMAIL_SUCCESS";
export const ADD_PROMOTION_EMAIL_FAIL = "ADD_PROMOTION_EMAIL_FAIL";

export const GET_TOP_HEADER_MESSAGE_REQUEST = "GET_TOP_HEADER_MESSAGE_REQUEST";
export const GET_TOP_HEADER_MESSAGE_SUCCESS = "GET_TOP_HEADER_MESSAGE_SUCCESS";
export const GET_TOP_HEADER_MESSAGE_FAIL = "GET_TOP_HEADER_MESSAGE_FAIL";

export const GET_MENU_TITLE_REQUEST = "GET_MENU_TITLE_REQUEST";
export const GET_MENU_TITLE_SUCCESS = "GET_MENU_TITLE_SUCCESS";
export const GET_MENU_TITLE_FAIL = "GET_MENU_TITLE_FAIL";

export const RESET_STATE = "RESET_STATE";



