import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { loadAllFixedBanner } from "../../redux/action/HomeAction";

export default function Fixed_banner() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadAllFixedBanner());
  }, []);

  const fixedBanner = useSelector((state) => state.Home.fixedbanner);
  console.log("fixedBanner", fixedBanner);

  return (
    <section className="section fixed">
      <div className="container">
        {fixedBanner.slice(0, 1).map((fixed) => {
          return (
            <div
              className="fixed_banner"
              style={{
                backgroundImage: `url('${process.env.REACT_APP_API_PATH}${fixed.banner}')`,
              }}
              key={fixed.id}
            >
              <h3>{fixed.heding}</h3>
              <div className="two_btn">
                {fixed.button1_url == null || fixed.button1_url == "" ? (
                  ""
                ) : (
                  <Link to={fixed.button1_url} className="btn">
                    {fixed.button1_title}
                  </Link>
                )}
                {fixed.button2_url == null || fixed.button2_url == "" ? (
                  ""
                ) : (
                  <Link to={fixed.button2_url} className="btn">
                    {fixed.button2_title}
                  </Link>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
}
