import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadAllBlogs } from "../../redux/action/AdditionalAction";
import { Link } from "react-router-dom";
import Loader from "../Shared/Loader";
import Message from "../share/Message";

export default function Post() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadAllBlogs());
  }, []);

  const blogData = useSelector((state) => state.Additional);

  const { loading, error, blog } = blogData;

  return (
    <aside className="left_sidebar">
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <ul className="blog_post">
          {blog &&
            blog.map((item, i) => (
              <li key={i}>
                <div className="blog_img">
                  <img src={process.env.REACT_APP_API_PATH + item.image} />

                </div>
                <div className="blog_meta">
                  <div className="blog_tiitle">{item.title}</div>
                  {/* 
                  <ul className="blog_author">
                    <li>
                      <strong>Author Name:</strong> {item.author}
                    </li>
                    <li>
                      <strong>Category:</strong> {item.category}
                    </li>
                  </ul>
                  */}
                  <div className="blog_short">{item.short_description}</div>
                  <ul className="blog_author posted">

                    {/* 
                     <li>
                      <strong>Posted:</strong> {item.created_at.substring(0, 10)}
                    </li>
                    <li>
                      <strong>Updated:</strong> {item.updated_at}
                    </li> */}
                    <li className="">
                      <Link to={`/post/${item.slug}`} className="btn read_more read_blog">
                        Read More
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
            ))}
        </ul>
      )}
    </aside>
  );
}
