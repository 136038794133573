import React, { useEffect } from "react";
import StoreSearch from "../component/storelocation/StoreSearch";
import Location from "../component/storelocation/Location";

export default function StoreLocation() {
  useEffect(() => {
    document.getElementById("maintittle").innerHTML = "Where to Buy iRobot® Products in India - iRobot Store";
    document.getElementById("description").setAttribute("content", "Know from where to buy iRobot products in India. The authorized selling partners and service centers. buy from near by iRobot store.");

    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      {/* <StoreSearch /> */}
      <Location />
    </>
  );
}
