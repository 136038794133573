import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import LoginBanner from "../component/share/LoginBanner";
import { useDispatch, useSelector } from "react-redux";

import { register } from "../redux/action/AuthAction";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import ReCAPTCHA from "react-google-recaptcha";
import spinner1 from "../image/spinner1.gif";


export default function Register() {

  const [hide, setHide] = useState(true);

  const [first_name, setFirst_Name] = useState("");
  const [last_name, setLast_Name] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirm_password] = useState("");
  const [licence_agreement, setLicence_agreement] = useState(1);
  const [encrpty, setencrpty] = useState("2ae153dd71da723edfc831bf0399cbfb643909472ad93e403473ec4c56ae4458fb6a03ea1c21512196d920c58392a38bb7e5b57ed6abbd041a008016f110133f43f371d03c514eee60787af7903aba9b5e0cbca5b14ac25d8fcd9c68a9389545afbb88f6e80367d4d783ce84f29be4fc4d43aac02d1e518592e7c7e85ee38354814419854d9712df40e95920c31c2ff1956a540a02c3086668d362cdc7e2e8613f19b8b458c7bdd81efc502c9d2fe1ea9ec0588036f6e46120187f6c9d69ad2da21b797d5cb6830fe159a8ba4aacade22991d3add3306116ce95047766c4baf755e7f818f5c6764d27ba92b9a68603889cfff38f955dcca9b9362d72d4464f9b5d5db1a807038163244a7dc29241787b21dd728541ab412a37c23ba97ba1243e0d367537a175911a60a20bd368433b44b46b7dcd4ad3145d0e6d3fe6bad67c5314fe4a6aaf18d0fb33cbe6a2f0153a0c373d9bee3d0d86d9a536985f8a59a50f32dee35470c58260bc6e9efa585275b5764337ecf3d5012075639f59976c6992903418b2392114b4144f5c232515e013b707bc95c930d08a7125384e30490f170aad72007305b178cbe79aae67f1055cdbc94850679060fa58b2a703e6e37f8bd39ffa036f40d136d3d1f809ed60675157321d3f068203217180b6ebaafae6e7ccd3e3aa8888783af880a6fc4b43d13bab223de56e9f584a52869e8b52ef41a25de49d862a5f7192808f88d21f543876aca7fbc35a4a4b60ea5d14cb557ac90fca83dac38c6f6a2ebc8463d4ebb5831625e22ca9f7e15abec83c8b5ec9478c54f3c23327c96cc8ab8e740f3f81d7671b364749697177c83177826137f0369e08b12eff1afdfb78b30d7eb79ba0d338e46bd5a0a43c3b63c4b4c45a8a2c1f05798de6f98944971d83f2936437d4396d41");
  const [access_code, setaccess_code] = useState("AVWN04JH40BY45NWYB");
 
  const [newsn, setNewsn] = useState(true);
  const [news, setNews] = useState(0);

  const [terms_conditionsn, setTerms_conditionsn] = useState(true);
  const [terms_conditions, setTerms_conditions] = useState(1);

  const [refer_by, setRrefer_by] = useState('');
  const [captchaResult, setCaptchaResult] = useState();
  const [action, setaction] = useState("https://test.ccavenue.com/transaction/transaction.do?command=initiateTransaction");
  //const [action, setaction] = useState("http://localhost:3000/login");


  console.log(news, "news");
  const dispatch = useDispatch();


  const key = process.env.REACT_APP_SITE_KEY;
  const url = process.env.REACT_APP_SITE_URL;
  const registerdata = useSelector((state) => state.AuthReducer);

  const { loading, error, isSignUp, msg } = registerdata;

  //const encrpty = "fd4a6221eb95cd050b82494b8f40835d800c97b00bc27044ce0fe85fdac0af601bb52bf12a17c7c38de98450879c8d9fd5e40ccf675224c9d50c1575fa31101f392654030980cff262b99f1d96653606a9becc8549b322afc8b3fc97851a9577359258965feddc20ed940ddfadd601b7cfd5c06f36a9e20ef5ff541027d5e477e9595e1a2451f2419b74b4ccf4921eb220821d7c8a74c813d6837c05539c5d08fea093499ad2c99c8d76afc93cf8a21de64cf33c2dc14452723b77cb85dd2c001bb3733978e04557aa8cf04cbad96eada019418127018655178d18cacc6014ab4d052953c47b3e4c4e91cad86ce7ee90b3b216c4897884ad6862992508cffe7c4618fe0700843f66c932f833a47f092530552d3e40184cbfaa33c4c9206332382857a28cd4dfdb2337cbcc4c5fa9f88ac0db963e90c9f9e416b05832221c110a1feb342c5b2b9c7b421cd2e5233848221a5a0beb231f8c5cdde6593ffa59576d869283342a0f4374fc3e24e0f493a4801c17744e250dd624550760cdac2002fa00e117e6316f054c946e3fd01d2f2f29bd2257fb226f5d2032fceba38710b6b91dcb906f64f6d2ab62fee354dac09efa46e9e68ae94af62e67cea39c31fb3eee11304fb302ebe6acc0037262dd79f877d2e51850ae93764331b9bed0733e0adeb62d6dca30ba739b8543cec65eca0636767b7c478c168e2faab0110047f0738685d5ea48642315629d56265175c030f87cbbf43aea77af3ff5c742f02948874767dd94d0cb1b33608a08ae12246c6b30c8c7d2eede1458363e1a8c1c34a5f1eec376893092f44a4e68af92e0413af1aa215ba7aed749ee67d774827154b9f856f7a2d2b1c8ef3d4ab7bc004251c75e4f";
  //const access_code = "AVWN04JH40BY46NWYB"
  //action="https://test.ccavenue.com/transaction/transaction.do?command=initiateTransaction"

  const handleRecaptcha = (value) => {
    fetch(url, {
      method: "POST",
      body: JSON.stringify({ captcha_value: value }),
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.captcha.success);
        setCaptchaResult(data.captcha.success);
      });
  };


  const setNewsnbutton = (e) => {
    setNewsn(!newsn);
    setNews(newsn ? 0 : 1);
  }

  const submitHandler = (e) => {
    e.preventDefault();
    var term = 0;
    if (terms_conditionsn) {
    }

    if (password === confirm_password) {
      if (email != null && mobile != null) {
        setHide(false);
        dispatch(
          register(
            first_name,
            last_name,
            email,
            password,
            confirm_password,
            mobile,
            terms_conditions,
            licence_agreement,
            news,
            refer_by,
          )
        );
        //setHide(true);

      } else {
        toast.error("Email And Contact Number is Required");
      }
    } else {
      toast.error("Password And Confirm Password Did Not Match");
    }
  };

  useEffect(() => {
    document.getElementById("maintittle").innerHTML = "Roomba robot vacuums & Braava mopping robot - iRobot India";
    document.getElementById("description").setAttribute("content", "irobot, irobot india, irobot roomba, irobot braava, roomba, braava, robotic vacuum cleaner, robot vacuum, robot mop");

    console.log('loading..', loading)

  }, []);

  const onSubmitHandler = (e) => {
    e.preventDefault();
    document.getElementById("nameForm").submit();
  }



  return (
    <>
      {/* <LoginBanner /> */}
      <ToastContainer />
      <br></br>
      {/* 
      <div>
        <input
          type="text"
          required
          placeholder="setaction"
          name="action"
          value={action}
          onChange={(e) => setaction(e.target.value)}
        />
        <br></br>
        <input
          type="text"
          required
          placeholder="setencrpty"
          name="encrpty"
          value={encrpty}
          onChange={(e) => setencrpty(e.target.value)}
        />
        <br></br>

        <input
          type="text"
          required
          placeholder="setaccess_code"
          name="access_code"
          value={access_code}
          onChange={(e) => setaccess_code(e.target.value)}
        />
        <br></br>

        <form
          onSubmit={onSubmitHandler}
          id="nameForm"
          action={action}
          method="POST"
        >
          <input type="hidden" id="encRequest" name="encRequest" value={encrpty} />
          <input type="hidden" name="access_code" id="access_code" value={access_code} />
          <button type="submit" onClick={onSubmitHandler}>Submit</button>
        </form>
      </div>
      */}

      {loading ? (<div id="loading">
        <img id="loading-image" src={spinner1} alt="Loading..." />
      </div>) : ""}


      <section className="section " >
        <div className="container">
          <div className="account_form register">

            <div className="account_bt">
              <h2 className="account_title">Register Account</h2>
              <Link to="/login" className="btn home">
                Login
              </Link>
            </div>
            <div className="acc_form">
              <form onSubmit={submitHandler}>
                <div className="input_field">
                  <label>
                    Name <span>*</span>
                  </label>
                  <input
                    type="text"
                    required
                    placeholder="Enter Your name"
                    name="first_name"
                    value={first_name}
                    onChange={(e) => setFirst_Name(e.target.value)}
                  />
                </div>
                {/*
                
   
                <div className="input_field">
                  <label>
                    Last name <span>*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Your Last name"
                    name="last_name"
                    value={last_name}
                    onChange={(e) => setLast_Name(e.target.value)}
                  />
            </div>
                   */}

                <div className="input_field">
                  <label>
                    Email  <span>*</span>
                  </label>
                  <input
                    type="email"
                    required
                    placeholder="Enter Your Email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="input_field">
                  <label>
                    Phone Number <span>*</span>
                  </label>
                  <input
                    type="number"
                    required
                    placeholder="Enter Your Contact Number"
                    name="mobile"
                    minLength="10"
                    maxLength="10"
                    value={mobile}
                    onChange={(e) => {
                      if (e.target.value.length < 11) {
                         setMobile(e.target.value);
                      // alert(e.target.value.length);

                      }
                    }}
                  />
                </div>
                <div className="input_field">
                  <label>
                    Password <span>*</span>
                  </label>
                  <input
                    type="password"
                    required
                    placeholder="Password"
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div className="input_field">
                  <label>
                    Confirm password <span>*</span>
                  </label>
                  <input
                    type="password"
                    required
                    placeholder="Confirm Password"
                    name="confirm_password"
                    value={confirm_password}
                    onChange={(e) => setConfirm_password(e.target.value)}
                  />
                </div>
                <div className="input_field">
                  <label>
                    Referal Code  (Optional) <span></span>
                  </label>
                  <input
                    type="text"
                    placeholder="REFR889"
                    name="refer_by"
                    value={refer_by}
                    onChange={(e) => setRrefer_by(e.target.value)}
                  />
                </div>

                <div className="input_field_checkbox">
                  <input
                    id="accept"
                    type="checkbox"
                    required
                    checked={terms_conditionsn}
                    name="terms_conditions"
                    value={terms_conditionsn}
                    onChange={(e) => setTerms_conditionsn(true)}
                  />
                  <label for="accept">
                    I accept{" "}
                    <Link to="/terms-condition">Terms &amp; Conditions</Link>
                  </label>
                </div>
                {/*

                <div className="input_field_checkbox">
                  <input
                    type="checkbox"
                    required
                    id="end_user"
                    name="licence_agreement"
                    value={licence_agreement}
                    onChange={(e) => setLicence_agreement(1)}
                  />
                  <label for="end_user">
                    I accept{" "}
                    <Link to="/data-security">End User License Agreement</Link>
                  </label>
                </div>
                  */}
                <div className="input_field_checkbox">
                  <label>
                    For more information about how iRobot handles personal data,
                    please read our{" "}
                    <Link to="/privacy-policy">Privacy Policy </Link>
                  </label>
                </div>
                <div className="input_field_checkbox">
                  <input
                    type="checkbox"
                    id="receive_news"
                    name="news"
                    value={newsn}
                    onChange={(e) => setNewsnbutton(e)}
                  />
                  <label for="receive_news">
                    Receive news and product updates from iRobot
                  </label>
                </div>
                <div className="input_field_btn">
                  {/* <ReCAPTCHA sitekey={key} onChange={handleRecaptcha}  className="captcha"/> */}

                  {/* {captchaResult && ()} */}
                  <input type="submit" value="Create Account" />
                </div>
              </form>
            </div>
          </div>
        </div>
      </section >
    </>
  );
}