import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { createUserAddress } from "../../redux/action/deliveryAddressAction";

export default function Step1() {
  const [house, setHouse] = useState("");
  const [sector, setSector] = useState("");
  const [address, setAddress] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [district, setDistrict] = useState("");
  const [landmark, setLandmark] = useState("");
  const [stateName, setStateName] = useState("");
  const [country, setCountry] = useState("");
  const [postCode, setPostCode] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");


  const dispatch = useDispatch();
  let user = useSelector((state) => state.AuthReducer.userResult);
  var user_id = user.id;

  let navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!address && mobile.length == 10 && postCode.length == 6) {
      toast.warning("Please input all input Field!");
    } else {
      dispatch(
        createUserAddress(
          user_id,
          email,
          firstname,
          lastname,
          mobile,
          address,
          house,
          sector,
          street,
          district,
          city,
          landmark,
          stateName,
          country,
          postCode,

        )
      );
      navigate(-1);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className="container">
      <div className="step1">
        <div className=" Bsns-Oppor-form">
          <div className="">
            <div className="back-btn">
              <Link
                to=""
                onClick={() => {
                  window.history.back();
                }}
              >
                Back
              </Link>
            </div>

            <h2 className="title">
              <span>Add New Address</span>
            </h2>

            <div className=" form text-black">
              <form autoComplete="off" onSubmit={handleSubmit}>

                <div className="full_fo py-2">
                  <label className="label-sm ">
                    Receivers name <span className="text-danger">*</span>
                  </label>
                  <input
                    required
                    type="text"
                    name="firstname"
                    value={firstname}
                    onChange={(e) => setFirstname(e.target.value)}
                  />
                </div>

                {/* <div className="full_fo py-2">
                  <label className="label-sm ">
                    Last Name <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="lastname"
                    value={lastname}
                    onChange={(e) => setLastname(e.target.value)}
                  />
                </div> */}


                <div className="full_fo py-2">
                  <label className="label-sm ">
                    Alternate number<span className="text-danger">*</span>
                  </label>
                  <input
                    type="number"
                    required
                    minLength="10" 
                    maxLength="10"
                    placeholder=""
                    name="mobile"
                    value={mobile}
                    //onChange={handleInputChange}
                    onChange={(e) => {
                      if (e.target.value.length < 11) {
                        setMobile(e.target.value);

                      }
                    }}

                  />

                </div>


                <div className="full_fo py-2">
                  <label className="label-sm ">
                    Address 1 <span className="text-danger">*</span>
                  </label>
                  <input
                    required
                    type="text"
                    name="address"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </div>

                <div className="full_fo py-2">
                  <label className="label-sm ">
                    Address 2 <span className="text-danger">*</span>
                  </label>
                  <input
                    required
                    type="text"
                    name="house"
                    value={house}
                    onChange={(e) => setHouse(e.target.value)}
                  />
                </div>

                {/*


                <div className="full_fo py-2">
                  <label className="label-sm ">
                    Sector <span className="text-danger">*</span>
                  </label>
                  <input
                    required
                    type="text"
                    name="sector"
                    value={sector}
                    onChange={(e) => setSector(e.target.value)}
                  />
                </div>

                <div className="full_fo py-2">
                  <label className="label-sm ">
                    Street <span className="text-danger">*</span>
                  </label>
                  <input
                    required
                    type="text"
                    name="street"
                    value={street}
                    onChange={(e) => setStreet(e.target.value)}
                  />
                </div>

                <div className="full_fo py-2">
                  <label className="label-sm ">
                    District <span className="text-danger">*</span>
                  </label>
                  <input
                    required
                    type="text"
                    name="district"
                    value={district}
                    onChange={(e) => setDistrict(e.target.value)}
                  />
                </div>
*/}
                <div className="full_fo py-2">
                  <label className="label-sm ">
                    City <span className="text-danger">*</span>
                  </label>
                  <input
                    required
                    type="text"
                    name="city"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                  />
                </div>

                <div className="full_fo py-2">
                  <label className="label-sm ">
                    Landmark <span className="text-danger">*</span>
                  </label>
                  <input
                    required
                    type="text"
                    name="landmark"
                    value={landmark}
                    onChange={(e) => setLandmark(e.target.value)}
                  />
                </div>

                <div className="full_fo py-2">
                  <label className="label-sm ">
                    State <span className="text-danger">*</span>
                  </label>
                  <input
                    required
                    type="text"
                    name="stateName"
                    value={stateName}
                    onChange={(e) => setStateName(e.target.value)}
                  />
                </div>
                {/* 
                <div className="full_fo py-2">
                  <label className="label-sm ">
                    Country <span className="text-danger">*</span>
                  </label>
                  <input
                    required
                    type="text"
                    name="country"
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                  />
                </div> */}

                <div className="full_fo py-2">
                  <label className="label-sm ">
                    Pincode <span className="text-danger">*</span>
                  </label>
                  <input
                    required
                    type="number"
                    name="postCode"
                    value={postCode}
                    minLength="6" 
                    maxLength="6"
                    placeholder=""
                    onChange={(e) => {
                      if (e.target.value.length < 7) {
                        setPostCode(e.target.value);
                      }
                    }}

                  />
                </div>
                <div className="full_fo py-2">
                  {/* <label className="label-sm ">
                    Email Address <span className="text-danger">*</span>
                  </label> */}
                  {/* <input
                    required
                    type="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  /> */}
                  {/* <p>
                    <small>
                      Your email will be used to send your order confirmation.
                    </small>
                  </p> */}
                  <div className="full_fo py-2 checkboxx">
                    <input type="checkbox" />
                    <b>
                      &nbsp; Please add me to the iRobot email list. iRobot does not sell personal info. See{" "} <Link to="/privacy-policy">Privacy Policy</Link>
                    </b>
                  </div>
                </div>

                <div className="py-3">
                  <input
                    type="submit"
                    value="Save Address"
                    className="btn btn-default saveaddress"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
