import React, { useEffect } from 'react';
import InnerBanner from "../component/iRobotGenius/InnerBanner";
import ContentOnly from "../component/iRobotGenius/ContentOnly";
// import Zigzag from "../component/iRobotGenius/Zigzag";
import LeftZigzag from "../component/iRobotGenius/LeftZigzag";
import OneStep from "../component/iRobotGenius/OneStep";
import SortingIrobot from "../component/iRobotGenius/SortingIrobot";
// import Tabbing from "../component/category/Tabbing"
import Series_tab10 from "../component/iRobotGenius/Series_tab10"
import Hard_work from "../component/home/Hard_work"
import Product_category from "../component/home/Product_category"
import OfferSection from "../component/home/OfferSection"
import { Link } from "react-router-dom";


export default function IRobotGenius() {
  useEffect(() => {
    document.getElementById("maintittle").innerHTML = "iRobot OS Home Intelligence | iRobot® India";
    document.getElementById("description").setAttribute("content", "Welcome to a new era of clean. iRobot OS Home Intelligence is a platform that gives users greater control of where, when and how their robots clean.");

    window.scrollTo(0, 0)
  }, [])
  return (
    <div>
      <InnerBanner />
      <ContentOnly />
      {/* <Zigzag/> */}
      {/* <Tabbing/> */}
      <Series_tab10 />
      {/* <LeftZigzag/> */}
      {/* <OneStep/> */}
      <SortingIrobot />
      <Product_category />
      <OfferSection />
      <section className='section offer-section'>

        <div
          className="title downloaddiv" >
          <h3>Download the iRobot Home App</h3>
          <div className="two_btn divdownloadwrap">


            <a href="https://play.google.com/store/apps/details?id=com.irobot.home&hl=en&gl=uk" rel="noreferrer" className="btn" target="_blank">
              Google Play
            </a>

            <a href="https://apps.apple.com/gb/app/irobot-home/id1012014442" rel="noreferrer" className="btn" target="_blank">
              App Store
            </a>
          </div>
        </div>
      </section>
      <div className='restt' style={{ height: "70px", }}></div>
    </div>

  )
}
