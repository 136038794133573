import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  loadCategoryImages,
  loadAllcategoriesContent,
} from "../../redux/action/StaticAction";
import zig_zag2 from "../../image/zig_zag2.jpg";
import irobot from "../../image/irobot.mp4";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";


export default function SortingSection() {
  const banner = useSelector((state) => state.Static.categoriesdata);

  let bannerData = [];

  useEffect(() => {

    if (banner != null) {
      bannerData = banner[0];
      if (bannerData !== "" && bannerData !== null && bannerData != undefined) {
        console.log("banner_id..", bannerData.id);
        var banner_id = bannerData.id;

        dispatch(loadCategoryImages(banner_id));
      }
    }

  }, [banner]);

  /*
  useEffect(() => {
    if (bannerData !== "" && bannerData !== null && bannerData != undefined) {
      console.log("banner_id..", bannerData.id);
      var banner_id = bannerData.id;

      dispatch(loadCategoryImages(banner_id));
    }
  }, [bannerData]);
*/

  const dispatch = useDispatch();

  const tab = useSelector((state) => state.Static.categories);
  const [play, setplay] = useState(0);
  var vid = document.getElementById("myVideo");

  console.log("tab", tab);
  const handleLinkClick = event => {

    setplay(1);

    vid.play();

  };
  const handleLinkClickpause = event => {
    setplay(0)

    vid.pause();

  };
  return (
    // <>
    //   {tab.slice(0, 1).map((da) => {
    //     return (
    //       <section className="section zigzag">
    //         <div className="zigzag_content">
    //           <div className="rightt">
    //             {da.bottom_video == null && da.videourl == null ? (
    //               <img
    //                 src={`${process.env.REACT_APP_API_PATH}${da.bottom_image}`}
    //                 alt=""
    //               />
    //             ) : (
    //               <>
    //                 {da.videourl == null ? (
    //                   <>
    //                     <video width="100%" height="100%" controls>
    //                       <source
    //                         src={`${process.env.REACT_APP_API_PATH}${da.bottom_video}`}
    //                         type="video/mp4"
    //                       />
    //                     </video>
    //                   </>
    //                 ) : <>
    //                 <iframe width="100%" height="500" src={da.videourl} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    //                 </>}
    //               </>
    //             )}
    //           </div>
    //           <div className="leftt">
    //             <h3 className="title">
    //               <span>{da.bottom_heding} </span>
    //             </h3>
    //             <p>{da.bottom_content}</p>
    //           </div>
    //         </div>
    //       </section>
    //     );
    //   })}
    // </>
    //   );
    <>
      {tab &&
        tab.map((da) => {
          if (da.position == 2) {
            return (
              <section className="section zigzag catt-category" key={da.id}>
                <div className="zigzag_content">
                  <div
                    className="leftt"
                    style={{ background: "da.background_color" }}
                  >
                    <h3 className="title">
                      <span>{da.heading} </span>
                    </h3>
                    <p>{da.content}</p>
                    {da.video !== null && da.video !== "" && play == 0 ? (
                      <div className="playbuttondiv">
                        <button className="playbutton" onClick={handleLinkClick}>

                          <i class="fa fa-play fa-3x"></i>
                        </button>

                      </div>

                    ) : ''}
                    <div className="two_btn">
                      {da.button1_url == null || da.button1_url == "" ? (
                        ""
                      ) : (
                        <a
                          href={da.button1_url}
                          target="_blank"
                          className="btn"
                        >
                          {da.button1_name}
                        </a>
                      )}
                      {da.button2_url == null || da.button2_url == "" ? (
                        ""
                      ) : (
                        <a
                          href={da.button2_url}
                          target="_blank"
                          className="btn"
                        >
                          {da.button2_name}
                        </a>
                      )}
                    </div>
                  </div>
                  <div className="rightt">
                    {da.video !== null && da.video !== "" ? (
                      <video id="myVideo" width="100%" height="100%" controls>
                        <source
                          src={`${process.env.REACT_APP_API_PATH}${da.video}`}
                          type="video/mp4"
                        />
                      </video>
                    ) : da.image == "" ? (
                      <img src={zig_zag2} alt="" />
                    ) : (
                      <>
                        <img
                          src={`${process.env.REACT_APP_API_PATH}${da.image}`}
                          alt="frf"
                          style={{
                            height: "da.image_height",
                            weight: "da.image_width",
                          }}
                        />
                      </>
                    )}
                  </div>
                </div>
              </section>
            );
          } else {
            return (
              <section className="section zigzag catt-category" key={da.id}>
                <div className="zigzag_content">
                  <div className="rightt">
                    {da.video !== null && da.video !== "" ? (
                      <video id="myVideo" width="100%" height="100%" controls>
                        <source
                          src={`${process.env.REACT_APP_API_PATH}${da.video}`}
                          type="video/mp4"
                        />
                      </video>

                    ) : da.image == "" ? (
                      <img src={zig_zag2} alt="" />
                    ) : (
                      <>
                        <img
                          src={`${process.env.REACT_APP_API_PATH}${da.image}`}
                          alt="frf"
                          style={{
                            height: "da.image_height",
                            weight: "da.image_width",
                          }}
                        />
                      </>
                    )}
                  </div>
                  <div
                    className="leftt"
                    style={{ background: "da.background_color" }}
                  >
                    <h3 className="title">
                      <span>{da.heading} </span>
                    </h3>
                    <p>{da.content}</p>
                    {da.video !== null && da.video !== "" && play == 0 ? (
                      <div className="playbuttondiv">
                        <button className="playbutton" onClick={handleLinkClick}>

                          <i class="fa fa-play fa-3x"></i>
                        </button>

                      </div>

                    ) : ''}
                    <div className="two_btn">
                      {da.button1_url == null || da.button1_url == "" ? (
                        ""
                      ) : (
                        <Link to={da.button1_url} className="btn">
                          {da.button1_name}
                        </Link>
                      )}
                      {da.button2_url == null || da.button2_url == "" ? (
                        ""
                      ) : (
                        <Link to={da.button2_url} className="btn">
                          {da.button2_name}
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </section>
            );
          }
        })}
    </>
  );
}
