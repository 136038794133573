import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// import './style.css';
import { Link } from "react-router-dom";
import techBottom from "../../image/tech-bottom.png";
import { loadAllAboutContent } from "../../redux/action/StaticAction";

export default function TechBottom() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadAllAboutContent());
  }, []);
  const about = useSelector((state) => state.Static.aboutContent);
  return (
    <>
      {about.slice(0, 1).map((e) => {
        return (
          <>
            <div style={{ width: "80%", margin: "0 auto" }}>
              <div className="about_heading">
                <h1>About iRobot</h1>
              </div>
              <div>
                <h2 className="about_h2">
                  iRobot designs and builds robots that make a difference.
                </h2>

                <p className="about_para">
                  iRobot was founded in 1990 (30 years ago), by Massachusetts
                  Institute of Technology roboticists with the vision of making
                  practical robots a reality.
                  <br />
                  <br />
                  In 2017, iRobot generated $883.91 million in revenue and
                  employed more than 920 of the robot industry’s top
                  professionals, including mechanical, electrical and software
                  engineers and related support staff. iRobot stock trades on
                  the NASDAQ stock market under the ticker symbol IRBT. <br />
                  <br />
                  iRobot’s corporate headquarters are located in Bedford, Mass.
                  The company also has offices in California, the United
                  Kingdom, China and Hong Kong. <br />
                  <br />
                  iRobot has made some of the world’s most important robots.
                  iRobot has made some of the world’s most important robots.{" "}
                  <br />
                </p>
              </div>
              <div>
                <h2 className="about_h2">
                  iRobot Remote Presence: Brings meaningful communication to you
                </h2>

                <p className="about_para">
                  Leveraging its state-of-the-art Ava® mobile robotics platform,
                  iRobot is bringing its solutions to new markets. The company’s
                  Ava® 500 video collaboration robot delivers autonomous
                  telepresence to the enterprise market, enabling remote workers
                  to more personally collaborate throughout the workplace. The
                  FDA-approved RP-VITA™ telemedicine robot expands the reach of
                  medical care by connecting physicians with patients from
                  anywhere in the world. With iRobot Ava®, there is potential to
                  add value and enable differentiation in a wide variety of
                  markets.
                </p>
              </div>
              <div>
                <h2 className="about_h2">
                  iRobot STEM is iRobot’s unique and multi-faceted educational
                  outreach initiative.
                </h2>
                <p className="about_para">
                  Designed to inspire students to choose careers in the STEM
                  fields including robotics, the program helps generate
                  excitement around science, technology, engineering, and math.
                  iRobot STEM brings the exciting and educational world of
                  robots into the classroom all year long with online resources,
                  classroom visits, demonstrations and much more. iRobot hosts
                  student groups for tours participates in educator workshops
                  and attends STEM-related events to bring the world of robotics
                  alive. In addition, the Robot® Create® programmable mobile
                  robot provides educators, students, and developers with an
                  affordable, pre-assembled platform for hands-on programming
                  and development. As a founder and lead organizer of National
                  Robotics Week, iRobot actively promotes robotics as a pillar
                  of 21-century American innovation and emphasizes its ability
                  to inspire technology education.
                  <br />
                  <br />
                  With more than two decades of leadership in the robot
                  industry, iRobot remains committed to providing platforms for
                  invention and discovery, developing key partnerships to foster
                  technological Roomba Resets Itself.
                </p>
              </div>

              <div>
                <h2 className="about_h2"> About Puresight Systems Pvt Ltd.</h2>

                <br />
                <div className="about_para address_specing">
                  Puresight Systems Pvt Ltd. was established with a vision of
                  becoming a leading provider of home robotic appliances in
                  India. Established in 2014, Puresight Systems has the
                  exclusive partnership with iRobot to distribute iRobot home
                  robots in India. Since 2015 Puresight Systems imports iRobot
                  home robots, world’s pioneer robotic floor cleaners into
                  India. <br />
                  <br />
                  <span className="about_span">
                    Head Office and Service Center:<strong>&nbsp;</strong>No.
                    15, Ground floor, Vasant's Business Centre, Queens Road,
                    Bangalore - 560052.
                    <br />
                    Toll-Free No: 1800-200-3355
                  </span>
                </div>
              </div>
{/* 
              <div style={{ width: "100%" }}>
                <h2 className="about_h2">Showrooms</h2>
              </div> */}
              {/* <div className="about_flex af">
                <div>
                  <div className="about_flex">
                    <div style={{ width: "26%" }}>
                      <img src="http://irobot.in/images/SR-Queens-Road.jpg" />
                    </div>
                    <div className="spacing_Left">
                      <h4 className="">NOW OPEN IN CHENNAI.</h4>
                      <p>
                        iRobot, Ground Floor, Ispahani Center Chennai.
                        <br />
                        123 / 124, Nungambakkam High Road,
                        <br />
                        Thousand Lights, Chennai, Tamil Nadu 600034 Thousand
                        Lights, Chennai, Tamil Nadu 600034
                        <br />
                        Tel. No: 1800-200-3355
                      </p>
                    </div>
                  </div>
                </div>
                <div className="about_flex">
                  <div style={{ width: "33%" }}>
                    <img src="http://irobot.in/images/SR-Chennai-Store-Front.jpg" />
                  </div>
                  <div className="spacing_Left">
                    <h4 className="">NOW OPEN IN CHENNAI.</h4>
                    <p>
                      iRobot, Ground Floor, Ispahani Center Chennai.
                      <br />
                      123 / 124, Nungambakkam High Road,
                      <br />
                      Thousand Lights, Chennai, Tamil Nadu 600034
                      <br />
                      Tel. No: 1800-200-3355
                    </p>
                  </div>
                </div>
              </div> */}
            </div>
          </>
        );
      })}
    </>
  );
}