import {
  FIND_FAQ_FAIL,
  FIND_FAQ_REQUEST,
  FIND_FAQ_SUCCESS,
  FIND_PROMO_CODE_FAIL,
  FIND_PROMO_CODE_REQUEST,
  FIND_PROMO_CODE_SUCCESS,
  ADD_APPOINTMENT_FAIL,
  ADD_APPOINTMENT_REQUEST,
  ADD_APPOINTMENT_SUCCESS,
  ADD_APPOINTMENTS_FAIL,
  ADD_APPOINTMENTS_REQUEST,
  ADD_APPOINTMENTS_SUCCESS,
  ADD_CAREER_FAIL,
  ADD_CAREER_REQUEST,
  ADD_CAREER_SUCCESS,
  ADD_CUSTOMER_SUPPORT_FAIL,
  ADD_CUSTOMER_SUPPORT_REQUEST,
  ADD_CUSTOMER_SUPPORT_SUCCESS,
  ADD_ENQUIRY_FAIL,
  ADD_ENQUIRY_REQUEST,
  ADD_ENQUIRY_SUCCESS,
  FIND_BLOGS_FAIL,
  FIND_BLOGS_REQUEST,
  FIND_BLOGS_SUCCESS,
  FIND_TESTIMONIAL_FAIL,
  FIND_TESTIMONIAL_REQUEST,
  FIND_TESTIMONIAL_SUCCESS,
  FIND_NEWS_FAIL,
  FIND_NEWS_REQUEST,
  FIND_NEWS_SUCCESS,
  ADD_PROMOTION_EMAIL_FAIL,
  ADD_PROMOTION_EMAIL_REQUEST,
  ADD_PROMOTION_EMAIL_SUCCESS,
  FIND_SINGLE_BLOGS_FAIL,
  FIND_SINGLE_BLOGS_REQUEST,
  FIND_SINGLE_BLOGS_SUCCESS,
  SEARCH_FAQ_FAIL,
  SEARCH_FAQ_REQUEST,
  SEARCH_FAQ_SUCCESS,
  GET_MENU_TITLE_FAIL,
  GET_MENU_TITLE_REQUEST,
  GET_MENU_TITLE_SUCCESS,
  GET_TOP_HEADER_MESSAGE_FAIL,
  GET_TOP_HEADER_MESSAGE_REQUEST,
  GET_TOP_HEADER_MESSAGE_SUCCESS,
  ADD_LOYALTY_REWARDS_FAIL,
  ADD_LOYALTY_REWARDS_REQUEST,
  ADD_LOYALTY_REWARDS_SUCCESS,
  RESET_STATE,
  SEARCH_ORDERTRACK_REQUEST,
  SEARCH_ORDERTRACK_SUCCESS,
  SEARCH_ORDERTRACK_FAIL
} from "../constants/AdditionalConstant";

const initialState = {
  loading: false,
  action: "Additonal-Data",
  response: {},
  faq: [],
  searchFaq: [],
  searchOrderTract: [],
  promoCode: [],
  promoCodeSingle: "",
  blog: [],
  singleblog: [],
  news: [],
  testimonial: [],
  appointment: [],
  career: [],
  enquiry: [],
  support: [],
  promotionemail: [],
  menuMessage: [],
  menuTitle: [],
  loyaltyReward: [],
  msg: "",
  error: "",
};

const AdditionalReducer = (state = initialState, action) => {
  switch (action.type) {


    case RESET_STATE:
      return {
        ...state,
        response: {},
      };
    case GET_TOP_HEADER_MESSAGE_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case GET_TOP_HEADER_MESSAGE_SUCCESS:
      return {
        ...state,
        menuMessage: action.result.data,
        loading: action.payload,
        msg: action.msg,
      };
    case GET_TOP_HEADER_MESSAGE_FAIL:
      return {
        ...state,
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };

    case GET_MENU_TITLE_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case GET_MENU_TITLE_SUCCESS:
      return {
        ...state,
        menuTitle: action.result.data,
        loading: action.payload,
        msg: action.msg,
      };
    case GET_MENU_TITLE_FAIL:
      return {
        ...state,
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };

    case FIND_FAQ_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case FIND_FAQ_SUCCESS:
      return {
        ...state,
        faq: action.result.data,
        loading: action.payload,
        msg: action.msg,
      };
    case FIND_FAQ_FAIL:
      return {
        ...state,
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };

    case SEARCH_FAQ_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case SEARCH_FAQ_SUCCESS:
      return {
        ...state,
        searchFaq: action.result.data,
        loading: action.payload,
        msg: action.msg,
      };
    case SEARCH_FAQ_FAIL:
      return {
        ...state,
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };

    case SEARCH_ORDERTRACK_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case SEARCH_ORDERTRACK_SUCCESS:
      return {
        ...state,
        searchOrderTract: action.result.data,
        loading: action.payload,
        msg: action.msg,
      };
    case SEARCH_ORDERTRACK_FAIL:
      return {
        ...state,
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };

    case FIND_PROMO_CODE_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case FIND_PROMO_CODE_SUCCESS:
      return {
        ...state,
        promoCode: action.result.data,
        promoCodeSingle: action.result.data,
        loading: action.payload,
        msg: action.msg,
      };
    case FIND_PROMO_CODE_FAIL:
      return {
        ...state,
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };

    case FIND_BLOGS_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case FIND_BLOGS_SUCCESS:
      return {
        ...state,
        blog: action.result.data,
        loading: action.payload,
        msg: action.msg,
      };
    case FIND_BLOGS_FAIL:
      return {
        ...state,
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };

    case FIND_SINGLE_BLOGS_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case FIND_SINGLE_BLOGS_SUCCESS:
      return {
        ...state,
        singleblog: action.result.data,
        loading: action.payload,
        msg: action.msg,
      };
    case FIND_SINGLE_BLOGS_FAIL:
      return {
        ...state,
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };

    case FIND_TESTIMONIAL_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case FIND_TESTIMONIAL_SUCCESS:
      return {
        ...state,
        testimonial: action.result.data,
        loading: action.payload,
        msg: action.msg,
      };
    case FIND_TESTIMONIAL_FAIL:
      return {
        ...state,
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };

    case FIND_NEWS_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case FIND_NEWS_SUCCESS:
      return {
        ...state,
        news: action.result.data,
        loading: action.payload,
        msg: action.msg,
      };
    case FIND_NEWS_FAIL:
      return {
        ...state,
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };

    case ADD_LOYALTY_REWARDS_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case ADD_LOYALTY_REWARDS_SUCCESS:
      return {
        ...state,
        loyaltyReward: state.appointment.concat(action.result.data),
        response: action.result.msg,
        loading: action.payload,
        msg: action.msg,
      };
    case ADD_LOYALTY_REWARDS_FAIL:
      return {
        ...state,
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };

    case ADD_APPOINTMENT_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case ADD_APPOINTMENT_SUCCESS:
      return {
        ...state,
        appointment: state.appointment.concat(action.result.data),
        response: action.result.msg,
        loading: action.payload,
        msg: action.msg,
      };
    case ADD_APPOINTMENT_FAIL:
      return {
        ...state,
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };

    case ADD_APPOINTMENTS_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case ADD_APPOINTMENTS_SUCCESS:
      return {
        ...state,
        appointment: state.appointment.concat(action.result.data),
        response: action.result.msg,
        loading: action.payload,
        msg: action.msg,
      };
    case ADD_APPOINTMENTS_FAIL:
      return {
        ...state,
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };

    case ADD_CAREER_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case ADD_CAREER_SUCCESS:
      return {
        ...state,
        career: state.career.concat(action.result.data),
        response: action.result.msg,
        loading: action.payload,
        msg: action.msg,
      };
    case ADD_CAREER_FAIL:
      return {
        ...state,
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };

    case ADD_ENQUIRY_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case ADD_ENQUIRY_SUCCESS:
      return {
        ...state,
        enquiry: state.enquiry.concat(action.result.data),
        response: action.result.msg,
        loading: action.payload,
        msg: action.msg,
      };
    case ADD_ENQUIRY_FAIL:
      return {
        ...state,
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };

    case ADD_CUSTOMER_SUPPORT_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case ADD_CUSTOMER_SUPPORT_SUCCESS:
      return {
        ...state,
        support: state.support.concat(action.result.data),
        response: action.result.msg,
        loading: action.payload,
        msg: action.msg,
      };
    case ADD_CUSTOMER_SUPPORT_FAIL:
      return {
        ...state,
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };

    case ADD_PROMOTION_EMAIL_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case ADD_PROMOTION_EMAIL_SUCCESS:
      return {
        ...state,
        promotionemail: state.promotionemail.concat(action.result.data),
        response: action.result.msg,
        loading: action.payload,
        msg: action.msg,
      };
    case ADD_PROMOTION_EMAIL_FAIL:
      return {
        ...state,
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };

    default:
      return state;
  }
};

export default AdditionalReducer;
