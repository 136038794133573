import React, { useEffect } from "react";
import TechTop from "../component/about/TechTop";
import TechBottom from "../component/about/TechBottom";
import LeaderShip from "../component/about/LeaderShip";
import Togther from "../component/about/Togther";

export default function CompanyINformation() {
  useEffect(() => {
    document.getElementById("maintittle").innerHTML = "iRobot India - Puresight Systems Pvt Ltd - Bangalore, India";
    document.getElementById("description").setAttribute("content", "iRobot products are launched in India by Puresight Systems Pvt Ltd in the year 2015. Puresight Systems is the authorized importers of iRobot home cleaning products in India.");

    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <TechTop />

      <TechBottom />
      {/* b
      <LeaderShip />
      c
      <Togther />d */}
    </div>
  );
}
