import {
 FIND_ALL_SATISFACTION_FAIL,
 FIND_ALL_SATISFACTION_REQUEST,
 FIND_ALL_SATISFACTION_SUCCESS,
 FIND_ALL_HOME_FAIL,
FIND_ALL_HOME_REQUEST,
FIND_ALL_HOME_SUCCESS,
FIND_ALL_FIXED_BANNER_FAIL,
FIND_ALL_FIXED_BANNER_REQUEST,
FIND_ALL_FIXED_BANNER_SUCCESS,
FIND_ALL_BANNER_SLIDER_FAIL,
FIND_ALL_BANNER_SLIDER_REQUEST,
FIND_ALL_BANNER_SLIDER_SUCCESS,
FIND_OFFER_BANNER_FAIL,
FIND_OFFER_BANNER_REQUEST,
FIND_OFFER_BANNER_SUCCESS,
  } from "../constants/HomeConstant";

  const initialState = {
    loading: false,
    action: "Home Action",
    result: [],
    offer: [],
    orderlist: [],
    response: {},
    singledata: [],
    fixedbanner: [],
    sliderbanner: [],
    msg: "",
    error: "",
  };

  const HomeReducer = (state = initialState, action) => {
    switch (action.type) {
      case FIND_ALL_SATISFACTION_REQUEST:
        return {
          ...state,
          loading: action.payload,
        };
      case FIND_ALL_SATISFACTION_SUCCESS:
        return {
          ...state,
          result: action.result.data,
          loading: action.payload,
          msg: action.msg,
        };
      case FIND_ALL_SATISFACTION_FAIL:
        return {
          ...state,
          error: action.error,
          loading: action.payload,
          msg: action.msg,
        };

        case FIND_OFFER_BANNER_REQUEST:
        return {
          ...state,
          loading: action.payload,
        };
      case FIND_OFFER_BANNER_SUCCESS:
        return {
          ...state,
          offer: action.result.data,
          loading: action.payload,
          msg: action.msg,
        };
      case FIND_OFFER_BANNER_FAIL:
        return {
          ...state,
          error: action.error,
          loading: action.payload,
          msg: action.msg,
        };

        case FIND_ALL_HOME_REQUEST:
        return {
          ...state,
          loading: action.payload,
        };
      case FIND_ALL_HOME_SUCCESS:
        return {
          ...state,
          singledata: action.result.data,
          loading: action.payload,
          msg: action.msg,
        };
      case FIND_ALL_HOME_FAIL:
        return {
          ...state,
          error: action.error,
          loading: action.payload,
          msg: action.msg,
        };

        case FIND_ALL_FIXED_BANNER_REQUEST:
        return {
          ...state,
          loading: action.payload,
        };
      case FIND_ALL_FIXED_BANNER_SUCCESS:
        return {
          ...state,
          fixedbanner: action.result.data,
          loading: action.payload,
          msg: action.msg,
        };
      case FIND_ALL_FIXED_BANNER_FAIL:
        return {
          ...state,
          error: action.error,
          loading: action.payload,
          msg: action.msg,
        };

        case FIND_ALL_BANNER_SLIDER_REQUEST:
          return {
            ...state,
            loading: action.payload,
          };
        case FIND_ALL_BANNER_SLIDER_SUCCESS:
          return {
            ...state,
            sliderbanner: action.result.data,
            loading: action.payload,
            msg: action.msg,
          };
        case FIND_ALL_BANNER_SLIDER_FAIL:
          return {
            ...state,
            error: action.error,
            loading: action.payload,
            msg: action.msg,
          };


      default:
        return state;
    }
  };

  export default HomeReducer;
