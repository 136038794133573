import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/fontawesome-free-solid";
import OrderInvoice from "../component/dashboard/OrderInvoice";
import { resetstate } from "../redux/action/AdditionalAction";
import { useDispatch, useSelector } from "react-redux";

export default function ThanksYou() {
  const [showInvoice, setshowInvoice] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetstate());

    window.scrollTo(0, 0);
  }, []);
  return (
    <section className="section thank-you">
      <div className="container">
        <div className="thanks-title">
          <h2 className="title">
            <span>Thank You</span>
          </h2>
        </div>
        <div className="thanks-middle">
          <p>
            Your order number is{" "}
            <span
              className="invoice-show subheading"
              style={{ display: "inline-block" }}
              onClick={() => setshowInvoice(!showInvoice)}
            >

            </span>
          </p>
          {/* 
          <p>
            Email us at
            <a href="mailto:Infor@nashtronics.in">Infor@nashtronics.in</a>
          </p>
          */}
        </div>
        <div className="thanks-bottom">
          <FontAwesomeIcon icon={faCheck} />
        </div>
      </div>
      {showInvoice ? <OrderInvoice /> : null}
    </section>
  );
}
