import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadCividUpdate } from "../redux/action/StaticAction";
import Loader from "../component/Shared/Loader";
import Message from "../component/share/Message";

export default function Covid19Updates() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadCividUpdate());
  }, [dispatch]);

  const CovidData = useSelector((state) => state.Static);

  const { loading, error, covidUpdateData } = CovidData;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section className="section" style={{ background: "#fff" }}>
      <div className="container">
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : (
          <div className="Covid19Updates page_content">
            <div className="rte">
              <h2 className="title center">COVID-19 UPDATE</h2>
            </div>
            {covidUpdateData &&
              covidUpdateData.map((item, i) => (
                <div className="rte" key={i}>
                  {item.heading ? <h3 className="">{item.heading}</h3> : <></>}

                  <p>{item.content}</p>
                </div>
              ))}
          </div>
        )}
      </div>
    </section>
  );
}
