import {
  ADD_PRODUCT_REVIEW_FAIL,
  ADD_PRODUCT_REVIEW_REQUEST,
  ADD_PRODUCT_REVIEW_SUCCESS,
  GET_ALL_PRODUCT_REVIEW_FAIL,
  GET_ALL_PRODUCT_REVIEW_REQUEST,
  GET_ALL_PRODUCT_REVIEW_SUCCESS,
  GET_ALL_PRODUCT_REVIEW_IMAGE_REQUEST,
  GET_ALL_PRODUCT_REVIEW_IMAGE_SUCCESS,
  GET_ALL_PRODUCT_REVIEW_IMAGE_FAIL,
} from "../constants/ProductReviewConstant";
import axios from "axios";
import { toast } from "react-toastify";

// action from get all the ProductReview

const loadAllProductReview = (id) => {
  return function (dispatch) {
    dispatch({
      type: GET_ALL_PRODUCT_REVIEW_REQUEST,
      payload: true,
    });

    let OPTION = {
      //url: `${process.env.REACT_APP_API_PATH}/api/v1/product-rating-prodid/${id}/`,
      url: `${process.env.REACT_APP_API_PATH}/api/v1/product-rating-prodid-n/${id}/`,

      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };

    axios(OPTION)
      .then((res) => {
        // console.log("res", res.data);
        dispatch(loadAllProductReviewPre(res.data));
      })
      .catch((error) => {
        // console.log("error", error);
        dispatch({
          type: GET_ALL_PRODUCT_REVIEW_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
      });
  };
};
export const loadAllProductReviewPre = (data) => {
  return {
    type: GET_ALL_PRODUCT_REVIEW_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

const loadAllProductReviewImage = (id) => {
  return function (dispatch) {
    dispatch({
      type: GET_ALL_PRODUCT_REVIEW_IMAGE_REQUEST,
      payload: true,
    });

    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/admin-product-rating/`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };

    axios(OPTION)
      .then((res) => {
        // console.log("res", res.data);
        dispatch(loadAllProductReviewImagePre(res.data));
      })
      .catch((error) => {
        // console.log("error", error);
        dispatch({
          type: GET_ALL_PRODUCT_REVIEW_IMAGE_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
      });
  };
};
export const loadAllProductReviewImagePre = (data) => {
  return {
    type: GET_ALL_PRODUCT_REVIEW_IMAGE_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};


// add New Vehicle
const createProductReview = (data) => {
  return function (dispatch) {
    dispatch({
      type: ADD_PRODUCT_REVIEW_REQUEST,
      payload: true,
    });

    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/admin-product-rating/`,
      method: "POST",
      data: data,
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    axios(OPTION)
      .then((res) => {
        console.log('dara', res.data);
        dispatch(createProductReviewPre(res.data));
        alert("Thank you for your valuable review")
      })
      .catch((error) => {
        console.log("error-data", error)
        dispatch({
          type: ADD_PRODUCT_REVIEW_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
        toast.danger(error.msg);
      });
  };
};
export const createProductReviewPre = (data) => {
  toast.success(data.msg);
  return {
    type: ADD_PRODUCT_REVIEW_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

export { loadAllProductReview, createProductReview, loadAllProductReviewImage };
