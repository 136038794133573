import axios from "axios";
import { toast } from "react-toastify";
import {
  FIND_ALL_SATISFACTION_FAIL,
  FIND_ALL_SATISFACTION_REQUEST,
  FIND_ALL_SATISFACTION_SUCCESS,
  FIND_ALL_HOME_FAIL,
  FIND_ALL_HOME_REQUEST,
  FIND_ALL_HOME_SUCCESS,
  FIND_ALL_FIXED_BANNER_FAIL,
  FIND_ALL_FIXED_BANNER_REQUEST,
  FIND_ALL_FIXED_BANNER_SUCCESS,
  FIND_ALL_BANNER_SLIDER_FAIL,
  FIND_ALL_BANNER_SLIDER_REQUEST,
  FIND_ALL_BANNER_SLIDER_SUCCESS,
  FIND_OFFER_BANNER_FAIL,
  FIND_OFFER_BANNER_REQUEST,
  FIND_OFFER_BANNER_SUCCESS
} from "../constants/HomeConstant";
// Find All Vehicle OWNER
const loadAllSatisfaction = () => {
  return function (dispatch) {
    dispatch({
      type: FIND_ALL_SATISFACTION_REQUEST,
      payload: true,
    });
    // const token = JSON.parse(localStorage.getItem("jwt"))
    //   ? JSON.parse(localStorage.getItem("jwt"))
    //   : "";
    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/static-content1/`,
      method: "GET",
      headers: {
        "content-type": "application/json",
        // token: token,
      },
    };
    axios(OPTION)
      .then((res) => {
        dispatch(loadAllSatisfactionPre(res.data));
      })
      .catch((error) => {
        dispatch({
          type: FIND_ALL_SATISFACTION_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
      });
  };
};
export const loadAllSatisfactionPre = (data) => {
  return {
    type: FIND_ALL_SATISFACTION_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

const loadOfferBanner = () => {
  return function (dispatch) {
    dispatch({
      type: FIND_OFFER_BANNER_REQUEST,
      payload: true,
    });
    // const token = JSON.parse(localStorage.getItem("jwt"))
    //   ? JSON.parse(localStorage.getItem("jwt"))
    //   : "";
    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/offer-banner/`,
      method: "GET",
      headers: {
        "content-type": "application/json",
        // token: token,
      },
    };
    axios(OPTION)
      .then((res) => {
        dispatch(loadOfferBannerPre(res.data));
      })
      .catch((error) => {
        dispatch({
          type: FIND_OFFER_BANNER_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
      });
  };
};
export const loadOfferBannerPre = (data) => {
  return {
    type: FIND_OFFER_BANNER_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

const loadAllHome = () => {
  return function (dispatch) {
    dispatch({
      type: FIND_ALL_HOME_REQUEST,
      payload: true,
    });
    // const token = JSON.parse(localStorage.getItem("jwt"))
    //   ? JSON.parse(localStorage.getItem("jwt"))
    //   : "";
    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/dashboard-static-content/`,
      method: "GET",
      headers: {
        "content-type": "application/json",
        // token: token,
      },
    };
    axios(OPTION)
      .then((res) => {
        dispatch(loadAllHomePre(res.data));
      })
      .catch((error) => {
        dispatch({
          type: FIND_ALL_HOME_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
      });
  };
};
export const loadAllHomePre = (data) => {
  return {
    type: FIND_ALL_HOME_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

const loadAllFixedBanner = () => {
  return function (dispatch) {
    dispatch({
      type: FIND_ALL_FIXED_BANNER_REQUEST,
      payload: true,
    });
    // const token = JSON.parse(localStorage.getItem("jwt"))
    //   ? JSON.parse(localStorage.getItem("jwt"))
    //   : "";
    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/static-content2/`,
      method: "GET",
      headers: {
        "content-type": "application/json",
        // token: token,
      },
    };
    axios(OPTION)
      .then((res) => {
        dispatch(loadAllFixedBannerPre(res.data));
      })
      .catch((error) => {
        dispatch({
          type: FIND_ALL_FIXED_BANNER_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
      });
  };
};
export const loadAllFixedBannerPre = (data) => {
  return {
    type: FIND_ALL_FIXED_BANNER_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

const loadAllSliderBanner = () => {
  return function (dispatch) {
    dispatch({
      type: FIND_ALL_BANNER_SLIDER_REQUEST,
      payload: true,
    });
    // const token = JSON.parse(localStorage.getItem("jwt"))
    //   ? JSON.parse(localStorage.getItem("jwt"))
    //   : "";
    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/dashboard-banner/`,
      method: "GET",
      headers: {
        "content-type": "application/json",
        // token: token,
      },
    };
    axios(OPTION)
      .then((res) => {
        dispatch(loadAllSliderBannerPre(res.data));
      })
      .catch((error) => {
        dispatch({
          type: FIND_ALL_BANNER_SLIDER_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
      });
  };
};
export const loadAllSliderBannerPre = (data) => {
  return {
    type: FIND_ALL_BANNER_SLIDER_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

export { loadAllSatisfaction, loadAllHome, loadAllFixedBanner, loadAllSliderBanner, loadOfferBanner };
