import React, { useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useNavigate } from "react-router-dom";
import UpdateProfile from "./UpdateProfile";
import DashboardProfile from "./DashboardProfile";
import OrderHistory from "./OrderHistory";
import UserAddress from "./UserAddress";
import RewardPoints from "./RewardPoints";
import Ordertract from "./Ordertract";

import RegisteredRobots from "./RegisteredRobots";
import RegisterRobot from "../../screen/RegisterRobot";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { signOut } from "../../redux/action/AuthAction";

export default function DashboardSideBar() {
  const userInfo = useSelector((state) => state.AuthReducer);

  let { userResult, isSigninIn } = userInfo;

  const dispatch = useDispatch();

  let localCart = localStorage.getItem("cart");

  let navigate = useNavigate();

  useEffect(() => {
    if (isSigninIn !== true) {
      navigate("/login");
    } else {
    }
  }, []);

  const logoutHandler = () => {
    dispatch(signOut());
    navigate("/");

  };

  return (
    <Tabs className="dashboard_sidebar">
      <ToastContainer />
      <TabList>
        <Tab>Dashboard</Tab>
        <Tab>Reward Points</Tab>
        <Tab>Update Profile</Tab>
        <Tab>User Address</Tab>
        <Tab>Order History</Tab>
        <Tab>Order Tracking</Tab>
        <Tab>Register Robot</Tab>
        <Tab>Registered Robots</Tab>
        {/* <Tab>Change Password</Tab> */}
        <Tab onClick={logoutHandler}>Logout</Tab>
      </TabList>

      <div className="content_area">
        <TabPanel>
          <DashboardProfile />
        </TabPanel>
        <TabPanel>
          <RewardPoints />
        </TabPanel>
        <TabPanel>
          <UpdateProfile />
        </TabPanel>
        <TabPanel>
          <UserAddress />
        </TabPanel>
        <TabPanel>
          <OrderHistory />
        </TabPanel>
        <TabPanel>
          <Ordertract />
        </TabPanel>

        <TabPanel>
          <RegisterRobot />
        </TabPanel>
        <TabPanel>
          <RegisteredRobots />
        </TabPanel>
        {/* <TabPanel>
          <UpdatePassword />
        </TabPanel> */}
        <TabPanel></TabPanel>
      </div>
    </Tabs>
  );
}
