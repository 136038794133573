import React, { useEffect } from "react";
import Banner from "../component/home/Banner";
import Hard_work from "../component/home/Hard_work";
import Fixed_banner from "../component/home/Fixed_banner";
import Satisfaction from "../component/home/Satisfaction";
import Insta_feed from "../component/home/Insta_feed";
import OfferSection from "../component/home/OfferSection";
// import InquireForm from "../component/home/InquireForm";

export default function Home() {
  useEffect(() => {
    document.getElementById("maintittle").innerHTML = "iRobot India: Roomba Robot Vacuums and Braava Robot Mops";
    document.getElementById("description").setAttribute("content", "Shop iRobot Roomba robotic vacuum cleaner and Braava mopping robot. Let these home robots help you with your daily cleaning chores. Floor Vacuum & Mop.");
    window.scrollTo(0, 0);

  }, []);
  return (
    <div>
      <Banner />
      <Satisfaction />
      <Hard_work />
      <OfferSection />
      <Fixed_banner />
      {/* <InquireForm /> */}

      {/* <Insta_feed />  */}
    </div>
  );
}
