import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { loadAllCategory } from "../../redux/action/CategoryAction";
import { loadAllHome } from "../../redux/action/HomeAction";
import { Link } from "react-router-dom";

export default function Product_category() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadAllCategory());
        dispatch(loadAllHome());
    }, []);

    const category = useSelector((state) => state.MainCategory.result);
    const homeContent = useSelector((state) => state.Home.singledata);
    // console.log("homContent",homeContent)
    return (
        <section className="section work_hard">
            <div className="container">
                <ul>
                    {category.slice(0, 5).map((e) => {
                        return (
                            <li key={e.id}>
                                <div className="bundeel">
                                    <Link to={"/series/" + e.id}>
                                        <img
                                            className="banner_image"
                                            // style={{ width: '30%' }}
                                            src={process.env.REACT_APP_API_PATH + e.image}
                                            alt={e.name}
                                        />
                                        <div className="cattext">
                                            <h3>{e.name}</h3>
                                            <h3>{e.alias}</h3>

                                        </div>
                                    </Link>
                                </div>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </section>
    );
}
