import React, { useState, useEffect } from "react";
import { loadProductAccessoryList } from "../../redux/action/ProductAction";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
const handleClick = () => {
  window.scrollTo(0, 0);
};
var settings = {
  dots: false,
  arrows: true,
  infinite: true,
  slidesToShow: 5,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
export default function SliderAccessories() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [cattype, setcattype] = useState(1);




  const { id } = useParams();
  let newData = data.data;
  console.log(newData, "avjiiiiiiiiiiiiiiiii");
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {

        //const url = process.env.REACT_APP_API_PATH+"/api/v1/admin-product/" + id + "/";
        const url = process.env.REACT_APP_API_PATH + "/api/v1/admin-product-n/" + id + "/";

        const { data: response } = await axios.get(
          url
        );
        // console.log('sal..', JSON.stringify(response.data.ac_item_no));
        let cattypercv = response.data.subcategory.type;
        console.log('cattype..', cattypercv);

        setcattype(cattypercv);
        if (cattypercv != 2) {
          let acid = response.data.ac_item_no;
          console.log("cattype acid.." + acid);

          //let acid = 63;

          try {

            const url = process.env.REACT_APP_API_PATH + "/api/v1/product-subcatid/" + acid + "/";
            const { data: response } = await axios.get(
              url
            );
            console.log("cattype rescheck.." + response);

            setData(response);
          } catch (error) {
            console.error(error.message);
          }
        }

      } catch (error) {
        console.error(error.message);

      }
      /*
      try {

        const url = "${process.env.REACT_APP_API_PATH}/api/v1/user-subcategory-catid/" + id + "/";
        const { data: response } = await axios.get(
          url
        );
        setData(response);
      } catch (error) {
        console.error(error.message);
      } 
      */
      setLoading(false);
    };
    fetchData();
  }, [id]);

  return (
    <section className="section shop_now acc-slider">
      <div className="container product_slider_main">
        {newData ? <h2 className="title">
          <p></p>
          <span>Compatible Accessories</span>
          <p></p>
        </h2> : ""}
        {newData ?
          <ul>
            <Slider {...settings}>
              {newData &&
                newData.map((shopnow, i) => {
                  return (
                    <li key={shopnow.id}>
                      <div className="shop_now_content">
                        <div className="product_main">
                          {shopnow.tag ? (
                            // <span className="savving">{shopnow.tag}</span>
                            ""
                          ) : (
                            " "
                          )}
                          <h3>{shopnow.name}</h3>
                          <p>{shopnow.subcategory.catid.alias}</p>
                        </div>
                        <div className="product_imagew">
                          <Link to={`/product/${shopnow.slug}`}>
                            <img src={`${process.env.REACT_APP_API_PATH}${shopnow.image}`} />
                          </Link>
                        </div>
                        <div className="product_descripton">
                          <div className="series_price">
                            <p className="regular">
                              {shopnow.price != null && shopnow.price != "" ? (
                                <strike>₹{shopnow.price}</strike>
                              ) : (
                                " "
                              )}

                              <span className="sale">₹{shopnow.sellprice}</span>
                            </p>
                            {shopnow.price != null && shopnow.sellprice != null && shopnow.price != "" && shopnow.sellprice != "" && (shopnow.price - shopnow.sellprice) != 0 ? (

                              <p className="saving_price">
                                Save: ₹{shopnow.price - shopnow.sellprice} (
                                {Math.ceil(
                                  (shopnow.price - shopnow.sellprice) /
                                  (shopnow.price / 100)
                                )}
                                %)
                              </p>
                            ) : (
                              " "
                            )}
                          </div>
                          <Link
                          onClick={handleClick}
                            to={`/product/${shopnow.slug}`}
                            className="btn shop_now"
                          >
                            Shop Now
                          </Link>
                        </div>
                      </div>
                    </li>

                  );
                })}
            </Slider>
          </ul> : ""}
      </div>
    </section>
  );
}
