import React, { useEffect } from 'react';
import { Link } from 'react-router-dom'
import { loadAllMainProductList } from "../redux/action/ProductAction";
import { useDispatch, useSelector } from "react-redux";
import { loadAllSubAccesssories } from "..//redux/action/SubAccessoriesAction";


export default function SiteMap() {

  useEffect(() => {
    document.getElementById("maintittle").innerHTML = "Site Map | iRobot India";
    document.getElementById("description").setAttribute("content", "Site Map | iRobot India");

    window.scrollTo(0, 0)
  }, [])

  let product = useSelector((state) => state.MainProduct);
  let { loading, error, allProducts } = product;

  const dispatch = useDispatch();

  const SubAccessories = useSelector(
    (state) => state.SubAccessories.result.data
  );

  useEffect(() => {

    dispatch(loadAllMainProductList());
    dispatch(loadAllSubAccesssories("roomba-accessories"));

  }, []);
  return (

    <section className="section" style={{ background: "#fff" }}>
      <div className="container">
        <div className="siteMap page_content">
          <div className="rte">
            <h2 className="title ">Site Map</h2>
            <p> <a href="/">Home Page</a></p>
            {/* <p> <Link to={#!}>Home Page</Link></p> */}


          </div>
          <div className="rte">
            <h3>Products</h3>

            {allProducts &&
              allProducts.map((item, i) => (
                
                item.visible == 1 ?
                (
                  <p><a href={`/product/${item.slug}`} >{item.name} </a></p>


                ) : "" 
                // <p><a href={`/product/${item.slug}`} >{item.name} </a></p>


                /*
                item.type == 1 ?
                  (
                    <p><a href='/{item.name}'>{item.name} </a></p>


                  ) : "" */


              ))}

          </div>

          {/* <div className="rte">
            <h3>Accessories</h3>
            {SubAccessories &&
              SubAccessories.map((accee, i) => (

                <p><a href={`/category/${accee.slug}`} >{accee.name} </a></p>


              ))}

          </div> */}
          <div className="singlesitemape">

            <p><a href='/irobot-os'>iRobot Os  </a></p>
          </div>

          <div className="rte">
            <h3>Support</h3>
            <p><a href='/faq'>Find an answer </a></p>
            <p><a href='/register'>Register a robot </a></p>
            <p><a href='/storelocator'>Where to buy </a></p>
            <p><a href='/contact-us'>Contact  </a></p>
          </div>

          <div className="rte">
            <h3>About</h3>
            <p><strong>• Company Information</strong></p>
            <p><a href='/company-information'>About us </a></p>
            <p><a href='/blog'>Blogs  </a></p>
            <p><strong>• Business Opportunity</strong></p>
            <p><a href='/enquiry'>Enquire here </a></p>
            <p><strong>• Press</strong></p>
            <p><a href='/news'>News </a></p>
            <p><a href='/testimonial'>Testimonials </a></p>
            <p><strong>• Careers</strong></p>
            <p><a href='/apply-now'>Apply Now </a></p>
          </div>

          <div className="singlesitemape">
            <p><a href='/video-demo'>video-demo </a></p>
          </div>

          <div className="singlesitemape">
            <p><a href='/offer'>Offer </a></p>
          </div>
          <div className="singlesitemape">
            <p><a href='/spin-wheel'>Reward Wheel</a></p>
          </div>



          <div className="rte">
            <h3>Account</h3>
            <p><a href='/login'>Login </a></p>
            <p><a href='/register'>Create Account  </a></p>
            <p><a href='/dashboard'>Returning customer </a></p>
            <p><a href='/dashboard'>Order status </a></p>
            <p><a href='/dashboard'>Customer Loyalty Program </a></p>
            <p><a href='/cart'>Shopping Cart </a></p>
            <p><a href='/checkout'>Check out </a></p>
          </div>

          <div className="rte">
            <h3>Information</h3>
            {/*
                <p><a href='/covid19-updates'>COVID 19 Update </a></p>
                 */}
            <p><a href='/privacy-policy'>Privacy Policy  </a></p>
            <p><a href='/terms-condition'>Terms &amp; Conditions </a></p>
            <p><a href='/data-security'>Data-security</a></p>
            <p><a href='/return-warranty'>Returns and Warranty </a></p>
            <p><a href='/no-cost-emi'>No Cost EMI</a></p>

            <p><a href='/sitemap'>Sitemap </a></p>
          </div>

        </div>
      </div>

    </section>
  )
}
