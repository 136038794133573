import React, { useEffect } from "react";
// import EmptyCart from "../component/cart/EmptyCart";
import ProductCart from "../component/cart/ProductCart";

export default function Cart() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* <EmptyCart/> */}
      <ProductCart />
    </>
  );
}
