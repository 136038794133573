import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { loadAllProductList } from "../../redux/action/ProductListingAction";
import { Link, useParams } from "react-router-dom";
import Series_tab1 from "./Series_tab1";
import SeriesBundel from "./SeriesBundel";
import FixedBanner from "./FixedBanner";
import SortingSubCategory from "./SortingSubCategory";
import Fixed_banner from "../home/Fixed_banner";
import Satisfaction from "../home/Satisfaction";

export default function FixedMenu(props) {
  const dispatch = useDispatch();



  const [ProductListing, setProductListing] = useState([]);


  let { id } = useParams();
  const ProductLisrcv = useSelector((state) => state.ProdutcListing.result);
  useEffect(() => {
    dispatch(loadAllProductList(id));
    console.log("call loadAllProductList", props.banneridglobe);

  }, [id]);

  useEffect(() => {


    setProductListing(ProductLisrcv);
    if (ProductLisrcv != undefined && ProductLisrcv != null) {

      if (ProductLisrcv[0] != undefined && ProductLisrcv[0] != null) {
        console.log("maintittle", ProductLisrcv[0].subcategory.seo_tittle);
        document.getElementById("maintittle").innerHTML = ProductLisrcv[0].subcategory.seo_tittle;

      }
      // document.getElementById("maintittle").innerHTML = bannerData.subcat_id.seo_tittle;
    }


  }, [ProductLisrcv]);


  // const scroll1 = () => {
  //   const section = document.querySelector('#sertires');
  //   section.scrollIntoView({ behavior: 'smooth', block: 'start' });
  // };
  const scroll2 = () => {
    const section = document.querySelector("#explore_new");
    section.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  const scroll3 = () => {
    const section = document.querySelector("#more-info");
    section.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  // const scroll4 = () => {
  //   const section = document.querySelector('#compare-product');
  //   section.scrollIntoView({ behavior: 'smooth', block: 'start' });
  // };
  const scroll5 = () => {
    const section = document.querySelector("#satisifaction");
    section.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  // const scroll6 = () => {
  //   const section = document.querySelector('#sorting');
  //   section.scrollIntoView({ behavior: 'smooth', block: 'start' });
  // };
  const scrollToTargetAdjustedS = () => {
    var element = document.getElementById("sertires");
    var headerOffset = 140;
    var elementPosition = element.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset + headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };
  const scrollToTargetAdjusted = () => {
    var element = document.getElementById("sorting");
    var headerOffset = 140;
    var elementPosition = element.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  const scrollToTargetAdjustedC = () => {
    var element = document.getElementById("compare-product");
    var headerOffset = 140;
    var elementPosition = element.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };
  
  return (
    <>
      <section className="section fixed-menu">
        {ProductListing.slice(0, 1).map((shopnow) => {
          return (
            <div className="container">
              <ul>
                <li>
                  <button onClick={scrollToTargetAdjustedS}>
                    {" "}
                    {shopnow.subcategory.name}
                  </button>
                </li>

                {/*
                 <li><button to="#sertires">Series</button></li>
                <li><button onClick={scroll2}>Explore new Era</button></li>
      */}
                <li>
                  <button onClick={scrollToTargetAdjusted}>
                    Product Features
                  </button>
                </li>
                <li>
                  <button onClick={scrollToTargetAdjustedC}>
                    Find Your Robot
                  </button>
                </li>
                {/*
                <li><button onClick={scroll5}>Our Satisifaction</button></li>
                <li><button onClick={scroll6}>Our Feature</button></li>
                 */}
              </ul>
            </div>
          );
        })}
      </section>
      {/* <span style={{ marginBottom: "500px" }}></span> */}
      {/* <div id="explore_new" className="section main">
        <FixedBanner />
      </div>
       */}

      <div id="sertires" className="section main">
        <SeriesBundel ProductListing={ProductListing} />
      </div>
      <div id="sorting" className="section main">
        <SortingSubCategory banneridglobe={props.banneridglobe} />
      </div>


      <div id="more-info" className="section main">
        <Series_tab1 banneridglobe={props.banneridglobe} />
      </div>

      <div id="compare-product" className="section main">
        <Fixed_banner />
      </div>

      <div id="satisifaction" className="section main">
        <Satisfaction />
      </div>

    </>
  );
}
