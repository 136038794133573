import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createAppointment } from "../redux/action/AdditionalAction";
import { loadAllCategory } from "../redux/action/CategoryAction";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../src/component/Shared/Loader";
import Calendar from 'react-calendar'
import Message from "../../src/component/share/Message";
import ReCAPTCHA from "react-google-recaptcha";
import spinner1 from "../image/spinner1.gif"




import ReactDOM from 'react-dom';
import $ from 'jquery';

$("#visible-part").hide();
$("#visible-part-one").hide();
$("#shom-more").on("click", function () {
    var txt = $("#visible-part").is(':visible') ? 'Read More' : 'Read Less';
    $("#shom-more").text(txt);
    $(this).prev('#visible-part').slideToggle(200);
});

$("#shom-more-one").on("click", function () {
    var txt = $("#visible-part-one").is(':visible') ? 'Read More' : 'Read Less';
    $("#shom-more-one").text(txt);
    $(this).prev('#visible-part-one').slideToggle(200);
});

function LandingPageOne() {



    const queryParams = new URLSearchParams(window.location.search);


    const [utm_source, setutm_source] = useState('');
    const [utm_campaign, setutm_campaign] = useState('');

    const [checkboxval, setPe] = useState(false);

    var utm_sourcercv = queryParams.get('utm_source');
    var utm_campaignrcv = queryParams.get('utm_campaign');

    const dispatch = useDispatch();

    const navigate = useNavigate();

    useEffect(() => {
        if (utm_sourcercv != null && utm_sourcercv != '' && utm_sourcercv != undefined) {

            console.log("utm source1");
            setutm_source(utm_sourcercv);
            setutm_campaign(utm_campaignrcv);

            localStorage.setItem('utm_source', utm_sourcercv);
            localStorage.setItem('utm_campaign', utm_campaignrcv);
            // setState({ ...state, ['source']: utm_sourcercv });
            //setState({ ...state, ['campaign']: utm_campaignrcv });
        }
        else {
            console.log("utm source2");

            var item1 = localStorage.getItem('utm_source');
            var item2 = localStorage.getItem('utm_campaign');
            if (item1 != null && item1 != undefined && item1 != '') {
                setutm_source(item1);
                setutm_campaign(item2);

                setState({ ...state, ['source']: item1 });
                setState({ ...state, ['campaign']: item2 });
            }

        }
        dispatch(loadAllCategory());
    }, [dispatch]);

    const [state, setState] = useState({
        city: "",
        landmark: "video",
        category: "",
        name: "",
        email: "",
        mobile: "",
        pincode: "",
        location: "",
        address: "",
        message: "",
        pe: "",
        reqtyop: "",
        source: utm_source,
        campaign: utm_campaign,

    });

    const {
        city,
        category,
        name,
        email,
        mobile,
        landmark,
        pincode,
        location,
        address,
        message,
        pe,
        reqtyop,
        source,
        campaign

    } = state;

    const Category = useSelector((state) => state.MainCategory);

    const { error, result } = Category;



    const handleInputChange = (e) => {


        let { name, value } = e.target;
        console.log("handleInputChange" + name);


        if (name == "pincode") {
            value = value.slice(0, 6);
        }
        if (name == "mobile") {
            value = value.slice(0, 10);
        }
        setState({ ...state, [name]: value });


    };

    const key = process.env.REACT_APP_SITE_KEY;
    const url = process.env.REACT_APP_SITE_URL;
    const [captchaResult, setCaptchaResult] = useState();

    const handleRecaptcha = (value) => {
        setCaptchaResult(value)
        /*
        fetch(url, {
          method: "POST",
          body: JSON.stringify({ captcha_value: value }),
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
          .then((data) => {
            console.log(data.captcha.success);
            setCaptchaResult(data.captcha.success);
          });
          */
    };

    const handleSubmit = (e) => {

        alert(checkboxval);
        return;
        state.campaign = utm_campaign;
        state.source = utm_source;
        e.preventDefault();
        if (captchaResult == undefined || captchaResult == '' || captchaResult == null) {
            toast.warning("Invalid Captcha");

            //return false;

        }
        if (checkboxval) {
            toast.warning("Check condition");
            //return false;

        }





        if (!category || !email || !mobile || !name) {
            toast.warning("Please input all input Field!");
        } else {
            dispatch(createAppointment(state));
        }
        //  navigate("/all-thank-you");
    };



    const { response, loading } = useSelector((state) => state.Additional);

    useEffect(() => {

        if (Object.keys(response).length > 0) {
            console.log('Object is NOT empty');
            //navigate('/thanks-you', { state: { messagefor: "contact", heading: "Thank You For Choosing iRobot and taking the first step", message: "We will get in touch with you shortly. For quick response connect on 18002003355. " } });
            navigate('/thanks-you-landingpage-one', { state: { messagefor: "contact", heading: "Thank You For Choosing iRobot and taking the first step", message: "We will get in touch with you shortly. For quick response connect on 18002003355. " } });
        }

    }, [response]);



    return (
        <>
            <div className="bodybackground">

                <headerdm class="main-header">
                    <div class="container-html">
                        <div class="row align-items-center">
                            <div class="col-lg-1 col-md-1 col-3">
                                <div class="logohtml">
                                    <a href="/">
                                        <img class="img-fluiddm" src={require("../../src/images/logo.png")} alt="iRobot" height="50" />
                                    </a>
                                </div>
                            </div>

                            <div class="col-lg-2 ms-auto col-7">
                                <div class="call_us text-end">
                                    <a href="tel:18002003355" target="_blank">Call Us : 18002003355</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </headerdm>

                <section class="menu_nav">
                    <div class="container-html">
                        <div class="row align-items-center">
                            <div class="col-lg-8 col-md-8 col-7">
                                <nav class="navbar navbar-expand-lg navbar-light bg-white">
                                    <div class="container-html-fluid p-0">
                                        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                                            aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                            <span class="navbar-toggler-icon"></span>
                                        </button>
                                        <div class="collapse navbar-collapse" id="navbarNav">
                                            <ul class="navbar-nav">
                                                <li class="nav-item">
                                                    <a class="nav-link active" aria-current="page" href="#">Home</a>
                                                </li>
                                                <li class="nav-item">
                                                    <a class="nav-link" href="#whychoose">Why choose iRobot Select VC?</a>
                                                </li>
                                                <li class="nav-item">
                                                    <a class="nav-link" href="#select_pro">iRobot Select Products</a>
                                                </li>
                                                <li class="nav-item">
                                                    <a class="nav-link" href="#whychoose_vcleaner">Why Choose a VC?</a>
                                                </li>
                                                <li class="nav-item">
                                                    <a class="nav-link" href="#advantage">iRobot Select Advantage</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </nav>
                            </div>

                            <div class="col-lg-4 col-md-4 col-5">
                                <div class="book_demo float-end">
                                    <a href="#BookADemo">BOOK A DEMO</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section class="banner-irobot">
                    <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel">
                        <div class="carousel-indicators">
                            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active"
                                aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1"
                                aria-label="Slide 2"></button>
                        </div>
                        <div class="carousel-inner">
                            <div class="carousel-item active">
                                <div clas="slider">
                                    <div class="left_content">
                                        <img src={require("../../src/images/NewYear_homepage.jpg")} class="d-block w-100" />
                                    </div>
                                    <div class="right_content">
                                        <h2>Roomba® j7+ Our most intelligent Roomba® robot</h2>
                                        <p>Identifies and avoids obstacles in life’s clutter, like cords, pet waste, socks, and shoes, to get the cleaning done right for you—without you having to lift a finger.</p>
                                        <div class="two_btn">
                                            <a class="btn btn_cat btn_cat_newcolor" href="#">Shop Now</a>
                                            <a class="btn btn_cat btn_cat_newcolor" href="#">Book a Demo</a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="carousel-item">
                                <div clas="slider">
                                    <div class="left_content">
                                        <img src={require("../../src/images/roomba.jpg")} class="d-block w-100" />
                                    </div>
                                    <div class="right_content">
                                        <h2>Roomba® j7+ Our most intelligent Roomba® robot</h2>
                                        <p>Identifies and avoids obstacles in life’s clutter, like cords, pet waste, socks, and shoes, to get the cleaning done right for you—without you having to lift a finger.</p>
                                        <div class="two_btn">
                                            <a class="btn btn_cat btn_cat_newcolor" href="#">Shop Now</a>
                                            <a class="btn btn_cat btn_cat_newcolor" href="#">Book a Demo</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="BookADemo" class="bookdemo_form">
                    <div class="container-html">
                        <div class="row">
                            <div class="col-lg-4 col-md-6 ms-auto">
                                <div class="book-demo-user">
                                    <h3 class="text-center">Try before you buy</h3>
                                    <p class="text-center">Fill in your details below or <img class="img-fluiddm phn-icon"
                                        src={require("../../src/images/phone.png")} /> <a target="_blank" href="tel:18002003355">Call now</a>
                                        <span>18002003355</span>
                                    </p>

                                    <div class="form-book">
                                        <form autoComplete="off" onSubmit={handleSubmit}>

                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <label>Full name*</label>
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        placeholder=""
                                                        name="name"
                                                        value={name}
                                                        onChange={handleInputChange}
                                                        required
                                                    />
                                                </div>
                                            </div>

                                            <div class="row mt-2 mb-2">
                                                <div class="col-lg-6 col-md-6">
                                                    <label>E-mail*</label>
                                                    <input
                                                        class="form-control"
                                                        type="email"
                                                        required
                                                        name="email"
                                                        placeholder=""
                                                        value={email}
                                                        onChange={handleInputChange}

                                                    />
                                                </div>

                                                <div class="col-lg-6 col-md-6 mob-mt-2">
                                                    <label>Phone</label>
                                                    <input
                                                        class="form-control"
                                                        type="number"
                                                        required
                                                        minLength="10" 
                                                        maxLength="10"
                                                        placeholder=""
                                                        name="mobile"
                                                        value={mobile}
                                                        //onChange={handleInputChange}
                                                        onChange={(e) => {
                                                            if (e.target.value.length < 11) {
                                                                handleInputChange(e);
                                                            }
                                                        }}

                                                    />
                                                </div>
                                            </div>

                                            <div class="row mb-2">
                                                <div class="col-lg-6 col-md-6">
                                                    <label>Select Product*</label>
                                                    <select class="form-control"
                                                        name="category"
                                                        onChange={handleInputChange}
                                                        value={category || ""}
                                                    >
                                                        <option>Select </option>
                                                        {result.map((item, i) => (
                                                            <option key={i} value={item.id}>
                                                                {item.name} {item.alias}
                                                            </option>
                                                        ))}
                                                    </select>

                                                </div>

                                                <div class="col-lg-6 col-md-6 mob-mt-2">
                                                    <label>City*</label>
                                                    <input
                                                        class="form-control"
                                                        type="text"
                                                        placeholder=""
                                                        name="city"
                                                        value={city}
                                                        onChange={handleInputChange}

                                                    />
                                                </div>
                                            </div>


                                            <div class="row">
                                                <div class="col-lg-12 agree ps-4">
                                                    <input
                                                        type="checkbox"
                                                        id="receive_news"
                                                        name="news"
                                                        value={checkboxval}
                                                        onChange={(e) => setPe(!checkboxval)}
                                                    />
                                                    &nbsp;
                                                    I agree to iRobot
                                                    <a href="https://irobot.in/terms-and-conditions.aspx" target="_blank" class="condition_link">Terms of
                                                        Use,</a>
                                                    <a href="https://irobot.in/privacy-policy.aspx" target="_blank" class="condition_link">Privacy
                                                        Policy</a>
                                                </div>
                                            </div>


                                            <div class="row">
                                                <ReCAPTCHA
                                                    sitekey={key}
                                                    onChange={handleRecaptcha}
                                                    className="captcha"
                                                />
                                                <div class="col-lg-12">
                                                    <input type="submit" value="Book a demo" class="container-html-fluid submit-demo">
                                                    </input>
                                                </div>
                                            </div>

                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section id="whychoose" class="whyChoose">
                    <div class="container-html-fluid">
                        <div class="row">
                            <div class="col-lg-12">
                                <h2 class="text-center">Why Choose iRobot Roomba Robot vacuum & Braava Robot mop</h2>
                            </div>
                        </div>

                        <div class="row mt-5">
                            <div class="col-lg-4 col-md-4">
                                <div class="choose-bx text-center">
                                    <img src={require("../../src/images/30years.png")} />
                                    <h3>Over 30 years of robotics expertise and continuous innovation</h3>
                                    <p>iRobot has over 30 years of robotics expertise and innovation with over 30 million home robots sold
                                        worldwide. Whether you choose Roomba Robot Vacuum Cleaner, Braava robot mop, or both - your floors get the
                                        specialized care they need.</p>
                                    <p>If you ever need help, we make it easy with specially trained Customer Care agents available by phone or
                                        email to answer your questions.</p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4 mob-mt-2">
                                <div class="choose-bx text-center">
                                    <img src={require("../../src/images/better-together.png")} />
                                    <h3>More thoughtful than you thought</h3>
                                    <p>iRobot Genius is not only packed with smarts, it’s thoughtful too. It offers more personalized
                                        suggestions, pet features, and voice commands1 than any other robot — all so you can focus on what you
                                        care about while the cleaning gets done.</p>
                                    <p>With Imprint® Link Technology, your Roomba® Robot Vacuum and Braava jet® m6 Robot Mop can team up to
                                        vacuum and then mop in perfect sequence, giving your floors a comprehensive clean without any effort from
                                        you</p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4 mob-mt-2">
                                <div class="choose-bx text-center">
                                    <img src={require("../../src/images/best-pro.png")} />
                                    <h3>Your best life starts here.</h3>
                                    <p>More than just power, precision, and performance, our robots are driven by thoughtful intelligence that
                                        cleans around your schedule and your life. So you can, you know, live it.</p>
                                    <p>Leading proprietary innovations, such as identifying obstacles & avoiding hazards, 3-Stage Cleaning, Dual
                                        Multi-Surface Brushes, Dirt DetectTM Sensors, and unique Edge-Sweeping Brush allow our robots to handle
                                        tougher messes and provide a powerful clean without lifting a finger.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section id="select_pro" class="select_product">
                    <div class="container-html">
                        <div class="row">
                            <h2 class="heading-pro text-center">Select Products</h2>
                        </div>

                        <div class="row mt-5">
                            <div class="col-lg-6 col-md-6 text-center">
                                <div class="pro-wrap">
                                    <img src={require("../../src/images/6Pnl3Img1.png")} />
                                    <div class="pro_bottom_tag">
                                        <span>Roomba®</span>
                                        <span class="font-weight-bold text-green">Vaccums</span>
                                    </div>
                                    <p>Our most powerful Wi-Fi® Connected Robot Vacuum captures dust and particles even in the tricky corners of
                                        your home. Our new Roomba j7+ Robot Vacuum identifies and avoids obstacles like pet waste and charging
                                        cords to get the whole job done.</p>

                                    <div id="visible-part">
                                        <ul>
                                            <li>Ideal for homes with pets and Large Homes.</li>
                                            <li>Cleans deep into corners and along edges.</li>
                                            <li>Learns & maps your home.</li>
                                            <li>Cleans when and where you want.</li>
                                            <li>Attacks mess with a 3-Stage Cleaning System.</li>
                                            <li>Avoids pet accidents on purpose.</li>
                                            <li>Identifies obstacles, avoids hazards</li>
                                            <li>Forget about vacuuming for months at a time.</li>
                                            <li>Focuses on the dirtier areas with Dirt Detect™? Technology.</li>
                                            <li>Customize with iRobot Genius and voice assistant* integration.</li>
                                        </ul>
                                    </div>
                                    <a href="javascript:void(0)" id="shom-more">Show More</a>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 text-center mob-mt-4">
                                <div class="pro-wrap">
                                    <img src={require("../../src/images/7Pnl3Img2.png")} />
                                    <div class="pro_bottom_tag">
                                        <span>Braava®</span>
                                        <span class="font-weight-bold text-green">Mops</span>
                                    </div>
                                    <p>The Braava m6 is one of the most advanced robot mops ever built by iRobot. Overall, the Braava robot mop
                                        is an excellent robot mop and really excels at navigating large floor plans, integrating with smart
                                        technology, and cleaning performance.</p>

                                    <div id="visible-part-one">
                                        <ul>
                                            <li>Best for multiple and large rooms.</li>
                                            <li>It has an intelligent navigation system.</li>
                                            <li>Mops tiles, hardwood, and stone.</li>
                                            <li>Learns and maps your home. Mops when and where you want.</li>
                                            <li>It has an intelligent navigation system.</li>
                                            <li>Tackles mess with Precision Jet Spray.</li>
                                            <li>Cleans the entire level of your home in neat rows.</li>
                                        </ul>
                                    </div>
                                    <a href="javascript:void(0)" id="shom-more-one">Show More</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="whychoose_vcleaner">
                    <div class="container-html-fluid">
                        <div class="row">
                            <div class="col-lg-5 col-md-5">
                                <img src={require("../../src/images/highest-level.jpg")} class="img-fluiddm" />
                            </div>

                            <div class="col-lg-7 col-md-7">
                                <div class="traditional-cleaning-main">
                                    <div class="traditional-cleaning">
                                        <h3>Why choose a vacuum cleaner</h3>
                                        <h2 class="mb-4">over traditional cleaning methods?</h2>
                                        <h5>Your home’s most dangerous enemy? Dust and dirt which triggers,</h5>
                                        <ul>
                                            <li>Captures 99% of mold, pollen, dust mite, dog, and cat allergens as small as 10 microns</li>
                                            <li>Robot Vacuum and Mop clean everywhere in your home (in corners, along skirting boards, under
                                                furniture)</li>
                                            <li>It cleans up pet hair, dirt, and dust.</li>
                                            <li>Our new Roomba® j7+ Robot Vacuum to empty itself for up to 60 days. No human effort is required.
                                            </li>
                                        </ul>
                                        <p>Robot Vacuum captures dust and particles even in the tricky corners of your home. iRobot's superior 3-
                                            Stage Cleaning System eliminates debris and pet hair from deep within your carpets for a clean you won’t
                                            believe – with no effort from you.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="advantage" class="irobot-adv mt-4">
                    <div class="container-html">
                        <div class="row pt-5">
                            <div class="col-lg-12">
                                <h2 class="heading-pro text-center">iRobot Advantage</h2>
                            </div>
                        </div>

                        <div class="row mt-5 mb-5">
                            <div class="col-lg-3 col-md-3 text-center">
                                <div class="adv-bx">
                                    <img src={require("../../src/images/ad-1.png")} />
                                    <p>Robotics Expertise & Innovation</p>
                                </div>
                            </div>

                            <div class="col-lg-3 col-md-3 text-center">
                                <div class="adv-bx">
                                    <img src={require("../../src/images/ad-2.png")} />
                                    <p>Varieties of Robotic Vacuum Cleaners</p>
                                </div>
                            </div>

                            <div class="col-lg-3 col-md-3 text-center">
                                <div class="adv-bx">
                                    <img src={require("../../src/images/ad-3.png")} />
                                    <p>2 Years Product Warranty*</p>
                                </div>
                            </div>

                            <div class="col-lg-3 col-md-3 text-center">
                                <div class="adv-bx">
                                    <img src={require("../../src/images/ad-4.png")} />
                                    <p>Free & Fast Delivery*</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <footer class="footer-part">
                    <div class="container-html">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="social_follow">
                                    <ul>
                                        <li><a href="https://www.facebook.com/irobotindia" target="_blank"><i class="fa fa-facebook-f"
                                            aria-hidden="true"></i></a></li>
                                        <li><a href="https://twitter.com/irobotindia" target="_blank"><i class="fa fa-twitter"
                                            aria-hidden="true"></i></a></li>
                                        <li><a href="https://in.linkedin.com/company/irobot-india" target="_blank"><i class="fa fa-linkedin"
                                            aria-hidden="true"></i></a></li>
                                        <li><a href="https://www.youtube.com/irobot-india" target="_blank"><i class="fa fa-youtube"
                                            aria-hidden="true"></i></a></li>
                                        <li><a href="https://www.instagram.com/irobot_india" target="_blank"><i class="fa fa-instagram"
                                            aria-hidden="true"></i></a></li>
                                    </ul>
                                </div>

                                <div class="footer-button text-end">
                                    <a href="#bookdemo_form">
                                        <a href="#BookADemo" class="Online1">BOOK A DEMO</a></a>
                                </div>
                            </div>

                            <div class="col-lg-12 mt-5">
                                <p class="h6 m-0 position-relative copyright text-white text-center"><small>© <span>2023</span> Copyright
                                    iRobot.in</small></p>
                            </div>
                        </div>
                    </div>
                </footer>

            </div>

        </>
    );
}

export default LandingPageOne;
