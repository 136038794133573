import React, { useEffect } from "react";
import Post from "../component/blog/Post";
import RightSidebar from "../component/blog/RightSidebar";

export default function Blog() {
  useEffect(() => {
    document.getElementById("maintittle").innerHTML = "iRobot India Official Blog - Read latest stories on iRobot Products";
    document.getElementById("description").setAttribute("content", "Read latest blogs on iRobot products, features & their launch over here. The official iRobot India blog, written & managed by Puresight Systems Pvt Ltd.");

    window.scrollTo(0, 0);
  }, []);
  return (
    <section className="blog_page" style={{ background: "#fff" }}>
      <div className="container">
        <Post />

        <RightSidebar />
      </div>
    </section>
  );
}
