import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import UpdateAddress from "./UpdateAddress";
// import NewAddress from "./NewAddress";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UpdateProfile } from "../../redux/action/AuthAction";
// import { useNavigate } from "react-router-dom";
import { fetchUserProfile } from "../../redux/action/AuthAction";

export default function UpdateUserProfile() {
  // const [showUpdate, setshowUpdate] = useState(false);
  // const onClickUpdate = () => setshowUpdate(!showUpdate);
  // const [showNew, setshowNew] = useState(false);
  // const onClickNew = () => setshowNew(!showNew);

  const dispatch = useDispatch();

  // const navigate = useNavigate();

  const [state, setState] = useState({
    first_name: "",
    last_name: "",
    mobile: "",
    email: "",
    about: "",
    id: "",
  });

  const { id, first_name, last_name, mobile, email, about } = state;

  const handleInputChange = (e) => {
    let { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const UserInfo = useSelector((state) => state.AuthReducer);

  const { userResult } = UserInfo;

  useEffect(() => {
    if (userResult) {
      dispatch(fetchUserProfile(userResult.id));
    }
  }, [dispatch, userResult]);

  const UserData = useSelector((state) => state.AuthReducer.result);

  useEffect(() => {
    if (UserData) {
      setState({ ...UserData });
    }
  }, [UserData]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!id && !mobile && !email) {
      toast.warning("Please input all input Field!");
    } else {
      dispatch(UpdateProfile(state));
    }
  };

  return (
    <div className="updateprofile">
      <h2 className="title">Update Profile</h2>
      <div className="update_profile">
        <form autoComplete="off" onSubmit={handleSubmit}>
          <div className="input_field">
            <label>
              First name <span>*</span>
            </label>
            <input
              required
              type="text"
              placeholder="First Name"
              name="first_name"
              value={first_name}
              onChange={handleInputChange}
            />
          </div>
          <div className="input_field">
            <label>
              Last name <span>*</span>
            </label>
            <input
              type="text"
              placeholder="Last Name"
              name="last_name"
              value={last_name}
              onChange={handleInputChange}
            />
          </div>
          <div className="input_field">
            <label>Contact Number</label>
            <input
              required
              type="number"
              placeholder="Contact Number"
              name="mobile"
              minLength="10" 
              maxLength="10"
              value={mobile}
              onChange={handleInputChange}
              readOnly
            />
          </div>
          <div className="input_field">
            <label>
              Email address <span>*</span>
            </label>
            <input
              required
              type="email"
              placeholder="Email Address"
              name="email"
              value={email}
              onChange={handleInputChange}
              readOnly
            />
          </div>

          <div className="input_field textarea">
            <label>
              About Me <span>*</span>
            </label>
            <textarea
              type="text"
              placeholder="About Me"
              name="about"
              value={about}
              onChange={handleInputChange}
            />
          </div>

          <div className="input_field_btn">
            <button type="submit">Update Profile</button>
          </div>
        </form>
      </div>
      {/* <button className="btn update_address" onClick={onClickUpdate}>
        Update Address
      </button>

      {showUpdate ? <UpdateAddress /> : null}
      <button className="btn update_address" onClick={onClickNew}>
        Add New Address
      </button>

      {showNew ? <NewAddress /> : null} */}
    </div>
  );
}
