import React, { useEffect } from "react";
import p2 from "../../image/p2.jpg";
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from "react-router-dom";

export default function Return() {
  //   const handleRecaptcha = (value) => {
  //     fetch(url, {
  //       method: "POST",
  //       body: JSON.stringify({ captcha_value: value }),
  //       headers: { "Content-Type": "application/json" },
  //     })
  //       .then((res) => res.json())
  //       .then((data) => {
  //         console.log(data.captcha.success);
  //         setCaptchaResult(data.captcha.success);
  //       });
  //   };

  //   const submitHandler = (e) => {
  //     e.preventDefault();

  //     dispatch(signinUser(email, password));
  //   };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section className="section return-page">
      <div className="container">
        <div className="product-details">
          <img src={p2} alt="" />
          <h3 className="subheading">iRobot® Roomba® s9+</h3>
          <p>Robot Vacuum with Automatic Dirt Disposal</p>
          <p>
            <strong>Item SKU:</strong> Item #s955020
          </p>
          <p>
            <strong>Price:</strong>₹ 149900
          </p>
        </div>
        <div className="return-form">
          <div className="back-btn">
            <Link
              to=""
              onClick={() => {
                window.history.back();
              }}
            >
              Back
            </Link>
          </div>
          <div className="acc_form">
            <form>
              <div className="input_field">
                <label>
                  Full Name <span>*</span>
                </label>
                <input type="text" required value="John  Peter" />
              </div>
              <div className="input_field">
                <label>
                  Email Address <span>*</span>
                </label>
                <input type="email" required value="john@gmail.com" />
              </div>
              <div className="input_field">
                <label>
                  Reason for Return <span>*</span>
                </label>
                <select>
                  <option>Poor Performance</option>
                  <option>Poor Service</option>
                  <option>Bad Condtion/Damage</option>
                  <option>Product is not same</option>
                  <option>Another Reason</option>
                </select>
              </div>
              <div className="input_field">
                <label>Feedback (Optional)</label>
                <textarea></textarea>
              </div>
              <div className="input_field_btn">
                <input type="submit" value="Submit" />
                {/* <ReCAPTCHA
                      sitekey={key}
                      onChange={handleRecaptcha}
                      className="captcha"
                    />

                    {captchaResult && (
                      <input type="submit" value="Check Status" />
                    )} */}
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}
