import React, { useEffect } from "react";
import yy from "../image/return.jpg";
import homepage from "../image/homepage.jpg";
import { useDispatch, useSelector } from "react-redux";
import { loadReturnPolicy } from "../redux/action/StaticAction";
import Loader from "../component/Shared/Loader";
import Message from "../component/share/Message";

export default function ReturnWarranty() {
  const dispatch = useDispatch();

  useEffect(() => {
    document.getElementById("maintittle").innerHTML = "Returns and Warranties | iRobot India";
    document.getElementById("description").setAttribute("content", "Returns and Warranties | iRobot India");
    dispatch(loadReturnPolicy());
  }, [dispatch]);

  const CovidData = useSelector((state) => state.Static);

  const { loading, error, returnPolicyData } = CovidData;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section className="section " >
      <div className="container">
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : (
          <div className="return_content page_content">
            {returnPolicyData &&
              returnPolicyData.map((item, i) => (
                <div className="rte" key={i}>
                  {item.heading ? <h1 className="hh1">{item.heading}</h1> : <></>}

                  <>
                    <div key={i} className="post__content" dangerouslySetInnerHTML={{ __html: item.content }}></div></>
                </div>

              ))}
          </div>
        )}
      </div>
    </section>
  );
}
