import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createEnquiry } from "../redux/action/AdditionalAction";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import spinner1 from "../image/spinner1.gif";
import ReCAPTCHA from "react-google-recaptcha";


export default function Enquiry() {

  const queryParams = new URLSearchParams(window.location.search);
  const [utm_source, setutm_source] = useState('');
  const [utm_campaign, setutm_campaign] = useState('');

  var utm_sourcercv = queryParams.get('utm_source');
  var utm_campaignrcv = queryParams.get('utm_campaign');

  useEffect(() => {
    if (utm_sourcercv != null && utm_sourcercv != '' && utm_sourcercv != undefined) {

      setutm_source(utm_sourcercv);
      setutm_campaign(utm_campaignrcv);
      localStorage.setItem('utm_source', utm_sourcercv);
      localStorage.setItem('utm_campaign', utm_campaignrcv);
    }
    else {
      var item1 = localStorage.getItem('utm_source');
      var item2 = localStorage.getItem('utm_campaign');
      if (item1 != null && item1 != undefined && item1 != '') {
        setutm_source(item1);
        setutm_campaign(item2);
      }

    }
  }, []);
  const [state, setState] = useState({
    first_name: "",
    last_name: "",
    email: "",
    mobile: "",
    title: "",
    company: "",
    city: "",
    company_website: "",
    area_of_focus: "",
    area_of_expertise: "",
    message: "",
    promotional_email: 1,
    source: utm_source,
    campaign: utm_campaign,
    enq: "be",
  });

  const {
    first_name,
    last_name,
    email,
    mobile,
    title,
    company,
    city,
    company_website,
    area_of_focus,
    area_of_expertise,
    promotional_email,
    message,
    source,
    campaign,
    enq,
  } = state;

  const handleInputChange = (e) => {
    let { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (captchaResult == undefined || captchaResult == '' || captchaResult == null) {
      toast.warning("Invalid Captcha");

     // return false;

    }
    state.campaign = utm_campaign;
    state.source = utm_source;
    if (!mobile && !email && !message) {
      toast.warning("Please input all input Field!");
    } else {
      dispatch(createEnquiry(state));
      // navigate(-1);
    }

  };

  const { response, loading } = useSelector((state) => state.Additional);

  useEffect(() => {

    if (Object.keys(response).length > 0) {
      console.log('Object is NOT empty');

      //navigate('/thanks-you', { state: { messagefor: "contact", heading: "", message: "Thank you for enquiring with us. We will get back to you shortly" } });
      navigate('/thanks-you-enquiry', { state: { messagefor: "contact", heading: "", message: "Thank you for enquiring with us. We will get back to you shortly" } });

    }

  }, [response]);

  useEffect(() => {
    document.getElementById("maintittle").innerHTML = "iRobot India Customer Service - 1800-200-3355 - Email Inquiry";
    document.getElementById("description").setAttribute("content", "iRobot India Customer Service - 1800-200-3355 - Email Inquiry");
    window.scrollTo(0, 0);
  }, []);

  const [captchaResult, setCaptchaResult] = useState();

  const key = process.env.REACT_APP_SITE_KEY;
  const url = process.env.REACT_APP_SITE_URL;

  const handleRecaptcha = (value) => {
    setCaptchaResult(value)
    /*
    fetch(url, {
      method: "POST",
      body: JSON.stringify({ captcha_value: value }),
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.captcha.success);
        setCaptchaResult(data.captcha.success);
      });
      */
  };
  return (
    <section className="section enquiry" style={{ background: "#fff" }}>
      {loading ? (<div id="loading">
        <img id="loading-image" src={spinner1} alt="Loading..." />
      </div>) : ""}
      <ToastContainer />
      <div className="container">
        <p className="subheading">
          Puresight Systems Pvt Ltd, the official distributor for iRobot in
          India is looking for channel partners/re-sellers across India. Please
          contact us for more information. Our representative will get back to
          you soon.
        </p>

        <div className=" Bsns-Oppor-form">
          <div className=" form text-black">
            <form autoComplete="off" onSubmit={handleSubmit}>
              <div className=" py-2">
                <label className="label-sm ">
                  First Name <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  placeholder=""
                  name="first_name"
                  value={first_name}
                  required
                  onChange={handleInputChange}
                />
              </div>

              <div className=" py-2">
                <label className="label-sm">
                  Last Name <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  placeholder=""
                  className=" form-control"
                  name="last_name"
                  value={last_name}
                  onChange={handleInputChange}
                />
              </div>

              <div className=" py-2">
                <label className="label-sm ">
                  Title <span className="text-danger"></span>
                </label>
                <input
                  type="text"
                  placeholder=""
                  className=" form-control"
                  name="title"
                  value={title}
                  onChange={handleInputChange}
                />
              </div>

              <div className=" py-2">
                <label className="label-sm ">
                  Company <span className="text-danger"></span>
                </label>
                <input
                  type="text"
                  placeholder=""
                  className=" form-control"
                  name="company"
                  value={company}
                  onChange={handleInputChange}
                />
              </div>

              <div className=" py-2">
                <label className="label-sm ">
                  Email <span className="text-danger">*</span>
                </label>
                <input
                  type="email"
                  placeholder=""
                  className=" form-control"
                  name="email"
                  value={email}
                  required
                  onChange={handleInputChange}
                />
              </div>

              <div className=" py-2">
                <label className="label-sm ">
                  Phone <span className="text-danger">*</span>
                </label>
                <input
                  type="number"
                  placeholder=""
                  className=" form-control"
                  name="mobile"
                  minLength="10" 
                  maxLength="10"
                  required
                  value={mobile}
                  //onChange={handleInputChange}
                  onChange={(e) => {
                    if (e.target.value.length < 11) {
                      handleInputChange(e);
                    }
                  }}
                />
              </div>

              <div className=" py-2">
                <label className="label-sm ">
                  City <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  placeholder="Type and select your city"
                  className=" form-control"
                  name="city"
                  value={city}
                  onChange={handleInputChange}
                />
              </div>

              <div className=" py-2">
                <label className="label-sm ">
                  Company website <span className="text-danger"></span>
                </label>
                <input
                  type="text"
                  placeholder=""
                  className=" form-control"
                  name="company_website"
                  value={company_website}
                  onChange={handleInputChange}
                />
              </div>

              <div className=" py-2">
                <label className="label-sm ">
                  Geographic Area of Focus <span className="text-danger"></span>
                </label>
                <input
                  type="text"
                  placeholder=""
                  className=" form-control"
                  name="area_of_focus"
                  value={area_of_focus}
                  onChange={handleInputChange}
                />
              </div>

              <div className="py-2">
                <label className="label-sm ">
                  What is your area of expertise?{" "}
                  <span className="text-danger"></span>
                </label>
                <input
                  type="text"
                  placeholder=""
                  className=" form-control"
                  name="area_of_expertise"
                  value={area_of_expertise}
                  onChange={handleInputChange}
                />
              </div>

              <div className="full_fo py-2">
                <label className="label-sm ">
                  Write your message <span className="text-danger"></span>
                </label>
                <textarea
                  className=" form-control"
                  rows="4"
                  name="message"
                  value={message}
                  onChange={handleInputChange}
                ></textarea>
              </div>

              <div className="py-2 checkboxx">
                <input
                  type="checkbox"
                  name="promotional_email"
                  value={promotional_email}
                  onChange={handleInputChange}
                />
                <b>&nbsp; Opt-in me for promotional emails </b>
                <br></br>
                <br></br>

                <ReCAPTCHA
                  sitekey={key}
                  onChange={handleRecaptcha}
                  className="captcha"
                />
              </div>

              <div className="  py-3">
                <input
                  type="submit"
                  defaultValue="Submit"
                  className="btn btn-default"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}
