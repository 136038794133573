import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { loadGeniusImages } from "../../redux/action/StaticAction";
import zig_zag2 from "../../image/zig_zag2.jpg";
import irobot from "../../image/irobot.mp4";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";



export default function SortingIrobot() {
    const banner = useSelector((state) => state.Static.genius);
    let bannerData = banner[0];

    if (bannerData) {
        var banner_id = bannerData.id;

        console.log("banner_id", banner_id);
    }

    useEffect(() => {
        if (banner_id !== "" || banner_id !== null) {
            dispatch(loadGeniusImages(banner_id));
        }
    }, [banner_id]);

    const dispatch = useDispatch();

    const tab = useSelector((state) => state.Static.geniusImage);
    // console.log("tab", tab);

    return (
        <>
            {tab &&
                tab.map((da) => {
                    return (
                        <section className="section zigzag catt-category" key={da.id}>
                            <div className="zigzag_content">

                                <div className="leftt" style={{ background: "da.background_color" }}>
                                    <h3 className="title">
                                        <span>{da.heading} </span>
                                    </h3>
                                    <p>{da.content}</p>
                                    <div className="two_btn">
                                        {da.button1_url == null || da.button1_url == "" ? (
                                            ""
                                        ) : (
                                            <Link to={da.button1_url} className="btn">
                                                {da.button1_name}
                                            </Link>
                                        )}
                                        {da.button2_url == null || da.button2_url == "" ? (
                                            ""
                                        ) : (
                                            <Link to={da.button2_url} className="btn">
                                                {da.button2_name}
                                            </Link>
                                        )}
                                    </div>
                                </div>
                                <div className="rightt">
                                    {da.video !== null && da.video !== "" ? (
                                        <video width="100%" height="100%" controls>
                                            <source src={da.video} type="video/mp4" />
                                        </video>
                                    ) : da.image == "" ? (
                                        <img src={zig_zag2} alt="" />
                                    ) : (
                                        <>
                                            <img src={`${process.env.REACT_APP_API_PATH}${da.image}`} alt="frf" style={{ height: "da.image_height", weight: "da.image_width" }} />

                                        </>
                                    )}
                                </div>
                            </div>
                        </section>
                    );
                })}
        </>
    );
}
