import React from "react";
import login from "../../image/login.jpeg";

export default function LoginBanner() {
  return (
    <section className="inner_banner login_banner">
      <img src={login} alt="" className="inner_bannerregidimg" />
      <div className="inner">
        <h2 className="title title-registered">Our robots don’t just take cleaning floors off your to-do list, they become part of your family.
          <br></br>
          Get Free Shipping & delivery on all Robot vacuum and Robot Mop orders. </h2>
      </div>
    </section>
  );
}
