import {
    GETDETAIL_VIDEODEMO_REQUEST,
    GETDETAIL_VIDEODEMO_FAIL,
    GETDETAIL_VIDEODEMO_SUCCESS,

} from "../constants/VideodemoConstant";


import axios from "axios";
import { toast } from "react-toastify";

// action from get all the category



const loadSingleVideodemo = () => {
    let data = {};
    return function (dispatch) {
        dispatch({
            type: GETDETAIL_VIDEODEMO_REQUEST,
            payload: true,
        });

        let OPTION = {
            url: `${process.env.REACT_APP_API_PATH}/api/v1/Videodemo_api/`,
            method: "GET",
            headers: {
                "content-type": "application/json",
            },
        };

        axios(OPTION)
            .then((res) => {
                dispatch(loadSingleVideodemoPre(res.data));
            })
            .catch((error) => {
                dispatch({
                    type: GETDETAIL_VIDEODEMO_FAIL,
                    payload: false,
                    error: error,
                    msg: "Failed to load the information",
                });
            });
    };
};
export const loadSingleVideodemoPre = (data) => {
    return {
        type: GETDETAIL_VIDEODEMO_SUCCESS,
        result: data,
        payload: false,
        msg: "SUCCESS",
    };
};








export {
    loadSingleVideodemo,
};
