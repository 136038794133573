import React, { useState, useEffect } from "react";
import Passionimg from '../../image/passion.jpg';

export default function Passion() {


    return (
        <>
            {/* 
            <section className="section-reliable2">
                <div className="reliable">
                    <div className="right_reliable">
                        <img className="image-fluid" src={Passionimg} />
                    </div>

                    <div className="left_reliable">
                        <h3 class="title">Our passion is helping you pursue yours. </h3>
                        <p>Roomba® robot vacuums don't just clean your floors. They give you permission to forget about vacuuming altogether. Focus on your passions. We'll focus on helping you maintain a cleaner home.</p>
                    </div>

                </div>
            </section>
            */}
        </>
    );
}