import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadTermCondition } from "../redux/action/StaticAction";
import Loader from "../component/Shared/Loader";
import Message from "../component/share/Message";

export default function TermCondition() {
  const dispatch = useDispatch();

  useEffect(() => {
    document.getElementById("maintittle").innerHTML = "Terms of service – Best Vacuum Cleaner - iRobot India";
    document.getElementById("description").setAttribute("content", "by using this website or ordering products from this website you agree to be bound by all of the terms and conditions of this agreement. this terms of service agreement governs your use of this website. ");

    dispatch(loadTermCondition());
  }, [dispatch]);

  const CovidData = useSelector((state) => state.Static);

  const { loading, error, termConditionData } = CovidData;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section className="section" >
      <div className="container">
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : (
          <div className="term_cond page_content">
            {termConditionData &&
              termConditionData.map((item, i) => (
                <div className="rte" key={i}>
                  {item.heading ? <h1 className="hh1">{item.heading}</h1> : <></>}

                  <>
                    <div key={i} className="post__content" dangerouslySetInnerHTML={{ __html: item.description }}></div></>
                </div>
              ))}
            {/* <div className="rte">
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop
                publishing software like Aldus PageMaker including versions of
                Lorem Ipsum.
              </p>
            </div> */}
          </div>
        )}
      </div>
    </section>
  );
}
