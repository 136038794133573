import {
  FIND_SERIES_FAIL,
  FIND_SERIES_REQUEST,
  FIND_SERIES_SUCCESS,
  FIND_PROUDLIST_FAIL,
  FIND_PROUDLIST_REQUEST,
  FIND_PROUDLIST_SUCCESS,
  FIND_GENUIS_FAIL,
  FIND_GENUIS_REQUEST,
  FIND_GENUIS_SUCCESS,
  FIND_OFFER_CONTENT_FAIL,
  FIND_OFFER_CONTENT_REQUEST,
  FIND_OFFER_CONTENT_SUCCESS,
  FIND_ABOUTUS_FAIL,
  FIND_ABOUTUS_REQUEST,
  FIND_ABOUTUS_SUCCESS,
  FIND_COVID_UPDATE_FAIL,
  FIND_COVID_UPDATE_REQUEST,
  FIND_COVID_UPDATE_SUCCESS,
  FIND_DATA_SECURITY_FAIL,
  FIND_DATA_SECURITY_REQUEST,
  FIND_DATA_SECURITY_SUCCESS,
  FIND_PRIVACY_POLICY_FAIL,
  FIND_PRIVACY_POLICY_REQUEST,
  FIND_PRIVACY_POLICY_SUCCESS,
  FIND_RETURN_POLICY_FAIL,
  FIND_RETURN_POLICY_REQUEST,
  FIND_RETURN_POLICY_SUCCESS,
  FIND_TERM_CONDITION_FAIL,
  FIND_TERM_CONDITION_REQUEST,
  FIND_TERM_CONDITION_SUCCESS,
  FIND_CATEGORY_FAIL,
  FIND_CATEGORY_REQUEST,
  FIND_CATEGORY_SUCCESS,
  FIND_CATEGORY_TAB_FAIL,
  FIND_CATEGORY_TAB_REQUEST,
  FIND_CATEGORY_TAB_SUCCESS,
  FIND_SUBCATEGORY_FAIL,
  FIND_SUBCATEGORY_REQUEST,
  FIND_SUBCATEGORY_SUCCESS,
  FIND_SUBCATEGORY_TAB_FAIL,
  FIND_SUBCATEGORY_TAB_REQUEST,
  FIND_SUBCATEGORY_TAB_SUCCESS,
  FIND_GENIUS_FAIL,
  FIND_GENIUS_REQUEST,
  FIND_GENIUS_SUCCESS,
  FIND_GENIUS_TAB_FAIL,
  FIND_GENIUS_TAB_REQUEST,
  FIND_GENIUS_TAB_SUCCESS,
} from "../constants/StaticConstant";

const initialState = {
  loading: false,
  action: "Static",
  result: [],
  categoriesdata: [],
  categories: [],
  categoriesTab: [],
  subCategories: [],
  subCategoriesTab: [],
  geniusImage: [],
  geniusTab: [],
  prodlist: [],
  genius: [],
  offerContent: [],
  aboutContent: [],
  covidUpdateData: [],
  dataSecurityData: [],
  privacyPolicyData: [],
  returnPolicyData: [],
  termConditionData: [],
  msg: "",
  error: "",
};

const StaticReducer = (state = initialState, action) => {
  switch (action.type) {
    case FIND_SERIES_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case FIND_SERIES_SUCCESS:
      return {
        ...state,
        categoriesdata: action.result.data,
        loading: action.payload,
        msg: action.msg,
      };
    case FIND_SERIES_FAIL:
      return {
        ...state,
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };

    case FIND_CATEGORY_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case FIND_CATEGORY_SUCCESS:
      return {
        ...state,
        categories: action.result.data,
        loading: action.payload,
        msg: action.msg,
      };
    case FIND_CATEGORY_FAIL:
      return {
        ...state,
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };

    case FIND_CATEGORY_TAB_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case FIND_CATEGORY_TAB_SUCCESS:
      return {
        ...state,
        categoriesTab: action.result.data,
        loading: action.payload,
        msg: action.msg,
      };
    case FIND_CATEGORY_TAB_FAIL:
      return {
        ...state,
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };

    case FIND_SUBCATEGORY_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case FIND_SUBCATEGORY_SUCCESS:
      return {
        ...state,
        subCategories: action.result.data,
        loading: action.payload,
        msg: action.msg,
      };
    case FIND_SUBCATEGORY_FAIL:
      return {
        ...state,
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };

    case FIND_SUBCATEGORY_TAB_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case FIND_SUBCATEGORY_TAB_SUCCESS:
      return {
        ...state,
        subCategoriesTab: action.result.data,
        loading: action.payload,
        msg: action.msg,
      };
    case FIND_SUBCATEGORY_TAB_FAIL:
      return {
        ...state,
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };

    case FIND_GENIUS_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case FIND_GENIUS_SUCCESS:
      return {
        ...state,
        geniusImage: action.result.data,
        loading: action.payload,
        msg: action.msg,
      };
    case FIND_GENIUS_FAIL:
      return {
        ...state,
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };

    case FIND_GENIUS_TAB_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case FIND_GENIUS_TAB_SUCCESS:
      return {
        ...state,
        geniusTab: action.result.data,
        loading: action.payload,
        msg: action.msg,
      };
    case FIND_GENIUS_TAB_FAIL:
      return {
        ...state,
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };

    case FIND_PROUDLIST_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case FIND_PROUDLIST_SUCCESS:
      return {
        ...state,
        prodlist: action.result.data,
        loading: action.payload,
        msg: action.msg,
      };
    case FIND_PROUDLIST_FAIL:
      return {
        ...state,
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };

    case FIND_GENUIS_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case FIND_GENUIS_SUCCESS:
      return {
        ...state,
        genius: action.result.data,
        loading: action.payload,
        msg: action.msg,
      };
    case FIND_GENUIS_FAIL:
      return {
        ...state,
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };

    case FIND_OFFER_CONTENT_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case FIND_OFFER_CONTENT_SUCCESS:
      return {
        ...state,
        offerContent: action.result.data,
        loading: action.payload,
        msg: action.msg,
      };
    case FIND_OFFER_CONTENT_FAIL:
      return {
        ...state,
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };

    case FIND_ABOUTUS_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case FIND_ABOUTUS_SUCCESS:
      return {
        ...state,
        aboutContent: action.result.data,
        loading: action.payload,
        msg: action.msg,
      };
    case FIND_ABOUTUS_FAIL:
      return {
        ...state,
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };

    case FIND_COVID_UPDATE_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case FIND_COVID_UPDATE_SUCCESS:
      return {
        ...state,
        covidUpdateData: action.result.data,
        loading: action.payload,
        msg: action.msg,
      };
    case FIND_COVID_UPDATE_FAIL:
      return {
        ...state,
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };

    case FIND_DATA_SECURITY_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case FIND_DATA_SECURITY_SUCCESS:
      return {
        ...state,
        dataSecurityData: action.result.data,
        loading: action.payload,
        msg: action.msg,
      };
    case FIND_DATA_SECURITY_FAIL:
      return {
        ...state,
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };

    case FIND_PRIVACY_POLICY_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case FIND_PRIVACY_POLICY_SUCCESS:
      return {
        ...state,
        privacyPolicyData: action.result.data,
        loading: action.payload,
        msg: action.msg,
      };
    case FIND_PRIVACY_POLICY_FAIL:
      return {
        ...state,
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };

    case FIND_RETURN_POLICY_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case FIND_RETURN_POLICY_SUCCESS:
      return {
        ...state,
        returnPolicyData: action.result.data,
        loading: action.payload,
        msg: action.msg,
      };
    case FIND_RETURN_POLICY_FAIL:
      return {
        ...state,
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };

    case FIND_TERM_CONDITION_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case FIND_TERM_CONDITION_SUCCESS:
      return {
        ...state,
        termConditionData: action.result.data,
        loading: action.payload,
        msg: action.msg,
      };
    case FIND_TERM_CONDITION_FAIL:
      return {
        ...state,
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };

    default:
      return state;
  }
};

export default StaticReducer;
