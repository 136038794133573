export const FIND_ALL_SATISFACTION_REQUEST = "FIND_ALL_SATISFACTION_REQUEST";
export const FIND_ALL_SATISFACTION_SUCCESS = "FIND_ALL_SATISFACTION_SUCCESS";
export const FIND_ALL_SATISFACTION_FAIL = "FIND_ALL_SATISFACTION_FAIL";

export const FIND_ALL_HOME_REQUEST = "FIND_ALL_SATISFACTION_REQUEST";
export const FIND_ALL_HOME_SUCCESS = "FIND_ALL_HOME_SUCCESS";
export const FIND_ALL_HOME_FAIL = "FIND_ALL_SATISFACTION_FAIL";

export const FIND_ALL_FIXED_BANNER_REQUEST = "FIND_ALL_FIXED_BANNER_REQUEST";
export const FIND_ALL_FIXED_BANNER_SUCCESS = "FIND_ALL_FIXED_BANNER_SUCCESS";
export const FIND_ALL_FIXED_BANNER_FAIL = "FIND_ALL_FIXED_BANNER_FAIL";

export const FIND_ALL_BANNER_SLIDER_REQUEST = "FIND_ALL_BANNER_SLIDER_REQUEST";
export const FIND_ALL_BANNER_SLIDER_SUCCESS = "FIND_ALL_BANNER_SLIDER_SUCCESS";
export const FIND_ALL_BANNER_SLIDER_FAIL = "FIND_ALL_BANNER_SLIDER_FAIL";

export const FIND_OFFER_BANNER_REQUEST = "FIND_OFFER_BANNER_REQUEST";
export const FIND_OFFER_BANNER_SUCCESS = "FIND_OFFER_BANNER_SUCCESS";
export const FIND_OFFER_BANNER_FAIL = "FIND_OFFER_BANNER_FAIL";

