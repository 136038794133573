import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { loadAllProduct } from "../../redux/action/ProductAction";
import { createCart } from "../../redux/action/CartAction";
import { Link, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Loader from "../Shared/Loader";
import Message from "../Shared/Message";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/fontawesome-free-solid";
import ProductDesc from "./ProductDesc";
import { loadAllProductReview } from "../../redux/action/ProductReviewAction";

import affirm from "../../image/affirm.png";
// import mobDemo from "../../image/mobDemo.jpg";
import TextCard from "../Common/TextCard";

import Select from 'react-select';

export default function ProductDescription({ userInfo }) {
  const [state, setState] = useState({
    name: "",
    description: "",
    price: "",
    sellprice: "",
    delivery: "",
    short_description: "",
    available_inventory: "",
    item_no: "",
  });
  const [qty, setQty] = useState(1);
  const [countreview, setcountreview] = useState(0);
  const [ratingper, setratingper] = useState(0);
  const [productid, setproductid] = useState(0);

  const [catname, setcatname] = useState('');
  const [catslug, setcatslug] = useState('');
  const [subname, setsubname] = useState('');
  const [subcatslug, setsubcatslug] = useState('');




  let [cart, setCart] = useState([]);

  const {
    name,
    description,
    price,
    sellprice,
    delivery,
    short_description,
    available_inventory,
    item_no,
  } = state;

  const setQtyN = (e) => {


    console.log('qty' + e.value);
    setSelectedOption(e);
    setQty(e.value);

    /*
    if (parseInt(e) < 1) {
      setQty(1);

    }

    if (parseInt(e) > 4) {
      setQty(4);
    }
    if (parseInt(e) >= 1 && parseInt(e) <= 4) {
      setQty(e);
    }
    */

  }
  let navigate = useNavigate();

  const dispatch = useDispatch();

  const { id } = useParams();



  let productData = useSelector((state) => state.MainProduct);

  useEffect(() => {
    dispatch(loadAllProduct(id));
    dispatch(loadAllProductReview(id));

  }, [id]);
  let user = useSelector((state) => state.AuthReducer);

  const reviewData = useSelector((state) => state.ProductReview);

  const { result } = reviewData;



  let { userResult, isSigninIn } = user;

  if (user) {
    var user_id = userResult.id;
  }

  let { loading, error, singledata } = productData;


  useEffect(() => {
    if (reviewData) {

      if (reviewData.result.length > 0) {
        if (countreview == 0) {
          console.log("reviewData..", reviewData.result);

          setcountreview(reviewData.result.length);

          var tot = 0;
          reviewData.result.map((key, index) => {

            tot = tot + Number(key.rating);
          })
          var rating = tot / reviewData.result.length;
          setratingper(rating.toFixed(1));

        }
      }
    }

  });
  useEffect(() => {
    if (singledata) {
      if (singledata != null && singledata != undefined && singledata != '') {
        setState({ ...singledata });
        setproductid(singledata.id)
        console.log("singledata " + singledata);

        var subcatslug = singledata.subcategory.slug;
        var subname = singledata.subcategory.name;
        var catslug = singledata.subcategory.catid.slug;
        var catname = singledata.subcategory.catid.name;
        setsubcatslug(subcatslug);
        setsubname(subname);
        setcatslug(catslug);
        setcatname(catname);

        document.getElementById("maintittle").innerHTML = singledata.seo_tittle;
      }

    }

  }, [singledata]);

  const handleSubmit = (e) => {
    const p = price - sellprice;
    const discount = ((p/sellprice)*100).toFixed(2);
    const finalP = price == ""? sellprice : price;


    if (isSigninIn != true) {
      //navigate("/login");
      e.preventDefault();

      var idrcv = localStorage.getItem("tempuser");
      if (idrcv == null || idrcv == "" || idrcv == undefined) {
        var d1 = new Date();
        // var tid = d1.getTime() / 2;
        var tid = Math.floor(10000000 + Math.random() * 9000);
        localStorage.setItem("tempuser", tid);
        dispatch(createCart(tid, productid, qty, 1,finalP,discount, sellprice));
      }
      else {
        var tid = localStorage.getItem("tempuser");
        dispatch(createCart(tid, productid, qty, 1,finalP,discount, sellprice));
      }

    }
    else {

      e.preventDefault();
      if (user_id != null) {
        dispatch(createCart(user_id, productid, qty, 1,finalP,discount, sellprice));
      } else {
        toast.warning("Please Login First");
        navigate("/login");
      }
    }
  };

  const handleBayNow = (e) => {
    const p = price - sellprice;
    const discount = ((p/sellprice)*100).toFixed(2);
    const finalP = price == ""? sellprice : price;

    if (isSigninIn !== true) {
      navigate("/login");
    }
    e.preventDefault();
    if (user_id != null) {
      dispatch(createCart(user_id, productid, qty, 2,finalP,discount, sellprice));
      //navigate("/cart");
    } else {
      toast.warning("Please Login First");
      navigate("/login");
    }
  };
  const scroll1 = () => {
    const section = document.querySelector("sertires");
    section.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const data = [
    {
      value: 1,
      label: "1"
    },
    {
      value: 2,
      label: "2"
    },
    {
      value: 3,
      label: "3"
    },
    {
      value: 4,
      label: "4"
    },

  ];
  const [selectedOption, setSelectedOption] = useState(data[0]);

  return (
    <>
      <div className="single_product_des">
        <ToastContainer />

        <><div className="">
          <Link className="breadcumprod" to={`/series/${catslug}`}>{catname}</Link> / <Link className="breadcumprod" to={`/category/${subcatslug}`}>{subname}</Link>
        </div>

          <div className="">
            <h1>{name}</h1>
            <p className="short_des">{short_description}</p>

            {/*
    <div className="product-number">
      <p>Item #</p>
      <span itemprop="productID">{item_no}</span>
    </div>
*/}
            <div className="decs-starts">
              <div className="starts">
                <FontAwesomeIcon icon={faStar} />
                <FontAwesomeIcon icon={faStar} />
                <FontAwesomeIcon icon={faStar} />
                <FontAwesomeIcon icon={faStar} />
                <FontAwesomeIcon icon={faStar} />
              </div>

              {/*

      */}
              <div aria-hidden="true" class="pr-snippet-rating-decimal">
                {ratingper}
              </div>

              <div className="number-of-review">
                <button> {countreview} Reviews</button>
              </div>
              <p>
                <div className="reviews">
                  <Link to={`/write-review/${id}`}>Write Review</Link>
                </div>
              </p>
            </div>
            <p className="short_des stroke-available">
              Availability:
              <span>
                {available_inventory > 0 ? "In Stock" : "Out of Stock "}
              </span>
            </p>

            <div class="promo-line">
              <p class="body" className="free-shipping">
                {/* {delivery} */}
              </p>
              <div class="tooltip">
                Details
                <span class="tooltiptext">{delivery}</span>
              </div>
            </div>

            <p className="single_price">
              <span className="single_sale">₹ {sellprice}</span>
              {price != null && price != "" ? (
                <span className="single_regular">
                  <strike>₹ {price}</strike>
                </span>
              ) : (
                ""
              )}
            </p>
            <div className="quantity_form">
              <form className="display_form">

                <Select isSearchable={false}
                  className="quantitydropdown"
                  placeholder="Qty"
                  value={selectedOption} // set selected value
                  options={data} // set list of the data
                  onChange={(e) => setQtyN(e)}// assign onChange function
                />

                {/*
                  <input
                    required
                    className="form-control quantity"
                    min="1"
                    type="number"
                    name="qty"
                    value={qty}

                    onChange={(e) => setQtyN(e.target.value)} />
                  */
                }

                {available_inventory > 0 ? (
                  <button
                    className="btn add_to_cart btn2"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    Add to cart
                  </button>
                ) : (
                  <button className="btn add_to_cart " type="submit" disabled>
                    Out Of Stock
                  </button>
                )}
               { available_inventory > 0 ? (<button
                  className="btn add_to_cart btn"
                  type="submit"
                  onClick={handleBayNow}
                >
                  Buy now
                </button>): null}
              </form>
            </div>
          </div></>
        <div
          className="desc-detail"
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        >
          {/* {description} */}
        </div>
        {/* <TextCard /> */}
        {/* <ProductDesc /> */}
      </div>
    </>
  );
}
