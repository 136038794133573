import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuoteLeft } from "@fortawesome/fontawesome-free-solid";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadAllTestimonial } from "../redux/action/AdditionalAction";
import Loader from "../component/Shared/Loader";
import Message from "../component/share/Message";
import testimonial1 from "../image/testimonial1.jpg"
export default function Testimonial() {
  const dispatch = useDispatch();

  useEffect(() => {
    document.getElementById("maintittle").innerHTML = "iRobot India Review - Public Testimonials - Roomba & Braava Users";
    document.getElementById("description").setAttribute("content", "Have a look at reviews shared by various Roomba & Braava users across India. People share their reviews of using iRobot robotic vacuum cleaners & mopping robots.");
    dispatch(loadAllTestimonial());
  }, []);

  const blogData = useSelector((state) => state.Additional);

  const { loading, error, testimonial } = blogData;
  console.log(testimonial, "testimonialavdesh");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <section className="section testminial">
          <div className="container">
            {testimonial &&
              testimonial.map((item, i) => (
                <div className="testimonal_section" key={i}>
                  <div className="testi-image">
                    <img
                      src={`${process.env.REACT_APP_API_PATH}${item.image}`}
                      alt=""
                    />
                  </div>
                  <div className="testi-content">
                    <span className="quotes_icon">
                      <FontAwesomeIcon icon={faQuoteLeft} />
                    </span>
                    <h2 className="heading_testi">{item.title}</h2>
                    <p className="content_para">{item.description}</p>
                    <p className="test_name">
                      {item.author} - {item.city}
                    </p>
                  </div>
                </div>
              ))}
          </div>
        </section>
      )}
    </>
  );
}
