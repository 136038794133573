import axios from "axios";
import { toast } from "react-toastify";
import {
  FIND_ALL_CATEGORY_FAIL,
  FIND_ALL_CATEGORY_REQUEST,
  FIND_ALL_CATEGORY_SUCCESS,
  FIND_ONE_CATEGORY_FAIL,
  FIND_ONE_CATEGORY_REQUEST,
  FIND_ONE_CATEGORY_SUCCESS,
} from "../constants/CategoryConstant";

// Find All Vehicle OWNER
const loadAllCategory = () => {
  return function (dispatch) {
    dispatch({
      type: FIND_ALL_CATEGORY_REQUEST,
      payload: true,
    });
    // const token = JSON.parse(localStorage.getItem("jwt"))
    //   ? JSON.parse(localStorage.getItem("jwt"))
    //   : "";
    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/user-category/1/`,
      // url: `${process.env.REACT_APP_API_PATH}/api/v1/user-category/`,
      method: "GET",
      headers: {
        "content-type": "application/json",
        // token: token,
      },
    };
    axios(OPTION)
      .then((res) => {
        dispatch(loadAllCategoryPre(res.data));
      })
      .catch((error) => {
        dispatch({
          type: FIND_ALL_CATEGORY_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
      });
  };
};
export const loadAllCategoryPre = (data) => {
  // console.log("data",data)
  return {
    type: FIND_ALL_CATEGORY_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};
// Find Single Category
const loadSingleCategory = (id, result) => {
  return function (dispatch) {
    dispatch({
      type: FIND_ONE_CATEGORY_REQUEST,
      payload: true,
    });
    const token = JSON.parse(localStorage.getItem("jwt"))
      ? JSON.parse(localStorage.getItem("jwt"))
      : "";
    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/user-category/${id}/`,
      method: "GET",
      headers: {
        "content-type": "application/json",
        token: token,
      },
    };
    axios(OPTION)
      .then((res) => {
        dispatch(loadSingleCategoryPre(res.data));
      })
      .catch((error) => {
        dispatch({
          type: FIND_ONE_CATEGORY_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
      });
  };
};
export const loadSingleCategoryPre = (data) => {
  return {
    type: FIND_ONE_CATEGORY_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

export { loadAllCategory, loadSingleCategory };
