
import React, { useState, useEffect } from "react";
import $ from "jquery";
import { createAppointments, createEnquiryHome } from "../../redux/action/AdditionalAction";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import spinner1 from "../../image/spinner1.gif";
import ReCAPTCHA from "react-google-recaptcha";



const InquireForm = () => {
  const queryParams = new URLSearchParams(window.location.search);
  //const [utm_source, setutm_source] = useState('');
  //const [utm_campaign, setutm_campaign] = useState('');

  var utm_sourcercv = queryParams.get('utm_source');
  var utm_campaignrcv = queryParams.get('utm_campaign');


  const dispatch = useDispatch();
  const [city, setCity] = useState("");
  const [category, setCategory] = useState("");
  const [first_name, setName] = useState("");
  const [last_name, setLastname] = useState("");

  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [landmark, setLandmark] = useState("");
  const [pincode, setPincode] = useState("");
  const [location, setLocation] = useState("");
  const [address, setAddress] = useState("");
  const [message, setMessage] = useState("");
  const [pe, setPe] = useState(0);
  const [reqtyop, setReqtyop] = useState("");
  const [title, settitle] = useState("Home Page Enquiry");
  const [company, setcompany] = useState("");
  const [company_website, setcompany_website] = useState("");
  const [area_of_focus, setarea_of_focus] = useState("");
  const [area_of_expertise, setarea_of_expertise] = useState("");
  const [promotional_email, setpromotional_email] = useState("1");
  const [source, setsource] = useState("");
  const [campaign, setcampaign] = useState("");


  useEffect(() => {
    if (utm_sourcercv != null && utm_sourcercv != '' && utm_sourcercv != undefined) {

      setsource(utm_sourcercv);
      setcampaign(utm_campaignrcv);
      localStorage.setItem('utm_source', utm_sourcercv);
      localStorage.setItem('utm_campaign', utm_campaignrcv);
    }
    else {
      var item1 = localStorage.getItem('utm_source');
      var item2 = localStorage.getItem('utm_campaign');
      if (item1 != null && item1 != undefined && item1 != '') {
        setsource(item1);
        setcampaign(item2);
      }

    }
  }, []);



  const searchResult = useSelector((state) => state.Additional);
  const { loading, error } = searchResult;

  const key = process.env.REACT_APP_SITE_KEY;
  const url = process.env.REACT_APP_SITE_URL;
  const [captchaResult, setCaptchaResult] = useState();
  const [togglebutton, settogglebutton] = useState(false);

  const handleRecaptcha = (value) => {
    setCaptchaResult(value)
    /*
    fetch(url, {
      method: "POST",
      body: JSON.stringify({ captcha_value: value }),
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.captcha.success);
        setCaptchaResult(data.captcha.success);
      });
      */
  };

  const handleSubmit = (e) => {
    console.log("captchaResult" + captchaResult)

    e.preventDefault();

    if (captchaResult == undefined || captchaResult == '' || captchaResult == null) {
      toast.warning("Invalid Captcha");

      // return false;

    }

    if (!first_name && !email && !mobile) {
      toast.warning("Please input all input Field!");
    } else {

      dispatch(createEnquiryHome(first_name, last_name, email, mobile, title, company, city, company_website, area_of_focus, area_of_expertise, message, promotional_email, window.location.href, campaign));
      setName("");
      setEmail("");
      setMobile("");
      setCity("");
      setMessage("");
      settogglebutton(false);

    }
  };

  const handleMobile = (value) => {
    // const { name, value } = ;

    setMobile(value.slice(0, 10));

    /*
    console.log('...', name);
    if (name == "phone") {
      setstate({ ...state, [name]: value.slice(0, 10) });

    } */

  }
  const enquireclick = (e) => {
    console.log('enquireclick');
    settogglebutton(!togglebutton)
    // const { name, value } = ;

    //setMobile(value.slice(0, 10));

    /*
    console.log('...', name);
    if (name == "phone") {
      setstate({ ...state, [name]: value.slice(0, 10) });

    } */

  }

  return (

    <>
      {loading ? (<div id="loading">
        <img id="loading-image" src={spinner1} alt="Loading..." />
      </div>) : ""}

      <div id="contactform" className={togglebutton ? "formparenthover" : "form-parent"}>

        <div id="contact-button" onClick={enquireclick}>

          <button className="rotated-text" >Enquire Now</button>
        </div>
        <form
          className="cc-float-form"
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          {/* Name: */}

          <input
            type="text"
            name="fname"
            placeholder="Name"
            className="contact-form-area"
            value={first_name}
            required
            onChange={(e) => setName(e.target.value)}
          />
          <br />
          <input
            className="contact-form-area"
            name="email"
            placeholder="Email"
            type="email"
            value={email}
            required
            onChange={(e) => setEmail(e.target.value)}
          />
          <br />
          <input
            className="contact-form-area"
            name="mobile"
            placeholder="Mobile"
            type="number"
            value={mobile}
            required
            minLength="10" 
            maxLength="10"
            onChange={(e) => handleMobile(e.target.value)}
          />
          <br />
          <input
            className="contact-form-area"
            name="city"
            placeholder="Your city"
            type="text"
            value={city}
            onChange={(e) => setCity(e.target.value)}
          />
          <br />
          <textarea
            className="contact-form-area"
            id="ContactForm1_contact-form-email-message"
            name="email-message"
            cols="25"
            placeholder="Message"
            rows="1"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <div className="input_field_checkbox">
            <input
              type="checkbox"
              id="receive_news"
              name="news"
              value={pe}
              onChange={(e) => 
                {console.log(e.target.checked);
                if(e.target.checked === true){
                  setPe(1);
                  setpromotional_email(1);
                }else{
                  setPe(0);
                  setpromotional_email(0);

                }
                console.log(pe);
                console.log(window.location.href);
                }}
            />
            <label
              style={{ color: "gray", fontSize: "13px" }}
            >
              Opt-in me for promotional emails

            </label>

          </div>

          <ReCAPTCHA
            sitekey={key}
            onChange={handleRecaptcha}
            className="captcha"
          />
          <button type="submit" className="btn btn-default">
            Call me Back
          </button>
        </form>
      </div>
    </>
  );
}

export default InquireForm