export const Find_ALL_REGISTER_ROBOT_REQUEST = "Find_ALL_REGISTER_ROBOT_REQUEST";
export const Find_ALL_REGISTER_ROBOT_SUCCESS = "Find_ALL_REGISTER_ROBOT_SUCCESS";
export const Find_ALL_REGISTER_ROBOT_FAIL = "Find_ALL_REGISTER_ROBOT_FAIL";

export const Find_REGISTER_ROBOT_DETAIL_REQUEST = "Find_ALL_REGISTER_ROBOT_REQUEST";
export const Find_REGISTER_ROBOT_DETAIL_SUCCESS = "Find_ALL_REGISTER_ROBOT_SUCCESS";
export const Find_REGISTER_ROBOT_DETAIL_FAIL = "Find_ALL_REGISTER_ROBOT_FAIL";

export const UPDATE_REGISTER_ROBOT_REQUEST = "UPDATE_REGISTER_ROBOT_REQUEST";
export const UPDATE_REGISTER_ROBOT_SUCCESS = "UPDATE_REGISTER_ROBOT_SUCCESS";
export const UPDATE_REGISTER_ROBOT_FAIL = "UPDATE_REGISTER_ROBOT_FAIL";

export const CREATE_REGISTER_ROBOT_REQUEST = "CREATE_REGISTER_ROBOT_REQUEST";
export const CREATE_REGISTER_ROBOT_SUCCESS = "CREATE_REGISTER_ROBOT_SUCCESS";
export const CREATE_REGISTER_ROBOT_FAIL = "CREATE_REGISTER_ROBOT_FAIL";

export const REMOVE_REGISTER_ROBOT_REQUEST = "REMOVE_REGISTER_ROBOT_REQUEST";
export const REMOVE_REGISTER_ROBOT_SUCCESS = "REMOVE_REGISTER_ROBOT_SUCCESS";
export const REMOVE_REGISTER_ROBOT_FAIL = "REMOVE_REGISTER_ROBOT_FAIL";
