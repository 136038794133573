import {
  Find_ALL_PRODUCTLIST_FAIL,
  Find_ALL_PRODUCTLIST_REQUEST,
  Find_ALL_PRODUCTLIST_SUCCESS,
  Find_ALL_OFFER_PRODUCTLIST_FAIL,
  Find_ALL_OFFER_PRODUCTLIST_REQUEST,
  Find_ALL_OFFER_PRODUCTLIST_SUCCESS,
} from "../constants/ProductListingConstant";

const initialState = {
  loading: false,
  action: "Product Listing",
  result: [],
  resultoffer: [],
  response: {},
  singledata: [],
  msg: "",
  error: "",
};

const ProductListingReducer = (state = initialState, action) => {
  switch (action.type) {
    case Find_ALL_PRODUCTLIST_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case Find_ALL_PRODUCTLIST_SUCCESS:
      return {
        ...state,
        result: action.result.data,
        loading: action.payload,
        msg: action.msg,
      };
    case Find_ALL_PRODUCTLIST_FAIL:
      return {
        ...state,
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };

      case Find_ALL_OFFER_PRODUCTLIST_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case Find_ALL_OFFER_PRODUCTLIST_SUCCESS:
      return {
        ...state,
        resultoffer: action.result.data,
        loading: action.payload,
        msg: action.msg,
      };
    case Find_ALL_OFFER_PRODUCTLIST_FAIL:
      return {
        ...state,
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };
    default:
      return state;
  }
};

export default ProductListingReducer;
