import axios from "axios";
import { toast } from "react-toastify";
import {
  GET_EARN_LOYALTY_POINTS_FAIL,
  GET_EARN_LOYALTY_POINTS_REQUEST,
  GET_EARN_LOYALTY_POINTS_SUCCESS,
  GET_LOYALTY_POINTS_FAIL,
  GET_LOYALTY_POINTS_REQUEST,
  GET_LOYALTY_POINTS_SUCCESS,
  GET_SPEND_LOYALTY_POINTS_FAIL,
  GET_SPEND_LOYALTY_POINTS_REQUEST,
  GET_SPEND_LOYALTY_POINTS_SUCCESS,
  GET_LOYALTY_POINTS_HISTORY_FAIL,
  GET_LOYALTY_POINTS_HISTORY_REQUEST,
  GET_LOYALTY_POINTS_HISTORY_SUCCESS,
} from "../constants/LoyaltypointsConstant";

// Find All Points
export const loadAllPoints = (id) => {
  return function (dispatch) {
    dispatch({
      type: GET_LOYALTY_POINTS_REQUEST,
      payload: true,
    });

    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/total-loyalty-points/${id}/`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    axios(OPTION)
      .then((res) => {
        // console.log("total-loyalty-points", res.data);
        dispatch(loadAllPointsPre(res.data));
      })
      .catch((error) => {
        console.log("error", error);

        dispatch({
          type: GET_LOYALTY_POINTS_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
      });
  };
};
export const loadAllPointsPre = (data) => {
  return {
    type: GET_LOYALTY_POINTS_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

// Find All Earn Points
export const loadAllEarnPoints = (id) => {
  return function (dispatch) {
    dispatch({
      type: GET_EARN_LOYALTY_POINTS_REQUEST,
      payload: true,
    });

    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/loyalty-points-earn/${id}/`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    axios(OPTION)
      .then((res) => {
        // console.log("loyalty-points-earn", res.data);
        dispatch(loadAllEarnPointsPre(res.data));
      })
      .catch((error) => {
        console.log("error", error);

        dispatch({
          type: GET_EARN_LOYALTY_POINTS_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
      });
  };
};
export const loadAllEarnPointsPre = (data) => {
  return {
    type: GET_EARN_LOYALTY_POINTS_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

// Find All Spend Points
export const loadAllSpendPoints = (id) => {
  return function (dispatch) {
    dispatch({
      type: GET_SPEND_LOYALTY_POINTS_REQUEST,
      payload: true,
    });

    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/loyalty-points-spend/${id}/`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    axios(OPTION)
      .then((res) => {
        // console.log("loyalty-points-spend", res.data);
        dispatch(loadAllSpendPointsPre(res.data));
      })
      .catch((error) => {
        console.log("error", error);

        dispatch({
          type: GET_SPEND_LOYALTY_POINTS_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
      });
  };
};
export const loadAllSpendPointsPre = (data) => {
  return {
    type: GET_SPEND_LOYALTY_POINTS_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

// Find Points History
export const loadPointsHistory = (id) => {
  return function (dispatch) {
    dispatch({
      type: GET_LOYALTY_POINTS_HISTORY_REQUEST,
      payload: true,
    });

    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/loyalty-points/${id}/`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    axios(OPTION)
      .then((res) => {
        // console.log("loyalty-points", res.data);
        dispatch(loadPointsHistoryPre(res.data));
      })
      .catch((error) => {
        console.log("error", error);

        dispatch({
          type: GET_LOYALTY_POINTS_HISTORY_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
      });
  };
};
export const loadPointsHistoryPre = (data) => {
  return {
    type: GET_LOYALTY_POINTS_HISTORY_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};
