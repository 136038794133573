import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { loadGeniusTab } from "../../redux/action/StaticAction";
import LeftZigzag110 from "./Left_zigzag110";
import LeftZigzag220 from "./Left_zigzag220";
import LeftZigzag330 from "./Left_zigzag330";
import LeftZigzag440 from "./Left_zigzag440";

// import { useParams } from 'react-router-dom';
import $ from "jquery";

export default function Series_tab10() {
  $(function () {
    $(".tabbing li").click(function () {
      $(this).addClass("active");
      $(this).siblings().removeClass("active");
    });
  });
  const [tab1, setTab1] = useState(true);
  const [tab2, setTab2] = useState(false);
  const [tab3, setTab3] = useState(false);
  const [tab4, setTab4] = useState(false);

  const tabhander1 = () => {
    if (tab1 !== true) {
      setTab1(!tab1);
      setTab2(false);
      setTab3(false);
      setTab4(false);
    }
  };
  const tabhander2 = () => {
    if (tab2 !== true) {
      setTab2(!tab2);
      setTab1(false);
      setTab3(false);
      setTab4(false);
    }
  };
  const tabhander3 = () => {
    if (tab3 !== true) {
      setTab3(!tab3);
      setTab2(false);
      setTab1(false);
      setTab4(false);
    }
  };
  const tabhander4 = () => {
    if (tab4 !== true) {
      setTab4(!tab4);
      setTab2(false);
      setTab3(false);
      setTab1(false);
    }
  };

  const banners = useSelector((state) => state.Static.genius);
  let bannerData = banners[0]

  if (bannerData) {
    var banner_id = bannerData.id
    // console.log('banner_id', banner_id);
  }
  const dispatch = useDispatch();

  useEffect(() => {
    if(banner_id !== "" || banner_id !== null){
    dispatch(loadGeniusTab(banner_id));
  }
  }, [banner_id]);

  const tab = useSelector((state) => state.Static.geniusTab);

  return (
    <>
      {tab1 ? <LeftZigzag110  banner_id={{banner_id}}/> : ""}
      {tab2 ? <LeftZigzag220 banner_id={{banner_id}}/> : ""}
      {tab3 ? <LeftZigzag330 banner_id={{banner_id}}/> : ""}
      {tab4 ? <LeftZigzag440 banner_id={{banner_id}}/> : ""}
{tab.lenght > 0 ?
      <section className="section tabbing">
        <div className="container">
          {tab.map((da) => {
            return (
              <ul key={da.id}>
                <li className="active" onClick={tabhander1}>
                  <div className="tabing_inner">
                    <h3>{da.tab1_title}</h3>
                    <p>{da.tab1_description}</p>
                  </div>
                </li>
                <li onClick={tabhander2}>
                  <div className="tabing_inner">
                    <h3>{da.tab2_title}</h3>
                    <p>{da.tab2_description}</p>
                  </div>
                </li>
                <li onClick={tabhander3}>
                  <div className="tabing_inner">
                    <h3>{da.tab3_title}</h3>
                    <p>{da.tab3_description}</p>
                  </div>
                </li>
                <li onClick={tabhander4}>
                  <div className="tabing_inner">
                    <h3>{da.tab4_title}</h3>
                    <p>{da.tab4_description}</p>
                  </div>
                </li>
              </ul>
            );
          })}
        </div>
      </section>
      : "" }
    </>
  );
}
