import {
    GETDETAIL_VIDEODEMO_REQUEST,
    GETDETAIL_VIDEODEMO_FAIL,
    GETDETAIL_VIDEODEMO_SUCCESS,
} from "../constants/VideodemoConstant";


const initialState = {
    loading: false,
    action: "Videodemo",
    result: [],
    response: {},
    singledata: [],
    msg: "",
    error: "",
};

const VideodemoReducer = (state = initialState, action) => {
    switch (action.type) {


        case GETDETAIL_VIDEODEMO_REQUEST:
            return {
                ...state,
                loading: action.payload,
            };
        case GETDETAIL_VIDEODEMO_SUCCESS:
            return {
                ...state,
                singledata: action.result.data,
                loading: action.payload,
                msg: action.msg,
            };
        case GETDETAIL_VIDEODEMO_FAIL:
            return {
                error: action.error,
                loading: action.payload,
                msg: action.msg,
            };

        default:
            return state;
    }
};

export default VideodemoReducer;
