import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import user from "../../image/user.jpg";
import { Link } from "react-router-dom";
// import { fetchOrderHistoryRequest } from "../../redux/action/OrderAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/fontawesome-free-solid";
import Loader from "../Shared/Loader";
import OrderInvoice from "./OrderInvoice";
import { loadAllUserAddress } from "../../redux/action/deliveryAddressAction";

export default function UserAddress() {
  const [showInvoice, setshowInvoice] = useState(false);

  const [currentPage, setcurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [pageNumberLimit] = useState(5);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0);

  let user = useSelector((state) => state.AuthReducer.userResult);

  var user_id = user.id;

  const dispatch = useDispatch();

  useEffect(() => {
    if (user_id) {
      dispatch(loadAllUserAddress(user_id));
    }
  }, []);

  const userAddress = useSelector((state) => state.DeliveryAddress);

  let { loading, error, result } = userAddress;

  // console.log("result", result);

  let formatter = new Intl.DateTimeFormat("en-GB", {
    year: "numeric",
    month: "numeric",
    day: "2-digit",
  });

  const handleClick = (e) => {
    setcurrentPage(Number(e.target.id));
  };

  const pages = [];
  for (let i = 1; i <= Math.ceil(result.length / itemsPerPage); i++) {
    pages.push(i);
  }

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = result.slice(indexOfFirstItem, indexOfLastItem);

  const renderPageNumbers = pages.map((number) => {
    if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
      return (
        <li
          key={number}
          id={number}
          onClick={handleClick}
          className={currentPage === number ? "active" : null}
        >
          {number}
        </li>
      );
    } else {
      return null;
    }
  });

  const handleNextbtn = () => {
    setcurrentPage(currentPage + 1);

    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };

  const handlePrevbtn = () => {
    setcurrentPage(currentPage - 1);

    if ((currentPage - 1) % pageNumberLimit === 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };

  let pageIncrementBtn = null;
  if (pages.length > maxPageNumberLimit) {
    pageIncrementBtn = <li onClick={handleNextbtn}> &hellip; </li>;
  }

  let pageDecrementBtn = null;
  if (minPageNumberLimit >= 1) {
    pageDecrementBtn = <li onClick={handlePrevbtn}> &hellip; </li>;
  }

  let j = currentPage * 10 + 1 - 10;

  return (
    <div className="orderHistory">
      <h2 className="title">User Address List</h2>
      <div className="product_cart">
        <div className="">
          <div className="regrobot">
            {loading == true ? (
              <>
                <Loader />
              </>
            ) : (
              <>
                {result.length > 0 ?

                  <>
                    <table>
                      <thead>
                        <tr>
                          <th className="section-header">Sr.no</th>
                          <th className="section-header">Address</th>
                          <th className="section-header">City</th>
                          <th className="section-header">State</th>
                          <th className="section-header">Potal-Code</th>
                          <th className="section-header">Mobile</th>
                          <th className="section-header">Receiver</th>
                          <th className="section-header">Edit</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentItems &&
                          currentItems.map((item, i) => (
                            <tr key={item.id}>
                              <td>
                                <strong>{j++}</strong>
                              </td>

                              <td>

                                <span className="bold" style={{ color: "#447355", fontWeight: "bold", fontSize: "18px" }}>{item.address + " " + item.landmark}

                                </span>
                              </td>
                              <td>
                                <h3>{item.city}</h3>
                              </td>
                              <td>

                                <span className="sale">{item.stateName}</span>

                              </td>
                              <td>

                                <span className="sale">{item.postCode}</span>

                              </td>
                              <td>

                                <span className="sale">{item.mobile}</span>

                              </td>

                              <td className="purchase_date">
                                <strong>
                                  {item.firstname + " " + item.lastname}
                                </strong>
                              </td>

                              <td>
                                <Link to={`/edit-address/${item.id}`}>
                                  <FontAwesomeIcon icon={faEye} />
                                </Link>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                    {result.length > 5 ?
                      <div className="mt-4">
                        <ul className="pageNumbers">
                          <li>
                            <button
                              onClick={handlePrevbtn}
                              disabled={currentPage === pages[0] ? true : false}
                            >
                              Prev
                            </button>
                          </li>
                          {pageDecrementBtn}
                          {renderPageNumbers}
                          {pageIncrementBtn}

                          <li>
                            <button
                              onClick={handleNextbtn}
                              disabled={
                                currentPage === pages[pages.length - 1] ? true : false
                              }
                            >
                              Next
                            </button>
                          </li>
                        </ul>
                      </div>
                      : ""}
                  </>
                  : "No Address Found"}
              </>
            )}
          </div>
        </div>
      </div>
      {showInvoice ? <OrderInvoice /> : null}
    </div>
  );
}
