import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createAppointment } from "../redux/action/AdditionalAction";
import { loadAllCategory } from "../redux/action/CategoryAction";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../component/Shared/Loader";
import { Link, useNavigate, useParams } from "react-router-dom";

import Calendar from "react-calendar";
import Message from "../component/share/Message";
import ReCAPTCHA from "react-google-recaptcha";
import { loadSingleStore } from "../redux/action/storeAction";
import { loadFullSubcategory1 } from "../redux/action/SubCategoryAction";
const Storedetail = () => {
  const [book, setBook] = useState(false);
  const [componyname, setcomponyname] = useState("");
  const [showroomName, setshowroomName] = useState("");
  const [showroomaddress, setshowroomaddress] = useState("");
  const [Showroomcontact, setShowroomcontact] = useState("");
  const [Showroomemail, setShowroomemail] = useState("");
  const [Showdescription, setShowdescription] = useState("");
  const navigate = useNavigate();
  const [categoryName, setCategoryName] = useState("");

  let { id } = useParams();

  const queryParams = new URLSearchParams(window.location.search);
  const [utm_source, setutm_source] = useState("");
  const [utm_campaign, setutm_campaign] = useState("");
  var utm_sourcercv = queryParams.get("utm_source");
  var utm_campaignrcv = queryParams.get("utm_campaign");

  useEffect(() => {
    if (
      utm_sourcercv != null &&
      utm_sourcercv != "" &&
      utm_sourcercv != undefined
    ) {
      setutm_source(utm_sourcercv);
      setutm_campaign(utm_campaignrcv);
      localStorage.setItem("utm_source", utm_sourcercv);
      localStorage.setItem("utm_campaign", utm_campaignrcv);
    } else {
      var item1 = localStorage.getItem("utm_source");
      var item2 = localStorage.getItem("utm_campaign");
      if (item1 != null && item1 != undefined && item1 != "") {
        setutm_source(item1);
        setutm_campaign(item2);
      }
    }
  }, []);
  const [state, setState] = useState({
    category: "",
    landmark: "store",

    name: "",
    email: "",
    mobile: "",
    address: "",
    message: "",

    pe: "",
    reqtyop: "",
    source: "",
    campaign: utm_campaign,
  });

  const {
    category,
    landmark,
    name,
    email,
    mobile,
    address,
    message,
    pe,
    reqtyop,
    source,
    campaign,
  } = state;
  const Category = useSelector((state) => state.MainCategory);

  const { result } = Category;

const[resultState, setResultState] = useState(result);
  




  const handleInputChange = (e) => {
    let { name, value } = e.target;

    


    // if (name === "category") {
    //     //  console.log(e.target.options[e.target.selectedIndex].text, "Input");
    //     // const categorySelected = e.target.options[e.target.selectedIndex].text;
      
    //     // setCategoryName(categorySelected);
       
    //     value = e.target.options[e.target.selectedIndex].text;
    //     // setState((value)=>{
    //     //     return {...value, category : e.target.options[e.target.selectedIndex].text}
    //     // })   
    // }
    console.log(e.target);

    if (name == "pincode") {
      value = value.slice(0, 6);
    }
    if (name == "mobile") {
      value = value.slice(0, 10);
    }
   
        setState({
            ...state,
            [name]: value,
            source : singledataStore["store_name"],
            
          
          //   "landmark":  singledataStore["store_name"],
            
          });
   
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!category || !email || !mobile || !name) {
      toast.warning("Please input all input Field!");
    } else {
      dispatch(createAppointment(state));
    }
    //  navigate("/all-thank-you");
  };
  const { response } = useSelector((state) => state.Additional);

  useEffect(() => {
    if (Object.keys(response).length > 0) {
      console.log("Object is NOT empty");

      //navigate('/thanks-you', { state: { messagefor: "contact", heading: "Thank You For Choosing iRobot and taking the first step", message: "We will get in touch with you shortly. For quick response connect on 18002003355. " } });
      navigate("/thanks-you-store", {
        state: {
          messagefor: "contact",
          heading: "Thank You For Choosing iRobot and taking the first step",
          message:
            "We will get in touch with you shortly. For quick response connect on 18002003355. ",
        },
      });
    }
  }, [response]);

  const dispatch = useDispatch();

  const storeList = useSelector((state) => state.StoreList);

  const { singledataStore } = storeList;

  const subcategory1 = useSelector((state) => state.SubCategory.singledata);
  if (subcategory1 != null) {
    console.log("subcategory1 ", subcategory1);
  }

  useEffect(() => {
    // dispatch(loadSingleVideodemo());
    if (id !== null) {
      // dispatch(loadFullSubcategory1());
      dispatch(loadAllCategory());

      dispatch(loadSingleStore(id));
    }
  }, [id]);

  const Videodemo = useSelector((state) => state.Videodemo);

  let { error, loading, singledata } = Videodemo;
  let VideoCallDemodata = singledata[0];

  useEffect(() => {
    //console.log('loadAllStore ', singledataStore)
    if (singledataStore != null) {
      document.getElementById("maintittle").innerHTML =
        "Buy iRobot® Products at -" + singledataStore["store_name"];
      document
        .getElementById("description")
        .setAttribute("content", singledataStore["store_name"]);

      var rname = singledataStore["store_name"];
      var cname =
        "https://maps.google.com/maps?q=" +
        rname +
        "&t=&z=15&ie=UTF8&iwloc=&output=embed";

      setcomponyname(cname);
      setshowroomName(singledataStore["store_name"]);
      //setshowroomaddress(singledataStore['address'] + "," + singledataStore['city'] + "," + singledataStore['stateName'] + " " + singledataStore['postCode'] + "," + singledataStore['country']);
      setshowroomaddress(
        singledataStore["address"] +
          "," +
          singledataStore["city"] +
          "," +
          singledataStore["stateName"] +
          " " +
          singledataStore["postCode"]
      );
      setShowroomemail(singledataStore["store_incharge_email"]);
      setShowdescription(singledataStore["country"]);
      setShowroomemail(singledataStore["store_incharge_email"]);
      setShowroomcontact(singledataStore["store_incharge_mobile"]);
    }
  }, [singledataStore]);

  return (
    <>
      <div className="container-showroom">
        <ToastContainer />

        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : (
          <>
            <div className="mapwrapper">
              <div class="mapouter">
                <div class="gmap_canvas">
                  <iframe
                    className="custommap"
                    id="gmap_canvas"
                    src={componyname}
                    frameBorder="0"
                    scrolling="no"
                    marginHeight="0"
                    marginWidth="0"
                  ></iframe>
                  <a href="https://123movies-a.com"></a>
                  <br></br>
                  <a href="https://www.embedgooglemap.net"></a>
                </div>
              </div>
            </div>

            <div className="storecontainer">
              <div className="storeleft">
                <div class="col-md-12 col-sm-12">
                  <h1 className="showroomheading">
                    <span id="">{showroomName}</span>
                  </h1>
                  <br></br>
                  <p className="">
                    <span id="">{Showdescription}</span>
                  </p>
                  <br></br>

                  <p className="py-2 para-xs-small">
                    <span id="ContentPlaceHolder1_lbladdres">
                      {showroomaddress}
                    </span>
                  </p>
                  <p className="py-2 para-xs-small">
                    <a
                      href={`tel:${Showroomcontact}`}
                      id="ContentPlaceHolder1_mobile"
                      className=""
                    >
                      <span className="text-green">
                        <i className="fa fa-phone" aria-hidden="true"></i>
                      </span>
                      <span
                        id="ContentPlaceHolder1_lblmble"
                        className="text-black"
                      >
                        {" "}
                        &nbsp;{Showroomcontact}
                      </span>
                    </a>
                    <br></br>
                    <a
                      href={"mailto:contact@puresight.co.in"}
                      id="ContentPlaceHolder1_email"
                      className=""
                    >
                      <span className="text-green">
                        <i className="fa fa-envelope" aria-hidden="true"></i>
                      </span>
                      <span
                        id="ContentPlaceHolder1_lblemail"
                        className="text-black"
                      >
                        &nbsp;{Showroomemail}
                      </span>
                    </a>
                  </p>
                  {/* 
                                    <p class="para-normal text-green font-weight-bolder"><span id="ContentPlaceHolder1_lblsortname">Showroom</span></p>
                                    */}
                </div>
              </div>

              <div className="storeright">
                <form autoComplete="off" onSubmit={handleSubmit}>
                  {/* <div className="input_field">
<label>
Select Location <span>*</span>
</label>
<input
type="text"
required
placeholder=""
name="location"
value={location}
onChange={handleInputChange}
/>
</div> */}
                  <div className="input_field">
                    <label>
                      Product Type <span>*</span>
                    </label>
                    <select
                      name="category"
                      onChange={handleInputChange}
                      value={category || ""}
                    >

                      <option>Select Product</option>
                      {result.map((item, i) => (
                        <option key={i} value={item.alias}>
                          {item.name} {item.alias}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="input_field">
                    <label>
                      Name <span>*</span>
                    </label>
                    <input
                      type="text"
                      placeholder=""
                      name="name"
                      value={name}
                      onChange={handleInputChange}
                      required
                    />
                  </div>

                  <div className="input_field">
                    <label>
                      E-mail <span>*</span>
                    </label>
                    <input
                      type="email"
                      required
                      name="email"
                      placeholder=""
                      value={email}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="input_field">
                    <label>
                      Mobile <span>*</span>
                    </label>
                    <input
                      type="number"
                      required
                      placeholder=""
                      name="mobile"
                      minLength="10"
                      maxLength="10"
                      value={mobile}
                      //onChange={handleInputChange}
                      onChange={(e) => {
                        if (e.target.value.length < 11) {
                          handleInputChange(e);
                        }
                      }}
                    />
                  </div>
                  <div className="input_field">
                    <label>Message</label>
                    <input
                      type="textarea"
                      placeholder=""
                      name="address"
                      value={address}
                      onChange={handleInputChange}
                    />
                  </div>

                  {/* 
<div className="input_field">
<label>
Source <span>*</span>
</label>
<input
type="text"
placeholder=""
name="source"
value={source}
onChange={handleInputChange}
/>
</div>
<div className="input_field">
<label>
Campaign<span>*</span>
</label>
<input
type="text"
placeholder=""
name="campaign"
value={campaign}
onChange={handleInputChange}
/>
</div>
*/}
                  {/* <div className="input_field">
<label>
Date <span>*</span>
</label>
<input
type="number"
placeholder=""
name="date"
value={date}
onChange={handleInputChange}
/>
</div> */}

                  <div className="input_field_btn">
                    {/* <ReCAPTCHA sitekey={key} onChange={handleRecaptcha} className="captcha"/> */}

                    {/* {captchaResult && ( <input type="submit" value="Book Online" />)} */}
                    <input type="submit" value="Submit" />
                  </div>
                </form>
              </div>
            </div>
          </>
        )}
      </div>

      {/*
      {book ? <SelectLocation onchange={onchange} /> : ""}
*/}
    </>
  );
};

export default Storedetail;
