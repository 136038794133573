import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import Slider_1 from "../../image/banner_1.jpg";
import Slider_2 from "../../image/banner_2.jpg";
import { loadAllSliderBanner } from "../../redux/action/HomeAction";

// import useAnalyticsEventTracker from "../../google_analytics";

var banner = {
  dots: true,
  infinite: true,
  speed: 500,
  autoplaySpeed: 5000,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const ResponsiveImage = ({ slider }) => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 1024);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 1024);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
};

export default function Banner() {

  // const gaEventTracker = useAnalyticsEventTracker('Banners');    //this parameter is the category for google analytics

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadAllSliderBanner());
  }, []);
  const sliderBanners = useSelector((state) => state.Home.sliderbanner);
  console.log("sliderBanners", sliderBanners);
  return (
    <section className="main_banner">
      <div className="slider_slide">
        <Slider {...banner}>
          {sliderBanners.map((slider) => {
            return (
              <div className="slider" key={slider.id}>
                <div className="left_content">
                  <img classname="image_banner" src={`${process.env.REACT_APP_API_PATH}${slider.banner}`} />
                </div>
                <div className="right_content">
                  {slider.tagline == null || slider.tagline == "" ? (
                    ""
                  ) : (
                    <span className="savving">{slider.tagline}</span>
                  )}

                  <h2 className="title home_banner_heading">{slider.banner_heding}</h2>
                  <p className="home_banner_para inner_p">{slider.banner_content}</p>
                  <div className="two_btn">
                    {slider.button1_url == "" || slider.button1_url == null ? (
                      ""
                    ) : (
                      <Link to={slider.button1_url}
                        // onClick={() => gaEventTracker('banner_clicked')}    //this parameter is the action for google analytics
                        className="btn btn_cat btn_cat_newcolor">
                        {slider.button1_name}
                      </Link>
                    )}
                    {slider.button2_url == "" || slider.button2_url == null ? (
                      ""
                    ) : (
                      <Link to={slider.button2_url}
                        // onClick={() => gaEventTracker('banner_clicked')}   //this parameter is the action for google analytics
                        className="btn btn_cat btn_cat_newcolor">
                        {slider.button2_name}
                      </Link>
                    )}
                  </div>
                  {slider.offer_ends !== null || slider.offer_ends !== "" ? (
                    ""
                  ) : (
                    <p className="value-offer">{slider.offer_ends}</p>
                  )}
                </div>
              </div>
            );
          })}
        </Slider>

      </div>
    </section>
  );
}

// import React from "react";
// import { Link } from "react-router-dom";
// import Slider from "react-slick";

// import Slider_1 from "../../image/banner_1.jpg";
// import Slider_2 from "../../image/banner_2.jpg";
// import Slider_3 from "../../image/banner_3.jpg";
// import Slider_4 from "../../image/banner_4.jpg";

// var banner = {
//   dots: true,
//   infinite: true,
//   autoplay:true,
//   speed: 500,
//   slidesToShow: 1,
//   slidesToScroll: 1,
//   fade: true,
//   cssEase: 'ease-in-out',
// };

// export default function Banner() {
//   return (
//     <section className="main_banner">
//     <div className="slider_slide">
//       <Slider {...banner}>
//         <div className="slider">
//           <div className="left_content">
//             <img src={Slider_1} />
//           </div>
//           <div className="right_content">
//             <h2 className="title">Spring’s even brighter with savings.</h2>
//             <p>
//             Always keep your robot fully stocked - get a free refill kit when you buy one of our most advanced robots.
//             </p>
//             <Link to="#!" className="btn sliderdd">
//               Shop Now
//             </Link>
//           </div>
//         </div>
//         <div className="slider">
//           <div className="right_content">
//             <h2 className="title">Pick a room, any room</h2>
//             <p>
//             Now the Roomba® i3+ EVO robot vacuum is smarter than ever, learning and mapping your home room-by-room so it can clean the room you want, when you want.
//             </p>
//             <Link to="#!" className="btn sliderdd">
//               Shop Now
//             </Link>
//           </div>
//           <div className="left_content">
//             <img src={Slider_2} />
//           </div>
//         </div>
//         <div className="slider Slider_3">
//           <div className="right_content">
//             <span className="savving">Save up to $350</span>
//             <h2 className="title">Tackle pollen and pet hair three refreshing ways</h2>
//             <p>
//             Our smartest bundle yet can suggest extra cleaning when pollen counts and pet shedding season are in high gear.
//             </p>
//             <Link to="#!" className="btn sliderdd">
//             Shop Now
//             </Link>
//           </div>
//           <div className="left_content">
//             <img src={Slider_3} />
//           </div>
//         </div>
//         <div className="slider">
//           <div className="right_content">
//             <h2 className="title">Ready to roll.</h2>
//             <p>
//             Stock up on our must-have accessory bundles that help keep your robot working in top condition. So you’ll never have to pause on cleaning.
//             </p>
//             <Link to="#!" className="btn sliderdd">
//             Shop Now
//             </Link>
//           </div>
//           <div className="left_content">
//             <img src={Slider_4} />
//           </div>
//         </div>
//       </Slider>
//     </div>
//   </section>
//   );
// }
