import axios from "axios";
import { toast } from "react-toastify";
import {
  FIND_ALL_SUBCATEGORY_FAIL,
  FIND_ALL_SUBCATEGORY_REQUEST,
  FIND_ALL_SUBCATEGORY_SUCCESS,
  FIND_ONE_SUBCATEGORY_FAIL,
  FIND_ONE_SUBCATEGORY_REQUEST,
  FIND_ONE_SUBCATEGORY_SUCCESS,
  FIND_ONE_SUBCATEGORY_FAIL2,
  FIND_ONE_SUBCATEGORY_REQUEST2,
  FIND_ONE_SUBCATEGORY_SUCCESS2,
} from "../constants/SubCategoryConstant";
// Find All Vehicle OWNER
const loadAllSubCategory = (id) => {
  return function (dispatch) {
    dispatch({
      type: FIND_ALL_SUBCATEGORY_REQUEST,
      payload: true,
    });
    // const token = JSON.parse(localStorage.getItem("jwt"))
    //   ? JSON.parse(localStorage.getItem("jwt"))
    //   : "";
    let OPTION = {
      //url: `${process.env.REACT_APP_API_PATH}/api/v1/user-subcategory-catid/${id}/`,
      url: `${process.env.REACT_APP_API_PATH}/api/v1/user-subcategory-catid-n/${id}/`,
      method: "GET",
      headers: {
        "content-type": "application/json",
        // token: token,
      },
    };
    axios(OPTION)
      .then((res) => {
        dispatch(loadAllSubCategoryPre(res.data));
      })
      .catch((error) => {
        dispatch({
          type: FIND_ALL_SUBCATEGORY_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
      });
  };
};
export const loadAllSubCategoryPre = (data) => {
  return {
    type: FIND_ALL_SUBCATEGORY_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};
// Find Single Vehicle
const loadFullSubcategory1 = (id, result) => {
  return function (dispatch) {
    dispatch({
      type: FIND_ONE_SUBCATEGORY_REQUEST,
      payload: true,
    });
    // const token = JSON.parse(localStorage.getItem("jwt"))
    //   ? JSON.parse(localStorage.getItem("jwt"))
    //   : "";
    let OPTION = {
      // url: `${process.env.REACT_APP_API_PATH}/api/v1/user-subcategory-catid/63/`,
      url: `${process.env.REACT_APP_API_PATH}/api/v1/admin-all-category/`,
      method: "GET",
      headers: {
        "content-type": "application/json",
        // token: token,
      },
    };
    axios(OPTION)
      .then((res) => {
        dispatch(loadFullSubcategoryPre1(res.data));
      })
      .catch((error) => {
        dispatch({
          type: FIND_ONE_SUBCATEGORY_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
      });
  };
};
export const loadFullSubcategoryPre1 = (data) => {
  return {
    type: FIND_ONE_SUBCATEGORY_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};
const loadFullSubcategory2 = (id, result) => {
  return function (dispatch) {
    dispatch({
      type: FIND_ONE_SUBCATEGORY_REQUEST2,
      payload: true,
    });
    // const token = JSON.parse(localStorage.getItem("jwt"))
    //   ? JSON.parse(localStorage.getItem("jwt"))
    //   : "";
    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/user-subcategory-catid/` + id,
      method: "GET",
      headers: {
        "content-type": "application/json",
        // token: token,
      },
    };
    axios(OPTION)
      .then((res) => {
        dispatch(loadFullSubcategoryPre2(res.data));
      })
      .catch((error) => {
        dispatch({
          type: FIND_ONE_SUBCATEGORY_FAIL2,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
      });
  };
};
export const loadFullSubcategoryPre2 = (data) => {
  return {
    type: FIND_ONE_SUBCATEGORY_SUCCESS2,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

export { loadAllSubCategory, loadFullSubcategory1, loadFullSubcategory2 };
