import React from "react";

import { Link } from "react-router-dom";

export default function SubMenuAbout({ showChange }) {
  return (
    <div className="about_us_menu sub_menu aboutmenudiv">
      <div className="container inner_menu">
        <ul className="submenu_main">
          <li>
            <h3 className="submenu_heading">Company Information</h3>
            <ul className="items">
              <li>
                <Link to="/company-information" onClick={showChange}>
                  About us
                </Link>
              </li>
              <li>
                <Link to="/blog" onClick={showChange}>
                  Blogs
                </Link>
              </li>
            </ul>
          </li>
          <li>
            <h3 className="submenu_heading">Business Opportunity</h3>
            <ul className="items">
              <li>
                <Link to="/enquiry" onClick={showChange}>
                  Enquire here
                </Link>
              </li>
            </ul>
          </li>
          <li>
            <h3 className="submenu_heading">Press</h3>
            <ul className="items">
              <li>
                <Link to="/news" onClick={showChange}>
                  News
                </Link>
              </li>
              <li>
                <Link to="/testimonial" onClick={showChange}>
                  Testimonials
                </Link>
              </li>
            </ul>
          </li>
          <li>
            <h3 className="submenu_heading">Careers</h3>
            <ul className="items">
              <li>
                <Link to="/apply-now" onClick={showChange}>
                  Apply Now
                </Link>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  );
}
