import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { loadAllStore, StoreFilter } from "../../redux/action/storeAction";
import Loader from "../Shared/Loader";
import Message from "../Shared/Message";
import { Link, useParams } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faPhone,
  faBuilding,
  faMapMarker,
} from "@fortawesome/fontawesome-free-solid";

export default function Location() {
  const [state, setState] = useState({
    statename: "",
    is_showroom: "",
  });

  const { statename, is_showroom } = state;

  const dispatch = useDispatch();

  // console.log("name", name);
  // console.log("is_showroom", is_showroom);

  const storeList = useSelector((state) => state.StoreList);

  const { loading, error, result, filter } = storeList;


  useEffect(() => {
    console.log('filter ', filter)
    let data = {};
    dispatch(StoreFilter(data));
    dispatch(loadAllStore());
    /*
    dispatch(loadAllStore());
    
    if (data) {
      dispatch(StoreFilter(data));
    }
    filterStoredata(); */
  }, [dispatch]);

  const filterStoredata = (statename, valuercv) => {

    console.log('...is_showroom', valuercv);
    var typefilter;
    var state;

    if (valuercv == "is_service_center") {
      typefilter = "is_showroom";
    }
    if (valuercv == "is_showroom") {
      typefilter = "is_service_center";
    }
    if (statename != "") {
      state = statename;
    }

    let data = {
      stateName: state,
      data: typefilter,
    };
    dispatch(StoreFilter(data));


    /*
    if (name !== "" && is_showroom === "") {
      let data = {
        stateName: name,
      };
      dispatch(StoreFilter(data));
      console.log("1");
    } else if (name === "" && is_showroom !== "") {
      let data = {
        data: is_showroom,
      };
      dispatch(StoreFilter(data));
      console.log("2");
    } else if (name !== "" && is_showroom !== "") {
      let data = {
        stateName: name,
        data: is_showroom,
      };
      dispatch(StoreFilter(data));
      // console.log("3");
    } */
  };

  const handleInputChange = (e) => {
    let { name, value } = e.target;
    setState({ ...state, [name]: value });
    // alert(value)
    filterStoredata(statename, value);
  };

  const handleInputChangestate = (e) => {
    let { name, value } = e.target;
    setState({ ...state, [name]: value });
    // alert(value)
    filterStoredata(value, is_showroom);
  };

  return (
    <>
      <section className="section store_search">
        <div className="container">
          <form>
            <div className="select_store">
              <div className="store_field">
                <select
                  name="is_showroom"
                  onChange={handleInputChange}
                  value={is_showroom}
                >
                  <option value="">All</option>
                  <option value="is_showroom">Showrooms</option>
                  <option value="is_service_center">Retailers</option>
                </select>
              </div>


              <div className="store_field">
                <select
                  name="statename"
                  onChange={handleInputChangestate}
                  value={statename || ""}
                >
                  <option value="">All Location</option>
                  {result.map((item, i) => (
                    <option key={i} value={item.stateName}>
                      {item.stateName}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </form>
        </div>
      </section>
      <section className="section location" style={{ background: "#fff" }}>
        <div className="container">
          {loading ? (
            <Loader />
          ) : error ? (
            <Message variant="danger">{error}</Message>
          ) : (
            <ul>
              {filter.map((item, i) => (
                <li key={i}>
                  <div className="addressBox">
                    <div className="top_title">
                      <Link to={`/storedetails/${item.id}`} className="anchortext">

                        <h3>{item.store_name}</h3>
                      </Link>
                      <div className="font_icon">
                        <button
                          onClick={(e) =>
                            window.open("mailto:contact@puresight.co.in")
                          // (window.location =
                          //   `mailto:${item.store_incharge_email}`)
                          }
                        >
                          <FontAwesomeIcon icon={faEnvelope} />
                        </button>
                        <button
                          onClick={(e) =>
                            (window.location = `tel:${item.store_incharge_mobile}`)
                          }
                        >
                          <FontAwesomeIcon icon={faPhone} />
                        </button>
                      </div>
                    </div>
                    <div className="bottom_address">
                      <Link to={`/storedetails/${item.id}`} className="anchortext">

                        <p>
                          {item.address +
                            ", " +
                            item.landmark +
                            ", " +
                            item.city +
                            ", " +
                            item.stateName +
                            ", " +
                            item.postCode}
                        </p>
                      </Link>
                    </div>
                    <div className="footer_address">
                      <div className="shop_desc">
                        {item.is_showroom === true &&
                          item.is_service_center === true ? (
                          <>
                            <p>
                              <span>ShowRoom</span>
                              <FontAwesomeIcon icon={faBuilding} />
                            </p>
                            <p>
                              <span>ServiceCenter </span>
                              <FontAwesomeIcon icon={faBuilding} />
                            </p>
                          </>
                        ) : item.is_showroom === true &&
                          item.is_service_center === false ? (
                          <>
                            <p>
                              <span>ShowRoom</span>
                              <FontAwesomeIcon icon={faBuilding} />
                            </p>
                          </>
                        ) : (
                          <>
                            <p>
                              <span>ServiceCenter </span>
                              <FontAwesomeIcon icon={faBuilding} />
                            </p>
                          </>
                        )}
                      </div>
                      <div>
                      <Link to={`/storedetails/${item.id}`} className="anchortext">

                        <FontAwesomeIcon icon={faMapMarker} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
      </section>
    </>
  );
}
