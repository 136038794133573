export const FIND_ALL_SUBCATEGORY_REQUEST = "FIND_ALL_SUBCATEGORY_REQUEST";
export const FIND_ALL_SUBCATEGORY_SUCCESS = "FIND_ALL_SUBCATEGORY_SUCCESS";
export const FIND_ALL_SUBCATEGORY_FAIL = "FIND_ALL_SUBCATEGORY_FAIL";

export const FIND_ONE_SUBCATEGORY_REQUEST = "FIND_ONE_SUBCATEGORY_REQUEST";
export const FIND_ONE_SUBCATEGORY_SUCCESS = "FIND_ONE_SUBCATEGORY_SUCCESS";
export const FIND_ONE_SUBCATEGORY_FAIL = "FIND_ONE_SUBCATEGORY_FAIL";

export const FIND_ONE_SUBCATEGORY_REQUEST2 = "FIND_ONE_SUBCATEGORY_REQUEST2";
export const FIND_ONE_SUBCATEGORY_SUCCESS2 = "FIND_ONE_SUBCATEGORY_SUCCESS2";
export const FIND_ONE_SUBCATEGORY_FAIL2 = "FIND_ONE_SUBCATEGORY_FAIL2";
