import React, { useEffect } from "react";
import MainAccesories from "../component/accesories/MainAccesories";

export default function Accesories() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <MainAccesories />
    </div>
  );
}
