// constant files

export const ADD_DELIVERY_ADDRESS_REQUEST = "ADD_DELIVERY_ADDRESS_REQUEST";
export const ADD_DELIVERY_ADDRESS_SUCCESS = "ADD_DELIVERY_ADDRESS_SUCCESS";
export const ADD_DELIVERY_ADDRESS_FAIL = "ADD_DELIVERY_ADDRESS_FAIL";

export const UPDATE_DELIVERY_ADDRESS_REQUEST = "UPDATE_DELIVERY_ADDRESS_REQUEST";
export const UPDATE_DELIVERY_ADDRESS_SUCCESS = "UPDATE_DELIVERY_ADDRESS_SUCCESS";
export const UPDATE_DELIVERY_ADDRESS_FAIL = "UPDATE_DELIVERY_ADDRESS_FAIL";

export const DELETE_DELIVERY_ADDRESS_REQUEST = "DELETE_DELIVERY_ADDRESS_REQUEST";
export const DELETE_DELIVERY_ADDRESS_SUCCESS = "DELETE_DELIVERY_ADDRESS_SUCCESS";
export const DELETE_DELIVERY_ADDRESS_FAIL = "DELETE_DELIVERY_ADDRESS_FAIL";

export const GET_ALL_DELIVERY_ADDRESS_REQUEST = "GET_ALL_DELIVERY_ADDRESS_REQUEST";
export const GET_ALL_DELIVERY_ADDRESS_SUCCESS = "GET_ALL_DELIVERY_ADDRESS_SUCCESS";
export const GET_ALL_DELIVERY_ADDRESS_FAIL = "GET_ALL_DELIVERY_ADDRESS_FAIL";

export const GET_SINGLE_DELIVERY_ADDRESS_REQUEST = "GET_SINGLE_DELIVERY_ADDRESS_REQUEST";
export const GET_SINGLE_DELIVERY_ADDRESS_SUCCESS = "GET_SINGLE_DELIVERY_ADDRESS_SUCCESS";
export const GET_SINGLE_DELIVERY_ADDRESS_FAIL = "GET_SINGLE_DELIVERY_ADDRESS_FAIL";
