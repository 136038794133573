import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createCareer } from "../../redux/action/AdditionalAction";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import spinner1 from "../../image/spinner1.gif";
import ReCAPTCHA from "react-google-recaptcha";


export default function ApplyNowForm() {

  const queryParams = new URLSearchParams(window.location.search);

  const [utm_source, setutm_source] = useState('');
  const [utm_campaign, setutm_campaign] = useState('');

  var utm_sourcercv = queryParams.get('utm_source');
  var utm_campaignrcv = queryParams.get('utm_campaign');

  useEffect(() => {
    if (utm_sourcercv != null && utm_sourcercv != '' && utm_sourcercv != undefined) {

      setutm_source(utm_sourcercv);
      setutm_campaign(utm_campaignrcv);
      localStorage.setItem('utm_source', utm_sourcercv);
      localStorage.setItem('utm_campaign', utm_campaignrcv);
    }

    else {
      var item1 = localStorage.getItem('utm_source');
      var item2 = localStorage.getItem('utm_campaign');
      if (item1 != null && item1 != undefined && item1 != '') {
        setutm_source(item1);
        setutm_campaign(item2);
      }

    }
  }, []);


  const [resume, setResume] = useState([]);
  const [state, setState] = useState({
    name: "",
    email: "",
    mobile: "",
    jobs: "",
    location: "",
  });

  const { name, email, mobile, jobs, location } = state;

  const handleInputChange = (e) => {
    let { name, value } = e.target;
    setState({ ...state, [name]: value });
  };


  const dispatch = useDispatch();
  const navigate = useNavigate();


  const { response, loading } = useSelector((state) => state.Additional);

  useEffect(() => {

    if (Object.keys(response).length > 0) {

      console.log('Object is NOT empty');

      //navigate('/thanks-you', { state: { messagefor: "contact", heading: "", message: "Thank you for your application. We will contact you soon to schedule your interview" } });
      navigate('/thank-you-apply', { state: { messagefor: "contact", heading: "", message: "Thank you for your application. We will contact you soon to schedule your interview" } });

    }

  }, [response]);

  const [file, setImage] = useState({ preview: "", raw: "" });
  const handleChange = (e) => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (captchaResult == undefined || captchaResult == '' || captchaResult == null) {
      toast.warning("Invalid Captcha");

      //return false;

    }
    //console.log('file.raw', )
    if (file.raw && email && mobile && name) {
      let formData = new FormData();
      formData.append("resume", file.raw);
      formData.append("name", name);
      formData.append("email", email);
      formData.append("mobile", mobile);
      formData.append("jobs", jobs);
      //formData.append("location", location);
      formData.append("location", "BG");

      formData.append("source", utm_source);
      formData.append("campaign", utm_campaign);

      dispatch(createCareer(formData));

      //navigate("/");
    } else {
      toast.error("Please input all input Field!");
    }
  };
  const [captchaResult, setCaptchaResult] = useState();

  const key = process.env.REACT_APP_SITE_KEY;
  const url = process.env.REACT_APP_SITE_URL;

  const handleRecaptcha = (value) => {
    setCaptchaResult(value)
    /*
    fetch(url, {
      method: "POST",
      body: JSON.stringify({ captcha_value: value }),
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.captcha.success);
        setCaptchaResult(data.captcha.success);
      });
      */
  };
  return (
    <section
      className="section enquiry apply_now"
      style={{ background: "#fff" }}
    >
      {loading ? (<div id="loading">
        <img id="loading-image" src={spinner1} alt="Loading..." />
      </div>) : ""}

      <ToastContainer />
      <div className="container">
        <p className="subheading">
          Are you willing to work for the leading consumer robot company? Do you
          have it in you to sell the award-winning robots? Then you are in the
          right place.
        </p>
        <p className="subheading">
          Become a product specialist with iRobot by filling out the form below.
        </p>

        <div className=" Bsns-Oppor-form">
          <div className=" form text-black">
            <form

              autoComplete="off"
              onSubmit={handleSubmit}
              encType="multipart/form-data"
            >
              <div className=" py-2">
                <label className="label-sm ">
                  Name <span className="text-danger">*</span>
                </label>
                <input
                  required
                  type="text"
                  placeholder=""
                  name="name"
                  value={name}
                  onChange={handleInputChange}
                />
              </div>

              <div className=" py-2">
                <label className="label-sm ">
                  Email <span className="text-danger">*</span>
                </label>
                <input
                  required
                  type="email"
                  placeholder=""
                  name="email"
                  value={email}
                  onChange={handleInputChange}
                />
              </div>

              <div className=" py-2">
                <label className="label-sm ">
                  Mobile <span className="text-danger">*</span>
                </label>
                <input
                  required
                  type="number"
                  minLength="10" 
                  maxLength="10"
                  placeholder=""
                  className=" form-control"
                  name="mobile"
                  value={mobile}
                  //onChange={handleInputChange}
                  onChange={(e) => {
                    if (e.target.value.length < 11) {
                      handleInputChange(e);
                    }
                  }}
                />
              </div>

              <div className=" py-2">
                <label className="label-sm ">
                  Jobs <span className="text-danger">*</span>
                </label>
                <select
                  required
                  className="form-control"
                  name="jobs"
                  value={jobs}
                  onChange={handleInputChange}

                >
                  <option>Select</option>
                  <option value="Sales Executive">Sales Executive</option>
                  <option value="Tele-Caller">Tele-Caller</option>
                </select>
              </div>


              {/* <div className="py-2">
                <label className="label-sm ">
                  Location <span className="text-danger">*</span>
                </label>
                <select
                  required
                  className="form-control"
                  name="location"
                  value={location}
                  onChange={handleInputChange}
                >
                  <option>Select</option>
                  <option value="Ahmedabad">Ahmedabad</option>
                  <option value="Bangalore">Bangalore</option>
                  <option value="Chennai">Chennai</option>
                  <option value="Coimbatore">Coimbatore</option>
                  <option value="New Delhi">New Delhi</option>
                </select>
              </div> */}

              <div className=" py-2">
                <label className="label-sm ">
                  Resume <span className="text-danger">*</span>
                </label>

                <input type="file" name="file" onChange={handleChange} />

              </div>
              <div className="py-2 checkboxx">


                <ReCAPTCHA
                  sitekey={key}
                  onChange={handleRecaptcha}
                  className="captcha"
                />
              </div>
              <div className="py-2 checkboxx">
                <input type="checkbox" />
                <b>&nbsp; Opt-in me for promotional emails </b>

              </div>



              <div className="">
                <input
                  type="submit"
                  defaultValue="Submit"
                  className="btn btn-default"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}
