
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { loadAllOfferProductList } from "../../redux/action/ProductListingAction";
import { Link, useParams } from "react-router-dom";
import series_bundel_1 from "../../image/series_bundel_1.png";


export default function OfferMain() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadAllOfferProductList(id));
  }, []);

  const ProductOfferListing = useSelector((state) => state.ProdutcListing.resultoffer);
  //   console.log( "ProductOfferListing", ProductOfferListing)
  let { id } = useParams();

  var shopnow = [
    { id: 1, img: series_bundel_1, tag: "NEW", name: "Roomba® s9+", short_des: "Robot Vacuum", desc: "ur most advanced robots work together to vacuum then mop, and now come in a sleek new pair. A complete clean for corners, edges and the details in between.", regular: "£1,199.99", sale: "£799.99", save: "£400", percent: "33%", url: "#!" },
    { id: 2, img: series_bundel_1, tag: "ONLINE EXCLUSIVE", name: "Roomba® s9+, Braava jet® m6 & iRobot® H1", short_des: "Robot Vacuum, Robot Mop and Handheld Vacuum Bundle", desc: "ur most advanced robots work together to vacuum then mop, and now come in a sleek new pair. A complete clean for corners, edges and the details in between.", regular: "£1,199.99", sale: "£799.99", save: "£400", percent: "33%", url: "#!" },
    { id: 3, img: series_bundel_1, tag: "SAVE", name: "Online Exclusive Roomba® s9+ Robot Vacuum & Braava jet® m6 Robot Mop Bundle", short_des: "Robot Vacuum", desc: "ur most advanced robots work together to vacuum then mop, and now come in a sleek new pair. A complete clean for corners, edges and the details in between.", regular: "£1,199.99", sale: "£799.99", save: "£400", percent: "33%", url: "#!" },
  ]
  return (
    <section className="section shop_now main_offer" style={{ paddingBottom: "70px" }}>
      <div className="container">

        <h2 className="title">Shop the latest iRobot® deals</h2>
        <ul>

          {ProductOfferListing.filter(objrcv => objrcv.product_id.isdeleted == 0).map((offer) => {
            return (
              <li key={offer.id}>
                {offer.product_id.isdeleted == 0 ? (

                  <div className="shop_now_content">
                    <div className="product_main">
                      {offer.product_id.tag ? (
                        // <span className="savving">{offer.product_id.tag}</span>
                        ""
                      ) : (
                        " "
                      )}
                      <h3>{offer.product_id.name}</h3>
                      <p>{offer.product_id.subcategory.name}</p>
                    </div>
                    <div className="product_imagew">
                      <img
                        src={`${process.env.REACT_APP_API_PATH}${offer.product_id.image}`}
                      />
                    </div>
                    <div className="product_descripton">

                      <p>{offer.product_id.short_description}</p>
                      <div className="series_price">
                        <p className="regular">
                          {offer.product_id.price != null ? (
                            <strike>₹{offer.product_id.price}</strike>
                          ) : (
                            " "
                          )}

                          <span className="sale">
                            ₹{offer.product_id.sellprice}
                          </span>
                        </p>
                        {offer.product_id.price != null && offer.product_id.sellprice != null && offer.product_id.price != "" && offer.product_id.sellprice != "" && (offer.product_id.price - offer.product_id.sellprice) != 0 ? (

                          <p className="saving_price">
                            Save: ₹
                            {offer.product_id.price - offer.product_id.sellprice}{" "}
                            (
                            {Math.ceil(
                              (offer.product_id.price -
                                offer.product_id.sellprice) /
                              (offer.product_id.price / 100)
                            )}
                            %)
                          </p>
                        ) : (
                          " "
                        )}
                      </div>
                      <Link
                        to={`/product/${offer.product_id.slug}`}
                        className="btn shop_now"
                      >
                        Shop Now
                      </Link>
                    </div>
                  </div>
                ) : ("")}

              </li>

            );
          })}
        </ul>
      </div>
    </section>
  )
}
