import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FaChevronCircleRight } from "react-icons/fa";
import {
  loadFullSubcategory1,
  loadFullSubcategory2,
} from "../../redux/action/SubCategoryAction";
import { Link, useParams } from "react-router-dom";
export default function SubMenuProduct({ showChange }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadFullSubcategory1());
    // dispatch(loadFullSubcategory2());
  }, []);

  const subcategory1 = useSelector((state) => state.SubCategory.singledata);
  // const subcategory2 = useSelector((state) => state.SubCategory.singledata2);
  // const subcategory1 = useSelector((state) => state.SubCategory.singledata);
  // console.log("subcategory1",subcategory1)

  let { id } = useParams();
  console.log("hkjhjhh");

  return (
    <div className="acceries_menu sub_menu  productmenudiv" >
      <div className="container inner_menu">
        <ul className="submenu_main product-submenu">
          {subcategory1 &&
            subcategory1.map((acc, i) => {
              //let y = "/series/" + acc.id;
              let y = "/series/" + acc.slug;

              return (
                <li key={acc.id}>
                  <Link
                    // onClick={() => {
                    //   window.location.href = '#/series/' + acc.id;
                    // }}
                    to={y}
                    class="a-link"
                    onClick={showChange}
                  >
                    <h3 className="a-inlinetext a-inlinetext--black">{acc.category_name}</h3>
                    <h3 class="a-inlinetext a-inlinetext--green">
                      {acc.alias}
                      <FaChevronCircleRight className="circlearroy" />
                    </h3>
                  </Link>
                  <ul className="sub-category-menu">
                    {acc.subdata !== null ? (
                      <>
                        {acc.subdata.map((item, i) => {
                          let z = "/category/" + item.slug;
                          return (
                            <li key={item.id}>
                              <Link to={z} onClick={showChange}>
                                <img
                                  src={`${process.env.REACT_APP_API_PATH}${item.image}`}
                                />
                                <span className="subcategorymenu">
                                  {item.name}
                                </span>
                                <span>{item.price_range}</span>
                              </Link>
                            </li>
                          );
                        })}
                      </>
                    ) : (
                      <></>
                    )}
                  </ul>
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
}
