import React, { useEffect } from "react";
import { Link } from "react-router-dom";

export default function CheckOut() {

  useEffect(() => {
    document.getElementById("maintittle").innerHTML = "Checkout - Shipping details";
    document.getElementById("description").setAttribute("content", "Checkout - Enter your shipping address details and shipping method.");

    window.scrollTo(0, 0);
  }, []);

  return (
    <section className="section checkout_page">
      <div className="container">
        <div className="left_data">
          <div className="guest_checkout">
            <h2 className="title">
              <span>Guests or New Customers</span>
            </h2>
            <p>
              You can checkout without creating an account.
              <br /> You'll have a chance to create an account later.
            </p>
            <Link to="/shipping" className="btn">
              Checkout as a Guest
            </Link>
            <p>
              Creating an Account is easy. Just fill in the form
              <br /> below and enjoy the benefits of having an account
            </p>
            <Link to="/register" className="btn">
              Create an Account Now
            </Link>
          </div>
        </div>
        <div className="right_data">
          <h2 className="title">
            <span>Returning Customers</span>
          </h2>
          <p>
            If you are a registered user, please enter your email and password.
          </p>
          <div className="account_form login">
            <div className="acc_form">
              <div className="input_field">
                <label>
                  Email address <span>*</span>
                </label>
                <input type="email" />
              </div>
              <div className="input_field">
                <label>
                  Password <span>*</span>
                </label>
                <input type="password" />
              </div>
              <div className="input_field_btn">
                <input type="submit" defaultValue="Log in" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
