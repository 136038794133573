import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useSelector, useDispatch } from "react-redux";
import { loadAllSubCategory } from "../../redux/action/SubCategoryAction";
import { Link, useParams } from "react-router-dom";

export default function Series_section() {
  const dispatch = useDispatch();
  const [catName, setCatname] = useState("");
  const [subcategory, setsubcategory] = useState([]);

  const subcategoryrcv = useSelector((state) => state.SubCategory.result);


  let { id } = useParams();
  //let subcategory = [];

  useEffect(() => {

    if (subcategoryrcv != null) {
      //subcategory = subcategoryrcv;
      setsubcategory(subcategoryrcv);
    }

  });

  useEffect(() => {
    dispatch(loadAllSubCategory(id));
    setCatname(subcategory[0]?.catid.alias);
  }, id);
  //[subcategory, id]);


  useEffect(() => {

    if (subcategory.length > 0) {
      console.log('subcategory..', subcategory[0]?.catid.seo_tittle)
      //var element = document.getElementById("sertires");
      document.getElementById("maintittle").innerHTML = subcategory[0]?.catid.seo_tittle;
      //document.getElementById('mylabel').setAttribute('title', myFunction())
    }

  }, [subcategory]);


  var slider = {
    dots: false,
    arrows: true,
    infinite: true,
    // speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    // fade: true,
    // cssEase: "linear",
    autoplay: false,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    // <section className="section ">
    <section className="section products_cat">
      <div className="container">


        {subcategory.slice(0, 1).map((series) => {
          return (
            <>
              <h3 className="title" key={series.id}>
                <span className="catheadblack"> {series.catid.name} </span>
                <span> {series.catid.alias} </span>
              </h3>
              <h2 className="serieshtext">
                <span className="catsubheadgreen"> {series.catid.tag} </span>
              </h2>
            </>
          );
        })}

        <div className="desckproduct">

          {/* {subcategory.length} */}
          {subcategory.length && subcategory.length < 5 ? (
            <>
              <ul className="cat_ul">
                {subcategory.map((series) => {
                  return (
                    <li key={series.id}>
                      <div className="product_show">
                        {/* <div className=""> */}
                        <div className="product_title">
                          <h3>{series.short_description}</h3>
                        </div>
                        <Link to={`/category/${series.slug}`}>
                          <div className="product_image">
                            <img
                              src={process.env.REACT_APP_API_PATH + series.image}
                              alt={series.name}
                            />
                          </div>
                          <div className="product_series">
                            <span className="savving">{series.tag}</span>
                            <h3>{series.name}</h3>
                            <span>{series.price_range}</span>
                          </div>
                        </Link>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </>
          ) : (
            <ul>
              <Slider {...slider}>
                {subcategory.map((series) => {
                  return (
                    <li key={series.id}>
                      <div className="product_show">
                        {/* <div className=""> */}
                        <div className="product_title">
                          <h3>{series.short_description}</h3>
                        </div>
                        <Link to={`/category/${series.slug}`}>
                          <div className="product_image">
                            <img
                              src={process.env.REACT_APP_API_PATH + series.image}
                              alt={series.name}
                            />
                          </div>
                          <div className="product_series">
                            <span className="savving">{series.tag}</span>
                            <h3>{series.name}</h3>
                            <span>{series.price_range}</span>
                          </div>
                        </Link>
                      </div>
                    </li>
                  );
                })}
              </Slider>
            </ul>
          )}
        </div>

        <div className="mobileproduct">


          <ul>

            {subcategory.map((series) => {
              return (
                <li key={series.id}>
                  <div className="product_show">
                    {/* <div className=""> */}
                    <div className="product_title">
                      <h3>{series.short_description}</h3>
                    </div>
                    <Link to={`/category/${series.slug}`}>
                      <div className="product_image">
                        <img
                          src={process.env.REACT_APP_API_PATH + series.image}
                          alt={series.name}
                        />
                      </div>
                      <div className="product_series">
                        <span className="savving">{series.tag}</span>
                        <h3>{series.name}</h3>
                        <span>{series.price_range}</span>
                      </div>
                    </Link>
                  </div>
                </li>
              );
            })}
          </ul>

        </div>
      </div>
    </section>
  );
}
