import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadAllAccesssories } from "../../redux/action/AccessoriesAction";
import { Link, useParams } from "react-router-dom";

export default function SubMenuAccesories({ showChange }) {

  const [accessories, setAccessories] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadAllAccesssories());

  }, []);

  const Accessories = useSelector((state) => state.MainAccessories.result.data);
  // console.log("Accessories",Accessories)

  return (
    <div className="acceries_menu sub_menu acceceriesmenudiv">
      <div className="container inner_menu">
        <ul className="submenu_main">
          <li>
            <ul className="items">
              {/* {Accessories.map((acce)=>{
                return(
                  <li key={acce.id}> <Link to="/accessories"><img src={process.env.REACT_APP_API_PATH  + acce.image}/><span>{acce.name}</span></Link></li>
                )
              })} */}
              {Accessories &&
                Accessories.map((acc, i) => {
                  return (
                    <li key={acc.id}>
                      <Link to={`/accessories/${acc.slug}`} onClick={showChange}>
                        <img src={`${process.env.REACT_APP_API_PATH}${acc.image}`} />
                        <span>{acc.name}</span>
                      </Link>
                    </li>
                  );
                })}
              {/* <li><Link to="/accessories"><img src={acc_1}/><span>Roomba Accessories</span></Link></li>
            <li><Link to="/accessories"><img src={acc_2}/><span>Braava Accessories</span></Link></li> */}
            </ul>
          </li>
        </ul>
      </div>
    </div>
  );
}
