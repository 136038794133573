import React from 'react'
import thank from "../../image/thank-you.png"
const AllthankU = () => {
  return (
    <>
      <div className="bg-light">
        <div className="shadow bg-white">
          <div className="border_thank ">
            <img src={thank} className="img-fluid mt-t" />
          </div>
          {/* <hr /> */}

          <h4 className="font-weight-bolder h4-small-title">
            Thank you for enquiring with us.
            <br />
            We will get back to you shortly.
          </h4>

          {/* 					
				<div className="">
					<div className=" text-center">
						<a href=""  target="_blank"><i class="fab fa-facebook-f"></i></a>
						<a href=""  target="_blank"><i class="fa fa-instagram" ></i></a>
						<a href=""  target="_blank"><i class="fa fa-twitter h2"></i></a>
						<a href=""  target="_blank"><i class="fa fa-youtube" ></i></a>
						<a href=""  target="_blank"><i class="fa fa-linkedin" ></i></a>
					</div>
				</div> */}
          <div className="col-12 pb-3">
            <a href="/" className="text-button">
              <i class="fa fa-home" aria-hidden="true"></i> Home
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default AllthankU