import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import {
  updateUserAddress,
  loadSingleUserAddress,
} from "../../redux/action/deliveryAddressAction";

export default function EditShippingAddress() {
  const [email, setEmail] = useState("");
  const [state, setState] = useState({
    house: "",
    sector: "",
    address: "",
    city: "",
    stateName: "",
    street: "",
    district: "",
    landmark: "",
    country: "",
    postCode: "",
    firstname: "",
    lastname: "",
    mobile: "",
  });

  const {
    mobile,
    lastname,
    firstname,
    postCode,
    country,
    landmark,
    district,
    street,
    city,
    house,
    sector,
    address,
    stateName,
  } = state;

  const handleInputChange = (e) => {
    let { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const dispatch = useDispatch();
  let user = useSelector((state) => state.AuthReducer.userResult);
  var user_id = user.id;

  let navigate = useNavigate();

  let { id } = useParams();

  useEffect(() => {
    if (id !== null) {
      dispatch(loadSingleUserAddress(id));
    }
  }, [dispatch, id]);

  const userAddress = useSelector((state) => state.DeliveryAddress);

  const { singledata } = userAddress;

  useEffect(() => {
    if (singledata) {
      setState({ ...singledata });
      // setEmail(singledata.user_id.email);
    }
  }, [singledata]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!address && !mobile && !postCode) {
      toast.warning("Please input all input Field!");
    } else {
      dispatch(
        updateUserAddress(
          id,
          user_id,
          email,
          firstname,
          lastname,
          mobile,
          address,
          house,
          sector,
          street,
          district,
          city,
          landmark,
          stateName,
          country,
          postCode
        )
      );
      navigate(-1);
    }

  };

  return (
    <div className="container">
      <ToastContainer />
      <div className="edit-shipping-address">
        <div className=" Bsns-Oppor-form">
          <div className="">
            <h2 className="title">
              <span>Edit Address</span>
            </h2>

            <div className=" form text-black">
              <form autoComplete="off" onSubmit={handleSubmit}>
                <div className="full_fo py-2 py-1">
                  <label className="label-sm ">
                    Email Address <span className="text-danger">*</span>
                  </label>
                  <input
                    required
                    type="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <p>
                    <small>
                      Your email will be used to send your order confirmation.
                    </small>
                  </p>
                  <div className="full_fo py-2 checkboxx">
                    <input type="checkbox" />

                    <b>
                      &nbsp; Please add me to the iRobot email list. iRobot does not sell personal info. See{" "} <Link to="/privacy-policy">Privacy Policy</Link>
                    </b>
                  </div>
                </div>

                <div className="full_fo py-2">
                  <label className="label-sm ">
                    Receivers Name <span className="text-danger">*</span>
                  </label>
                  <input
                    required
                    type="text"
                    name="firstname"
                    value={firstname}
                    onChange={handleInputChange}
                  />
                </div>

                {/* <div className="full_fo py-2">
                  <label className="label-sm ">
                    Last Name <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="lastname"
                    value={lastname}
                    onChange={handleInputChange}
                  />
                </div> */}

                <div className="full_fo py-2">
                  <label className="label-sm ">
                    Alternate Number <span className="text-danger">*</span>
                  </label>
                  <input
                    required
                    type="number"
                    name="mobile"
                    value={mobile}
                    minLength="10" 
                    maxLength="10"
                    onChange={handleInputChange}
                  />
                </div>

                <div className="full_fo py-2">
                  <label className="label-sm ">
                    Address 1 <span className="text-danger">*</span>
                  </label>
                  <input
                    required
                    type="text"
                    name="address"
                    value={address}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="full_fo py-2">
                  <label className="label-sm ">
                    Address 2<span className="text-danger">*</span>
                  </label>
                  <input
                    required
                    type="text"
                    name="house"
                    value={house}
                    onChange={handleInputChange}
                  />
                </div>

                {/* <div className="full_fo py-2">
                  <label className="label-sm ">
                    Sector <span className="text-danger">*</span>
                  </label>
                  <input
                    required
                    type="text"
                    name="sector"
                    value={sector}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="full_fo py-2">
                  <label className="label-sm ">
                    Street <span className="text-danger">*</span>
                  </label>
                  <input
                    required
                    type="text"
                    name="street"
                    value={street}
                    onChange={handleInputChange}
                  />
                </div> */}

                {/* <div className="full_fo py-2">
                  <label className="label-sm ">
                    District <span className="text-danger">*</span>
                  </label>
                  <input
                    required
                    type="text"
                    name="district"
                    value={district}
                    onChange={handleInputChange}
                  />
                </div> */}

                <div className="full_fo py-2">
                  <label className="label-sm ">
                    City <span className="text-danger">*</span>
                  </label>
                  <input
                    required
                    type="text"
                    name="city"
                    value={city}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="full_fo py-2">
                  <label className="label-sm ">
                    Landmark <span className="text-danger">*</span>
                  </label>
                  <input
                    required
                    type="text"
                    name="landmark"
                    value={landmark}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="full_fo py-2">
                  <label className="label-sm ">
                    State <span className="text-danger">*</span>
                  </label>
                  <input
                    required
                    type="text"
                    name="stateName"
                    value={stateName}
                    onChange={handleInputChange}
                  />
                </div>

                {/* <div className="full_fo py-2">
                  <label className="label-sm ">
                    Country <span className="text-danger">*</span>
                  </label>
                  <input
                    required
                    type="text"
                    name="country"
                    value={country}
                    onChange={handleInputChange}
                  />
                </div> */}

                <div className="full_fo py-2">
                  <label className="label-sm ">
                    Pincode <span className="text-danger">*</span>
                  </label>
                  <input
                    required
                    type="number"
                    name="postCode"
                    value={postCode}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="py-3">
                  <input
                    type="submit"
                    defaultValue="Submit"
                    className="btn btn-default"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
