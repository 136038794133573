// constant files

export const ADD_CART_REQUEST = "ADD_CART_REQUEST";
export const ADD_CART_SUCCESS = "ADD_CART_SUCCESS";
export const ADD_CART_FAIL = "ADD_CART_FAIL";

export const UPDATE_CART_REQUEST = "UPDATE_CART_REQUEST";
export const UPDATE_CART_SUCCESS = "UPDATE_CART_SUCCESS";
export const UPDATE_CART_FAIL = "UPDATE_CART_FAIL";

export const DELETE_CART_REQUEST = "DELETE_CART_REQUEST";
export const DELETE_CART_SUCCESS = "DELETE_CART_SUCCESS";
export const DELETE_CART_FAIL = "DELETE_CART_FAIL";

export const GET_ALL_CART_REQUEST = "GET_ALL_CART_REQUEST";
export const GET_ALL_CART_SUCCESS = "GET_ALL_CART_SUCCESS";
export const GET_ALL_CART_FAIL = "GET_ALL_CART_FAIL";

export const GET_SINGLE_CART_REQUEST = "GET_SINGLE_CART_REQUEST";
export const GET_SINGLE_CART_SUCCESS = "GET_SINGLE_CART_SUCCESS";
export const GET_SINGLE_CART_FAIL = "GET_SINGLE_CART_FAIL";
