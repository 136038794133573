import React, { useState, useEffect, useRef } from "react";
import Inner_banner from "../component/category/InnerBanner";
import SeriesBundel from "../component/category/SeriesBundel";
// import ShopNow from '../component/category/ShopNow';
import FixedMenu from "../component/category/FixedMenu";
import FixedBanner from "../component/category/FixedBanner";
// import Zigzag from "../component/category/Zigzag";
// import Tabbing from "../component/category/Tabbing";
import Series_tab1 from "../component/category/Series_tab1";
import Fixed_banner from "../component/home/Fixed_banner";
import Satisfaction from "../component/home/Satisfaction";

export default function Category() {
  const [navheight, setNavheight] = useState(0);
  const [banneridglobe, setbanneridglobe] = useState(0);

  const ref = useRef();
  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {

    console.log("....banner id cat..", banneridglobe);
  })
  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(setNavheight(ref.current.clientHeight), 5000);

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      <div
        ref={ref}
        className={
          scrollPosition > 600
            ? "active-fixed"
            : scrollPosition > 430
              ? "active-fixed-tab"
              : scrollPosition > 310
                ? "active-fixed-tab-rotate"
                : scrollPosition > 210
                  ? "active-fixed-tab-mobile"
                  : ""
        }
      >
        <Inner_banner setbanneridglobe={setbanneridglobe} />

        {/* {scrollPosition} */}
        <FixedMenu banneridglobe={banneridglobe} />
      </div>
    </div>
  );
}
