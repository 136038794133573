import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RegisterNewRobot } from "../redux/action/RegisterRobotAction";
import { loadAllMainProductList } from "../redux/action/ProductAction";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReCAPTCHA from "react-google-recaptcha";
import Loader from "../component/Shared/Loader";
import Message from "../component/share/Message";


const RegisterRobot = () => {
  const key = process.env.REACT_APP_SITE_KEY;
  const url = process.env.REACT_APP_SITE_URL;

  useEffect(() => {
    document.getElementById("maintittle").innerHTML = "Warranty Registration - iRobot India.";
    document.getElementById("description").setAttribute("content", "Congratulations on the purchase or your new iRobot product. iRobot is committed to providing you with outstanding customer support. To register your new product, please fill in this form. By registering your robots today you are taking the first step towards receiving faster and more effective service should you ever need our assistance in the future.");

    window.scrollTo(0, 0);
  }, []);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  let user = useSelector((state) => state.AuthReducer);
  let { isSigninIn, userResult } = user;
  let user_id = userResult.id;

  useEffect(() => {
    if (isSigninIn !== true) {
      navigate("/login");
    }
    dispatch(loadAllMainProductList());
  }, [isSigninIn, dispatch]);

  let product = useSelector((state) => state.MainProduct);
  let { loading, error, allProducts } = product;

  const [state, setState] = useState({
    productId: "",
    userId: "",
    serial_no: "",
    purchase_date: "",
    seller_name: "",
  });

  const { productId, userId, serial_no, purchase_date, seller_name } = state;

  const handleInputChange = (e) => {
    let { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const [captchaResult, setCaptchaResult] = useState();

  const handleRecaptcha = (value) => {
    setCaptchaResult(value)
    /*
    fetch(url, {
      method: "POST",
      body: JSON.stringify({ captcha_value: value }),
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.captcha.success);
        setCaptchaResult(data.captcha.success);
      });
      */
  };

  const submitHandler = (e) => {


    e.preventDefault();
    if (captchaResult == undefined || captchaResult == '' || captchaResult == null) {
      toast.warning("Invalid Captcha");

      // return false;

    }
    if (productId !== null && seller_name !== "" && serial_no !== "" && purchase_date !== "") {
      let dataa = {
        productId: productId,
        userId: user_id,
        serial_no: serial_no,
        purchase_date: purchase_date,
        seller_name: seller_name,
      };
      dispatch(RegisterNewRobot(dataa));
      //navigate("/dashboard");
    } else {
      toast.error("All Fields Required");
    }
  };

  return (
    <div>
      <ToastContainer />
      <section className="section " style={{ background: "#fff" }}>
        <div className="container">
          <div className="account_form register">
            <div className="account_bt">
              <h2 className="account_title">Register New Robot</h2>
            </div>
            <div className="account_bt">
              <h5 className="account_title">Enter your Robot Information - For warranty support
              </h5>

            </div>


            <div className="acc_form">
              <form onSubmit={submitHandler}>
                {loading ? (
                  <Loader />
                ) : error ? (
                  <Message />
                ) : (
                  <>
                    <div className="input_field">
                      <label>
                        Select your product <span>*</span>
                      </label>
                      <select
                        required
                        name="productId"
                        value={productId}
                        onChange={handleInputChange}
                      >
                        <option value="">Select</option>
                        {allProducts &&
                          allProducts.map((item, i) => (

                            // item.type == 1 ?
                            //   (<option key={i} value={item.id}>
                            //     {item.name}
                            //   </option>) : ""
 
                              (<option key={i} value={item.id}>
                               {item.name}
                             </option>) 

                          ))}
                      </select>
                    </div>

                    <div className="input_field">
                      <label>
                        Enter your Serial Number<span>*</span>
                      </label>
                      <input
                        type="text"
                        placeholder=""
                        name="serial_no"
                        value={serial_no}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="input_field">
                      <label>
                        When did you purchase or receive your robot?
                        <span>*</span>
                      </label>
                      <input
                        type="date"
                        required
                        placeholder=""
                        name="purchase_date"
                        value={purchase_date}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="input_field">
                      <label>
                        Where did you buy it?<span>*</span>
                      </label>
                      <select
                        required
                        name="seller_name"
                        value={seller_name}
                        onChange={handleInputChange}
                      >
                        <option value="">Select</option>
                        {/* <option value="Access Store, Mumbai">
                          Access Store, Mumbai
                        </option> */}
                        <option value="Amazon India">Amazon</option>
                        <option value="Croma Retail">Croma Retail</option>
                        <option value="Flipkart">Flipkart</option>
                        <option value="iRobot Delhi, Noida Sector-18">
                          Reliance digital
                        </option>
                        <option value="iRobot Koramangala Showroom">
                          Exclusive showroom
                        </option>
                        {/* <option value="iRobot Mumbai, Santa Cruz West">
                          iRobot Mumbai, Santa Cruz West
                        </option> */}
                        <option value="iRobot.in">IRobot.in</option>
                        {/* <option value="Ishapani Center Showroom">
                          Ishapani Center Showroom
                        </option> */}
                        <option value="Others">Other reseller</option>
                        {/* <option value="Queens Road Showroom">
                          Queens Road Showroom
                        </option>
                        <option value="Reliance Digital (Online)">
                          Reliance Digital (Online)
                        </option> */}
                        {/* <option value="Sweet Melody">Sweet Melody</option>
                        <option value="Seniority.in">Seniority.in</option>
                        <option value="SmarthomeNX">SmarthomeNX</option> */}
                      </select>
                    </div>
                    <div>

                    </div>

                    <div className="input_field_btn">
                      <ReCAPTCHA
                        sitekey={key}
                        onChange={handleRecaptcha}
                        className="captcha"
                      />

                      {/* {captchaResult && ()} */}

                      <input type="submit" value="Register Robot" />
                    </div>
                  </>
                )}
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default RegisterRobot;
