import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/fontawesome-free-solid";
import OrderInvoice from "../component/dashboard/OrderInvoice";
import thank from "../image/thank-you.png"
import { resetstate } from "../redux/action/AdditionalAction";
import { useDispatch, useSelector } from "react-redux";


export default function ThanksYouNew(prpos) {
  const [showInvoice, setshowInvoice] = useState(false);
  const [orderflag, setorderflag] = useState(false);
  const [orderid, setorderid] = useState("");

  const location = useLocation();
  const heading = location.state.heading;
  const message = location.state.message;
  const dispatch = useDispatch();





  useEffect(() => {
    dispatch(resetstate());

    if (location.state != null && location.state != undefined) {
      if (location.state.orderid != null && location.state.orderid != undefined) {
        setorderflag(true);
        setorderid(location.state.orderid);
      }
    }
  }, [orderid]);


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="thnx-box-wrap">
        <div className="border_thank-new ">
          <div >
            <img src={thank} className="center-img" />

          </div>
          {orderflag ? <h3 className="font-weight-bolder h3-small-title-new">
            Order Id : {orderid}
          </h3> : ""}
          <hr />

          <h3 className="font-weight-bolder h3-small-title-new">
            {heading}
          </h3>

          <h4 className="font-weight-bolder h4-small-title-new">
            {message}
          </h4>


        </div>
        <a href="/" className="text-button btn-center">
          <i class="fa fa-home" aria-hidden="true"></i> Home
        </a>
      </div>
    </>
  );
}
