import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createAppointment } from "../../redux/action/AdditionalAction";
import { loadAllCategory } from "../../redux/action/CategoryAction";
import { createCustomerSupport } from "../../redux/action/AdditionalAction";



import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../Shared/Loader";
import Message from "../share/Message";

import spinner1 from "../../image/spinner1.gif";


export default function ContactForm() {
  //const { opened, setOpened } = useState();
  const [opened, setMessage] = useState(false);


  const queryParams = new URLSearchParams(window.location.search);
  const [utm_source, setutm_source] = useState('');
  const [utm_campaign, setutm_campaign] = useState('');
  var utm_sourcercv = queryParams.get('utm_source');
  var utm_campaignrcv = queryParams.get('utm_campaign');

  useEffect(() => {
    if (utm_sourcercv != null && utm_sourcercv != '' && utm_sourcercv != undefined) {

      setutm_source(utm_sourcercv);
      setutm_campaign(utm_campaignrcv);
      localStorage.setItem('utm_source', utm_sourcercv);
      localStorage.setItem('utm_campaign', utm_campaignrcv);
    }
    else {
      var item1 = localStorage.getItem('utm_source');
      var item2 = localStorage.getItem('utm_campaign');
      if (item1 != null && item1 != undefined && item1 != '') {
        setutm_source(item1);
        setutm_campaign(item2);
      }

    }
  }, []);
  const dispatch = useDispatch();



  useEffect(() => {
    var utm_source = localStorage.getItem("utm_source");
    //console.log('...utm' + utm_source);

    dispatch(loadAllCategory());

  }, []);

  const [state, setState] = useState({
    city: "",
    category: "",
    name: "",
    // lname: "",
    email: "",
    mobile: "",
    landmark: "",
    pincode: "",
    location: "",
    address: "",
    message: "",
    pe: "",
    reqtyop: "",
  });

  const {
    city,
    category,
    name,
    email,
    mobile,
    landmark,
    pincode,
    location,
    address,
    message,
    pe,
    reqtyop,
    source,
    campaign,
  } = state;

  const Category = useSelector((state) => state.MainCategory);



  const { error, result } = Category;


  const navigate = useNavigate();

  const { response, loading } = useSelector((state) => state.Additional);

  useEffect(() => {

    if (Object.keys(response).length > 0) {
      console.log('Object is NOT empty');
      //navigate('/thanks-you', { state: { messagefor: "contact", heading: "", message: "We have received your request. Our team will reach out to you soon regarding the same. We look forward to speaking with you soon!" } });
      navigate('/thanks-you-contact', { state: { messagefor: "contact", heading: "", message: "We have received your request. Our team will reach out to you soon regarding the same. We look forward to speaking with you soon!" } });

    }

  }, [response]);



  const handleInputChange = (e) => {
    console.log(e.target.value.length);
    let { name, value } = e.target;
    setState({ ...state, [name]: value });


  };
  // image preview section start
  const [file, setImage] = useState({ preview: "", raw: "" });
  const handleChange = (e) => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  };

  const changeHandler = (event) => {
    //setSelectedFile(event.target.files[0]);
    //setIsSelected(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!category && !email && !mobile && !name && !city) {
      toast.warning("Please input all input Field!");
    } else {

      let formData = new FormData();
      formData.append("support", category);
      formData.append("first_name", name);
      formData.append("last_name", '');
      formData.append("email", email);
      formData.append("mobile", mobile);
      formData.append("city", city);
      formData.append("question", message);
      formData.append("document", file.raw);
      formData.append("source", utm_source);
      formData.append("campaign", utm_campaign);



      dispatch(createCustomerSupport(formData));
      //  dispatch(createAppointment(state));
      setMessage(true);
      //  navigate("/all-thank-you");
    }

  };

  return (
    <section className="form-bottom" style={{ background: "#fff" }}>
      <ToastContainer />

      <div className="container">
        <div className="account_form register">
          <div className="account_bt">
            <h2 className="account_title">Send Your Message</h2>
          </div>
          <div className="acc_form">
            {loading ? (<div id="loading">
              <img id="loading-image" src={spinner1} alt="Loading..." />
            </div>) : ""}

            {loading ? (
              <Loader />
            ) : error ? (
              <Message variant="danger">{error}</Message>
            ) : (
              <form autoComplete="off" onSubmit={handleSubmit}>
                <div className="input_field">
                  <label>
                    Sales / Support <span>*</span>
                  </label>
                  <select
                    name="category"
                    onChange={handleInputChange}
                    value={category || ""}
                  >
                    <option>Select </option>
                    <option value="1">Sales Request</option>
                    <option value="2">Support Request</option>
                  </select>
                </div>
                <div className="input_field">
                  <label>
                    Name <span>*</span>
                  </label>
                  <input
                    type="text"
                    placeholder=""
                    name="name"
                    value={name}
                    onChange={handleInputChange}
                  />
                </div>
                {/* <div className="input_field">
                  <label>
                    Last Name <span>*</span>
                  </label>
                  <input
                    type="text"
                    placeholder=""
                    name="lname"
                    value={lname}
                    onChange={handleInputChange}
                  />
                </div> */}

                <div className="input_field">
                  <label>
                    Email address <span>*</span>
                  </label>
                  <input
                    type="email"
                    required
                    name="email"
                    placeholder=""
                    value={email}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="input_field">
                  <label>
                    Mobile <span>*</span>
                  </label>
                  <input
                    type="number"
                    required
                    placeholder=""
                    minLength="10" 
                    maxLength="10"
                    name="mobile"
                    value={mobile}
                    //onChange={handleInputChange}
                    onChange={(e) => {

                      if (e.target.value.length < 11) {
                        handleInputChange(e);
                      }
                    }}
                  />
                </div>

                <div className="input_field">
                  <label>
                    City <span>*</span>
                  </label>
                  <input
                    type="text"
                    placeholder=""
                    name="city"
                    value={city}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="input_field">
                  <label>Question</label>
                  <textarea
                    placeholder=""
                    name="message"
                    value={message}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="input_field">
                  <label>Attach Document</label>
                  <input type="file" name="file" onChange={handleChange} />


                </div>

                <div className="input_field_btn">
                  <input type="submit" placeholder="Create Account" />
                </div>
              </form>
            )}
          </div>
        </div>
      </div>

      {opened && (
        <div id="loading">
          <img id="loading-image" src={spinner1} alt="Loading..." />
        </div>
      )}
    </section>

  );
}
