import React from "react";
import support_1 from "../../image/support_1.png";
import support_2 from "../../image/support_2.png";
import support_3 from "../../image/support_5.png";
import support_4 from "../../image/support_4.png";

import { Link } from "react-router-dom";

export default function SubMenuContact({ showChange }) {
  return (
    <div className="support_menu sub_menu supportmenudiv">
      <div className="container inner_menu">
        <ul className="submenu_main">
          <li>
            <ul className="items">
              <li>
                <Link to="/faq" onClick={showChange}>
                  <img src={support_1} />
                  <span>Find Answers</span>
                </Link>
              </li>
              <li>
                <Link to="/register-robot" onClick={showChange}>
                  <img src={support_2} />
                  <span>Register Robots</span>
                </Link>
              </li>
              <li>
                <Link to="/storelocator" onClick={showChange}>
                  <img src={support_3} />
                  <span>Where to buy</span>
                </Link>
              </li>
              <li>
                <Link to="/contact-us" onClick={showChange}>
                  <img src={support_4} />
                  <span>Contact</span>
                </Link>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  );
}
