export const GET_LOYALTY_POINTS_REQUEST = "GET_LOYALTY_POINTS_REQUEST";
export const GET_LOYALTY_POINTS_SUCCESS = "GET_LOYALTY_POINTS_SUCCESS";
export const GET_LOYALTY_POINTS_FAIL = "GET_LOYALTY_POINTS_FAIL";

export const GET_EARN_LOYALTY_POINTS_REQUEST =
  "GET_EARN_LOYALTY_POINTS_REQUEST";
export const GET_EARN_LOYALTY_POINTS_SUCCESS =
  "GET_EARN_LOYALTY_POINTS_SUCCESS";
export const GET_EARN_LOYALTY_POINTS_FAIL = "GET_EARN_LOYALTY_POINTS_FAIL";

export const GET_SPEND_LOYALTY_POINTS_REQUEST =
  "GET_SPEND_LOYALTY_POINTS_REQUEST";
export const GET_SPEND_LOYALTY_POINTS_SUCCESS =
  "GET_SPEND_LOYALTY_POINTS_SUCCESS";
export const GET_SPEND_LOYALTY_POINTS_FAIL = "GET_SPEND_LOYALTY_POINTS_FAIL";

export const GET_LOYALTY_POINTS_HISTORY_REQUEST =
  "GET_SPEND_LOYALTY_POINTS_REQUEST";
export const GET_LOYALTY_POINTS_HISTORY_SUCCESS =
  "GET_SPEND_LOYALTY_POINTS_SUCCESS";
export const GET_LOYALTY_POINTS_HISTORY_FAIL = "GET_SPEND_LOYALTY_POINTS_FAIL";
