import axios from "axios";
import { toast } from "react-toastify";
import {
  FIND_ALL_ACCESSORIES_REQUEST,
  FIND_ALL_ACCESSORIES_SUCCESS,
  FIND_ALL_ACCESSORIES_FAIL,
} from "../constants/AccessoriesConstant";

// Find All Accessories
const loadAllAccesssories = () => {
  return function (dispatch) {
    dispatch({
      type: FIND_ALL_ACCESSORIES_REQUEST,
      payload: true,
    });

    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/user-category/2/`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    axios(OPTION)
      .then((res) => {
        // console.log('res',res.data);
        dispatch(loadAllAccesssoriesPre(res.data));
      })
      .catch((error) => {
        // console.log('error',error);

        dispatch({
          type: FIND_ALL_ACCESSORIES_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
      });
  };
};
export const loadAllAccesssoriesPre = (data) => {
  // console.log("data",data)
  return {
    type: FIND_ALL_ACCESSORIES_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};
// Find Single Category
// const loadSingleCategory = (id, result) => {
//   return function (dispatch) {
//     dispatch({
//       type: FIND_ONE_CATEGORY_REQUEST,
//       payload: true,
//     });
//     const token = JSON.parse(localStorage.getItem("jwt"))
//       ? JSON.parse(localStorage.getItem("jwt"))
//       : "";
//     let OPTION = {
//       url: `${process.env.REACT_APP_API_PATH}/api/v1/user-category/${id}/`,
//       method: "GET",
//       headers: {
//         "content-type": "application/json",
//         token: token,
//       },
//     };
//     axios(OPTION)
//       .then((res) => {
//         dispatch(loadSingleCategoryPre(res.data));
//       })
//       .catch((error) => {
//         dispatch({
//           type: FIND_ONE_CATEGORY_FAIL,
//           payload: false,
//           error: error,
//           msg: "Failed to load the information",
//         });
//       });
//   };
// };
// export const loadSingleCategoryPre = (data) => {
//   return {
//     type: FIND_ONE_CATEGORY_SUCCESS,
//     result: data,
//     payload: false,
//     msg: "SUCCESS",
//   };
// };

export { loadAllAccesssories };
