import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { loadCategoryTab } from "../../redux/action/StaticAction";
import LeftZigzag from "./Left_zigzag";
import LeftZigzag2 from "./Left_zigzag2";
import LeftZigzag3 from "./Left_zigzag3";
import LeftZigzag4 from "./Left_zigzag4";

import $, { isEmptyObject } from "jquery";

export default function Series_tab() {
  $(function () {
    $(".tabbing li").click(function () {
      $(this).addClass("active");
      $(this).siblings().removeClass("active");
    });
  });
  const [tab1, setTab1] = useState(true);
  const [tab2, setTab2] = useState(false);
  const [tab3, setTab3] = useState(false);
  const [tab4, setTab4] = useState(false);
  const [banner_id, setbanner_id] = useState(0);


  const tabhander1 = () => {
    if (tab1 !== true) {
      setTab1(!tab1);
      setTab2(false);
      setTab3(false);
      setTab4(false);
    }
  };
  const tabhander2 = () => {
    if (tab2 !== true) {
      setTab2(!tab2);
      setTab1(false);
      setTab3(false);
      setTab4(false);
    }
  };
  const tabhander3 = () => {
    if (tab3 !== true) {
      setTab3(!tab3);
      setTab2(false);
      setTab1(false);
      setTab4(false);
    }
  };
  const tabhander4 = () => {
    if (tab4 !== true) {
      setTab4(!tab4);
      setTab2(false);
      setTab3(false);
      setTab1(false);
    }
  };

  const banner = useSelector((state) => state.Static.categoriesdata);
  //const [bannerData, setbannerData] = useState([]);

  let bannerData = [];
  const dispatch = useDispatch();


  useEffect(() => {

    if (banner != null && !isEmptyObject(banner)) {
      console.log("banner_idrcv..useEffect..", banner);

      bannerData = banner[0];
      if (bannerData !== "" && bannerData !== null && bannerData != undefined) {
        var banner_id = bannerData.id;

      }

      if (bannerData !== "" && bannerData !== null && bannerData != undefined) {
        var banner_id = bannerData.id;
        console.log("banner_idrcvserv..", banner_id);

        setbanner_id(banner_id);

        dispatch(loadCategoryTab(banner_id));

      }

    }


  }, [banner]);





  /*
    useEffect(() => {
      console.log("banner_idrcv..bannerData..", bannerData);
  
      if (bannerData !== "" && bannerData !== null && bannerData != undefined) {
        var banner_id = bannerData.id;
        console.log("banner_idrcv..", banner_id);
  
        //setbanner_id(banner_id);
  
        dispatch(loadCategoryTab(banner_id));
  
      }
    }, [bannerData]);
    */

  const tab = useSelector((state) => state.Static.categoriesTab);
  // console.log("tab",tab)

  return (
    <>
      {tab.length > 0 ? <>
        {tab1 ? <LeftZigzag banner_id={{ banner_id }} /> : ""}
        {tab2 ? <LeftZigzag2 banner_id={{ banner_id }} /> : ""}
        {tab3 ? <LeftZigzag3 banner_id={{ banner_id }} /> : ""}
        {tab4 ? <LeftZigzag4 banner_id={{ banner_id }} /> : ""}

        <section className="section tabbing">
          <div className="container">
            {tab.map((da) => {
              return (
                <ul key={da.id}>
                  <li className="active" onClick={tabhander1}>
                    <div className="tabing_inner">
                      <h3>{da.tab1_title}</h3>
                      <p>{da.tab1_description}</p>
                    </div>
                  </li>
                  <li onClick={tabhander2}>
                    <div className="tabing_inner">
                      <h3>{da.tab2_title}</h3>
                      <p>{da.tab2_description}</p>
                    </div>
                  </li>
                  <li onClick={tabhander3}>
                    <div className="tabing_inner">
                      <h3>{da.tab3_title}</h3>
                      <p>{da.tab3_description}</p>
                    </div>
                  </li>
                  <li onClick={tabhander4}>
                    <div className="tabing_inner">
                      <h3>{da.tab4_title}</h3>
                      <p>{da.tab4_description}</p>
                    </div>
                  </li>
                </ul>
              );
            })}
          </div>
        </section>
      </> : ""}
    </>
  );
}
