import axios from "axios";
import { toast } from "react-toastify";
import {
  FIND_FAQ_FAIL,
  FIND_FAQ_REQUEST,
  FIND_FAQ_SUCCESS,
  FIND_PROMO_CODE_FAIL,
  FIND_PROMO_CODE_REQUEST,
  FIND_PROMO_CODE_SUCCESS,
  ADD_APPOINTMENT_FAIL,
  ADD_APPOINTMENT_REQUEST,
  ADD_APPOINTMENT_SUCCESS,
  ADD_APPOINTMENTS_FAIL,
  ADD_APPOINTMENTS_REQUEST,
  ADD_APPOINTMENTS_SUCCESS,
  ADD_CAREER_FAIL,
  ADD_CAREER_REQUEST,
  ADD_CAREER_SUCCESS,
  ADD_CUSTOMER_SUPPORT_FAIL,
  ADD_CUSTOMER_SUPPORT_REQUEST,
  ADD_CUSTOMER_SUPPORT_SUCCESS,
  ADD_ENQUIRY_FAIL,
  ADD_ENQUIRY_REQUEST,
  ADD_ENQUIRY_SUCCESS,
  FIND_BLOGS_FAIL,
  FIND_BLOGS_REQUEST,
  FIND_BLOGS_SUCCESS,
  FIND_TESTIMONIAL_FAIL,
  FIND_TESTIMONIAL_REQUEST,
  FIND_TESTIMONIAL_SUCCESS,
  FIND_NEWS_FAIL,
  FIND_NEWS_REQUEST,
  FIND_NEWS_SUCCESS,
  ADD_PROMOTION_EMAIL_FAIL,
  ADD_PROMOTION_EMAIL_REQUEST,
  ADD_PROMOTION_EMAIL_SUCCESS,
  FIND_SINGLE_BLOGS_FAIL,
  FIND_SINGLE_BLOGS_REQUEST,
  FIND_SINGLE_BLOGS_SUCCESS,
  SEARCH_FAQ_FAIL,
  SEARCH_FAQ_REQUEST,
  SEARCH_FAQ_SUCCESS,
  GET_MENU_TITLE_FAIL,
  GET_MENU_TITLE_REQUEST,
  GET_MENU_TITLE_SUCCESS,
  GET_TOP_HEADER_MESSAGE_FAIL,
  GET_TOP_HEADER_MESSAGE_REQUEST,
  GET_TOP_HEADER_MESSAGE_SUCCESS,
  ADD_LOYALTY_REWARDS_FAIL,
  ADD_LOYALTY_REWARDS_REQUEST,
  ADD_LOYALTY_REWARDS_SUCCESS,
  RESET_STATE,
  SEARCH_ORDERTRACK_REQUEST,
  SEARCH_ORDERTRACK_SUCCESS,
  SEARCH_ORDERTRACK_FAIL
} from "../constants/AdditionalConstant";

// Find All Blogs
const loadAllBlogs = (id) => {
  return function (dispatch) {
    dispatch({
      type: FIND_BLOGS_REQUEST,
      payload: true,
    });

    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/blog/`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    axios(OPTION)
      .then((res) => {
        dispatch(loadAllBlogsPre(res.data));
      })
      .catch((error) => {
        dispatch({
          type: FIND_BLOGS_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
      });
  };
};
export const loadAllBlogsPre = (data) => {
  return {
    type: FIND_BLOGS_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

const loadAllBlogsSingle = (id) => {
  return function (dispatch) {
    dispatch({
      type: FIND_SINGLE_BLOGS_REQUEST,
      payload: true,
    });

    let OPTION = {
      // url: `${process.env.REACT_APP_API_PATH}/api/v1/blog/${id}/`,
      url: `${process.env.REACT_APP_API_PATH}/api/v1/blog-n/${id}/`,

      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    axios(OPTION)
      .then((res) => {
        dispatch(loadAllBlogsSinglePre(res.data));
      })
      .catch((error) => {
        dispatch({
          type: FIND_SINGLE_BLOGS_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
      });
  };
};
export const loadAllBlogsSinglePre = (data) => {
  return {
    type: FIND_SINGLE_BLOGS_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

// Find All Testimonial
const loadAllTestimonial = () => {
  return function (dispatch) {
    dispatch({
      type: FIND_TESTIMONIAL_REQUEST,
      payload: true,
    });

    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/admin-testimonial/`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    axios(OPTION)
      .then((res) => {
        dispatch(loadAllTestimonialPre(res.data));
      })
      .catch((error) => {
        dispatch({
          type: FIND_TESTIMONIAL_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
      });
  };
};
export const loadAllTestimonialPre = (data) => {
  return {
    type: FIND_TESTIMONIAL_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

// Find All Faq
const loadAllFaq = () => {
  return function (dispatch) {
    dispatch({
      type: FIND_FAQ_REQUEST,
      payload: true,
    });

    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/faq/`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    axios(OPTION)
      .then((res) => {
        dispatch(loadAllFaqPre(res.data));
      })
      .catch((error) => {
        dispatch({
          type: FIND_FAQ_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
      });
  };
};
export const loadAllFaqPre = (data) => {
  return {
    type: FIND_FAQ_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

// Search Faq
const searchFaq = (inputData) => {
  return function (dispatch) {
    dispatch({
      type: SEARCH_FAQ_REQUEST,
      payload: true,
    });

    var data = JSON.stringify({
      language: "en_US",
      region: "All__c",
      "customer-type": "Customer__c",
      keyword: inputData,
      requiredFields: "title,UrlName,Description__c",
    });

    // console.log(inputData, "inputDatavvvvv");
    let OPTION = {
      url: process.env.REACT_APP_API_PATH + '/api/v1/faq-search/' + inputData + '/',
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
      // data: data,
    };
    axios(OPTION)
      .then((res) => {
        console.log(res.data, "avdeshkkk");
        dispatch(searchFaqPre(res.data));
      })
      .catch((error) => {
        dispatch({
          type: SEARCH_FAQ_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
      });
  };
};
export const searchFaqPre = (data) => {
  return {
    type: SEARCH_FAQ_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};
const searchOrdertract = (awb, number) => {
  return function (dispatch) {
    dispatch({
      type: SEARCH_ORDERTRACK_REQUEST,
      payload: true,
    });



    // console.log(inputData, "inputDatavvvvv");
    let OPTION = {
      url: process.env.REACT_APP_API_PATH + '/api/v1/awbtract/?awb=' + awb + '&number=' + number,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
      // data: data,
    };
    axios(OPTION)
      .then((res) => {
        dispatch(searchOrdertractPre(res.data));
      })
      .catch((error) => {
        dispatch({
          type: SEARCH_ORDERTRACK_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
      });
  };
};
export const searchOrdertractPre = (data) => {
  //console.log(data)
  return {
    type: SEARCH_ORDERTRACK_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

// Find All News
const loadAllNews = () => {
  return function (dispatch) {
    dispatch({
      type: FIND_NEWS_REQUEST,
      payload: true,
    });

    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/admin-news/`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    axios(OPTION)
      .then((res) => {
        dispatch(loadAllNewsPre(res.data));
      })
      .catch((error) => {
        dispatch({
          type: FIND_NEWS_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
      });
  };
};
export const loadAllNewsPre = (data) => {
  return {
    type: FIND_NEWS_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

// Find All Promo-Code
const loadAllPromoCode = () => {
  return function (dispatch) {
    dispatch({
      type: FIND_PROMO_CODE_REQUEST,
      payload: true,
    });

    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/admin-promo-code/`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    axios(OPTION)
      .then((res) => {
        dispatch(loadAllPromoCodePre(res.data));
      })
      .catch((error) => {
        dispatch({
          type: FIND_PROMO_CODE_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
      });
  };
};
export const loadAllPromoCodePre = (data) => {
  return {
    type: FIND_PROMO_CODE_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};


const loadPromoCodeByCode = (code) => {
  return function (dispatch) {
    dispatch({
      type: FIND_PROMO_CODE_REQUEST,
      payload: true,
    });

    let OPTION = {
      url: process.env.REACT_APP_API_PATH + '/api/v1/promo-code/' + code + '/',
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    axios(OPTION)
      .then((res) => {
        dispatch(loadPromoCodePre(res.data));
      })
      .catch((error) => {
        dispatch({
          type: FIND_PROMO_CODE_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
      });
  };
};
export const loadPromoCodePre = (data) => {
  return {
    type: FIND_PROMO_CODE_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

const resetstate = () => {
  return function (dispatch) {
    dispatch({
      type: RESET_STATE,
    });

  };
};

// Add Carrer
const createCareer = (data) => {
  return function (dispatch) {
    dispatch({
      type: ADD_CAREER_REQUEST,
      payload: true,
    });

    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/admin-career/`,
      method: "POST",
      data: data,
      headers: {
        "content-type": "application/json",
      },
    };
    axios(OPTION)
      .then((res) => {
        dispatch(createCareerPre(res.data));
      })
      .catch((error) => {
        dispatch({
          type: ADD_CAREER_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
        toast.error(error.msg);
      });
  };
};
export const createCareerPre = (data) => {
  toast.success(data.msg);
  return {
    type: ADD_CAREER_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

// Add Appointment
const createAppointment = (data) => {

  return function (dispatch) {
    dispatch({
      type: ADD_APPOINTMENT_REQUEST,
      payload: true,
    });

    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/admin-book-appointment/`,
      method: "POST",
      data: data,
      headers: {
        "content-type": "application/json",
      },
    };
    axios(OPTION)
      .then((res) => {

        dispatch(createAppointmentPre(res.data));
        // toast.success("successfully!");
        // window.location.href = "/all-thank-you";
      })
      .catch((error) => {
        dispatch({
          type: ADD_APPOINTMENT_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
        toast.error(error.msg);
      });
  };
};

export const createAppointmentPre = (data) => {
  toast.success(data.msg);
  return {
    type: ADD_APPOINTMENT_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };

};

const createAppointments = (
  city,
  category,
  name,
  email,
  mobile,
  landmark,
  pincode,
  location,
  address,
  message,
  pe,
  reqtyop
) => {
  return function (dispatch) {
    dispatch({
      type: ADD_APPOINTMENTS_REQUEST,
      payload: true,
    });

    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/admin-book-appointment/`,
      method: "POST",
      data: {
        city,
        category,
        name,
        email,
        mobile,
        landmark,
        pincode,
        location,
        address,
        message,
        pe,
        reqtyop,
      },
      headers: {
        "content-type": "application/json",
      },
    };
    axios(OPTION)
      .then((res) => {
        dispatch(createAppointmentsPre(res.data));
        //toast.success(" submited successfully!");
        alert("Thank you for sharing the details. Our sales representatives will call you shortly");

        // window.location.href = "/all-thank-you";
        window.location.href = "/";
      })
      .catch((error) => {
        dispatch({
          type: ADD_APPOINTMENTS_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
        toast.error(error.msg);
      });
  };
};

export const createAppointmentsPre = (data) => {
  toast.success(data.msg);
  return {
    type: ADD_APPOINTMENTS_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};
// Add Customer Support
const createCustomerSupport = (data) => {
  return function (dispatch) {
    dispatch({
      type: ADD_CUSTOMER_SUPPORT_REQUEST,
      payload: true,
    });

    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/admin-customer-support/`,
      method: "POST",
      data: data,
      headers: {
        "content-type": "application/json",
      },
    };
    axios(OPTION)
      .then((res) => {
        dispatch(createCustomerSupportPre(res.data));
      })
      .catch((error) => {
        dispatch({
          type: ADD_CUSTOMER_SUPPORT_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
        toast.error(error.msg);
      });
  };
};
export const createCustomerSupportPre = (data) => {
  toast.success(data.msg);
  return {
    type: ADD_CUSTOMER_SUPPORT_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};
const createEnquiryHome = (first_name, last_name, email, mobile, title, company, city, company_website, area_of_focus, area_of_expertise, message, promotional_email, source, campaign) => {
  return function (dispatch) {
    dispatch({
      type: ADD_ENQUIRY_REQUEST,
      payload: true,
    });

    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/admin-enquery/`,
      method: "POST",
      data: { first_name, last_name, email, mobile, title, company, city, company_website, area_of_focus, area_of_expertise, message, promotional_email, source, campaign },
      headers: {
        "content-type": "application/json",
      },
    };
    axios(OPTION)
      .then((res) => {
        dispatch(createEnquiryPre(res.data));
        //toast.success("successfully!");
        // window.location.href = "/all-thank-you";
      })
      .catch((error) => {
        dispatch({
          type: ADD_ENQUIRY_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
        toast.error(error.msg);
      });
  };
};

// Add Customer Support
const createEnquiry = (data) => {
  return function (dispatch) {
    dispatch({
      type: ADD_ENQUIRY_REQUEST,
      payload: true,
    });

    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/admin-enquery/`,
      method: "POST",
      data: data,
      headers: {
        "content-type": "application/json",
      },
    };
    axios(OPTION)
      .then((res) => {
        dispatch(createEnquiryPre(res.data));
        //toast.success("successfully!");
        // window.location.href = "/all-thank-you";
      })
      .catch((error) => {
        dispatch({
          type: ADD_ENQUIRY_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
        toast.error(error.msg);
      });
  };
};

export const createEnquiryPre = (data) => {
  toast.success(data.msg);
  return {
    type: ADD_ENQUIRY_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

// Add Customer Support
const createPromotionEmail = (email) => {
  return function (dispatch) {
    dispatch({
      type: ADD_PROMOTION_EMAIL_REQUEST,
      payload: true,
    });

    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/promotion-email/`,
      method: "POST",
      data: { email },
      headers: {
        "content-type": "application/json",
      },
    };
    axios(OPTION)
      .then((res) => {
        dispatch(createPromotionEmailPre(res.data));
      })
      .catch((error) => {
        dispatch({
          type: ADD_PROMOTION_EMAIL_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
        toast.error(error.msg);
      });
  };
};
export const createPromotionEmailPre = (data) => {
  toast.success(data.msg);
  return {
    type: ADD_PROMOTION_EMAIL_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

// load Top Header Message
const loadAllTopHeaderMessage = () => {
  return function (dispatch) {
    dispatch({
      type: GET_TOP_HEADER_MESSAGE_REQUEST,
      payload: true,
    });

    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/menu-message/`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    axios(OPTION)
      .then((res) => {
        dispatch(loadAllTopHeaderMessagePre(res.data));
      })
      .catch((error) => {
        dispatch({
          type: GET_TOP_HEADER_MESSAGE_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
      });
  };
};
export const loadAllTopHeaderMessagePre = (data) => {
  return {
    type: GET_TOP_HEADER_MESSAGE_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

// load Menu Title
const loadAllMenuTitle = () => {
  return function (dispatch) {
    dispatch({
      type: GET_MENU_TITLE_REQUEST,
      payload: true,
    });

    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/main-menu/`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    axios(OPTION)
      .then((res) => {
        dispatch(loadAllMenuTitlePre(res.data));
      })
      .catch((error) => {
        dispatch({
          type: GET_MENU_TITLE_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
      });
  };
};
export const loadAllMenuTitlePre = (data) => {
  return {
    type: GET_MENU_TITLE_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

// Add Loyalty Rewards
const createLoyaltyReward = (data) => {
  return function (dispatch) {
    dispatch({
      type: ADD_LOYALTY_REWARDS_REQUEST,
      payload: true,
    });

    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/admin-loyalty-reward/`,
      method: "POST",
      data: data,
      headers: {
        "content-type": "application/json",
      },
    };
    axios(OPTION)
      .then((res) => {
        dispatch(createLoyaltyRewardPre(res.data));
      })
      .catch((error) => {
        dispatch({
          type: ADD_LOYALTY_REWARDS_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
        toast.error(error.msg);
      });
  };
};
export const createLoyaltyRewardPre = (data) => {
  toast.success(data.msg);
  return {
    type: ADD_LOYALTY_REWARDS_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

export {
  loadAllFaq,

  loadAllPromoCode,
  createAppointment,
  createAppointments,
  loadAllNews,
  loadAllTestimonial,
  loadAllBlogs,
  createCareer,
  createCustomerSupport,
  createEnquiry,
  createEnquiryHome,
  createPromotionEmail,
  loadAllBlogsSingle,
  searchFaq,
  searchOrdertract,
  loadAllTopHeaderMessage,
  loadAllMenuTitle,
  createLoyaltyReward,
  loadPromoCodeByCode,
  resetstate,
};
