import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { searchFaq } from "../../redux/action/AdditionalAction";
import Loader from "../Shared/Loader";
import Message from "../share/Message";
import spinner1 from "../../image/spinner1.gif";

export default function FaqTop() {
  const [inputData, setInputData] = useState("");
  const [searchN, setSearchN] = useState([]);



  console.log(inputData, "inputData");
  const [hide, setHide] = useState(true);

  const dispatch = useDispatch();

  const search = useSelector((state) => state.Additional.searchFaq);


  const searchResult = useSelector((state) => state.Additional);
  const { loading, error } = searchResult;

  useEffect(() => {

    if (search?.length > 0) {
      let txt = JSON.parse(search);
      if (!txt.Status) {
        setSearchN(txt);
        console.log('array is not empty');
        console.log("search..", search);
      }
    }



  }, [search]);

  let user = useSelector((state) => state.AuthReducer);



  let { isSigninIn } = user;


  // var objs = search.map((x) => ({
  //   id: x[0],
  //   title: x[1],
  //   description: x[2],
  //   created_at: x[3],
  // }));

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!inputData) {
      setHide(true);
    } else {
      dispatch(searchFaq(inputData));
      setHide(false);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      //setUpdated(inputRef.current.value);
      event.preventDefault();
      if (!inputData) {
        setHide(true);
      } else {
        dispatch(searchFaq(inputData));
        setHide(false);
      }
    }
  };
  return (
    <section className="section faq_search">

      <div className="container">
        <div className="find_ans">
          <h2 className="title">Find Answer</h2>
          <form className="search_faq" noValidate autoComplete="off">
            <div className="search_form_faq">
              <input
                type="text"
                placeholder="Search keyword"
                required
                name="inputData"
                value={inputData}
                onChange={(e) => setInputData(e.target.value)}
                onKeyDown={handleKeyDown}
              />
              <button type="button" onClick={handleSubmit}>
                Search
              </button>
            </div>
          </form>
        </div>

        <div >


          {loading ? (
            <>
              {/* 
            <Loader />
            */}
              <div id="loading">
                <img id="loading-image" src={spinner1} alt="Loading..." />
              </div></>

          ) : error ? (
            <Message variant="danger">{error}</Message>
          ) : (
            <>
              {hide !== true ? (
                <>
                  {inputData ? (
                    <>
                      <ul className="search-list">
                        {searchN &&
                          searchN.map((item, i) => (
                            <div className="faqDes">
                              <li key={i}>
                                <h3>{item.Title}</h3>
                                <div
                                  key={i}
                                  className="post__content"
                                  dangerouslySetInnerHTML={{
                                    __html: item.Description__c,
                                  }}
                                ></div>
                                {/* <p>{item.Description__c}</p> */}
                                {/* <span>{item.created_at}</span> */}
                              </li>
                            </div>
                          ))}
                      </ul>
                    </>
                  ) : (
                    <>
                      <ul className="search-list">
                        <li>
                          <span>No Data Found</span>
                        </li>
                      </ul>
                    </>
                  )}
                </>
              ) : (
                <></>
              )}
            </>
          )}


        </div>

      </div>

    </section>
  );
}
