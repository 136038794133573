import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadPrivacyPolicy } from "../redux/action/StaticAction";
import Loader from "../component/Shared/Loader";
import Message from "../component/share/Message";

export default function PrivacyPolicy() {
  const dispatch = useDispatch();

  useEffect(() => {
    document.getElementById("maintittle").innerHTML = "Privacy Policy | iRobot India";
    document.getElementById("description").setAttribute("content", "Privacy Policy | iRobot India");

    dispatch(loadPrivacyPolicy());
  }, [dispatch]);

  const CovidData = useSelector((state) => state.Static);

  const { loading, error, privacyPolicyData } = CovidData;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section className="section" >
      <div className="container">
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : (
          <div className="privacy_policy page_content">
            {privacyPolicyData &&
              privacyPolicyData.map((item, i) => (
                <div className="rte" key={i}>
                  {item.heading ? <h1 className="hh1">{item.heading}</h1> : <></>}

                  <>
                    <div key={i} className="post__content" dangerouslySetInnerHTML={{ __html: item.description }}></div></>
                </div>
              ))}
          </div>
        )}
      </div>
    </section>
  );
}
