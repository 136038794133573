import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadAllBlogs } from "../../redux/action/AdditionalAction";
import { Link, useParams } from "react-router-dom";
import Loader from "../Shared/Loader";
import Message from "../share/Message";

export default function RightSidebar() {
  const dispatch = useDispatch();
  let { id } = useParams();
  useEffect(() => {
    if (id) {
      dispatch(loadAllBlogs());
    }
  }, []);

  const blogData = useSelector((state) => state.Additional);

  const { loading, error, blog } = blogData;
  return (
    <aside className="right_sidebar">
      <h3>Latest Stories</h3>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <ul className="stories">
          {blog &&
            blog.slice(0, 5).reverse().map((item, i) => (
              <li key={i}>
                <Link to={`/post/${item.slug}`}>{item.title}</Link>
              </li>
            ))}
        </ul>
      )}
    </aside>
  );
}
