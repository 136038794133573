

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { createProductReview } from "../redux/action/ProductReviewAction";
import { loadAllProduct } from "../redux/action/ProductAction";
import { loadAllProductImage } from "../redux/action/ProductAction";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import p2 from "../image/p2.jpg";

export default function RightReviews() {
  const [hover, setHover] = useState(0);
  const [image, setImage] = useState([]);
  const [rating, setRating] = useState("");
  const [bottomline, setBotomline] = useState("");
  const [productid, setproductid] = useState(0);

  const [state, setState] = useState({
    nickname: "",
    email: "",
    product_id: "",
    heding: "",
    comments: "",
    location: "",

  });

  function handleSelectChange(event) {
    setBotomline(event.target.value);
  }
  const { nickname, email, heding, comments, location, product_id } = state;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };
  const { id } = useParams();
  let dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!email && !rating) {
      toast.warning("Please input all input Field!");

    } else {
      let formData = new FormData();

      if (image[0] != null && image[0] != undefined) {
        formData.append("image", image[0]);
      }
      formData.append("email", email);
      formData.append("product_id", productid);
      formData.append("rating", rating);
      formData.append("nickname", nickname);
      formData.append("heding", heding);
      formData.append("comments", comments);
      formData.append("location", location);
      formData.append("bottomline", bottomline);
      dispatch(createProductReview(formData));
      window.history.back();
    }
  };

  useEffect(() => {
    dispatch(loadAllProduct(id));
    dispatch(loadAllProductImage(id));
  }, []);



  let productData = useSelector((state) => state.MainProduct.singledata);
  let productImage = useSelector((state) => state.MainProduct.productImages);
  console.log("productImage", productImage);

  useEffect(() => {
    if (productData) {
      if (productData != null && productData != undefined) {
        setproductid(productData.id)
        //console.log("useEffectsingledata", productData.id);
      }

    }

  }, [productData]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section className="write_review section ">
      <div className="overlay_review"></div>
      <div className="review">
        <div className="back-btn">
          <Link
            to=""
            onClick={() => {
              window.history.back();
            }}
          >
            Back
          </Link>
        </div>
        <div className="section_titile">
          <div className="revsect">
            <div className="revimg">
              {productImage && productImage.slice(0, 1).map((da) => {
                return (
                  <img src={`${process.env.REACT_APP_API_PATH}${da.image}`} alt="" />
                )
              })}

              <div className="revtext">
                <h3>Write Review</h3>
                <p>{productData.name}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="review_form">
          <form
            
            autoComplete="off"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <div class="input_field">
              <label htmlFor="email">
                <span>*</span>Required question{" "}
              </label>
            </div>
            <div className="input_field">
              <label htmlFor="heding">
                Review Headline <span>*</span>
              </label>
              <input
                type="text"
                placeholder="I would buy this product again and again"
                className="form-control"
                name="heding"
                value={heding}
                onChange={handleInputChange}
              />
            </div>
            <div className="input_field">
              <label htmlFor="rating">
                Your Rating <span>*</span>
              </label>
              {[...Array(5)].map((star, index) => {
                index += 1;
                return (
                  <button
                    type="button"
                    key={index}
                    name="rating"
                    value={rating}
                    className={index <= (hover || rating) ? "on" : "off"}
                    onClick={() => setRating(index)}
                    // onChange={(e) => setRating(e.target.value(index))}
                    // onChange={handleInputChange()}
                    onMouseEnter={() => setHover(index)}
                    onMouseLeave={() => setHover(rating)}
                  >
                    <span className="star">&#9733;</span>
                  </button>
                );
              })}
            </div>
            <div className="input_field">
              <label htmlFor="comments">Comments</label>
              <textarea
                type="text"
                placeholder="How you use the product. Things that are great about it. Things that aren't great about it."
                name="comments"
                value={comments}
                // onChange={(e) => setDescription(e.target.value)}
                onChange={handleInputChange}
              ></textarea>
            </div>
            <div className="input_field">
              <label htmlFor="email">Bottom Line</label>
              <select
                name="botom_line"
                onChange={handleSelectChange}
                value={bottomline}
              >
                <option value="1">
                  Yes, I would recommend this to a friend
                </option>
                <option value="2">
                  No, I would not recommend this to a friend
                </option>
              </select>

              {/* <div className="both-alter onChange={handleInputChange}">
                <p>
                  <input
                    type="radio"
                    id="yes-recommend"
                    // name="bottom-line"
                    value={botom_line}
                    name="botom_line"
                  />
                  <label htmlfor="yes-recommend">
                    Yes, I would recommend this to a friend
                  </label>
                </p>
                <p>
                  <input
                    type="radio"
                    id="no-recommend"
                    // name="bottom-line"
                    value={botom_line}
                    name="botom_line"
                  />
                  <label htmlfor="no-recommend">
                    No, I would not recommend this to a friend
                  </label>
                </p>
              </div> */}
            </div>
            <div className="input_field">
              <label htmlFor="nickname">
                Nickname <span>*</span>
              </label>
              <input
                required
                type="text"
                placeholder="Enter Your Name"
                className="form-control"
                name="nickname"
                value={nickname}
                // onChange={(e) => setNickname(e.target.value)}
                onChange={handleInputChange}
              />
            </div>
            <div className="input_field">
              <label htmlFor="location">
                Location <span>*</span>
              </label>
              <input
                required
                type="text"
                placeholder="Ex. San Jose, CA"
                className="form-control"
                name="location"
                value={location}
                onChange={handleInputChange}
              />
            </div>
            <div className="input_field">
              <label htmlFor="image">Add an Image</label>
              <input
                alt="image"
                type="file"
                name="image"
                className="form-control"
                onChange={(e) => setImage(e.target.files)}
                accept="image/*"
              />
            </div>

            <div className="input_field">
              <label htmlFor="email">Email</label>
              <input
                required
                type="email"
                placeholder="john@mexample.com"
                className="form-control"
                name="email"
                value={email}
                onChange={handleInputChange}
              />
            </div>

            <div className="input_field_btn">
              <input
                type="submit"
                value="Submit Review"
                className="btn submt_comment"
              />
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}

