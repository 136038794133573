import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/fontawesome-free-solid";
import { useDispatch, useSelector } from "react-redux";
import { ProductSearch } from "../../redux/action/ProductAction";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../Shared/Loader";
import Message from "../share/Message";
import { Link } from "react-router-dom";

function HeaderSearch({ showChange }) {
  const [inputData, setInputData] = useState("");
  const [hide, setHide] = useState(true);

  const dispatch = useDispatch();

  const searchResult = useSelector((state) => state.MainProduct);
  const search = useSelector((state) => state.MainProduct.search);

  const { loading, error } = searchResult;

  var objs = search.map((x) => ({
    id: x[0],
    name: x[1],
    image: x[2],
    price: x[3],
    sellprice: x[4],
    slug: x[5],

  }));
  console.log("objs", objs);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!inputData) {
      setHide(true);
    } else {
      dispatch(ProductSearch(inputData));
      setHide(false);
    }
  };

  return (
    <div className="header-search">
      <div className="container">
        <form noValidate autoComplete="off">
          <input
            type="search"
            placeholder="Search..."
            required
            name="inputData"
            value={inputData}
            onChange={(e) => setInputData(e.target.value)}
            onKeyUp={handleSubmit}
          />
          <button className="btn btn-search" type="submit">
            Search
            <span>
              <FontAwesomeIcon icon={faSearch} />
            </span>
          </button>
          {loading ? (
            <Loader />
          ) : error ? (
            <Message variant="danger">{error}</Message>
          ) : (
            <>
              {hide !== true ? (
                <>
                  {objs ? (
                    <>
                      <ul className="search-list">
                        {objs &&
                          objs.map((item, i) => (
                            <Link
                              to={`product/${item.slug}`}
                              onClick={showChange}
                            >
                              <li key={i} onClick={showChange}>
                                <img
                                  src={process.env.REACT_APP_API_PATH + "/media/" + item.image}

                                  alt=""
                                  width="50"
                                  height="40"
                                />
                                <p>
                                  <span className="name-item">{item.name}</span>
                                  {item.sellprice == null || item.sellprice == "" ? (
                                    ""
                                  ) : (
                                    <span className="name-price">
                                      ₹ {item.sellprice}
                                    </span>
                                  )}
                                </p>
                              </li>
                            </Link>
                          ))}
                      </ul>
                    </>
                  ) : (
                    <>
                      <ul className="search-list">
                        <li>
                          <span>No Product Found</span>
                        </li>
                      </ul>
                    </>
                  )}
                </>
              ) : (
                <></>
              )}
            </>
          )}
        </form>
      </div>
    </div>
  );
}

export default HeaderSearch;
