import React, { useEffect } from 'react';
import Inner_banner from '../component/series/Inner_banner';
import Series_section from '../component/series/Series_section';
// import Left_zigzag from '../component/series/Left_zigzag';
import Series_tab from '../component/series/Series_tab';
import Right_zigzag from '../component/series/Right_zigzag';
import SortingSection from '../component/series/SortingSection';
import Fixed_banner from '../component/home/Fixed_banner';
import Satisfaction from '../component/home/Satisfaction';
import Passion from '../component/series/Passion';

export default function Series() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <div>
      <Inner_banner />
      {/* seo in serieas sectioon */}
      <Series_section />
      <Series_tab />
      <SortingSection />
      <Fixed_banner />
      <Passion />
      {/* <Right_zigzag/> */}
      <Satisfaction />


    </div>
  )
}
