import uuid from "uuid";
import axios from "axios";
import { toast } from "react-toastify";
import {
  ADD_ORDER_FAIL,
  ADD_ORDER_REQUEST,
  ADD_ORDER_SUCCESS,
  CANCEL_ORDER_FAIL,
  CANCEL_ORDER_REQUEST,
  CANCEL_ORDER_SUCCESS,
  DELETE_ORDER_FAIL,
  DELETE_ORDER_REQUEST,
  DELETE_ORDER_SUCCESS,
  FIND_ORDER_DETAIL_FAIL,
  FIND_ORDER_DETAIL_REQUEST,
  FIND_ORDER_DETAIL_SUCCESS,
  FIND_ORDER_FAIL,
  FIND_ORDER_REQUEST,
  FIND_ORDER_SUCCESS,
  RETURN_ORDER_FAIL,
  RETURN_ORDER_REQUEST,
  RETURN_ORDER_SUCCESS,
  UPDATE_ORDER_FAIL,
  UPDATE_ORDER_REQUEST,
  UPDATE_ORDER_SUCCESS,
  FIND_ORDER_LIST_DETAIL_FAIL,
  FIND_ORDER_LIST_DETAIL_REQUEST,
  FIND_ORDER_LIST_DETAIL_SUCCESS,
} from "../constants/OrderConstant";

// Fetch Order History
const fetchOrderHistoryRequest = (id) => {
  return function (dispatch) {
    dispatch({
      type: FIND_ORDER_REQUEST,
      payload: true,
    });

    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/admin-order-history/${id}/`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };

    axios(OPTION)
      .then((res) => {
        // console.log("res", res.data);
        dispatch(fetchOrderHistoryRequestPre(res.data));
      })
      .catch((error) => {
        console.log("error", error);
        dispatch({
          type: FIND_ORDER_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
      });
  };
};
export const fetchOrderHistoryRequestPre = (data) => {
  return {
    type: FIND_ORDER_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

// Fetch Order Details
const fetchOrderDetailsRequest = (data) => {
  return function (dispatch) {
    dispatch({
      type: FIND_ORDER_DETAIL_REQUEST,
      payload: true,
    });

    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/admin-order-list/`,
      method: "POST",
      data: data,
      headers: {
        "content-type": "application/json",
      },
    };

    axios(OPTION)
      .then((res) => {
        dispatch(fetchOrderDetailsRequestPre(res.data));
      })
      .catch((error) => {
        dispatch({
          type: FIND_ORDER_DETAIL_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
      });
  };
};
export const fetchOrderDetailsRequestPre = (data) => {
  return {
    type: FIND_ORDER_DETAIL_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

// Fetch Order Details
const fetchOrderListDetailsRequest = (data) => {
  return function (dispatch) {
    dispatch({
      type: FIND_ORDER_LIST_DETAIL_REQUEST,
      payload: true,
    });

    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/admin-order-history/`,
      method: "POST",
      data: data,
      headers: {
        "content-type": "application/json",
      },
    };

    axios(OPTION)
      .then((res) => {
        dispatch(fetchOrderListDetailsRequestPre(res.data));
      })
      .catch((error) => {
        dispatch({
          type: FIND_ORDER_LIST_DETAIL_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
      });
  };
};
export const fetchOrderListDetailsRequestPre = (data) => {
  return {
    type: FIND_ORDER_LIST_DETAIL_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

// add Order Action
const createOrder = (user_id, id, qty) => {
  let code = uuid.v4().toString();
  return function (dispatch) {
    dispatch({
      type: ADD_ORDER_REQUEST,
      payload: true,
    });

    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/create-order/`,
      method: "POST",
      data: {
        user_id: user_id,
        product_id: id,
        quantity: qty,
        code,
      },
      headers: {
        "content-type": "application/json",
      },
    };

    axios(OPTION)
      .then((res) => {
        console.log("res", res.data);
        dispatch(createOrderPre(res.data));
        localStorage.setItem("userCode", JSON.stringify(res.data.data.code));
      })
      .catch((error) => {
        console.log("error", error);
        dispatch({
          type: ADD_ORDER_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
        toast.danger(error.msg);
      });
  };
};
export const createOrderPre = (data) => {
  toast.success(data.msg);
  return {
    type: ADD_ORDER_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

// update Order
const updateOrder = (id, data) => {
  return function (dispatch) {
    dispatch({
      type: UPDATE_ORDER_REQUEST,
      payload: true,
    });
    const token = JSON.parse(localStorage.getItem("jwt"))
      ? JSON.parse(localStorage.getItem("jwt"))
      : "";
    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/admin-Order/`,
      method: "POST",
      data: data,
      headers: {
        "content-type": "application/json",
        token: token,
      },
    };

    axios(OPTION)
      .then((res) => {
        dispatch(updateOrderPre(res.data));
        // dispatch(loadAllOrder());
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_ORDER_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
        toast.danger("Failed To Update The Best Uses!");
      });
  };
};
export const updateOrderPre = (data) => {
  toast.success("Order Updated Successfully!");

  return {
    type: UPDATE_ORDER_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

// Cancel Order
const cancelOrder = (id, data) => {
  /*
  id = request.data.get('id')
  user_id = request.data.get('user_id')
  order_id = request.data.get('order_id')
  productid = request.data.get('productid')
*/
  return function (dispatch) {
    dispatch({
      type: CANCEL_ORDER_REQUEST,
      payload: true,
    });
    const token = JSON.parse(localStorage.getItem("jwt"))
      ? JSON.parse(localStorage.getItem("jwt"))
      : "";
    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/cancel-order/`,
      method: "POST",
      data: data,
      headers: {
        "content-type": "application/json",
        token: token,
      },
    };

    axios(OPTION)
      .then((res) => {
        dispatch(cancelOrderPre(res.data));
        // dispatch(loadAllOrder());
      })
      .catch((error) => {
        dispatch({
          type: CANCEL_ORDER_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
        toast.danger("Failed To Update The Best Uses!");
      });
  };
};
export const cancelOrderPre = (data) => {
  toast.success("Order Updated Successfully!");

  return {
    type: CANCEL_ORDER_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

// Return Order
const returnOrder = (id, data) => {
  /*
   id = request.data.get('id')
        user_id = request.data.get('user_id')
        order_id = request.data.get('order_id')
        productid = request.data.get('productid')
        */

  return function (dispatch) {
    dispatch({
      type: RETURN_ORDER_REQUEST,
      payload: true,
    });
    const token = JSON.parse(localStorage.getItem("jwt"))
      ? JSON.parse(localStorage.getItem("jwt"))
      : "";
    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/return-order/`,
      method: "POST",
      data: data,
      headers: {
        "content-type": "application/json",
        token: token,
      },
    };

    axios(OPTION)
      .then((res) => {
        dispatch(returnOrderPre(res.data));
        // dispatch(loadAllOrder());
      })
      .catch((error) => {
        dispatch({
          type: RETURN_ORDER_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
        toast.danger("Failed To Update The Best Uses!");
      });
  };
};
export const returnOrderPre = (data) => {
  toast.success("Order Updated Successfully!");

  return {
    type: RETURN_ORDER_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

const deleteOrder = (data) => {
  return function (dispatch) {
    dispatch({
      type: DELETE_ORDER_REQUEST,
      payload: true,
    });
    // const token = JSON.parse(localStorage.getItem("jwt"))
    //   ? JSON.parse(localStorage.getItem("jwt"))
    //   : "";
    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/Order-remove/`,
      method: "POST",
      data: data,
      headers: {
        "content-type": "application/json",
      },
    };

    axios(OPTION)
      .then((res) => {
        dispatch(deleteOrderPre(res.data));
        // dispatch(loadAllOrder());
      })
      .catch((error) => {
        dispatch({
          type: DELETE_ORDER_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
      });
  };
};
export const deleteOrderPre = (data) => {
  toast.error("Order Deleted Successfully!");
  return {
    type: DELETE_ORDER_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

export {
  fetchOrderHistoryRequest,
  fetchOrderDetailsRequest,
  fetchOrderListDetailsRequest,
  createOrder,
  updateOrder,
  deleteOrder,
  cancelOrder,
  returnOrder,
};
