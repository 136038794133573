import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { loadSubCategoryImages } from "../../redux/action/StaticAction";
import zig_zag2 from "../../image/zig_zag2.jpg";
import irobot from "../../image/irobot.mp4";
// import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function SortingSubCategory(props) {
  const [tab, settab] = useState([]);
  const [play, setplay] = useState(0);



  //const banner = useSelector((state) => state.Static.prodlist);

  //let bannerData = banner[0];

  const dispatch = useDispatch();

  let tabrcv = useSelector((state) => state.Static.subCategories);
  console.log("tab_main", tab);

  useEffect(() => {
    if (props.banneridglobe != undefined && props.banneridglobe != 0) {
      dispatch(loadSubCategoryImages(props.banneridglobe));
      console.log("...banner in sortingcatge", props.banneridglobe);
    }
    else {
      tabrcv = [];
      console.log("...banner in sortingcatge tabrcv", tabrcv);
      settab([]);
    }

  }, [props.banneridglobe]);

  useEffect(() => {

    settab(tabrcv);

  }, [tabrcv]);





  var vid = document.getElementById("myVideo");

  const playVid = () => {
    vid.play();
  };
  const handleLinkClick = event => {

    setplay(1);

    vid.play();

  };
  const handleLinkClickpause = event => {
    setplay(0)

    vid.pause();

  };
  return (
    <>
      {tab &&
        tab.map((da) => {
          if (da.position == 2) {
            return (
              <section className="section zigzag catt-category" key={da.id}>
                <div className="zigzag_content">
                  {da.status !== 1 ? (
                    ""
                  ) : (
                    <div
                      className="leftt"
                      style={{ background: "da.background_color" }}
                    >
                      <h3 className="title">
                        <span>{da.heading} </span>
                      </h3>
                      <p>{da.content}</p>
                      {da.video !== null && da.video !== "" && play == 0 ? (
                        <div className="playbuttondiv">
                          <button className="playbutton" onClick={handleLinkClick}>

                            <i class="fa fa-play fa-3x"></i>
                          </button>

                        </div>

                      ) : ''}


<div className="two_btn">
                        {da.button1_url == null || da.button1_url == "" ? (
                          ""
                        ) : (
                          <Link to={da.button1_url} className="btn" >
                            {da.button1_name}
                          </Link>
                        )}
                        {da.button2_url == null || da.button2_url == "" ? (
                          ""
                        ) : (
                          <Link to={da.button2_url} className="btn">
                            {da.button2_name}
                          </Link>
                        )}
                      </div>


                      {/* <div className="two_btn">
                        {da.button1_url == null || da.button1_url == "" ? (
                          ""
                        ) : (
                          <button
                            type="button"
                            className="btn"
                            onClick={playVid}
                          >

                            {da.button1_name}{" "}
                          </button>
                     
                        )}
                        {da.button2_url == null || da.button2_url == "" ? (
                          ""
                        ) : (
                          <Link to={da.button2_url} className="btn">
                            {da.button2_name}
                          </Link>
                        )}
                      </div> */}
                    </div>
                  )}
                  {da.status !== 1 ? (
                    ""
                  ) : (
                    <div className="rightt">
                      {da.video !== null && da.video !== "" ? (
                        <video id="myVideo" width="100%" height="100%" controls>
                          <source
                            src={`${process.env.REACT_APP_API_PATH}${da.video}`}
                            type="video/mp4"
                          />
                        </video>
                      ) : da.image == "" ? (
                        <img src={zig_zag2} alt="" />
                      ) : (
                        <>
                          <img
                            src={`${process.env.REACT_APP_API_PATH}${da.image}`}
                            alt="frf"
                            style={{
                              height: "da.image_height",
                              weight: "da.image_width",
                            }}
                          />
                        </>
                      )}
                    </div>
                  )}
                </div>
              </section>
            );
          } else {
            return (
              <section className="section zigzag catt-category" key={da.id}>
                <div className="zigzag_content">
                  {da.status !== 1 ? (
                    ""
                  ) : (
                    <div className="rightt">
                      {da.video !== null && da.video !== "" ? (
                        <video id="myVideo" width="100%" height="100%" controls>
                          <source
                            src={`${process.env.REACT_APP_API_PATH}${da.video}`}
                            type="video/mp4"
                          />
                        </video>
                      ) : da.image == "" ? (
                        <img src={zig_zag2} alt="" />
                      ) : (
                        <>
                          <img
                            src={`${process.env.REACT_APP_API_PATH}${da.image}`}
                            alt="frf"
                            style={{
                              height: "da.image_height",
                              weight: "da.image_width",
                            }}
                          />
                        </>
                      )}
                    </div>
                  )}
                  {da.status !== 1 ? (
                    ""
                  ) : (
                    <div
                      className="leftt"
                      style={{ background: "da.background_color" }}
                    >
                      <h3 className="title">
                        <span>{da.heading} </span>
                      </h3>
                      <p>{da.content}</p>

                      {da.video !== null && da.video !== "" && play == 0 ? (
                        <div className="playbuttondiv">
                          <button className="playbutton" onClick={handleLinkClick}>

                            <i class="fa fa-play fa-3x"></i>
                          </button>

                        </div>

                      ) : ''}
                      {da.video !== null && da.video !== "" && play == 1 ? (
                        <div className="playbuttondiv">
                          <button className="playbutton" onClick={handleLinkClickpause}>

                            <i class="fa fa-stop fa-3x"></i>
                          </button>

                        </div>

                      ) : ''}

                      <div className="two_btn">
                        {da.button1_url == null || da.button1_url == "" ? (
                          ""
                        ) : (
                          <Link to={da.button1_url} className="btn" >
                            {da.button1_name}
                          </Link>
                        )}
                        {da.button2_url == null || da.button2_url == "" ? (
                          ""
                        ) : (
                          <Link to={da.button2_url} className="btn">
                            {da.button2_name}
                          </Link>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </section>
            );
          }
        })}
    </>
  );
}
