import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { signinUser } from "../redux/action/AuthAction";
import OrderInvoice from "../component/dashboard/OrderInvoice";
import { searchOrdertract } from "../redux/action/AdditionalAction";

const FlickerMessage = () => (
  <div className="flicker-message">
   If you are unable to log into your account , please try to re-register. Regret for the inconvenience.
  </div>
);

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [order_email, setOrder_email] = useState("");
  const [captchaResult, setCaptchaResult] = useState();
  const [showInvoice, setshowInvoice] = useState(false);
  const [order_id, setOrder_id] = useState("");
  const [awbnumber, setawbnumber] = useState("");

  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.AuthReducer.msg);
  const ordertrack = useSelector((state) => state.Additional.searchOrderTract);

  const handleInvoice = (id) => {
    setOrder_id(id);
    setshowInvoice(!showInvoice);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(signinUser(email, password));
  };

  const handleawbInvoice = () => {
    dispatch(searchOrdertract(awbnumber, ""));
  };

  useEffect(() => {
    document.getElementById("maintittle").innerHTML = "Roomba robot vacuums & Braava mopping robot - iRobot India";
    document.getElementById("description").setAttribute("content", "irobot, irobot india, irobot roomba, irobot braava, roomba, braava, robotic vacuum cleaner, robot vacuum, robot mop");
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    console.log("ordertrack..", ordertrack);
  }, [ordertrack]);

  return (
    <>
      <FlickerMessage />
      <section className="section login-page">
        <div className="container">
          <div className="left-login">
            <div className="accou">
              <p className="subheading">My account</p>
              <p>
                <Link to="/register">Create an Account</Link>
              </p>
            </div>
            <div className="need-help">
              <p className="subheading">Need Help?</p>
              <p className="userful">
                If you have questions about your account, we're happy to take your
                call
                <button onClick={(e) => (window.location = "tel:1-800-727-9077")}>
                  (1800-200-33-55)
                </button>
                .
              </p>
            </div>
          </div>
          <div className="right-large">
            <div className="title-login">
              <h3 className="title">My Account Login</h3>
            </div>
            <div className="divide-login">
              <div className="left-small">
                <div className="login-form">
                  <h3 className="login-heading">Returning Customers</h3>
                  <p className="login-content">
                    If you are a registered user, please enter your email and
                    password.
                  </p>
                  <form onSubmit={submitHandler}>
                    <div className="input_field">
                      <label>
                        Email address <span>*</span>
                      </label>
                      <input
                        type="email"
                        required
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className="input_field">
                      <label>
                        Password <span>*</span>
                      </label>
                      <input
                        type="password"
                        name="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                    <div>
                      <Link to="/forgot-password">
                        <p style={{ color: "black" }}>Forgot Password ?</p>
                      </Link>
                    </div>
                    <p className="" style={{ color: "red", margin: 0 }}>
                      {userLogin}
                    </p>
                    <div className="input_field_btn">
                      <input type="submit" value="Log in" />
                    </div>
                  </form>
                </div>
                <div className="check-status">
                  <h3 className="login-heading">Check An Order</h3>
                  <form>
                    <div className="input_field">
                      <label>
                        AWB Number <span>*</span>
                      </label>
                      <input
                        required
                        type="text"
                        value={awbnumber}
                        name="awbnumber"
                        onChange={(e) => setawbnumber(e.target.value)}
                      />
                    </div>
                    <div className="input_field_btn">
                      <input
                        type="button"
                        value="Check Status"
                        onClick={() => handleawbInvoice()}
                      />
                    </div>
                  </form>
                  <div className="">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: ordertrack,
                      }}
                    ></div>
                  </div>
                </div>
              </div>
              <div className="right-small">
                <div className="new-customer">
                  <h3 className="login-heading">New Customers</h3>
                  <p className="login-content">
                    Creating an Account is easy. Just fill in the form below and
                    enjoy the benefits of having an account.
                  </p>
                  <Link to="/register" className="btn create-account">
                    Create an Account
                  </Link>
                </div>
                <div className="custer-benifits">
                  <h3>Benefits of Creating an Account</h3>
                  <p>
                    <span>Order History</span>
                    Receive important information about your order. You can even
                    track it up to the minute it arrives.
                  </p>
                  <p>
                    <span>Faster Checkout</span>
                    Save your shipping information to make it easier and faster
                    the next time you buy.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="logininvoice">
        {showInvoice ? <OrderInvoice order_id={order_id} /> : null}
      </div>
    </>
  );
}