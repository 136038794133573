import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { Link} from "react-router-dom";
import home from "../../image/home.jpg";
import { loadAllAboutContent } from "../../redux/action/StaticAction";

export default function TechTop() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadAllAboutContent());
  }, [dispatch]);
  const about = useSelector((state) => state.Static.aboutContent);
  console.log("about", about);
  return (
    <>
      {about.slice(0, 1).map((e) => {
        return (
          <>
            <div className="container-fluid">
              <div className="row g-0">
                <div className="col-md-12 bg_img ">
                  <img src={home} className="img-fluid-n w-100" alt="" />
                </div>
              </div>
            </div>
          </>
        );
      })}
    </>
  );
}
