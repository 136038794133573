export const Find_ALL_PRODUCT_DETAILS_FAIL = "Find_ALL_PRODUCT_DETAILS_FAIL";
export const Find_ALL_PRODUCT_DETAILS_SUCCESS =
  "Find_ALL_PRODUCT_DETAILS_SUCCESS";
export const Find_ALL_PRODUCT_DETAILS_REQUEST =
  "Find_ALL_PRODUCT_DETAILS_REQUEST";

export const Find_ALL_PRODUCT_IMAGES_FAIL = "Find_ALL_PRODUCT_IMAGES_FAIL";
export const Find_ALL_PRODUCT_IMAGES_SUCCESS =
  "Find_ALL_PRODUCT_IMAGES_SUCCESS";
export const Find_ALL_PRODUCT_IMAGES_REQUEST =
  "Find_ALL_PRODUCT_IMAGES_REQUEST";

export const Find_ALL_PRODUCT_SPECIFICATION_FAIL =
  "Find_ALL_PRODUCT_SPECIFICATION_FAIL";
export const Find_ALL_PRODUCT_SPECIFICATION_SUCCESS =
  "Find_ALL_PRODUCT_SPECIFICATION_SUCCESS";
export const Find_ALL_PRODUCT_SPECIFICATION_REQUEST =
  "Find_ALL_PRODUCT_SPECIFICATION_REQUEST";

export const Find_ALL_PRODUCT_ADD_DETAIL_FAIL =
  "Find_ALL_PRODUCT_ADD_DETAIL_FAIL";
export const Find_ALL_PRODUCT_ADD_DETAIL_SUCCESS =
  "Find_ALL_PRODUCT_ADD_DETAIL_SUCCESS";
export const Find_ALL_PRODUCT_ADD_DETAIL_REQUEST =
  "Find_ALL_PRODUCT_ADD_DETAIL_REQUEST";

export const Find_ACCESSORY_PRODUCT_LIST_FAIL =
  "Find_ACCESSORY_PRODUCT_LIST_FAIL";
export const Find_ACCESSORY_PRODUCT_LIST_SUCCESS =
  "Find_ACCESSORY_PRODUCT_LIST_SUCCESS";
export const Find_ACCESSORY_PRODUCT_LIST_REQUEST =
  "Find_ACCESSORY_PRODUCT_LIST_REQUEST";

export const Find_PRODUCT_LIST_FAIL = "Find_PRODUCT_LIST_FAIL";
export const Find_PRODUCT_LIST_SUCCESS = "Find_PRODUCT_LIST_SUCCESS";
export const Find_PRODUCT_LIST_REQUEST = "Find_PRODUCT_LIST_REQUEST";

export const SEARCH_PRODUCT_LIST_FAIL = "SEARCH_PRODUCT_LIST_FAIL";
export const SEARCH_PRODUCT_LIST_SUCCESS = "SEARCH_PRODUCT_LIST_SUCCESS";
export const SEARCH_PRODUCT_LIST_REQUEST = "SEARCH_PRODUCT_LIST_REQUEST";

export const FIND_ALL_PRODUCT_DESC_FAIL = "FIND_ALL_PRODUCT_DESC_FAIL";
export const FIND_ALL_PRODUCT_DESC_SUCCESS = "FIND_ALL_PRODUCT_DESC_SUCCESS";
export const FIND_ALL_PRODUCT_DESC_REQUEST = "FIND_ALL_PRODUCT_DESC_REQUEST";

export const FIND_ALL_PRODUCT_FAQ_FAIL = "FIND_ALL_PRODUCT_FAQ_FAIL";
export const FIND_ALL_PRODUCT_FAQ_SUCCESS = "FIND_ALL_PRODUCT_FAQ_SUCCESS";
export const FIND_ALL_PRODUCT_FAQ_REQUEST = "FIND_ALL_PRODUCT_FAQ_REQUEST";

export const LOAD_ALL_MAIN_PRODUCT_LIST_FAIL =
  "LOAD_ALL_MAIN_PRODUCT_LIST_FAIL";
export const LOAD_ALL_MAIN_PRODUCT_LIST_SUCCESS =
  "LOAD_ALL_MAIN_PRODUCT_LIST_SUCCESS";
export const LOAD_ALL_MAIN_PRODUCT_LIST_REQUEST =
  "LOAD_ALL_MAIN_PRODUCT_LIST_REQUEST";
