import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { loadAllProductReview } from "../../redux/action/ProductReviewAction";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/fontawesome-free-solid";
import Loader from "../Shared/Loader";
import Message from "../share/Message";
import ReviewSlider from "./ReviewSlider"
import { Link } from "react-router-dom"
import { loadAllProductImage } from "../../redux/action/ProductAction";
import { isEmptyObject } from "jquery";
import axios from "axios";


export default function Review() {
  const [currentPage, setcurrentPage] = useState(1);
  const [itemsPerPage, setitemsPerPage] = useState(10);
  const [pageNumberLimit, setpageNumberLimit] = useState(5);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0);
  let dispatch = useDispatch();
  let { id } = useParams();
  const [countreview, setcountreview] = useState(0);
  const [ratingper, setratingper] = useState(0);

  useEffect(() => {
    if (id) {
      dispatch(loadAllProductReview(id));
    }
  }, [id]);

  const reviewData = useSelector((state) => state.ProductReview);

  const { loading, error, result } = reviewData;


  useEffect(() => {
    if (reviewData) {

      if (reviewData.result.length > 0) {
        if (countreview == 0) {
          console.log("reviewData..", reviewData.result);

          setcountreview(reviewData.result.length);

          var tot = 0;
          reviewData.result.map((key, index) => {

            tot = tot + Number(key.rating);
          })
          var rating = tot / reviewData.result.length;
          setratingper(rating.toFixed(1));

        }
      }
    }

  });

  // console.log("result",reviewData.result);
  const handleClick = (e) => {
    setcurrentPage(Number(e.target.id));
  };

  const pages = [];
  for (let i = 1; i <= Math.ceil(result.length / itemsPerPage); i++) {
    pages.push(i);
  }

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = result.slice(indexOfFirstItem, indexOfLastItem);
  //  console.log(currentItems, "avvv");
  const renderPageNumbers = pages.map((number) => {
    if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
      return (
        <li
          key={number}
          id={number}
          onClick={handleClick}
          className={currentPage === number ? "active" : null}
        >
          {number}
        </li>
      );
    } else {
      return null;
    }
  });

  const handleNextbtn = () => {
    setcurrentPage(currentPage + 1);

    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };

  const handlePrevbtn = () => {
    setcurrentPage(currentPage - 1);

    if ((currentPage - 1) % pageNumberLimit === 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };

  let pageIncrementBtn = null;
  if (pages.length > maxPageNumberLimit) {
    pageIncrementBtn = <li onClick={handleNextbtn}> &hellip; </li>;
  }

  let pageDecrementBtn = null;
  if (minPageNumberLimit >= 1) {
    pageDecrementBtn = <li onClick={handlePrevbtn}> &hellip; </li>;
  }

  let formatter = new Intl.DateTimeFormat("en-GB", {
    year: "numeric",
    month: "numeric",
    day: "2-digit",
  });
  useEffect(() => {
    //dispatch(loadAllProductImage(id));
    const fetchData = async () => {
      // setLoading(true);
      try {

        //const url = process.env.REACT_APP_API_PATH+"/api/v1/admin-product/" + id + "/";
        const url = process.env.REACT_APP_API_PATH + "/api/v1/admin-product-n/" + id + "/";

        const { data: response } = await axios.get(
          url
        );
        // console.log('sal..', JSON.stringify(response.data.ac_item_no));
        let cattypercv = response.data.subcategory.type;
        console.log('cattype..', cattypercv);

        prodtype(cattypercv);

      } catch (error) {
        console.error(error.message);

      }

      //setLoading(false);
    };
    fetchData();
  }, [id]);
  let productData = useSelector((state) => state.MainProduct);

  let { productImages } = productData;
  const [prodtype, setProdtype] = useState();

  useEffect(() => {
    if (productImages != null && !isEmptyObject(productImages)) {
      console.log('setProdtype...' + productImages[0].productId.type)
      setProdtype(productImages[0].productId.type);
    }
  }, [productImages]);


  return (
    <>
      {/* <ReviewSlider/> */}

      {prodtype != 2 ? <section className="review_content">
        <div className="container rew_con">
          <div className="top_coment revv">
            <div className="comment_title">
              <h2 className="titile" style={{ marginTop: "25px" }}>
                Recent Reviews
              </h2>
            </div>
            <div className="comment-steps">
              <div className="total-number">
                <h4> {ratingper}</h4>
                {/*


                <div className="starts">
                  <FontAwesomeIcon icon={faStar} />
                  <FontAwesomeIcon icon={faStar} />
                  <FontAwesomeIcon icon={faStar} />
                  <FontAwesomeIcon icon={faStar} />
                  <FontAwesomeIcon icon={faStar} />
                </div>
                  */}
                <p className="number-of rev">{result.length} Review</p>
                {/* <button className="btn write_review_btn">Write a Review</button> */}
                <Link
                  to={`/write-review/${id}`}
                  className="btn write_review_btn btn3"
                >
                  Write a Review
                </Link>
              </div>
            </div>

            {/* 
            <div className="response">
              <h5>
                <span>100%</span>
                <small>of respondents would recommend this to a friend</small>
              </h5>
            </div>
            <div className="starts-rating-value">
              <ul>
                <li>
                  <span className="value">5 Starts</span>
                  <span className="value-stars">
                    <span
                      className="value-color"
                      style={{ width: "30%" }}
                    ></span>
                  </span>
                  <span className="nu-of-review">313</span>
                </li>
                <li>
                  <span className="value">4 Starts</span>
                  <span className="value-stars">
                    <span
                      className="value-color"
                      style={{ width: "40%" }}
                    ></span>
                  </span>
                  <span className="nu-of-review">313</span>
                </li>
                <li>
                  <span className="value">3 Starts</span>
                  <span className="value-stars">
                    <span
                      className="value-color"
                      style={{ width: "60%" }}
                    ></span>
                  </span>
                  <span className="nu-of-review">313</span>
                </li>
                <li>
                  <span className="value">2 Starts</span>
                  <span className="value-stars">
                    <span
                      className="value-color"
                      style={{ width: "10%" }}
                    ></span>
                  </span>
                  <span className="nu-of-review">313</span>
                </li>
                <li>
                  <span className="value">1 Starts</span>
                  <span className="value-stars">
                    <span
                      className="value-color"
                      style={{ width: "25%" }}
                    ></span>
                  </span>
                  <span className="nu-of-review">313</span>
                </li>
              </ul>
            </div>
*/}
          </div>

          <div className="detail_comment">
            <ul>
              {currentItems &&
                currentItems.map((item, i) => (
                  <li key={item.id}>
                    <div className="comment_title">
                      <div className="prod-review">
                        <img
                          src={`${process.env.REACT_APP_API_PATH}${item.image}`}
                        />
                      </div>



                      <div className="comment_content">

                        <p>
                          <strong>
                            {item.heding}
                          </strong>
                          <br />
                          by {item.nickname}
                        </p>
                        <p>


                          {item.comments}
                        </p>

                      </div>

                      <div className="right-revv">
                        <div className="starts">
                          {item.rating == 1 ? (
                            <>
                              <FontAwesomeIcon icon={faStar} />
                            </>
                          ) : item.rating == 2 ? (
                            <>
                              <FontAwesomeIcon icon={faStar} />
                              <FontAwesomeIcon icon={faStar} />
                            </>
                          ) : item.rating == 3 ? (
                            <>
                              <FontAwesomeIcon icon={faStar} />
                              <FontAwesomeIcon icon={faStar} />
                              <FontAwesomeIcon icon={faStar} />
                            </>
                          ) : item.rating == 4 ? (
                            <>
                              <FontAwesomeIcon icon={faStar} />
                              <FontAwesomeIcon icon={faStar} />
                              <FontAwesomeIcon icon={faStar} />
                              <FontAwesomeIcon icon={faStar} />
                            </>
                          ) : (
                            <>
                              <FontAwesomeIcon icon={faStar} />
                              <FontAwesomeIcon icon={faStar} />
                              <FontAwesomeIcon icon={faStar} />
                              <FontAwesomeIcon icon={faStar} />
                              <FontAwesomeIcon icon={faStar} />
                            </>
                          )}
                        </div>
                        {/* 
                          <div className="comment_name">
                            <span>Submitted on</span>
                            <span className="date">
                              {formatter.format(Date.parse(item.created_at))}
                            </span>
                          </div>
                          */}
                      </div>
                    </div>


                    <div className="comment_content">{item.reply}</div>
                    {/* <div className="comment_content">{item.description}</div> */}

                  </li>
                ))}
            </ul>
            <div className="mt-4">
              {pages.length == 5 ? (
                <ul className="pageNumbers">
                  <li>
                    <button
                      onClick={handlePrevbtn}
                      disabled={currentPage === pages[0] ? true : false}
                    >
                      Prev
                    </button>
                  </li>
                  {pageDecrementBtn}
                  {renderPageNumbers}
                  {pageIncrementBtn}

                  <li>
                    <button
                      onClick={handleNextbtn}
                      disabled={
                        currentPage === pages[pages.length - 1] ? true : false
                      }
                    >
                      Next
                    </button>
                  </li>
                </ul>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </section> : ""}
    </>
  );
}
