import React, { useState, useEffect, useRef } from "react";

import { useSelector, useDispatch } from "react-redux";
import { loadCompareProductList } from "../../redux/action/ProductAction";
import { Link } from "react-router-dom";
import Loader from "../Shared/Loader";
import Message from "../share/Message";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import StickyTable from "react-sticky-table-thead";
import $ from "jquery";
import Parser from "html-react-parser";
import Footer from "../../component/share/Footer";

//import 'bootstrap/dist/css/bootstrap.css';
import Dropdown from 'react-bootstrap/Dropdown';

import ReactSelect from 'react-select';
import Select from 'react-select';
import { isMobile } from 'react-device-detect';



export default function CompareProductMain() {

  useEffect(() => {
    document.getElementById("maintittle").innerHTML = "Compare Robot Vacuums | iRobot®";
    document.getElementById("description").setAttribute("content", "Not sure which robot vacuum to buy? Compare Roomba robot vacuum models using the Roomba comparison chart to find your perfect robot vacuum or mop.");
  }, []);

  const ref = useRef();
  const [navheight, setNavheight] = useState(0);
  const [objs, setobjs] = useState([]);

  const [scrollPosition, setScrollPosition] = useState(0);

  // var classHighlight = "highlight";
  // var $thumbs = $(".thumbnail li").click(function () {
  //   $thumbs.removeClass("highlight");
  //   $(this).addClass("highlight");
  // });
  $(function () {
    $(".thumbnail li ").click(function () {
      $(this).addClass("highlight");
      $(this).siblings().removeClass("highlight");
    });
  });

  var settings = {
    dots: false,
    arrows: true,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 4,
    autoplay: false,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const productList = useSelector((state) => state.MainProduct);

  const { loading, error, result3 } = productList;

  useEffect(() => {
    console.log(result3, "result3");


    var objsrcv = result3.map((x) => ({
      value: x[0],
      //label: x[1],
      label: x[48],

      id: x[0],
      //name: x[1],
      name: x[48],

      image: x[2],
      price: x[3],
      sellprice: x[4],
      pcf_title1: x[5],
      pcf_title2: x[6],
      pcf_title3: x[7],
      pcf_title4: x[8],
      pcf_title5: x[9],
      pcf_title6: x[10],
      pcf_title7: x[11],
      pcf_title8: x[12],
      pcf_title9: x[13],
      pcf_title10: x[14],

      //product_mapping_navigation_features_id: x[15],
      product_mapping_navigation_features_id: x[16],

      pmnf_title1: x[17],
      pmnf_title2: x[18],
      pmnf_title3: x[19],
      pmnf_title4: x[20],
      pmnf_title5: x[21],
      pmnf_title6: x[21],
      pmnf_title7: x[23],
      pmnf_title8: x[24],
      pmnf_title9: x[25],
      pmnf_title10: x[26],
      pmnf_title11: x[27],
      pmnf_title12: x[28],
      pmnf_title13: x[29],

      product_mopping_features_id: x[30],
      pmf_title1: x[31],
      pmf_title2: x[32],
      pmf_title3: x[33],
      pmf_title4: x[34],
      pmf_title5: x[35],
      pmf_title6: x[36],
      product_smart_features_id: x[37],
      psf_title1: x[38],
      psf_title2: x[39],
      psf_title3: x[40],
      psf_title4: x[41],
      product_customisation_features_id: x[42],
      pcuf_title1: x[43],
      pcuf_title2: x[44],
      pcuf_title3: x[45],
      alias: x[46],
      slug: x[47],


    }));

    setobjs(objsrcv)
  }, [result3]);






  const dispatch = useDispatch();
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedItemsMob, setSelectedItemsMob] = useState([]);

  const [count, setCount] = useState(0);
  const [color, setColor] = useState();
  const [flag1, setflag1] = useState(1);
  const [flag2, setflag2] = useState(1);

  const [flag3, setflag3] = useState(1);

  const [flagDropbind, setflagDropbind] = useState(1);


  //console.log(count, "count");
  //console.log("selectedItems", selectedItems);
  //console.log("selectedItemsMob", selectedItemsMob);


  const addToCompare = (item) => {


    var flag = true;
    selectedItems.map((itemr, i) => {

      if (itemr.id == item.id) {
        console.log('..', itemr.id)
        flag = false;
      }
    }

    );

    if (flag) {
      console.log(item.id, "compare");
      $('.' + item.id).show();
      // setCount(item.id);
      // if (count != item.id) {
      setSelectedItems((selectedItems) => [...selectedItems, item]);
      setColor("red");
      //}
    }

  };


  const removeFromCompare = (item) => {
    console.log(item.id, "remove");

    $("#" + item.id).removeClass("highlight");
    $("." + item.id).hide();
    const filteredItems = selectedItems.filter(
      (product) => product.id !== item.id
    );
    setSelectedItems(filteredItems);

  };

  useEffect(() => {
    dispatch(loadCompareProductList());
  }, [dispatch]);


  const [dropdown1, setdropdown1] = useState();
  const [dropdown2, setdropdown2] = useState();
  const [imageone, setimageone] = useState();
  const [imagetwo, setimagetwo] = useState();
  const [compareid1, setcompareid1] = useState();
  const [compareid2, setcompareid2] = useState();

  useEffect(() => {

    if (objs.length > 0) {

      if (flagDropbind == 1) {

        setflagDropbind(0);
        setdropdown1(objs[0]);
        setdropdown2(objs[1]);
        console.log("setflagDropbind")

        setimageone(objs[0].image);
        setimagetwo(objs[1].image);
        setcompareid1(objs[0].slug);
        setcompareid2(objs[1].slug);
        setSelectedItemsMob((selectedItemsMob) => [...selectedItemsMob, objs[0]]);
        setSelectedItemsMob((selectedItemsMob) => [...selectedItemsMob, objs[1]]);


      }
      if (flag1 == 1) {
        setflag1(0);
        $("#" + objs[0].id).addClass("highlight");
        $("#" + objs[0].id).siblings().removeClass("highlight");
        addToCompare(objs[0]);


      }
      if (flag2 == 1) {
        setflag2(0);

        $("#" + objs[1].id).addClass("highlight");
        $("#" + objs[1].id).siblings().removeClass("highlight");
        addToCompare(objs[1]);
      }
      if (flag3 == 1) {
        setflag3(0);

        $("#" + objs[2].id).addClass("highlight");
        $("#" + objs[2].id).siblings().removeClass("highlight");
        addToCompare(objs[2]);

      }

    }
  }, [objs]);

  // console.log("objs", objs);
  if (selectedItems !== []) {
    var result = objs.find(function (selectedItems) {
      return selectedItems;
    });
    // console.log("result", result);
  }


  const drop1change = (item) => {

    setdropdown1(item);
    setimageone(item.image);
    setSelectedItemsMob([]);

    setSelectedItemsMob((selectedItemsMob) => [...selectedItemsMob, item]);
    setSelectedItemsMob((selectedItemsMob) => [...selectedItemsMob, dropdown2]);
    console.log('setdropdown1', item);
    //console.log(dropdown2);


  };
  const drop2change = (item) => {

    setdropdown2(item);
    setimagetwo(item.image);
    setSelectedItemsMob([]);
    setSelectedItemsMob((selectedItemsMob) => [...selectedItemsMob, dropdown1]);
    setSelectedItemsMob((selectedItemsMob) => [...selectedItemsMob, item]);
    // console.log(dropdown1);
    console.log('drop2change', item);
  };
  const [divheight, setdivheight] = useState(800);


  useEffect(() => {
    console.log('useEffect-call')

    // window.scrollTo(0, 0);

    setTimeout(setNavheight(ref.current.clientHeight), 9000);
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  var [scrollTop, setscrollTop] = useState(true);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);

    //setScrollPosition(position);
    // if (position > 700) {
    console.log('handleScroll ' + position + ".." + scrollTop + ".." + divheight)

    //setScrollPosition(position);
    //if (!isMobile) {

    // window.scrollTo(0, 725);
    // }

    //  }
    if (position > 720) {
      //setdivheight(800);
    }
    if (position < 720) {
      //setScrollPosition(position);
      //setdivheight(5000);
      // setdivheight(800);

    }


    /*
    if (position > 600) {
      console.log('handleScroll: ');
      setScrollPosition(position);
      setdivheight(800);
    }
    else {
      // setScrollPosition(position);
      // setdivheight(500);
    }
    */

  };
  const handleScrollnew = event => {

    if (!isMobile) {

      window.scrollTo(0, 725);
    }
    if (event.currentTarget.scrollTop > 2400) {
      //setdivheight(100);
      var heightrcv = event.currentTarget.scrollTop - 2400;
      console.log('handleScrollnew ' + event.currentTarget.scrollTop + ".." + scrollTop + ".." + divheight + ".." + heightrcv)

      // console.log('cal heiht: ', heightrcv);
      if (heightrcv < 725) {

        //setdivheight(800 - heightrcv);
        setscrollTop(false);
        window.scrollTo(0, 800 + heightrcv)
        if (!isMobile) {

          // window.scrollTo(0, 800 - heightrcv);
        }

      }
      else {

      }

    }

    //if (divheight >= 800) {
    if (!isMobile) {

      // window.scrollTo(0, 725);
    }



  };
  const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      // const color = chroma(data.color);
      console.log({ data, isDisabled, isFocused, isSelected });
      return {
        ...styles,
        backgroundColor: isFocused ? "#447355" : null,
        color: "#333333",
        padding: 10
      };
    }
  };

  return (
    <>
      <section className="section compare-all comparedeskmob">
        <h2 className="compare_heading">
          Choose products to see how the features stack up
        </h2>
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : (
          <div className="containermobcampare">

            <div className="containersticky">

              <Select isSearchable={false}
                placeholder="Select Option"
                value={dropdown1} // set selected value
                options={objs} // set list of the data
                onChange={drop1change} // assign onChange function
                styles={colourStyles}
              />
            </div>

            <div className="containersticky">

              <Select isSearchable={false}
                placeholder="Select Option"
                value={dropdown2} // set selected value
                options={objs} // set list of the data
                onChange={drop2change} // assign onChange function
                styles={colourStyles}
              />
            </div>

            <div className="comparemobdropdown">


              {/* 
              <ReactSelect
                value={dropdown1}
                onChange={drop1change}
                options={objs}
                formatOptionLabel={item => (
                  <div className="country-option">
                    <span>{item.name}</span>
                    <br />
                    <span>{item.alias}</span>

                  </div>
                )}
              />
              */}


              <div>
                <img
                  src={process.env.REACT_APP_API_PATH + "/media/" + imageone}
                  alt=""

                />
                <Link to={`/product/${compareid1}`}>Shop Now</Link>


              </div>
            </div>

            <div className="comparemobdropdown">

              {/* 

              <ReactSelect
                value={dropdown2}
                onChange={drop2change}
                options={objs}
                formatOptionLabel={item => (
                  <div className="country-option">
                    <span>{item.name}</span>
                    <br />
                    <span>{item.alias}</span>

                  </div>
                )}
              />
              */}

              <div>
                <img
                  src={process.env.REACT_APP_API_PATH + "/media/" + imagetwo}
                  alt=""

                />
                <Link to={`/product/${compareid1}`}>Shop Now</Link>

              </div>
            </div>

            {selectedItemsMob.length > 0 && (
              <div className="compare-tabel-mob">
                <table>

                  <tbody>
                    <tr className="cell-heading">
                      <td className="subheading" style={{ margin: 0 }}>
                      </td>
                      <td className="subheading" style={{ margin: 0 }}>
                        Mapping and Navigation Features
                      </td>
                    </tr>
                    <tr>

                      {selectedItemsMob.map((item, i) => (

                        <>
                          {i === 1 ? (

                            <td className="fixed-cell">
                              <div className="fixed-cell-heading">
                                Imprint® Smart Mapping– Hazard Detection & Avoidance
                              </div>

                              Careful Drive
                            </td>
                          ) : (
                            ''
                          )}

                          {item.pmnf_title1 === "1" ? (
                            <td key={i}>
                              <span className="tick"></span>
                            </td>
                          ) : item.pmnf_title1 === "0" ? (
                            <td key={i}> <div className="wraptdmob"></div></td>
                          ) : (
                            <td key={i}> <div className="wraptdmob">{item.pmnf_title1}</div></td>
                          )}
                        </>
                      ))}
                    </tr>
                    <tr>

                      {selectedItemsMob.map((item, i) => (
                        <>
                          {i === 1 ? (

                            <td className="fixed-cell">
                              Detects and avoids objects, then adds them to its map

                            </td>
                          ) : (
                            ''
                          )}

                          {item.pmnf_title2 === "1" ? (
                            <td key={i}>
                              <span className="tick"></span>
                            </td>
                          ) : item.pmnf_title2 === "0" ? (
                            <td key={i}> <div className="wraptdmob"></div></td>
                          ) : (
                            <td key={i}> <div className="wraptdmob">{item.pmnf_title2}</div></td>
                          )}
                        </>
                      ))}
                    </tr>
                    <tr>

                      {selectedItemsMob.map((item, i) => (

                        <>
                          {i === 1 ? (

                            <td className="fixed-cell">
                              Notifies you about hazards in your home and remembers
                              your input
                            </td>
                          ) : (
                            ''
                          )}
                          {item.pmnf_title3 === "1" ? (
                            <td key={i}>
                              <span className="tick"></span>
                            </td>

                          ) : item.pmnf_title3 === "0" ? (
                            <td key={i}> <div className="wraptdmob"></div></td>
                          ) : (
                            <td key={i}> <div className="wraptdmob">{item.pmnf_title3}</div></td>
                          )}
                        </>
                      ))}
                    </tr>
                    <tr>

                      {selectedItemsMob.map((item, i) => (
                        <>
                          {i === 1 ? (

                            <td className="fixed-cell">
                              <div className="fixed-cell-heading">
                                Imprint® Smart Mapping– Targeted Area Cleaning
                              </div>
                              Creates Clean Zones and Keep Out
                              Zones
                            </td>
                          ) : (
                            ''
                          )}
                          {item.pmnf_title4 === "1" ? (
                            <td key={i}>
                              <span className="tick"></span>
                            </td>
                          ) : item.pmnf_title4 === "0" ? (
                            <td key={i}> <div className="wraptdmob"></div></td>
                          ) : (
                            <td key={i}> <div className="wraptdmob">{item.pmnf_title4}</div></td>
                          )}
                        </>
                      ))}
                    </tr>

                    <tr>

                      {selectedItemsMob.map((item, i) => (
                        <>
                          {i === 1 ? (

                            <td className="fixed-cell">
                              Customisable Clean Zones within rooms

                            </td>
                          ) : (
                            ''
                          )}
                          {item.pmnf_title5 === "1" ? (
                            <td key={i}>
                              <span className="tick"></span>
                            </td>
                          ) : item.pmnf_title5 === "0" ? (
                            <td key={i}> <div className="wraptdmob"></div></td>
                          ) : (
                            <td key={i}> <div className="wraptdmob">{item.pmnf_title5}</div></td>
                          )}
                        </>
                      ))}
                    </tr>
                    <tr>

                      {selectedItemsMob.map((item, i) => (
                        <>
                          {i === 1 ? (

                            <td className="fixed-cell">
                              Customisable Clean Zones within rooms
                              Cleans small areas within rooms at your voice command*

                            </td>
                          ) : (
                            ''
                          )}
                          {item.pmnf_title6 === "1" ? (
                            <td key={i}>
                              <span className="tick"></span>
                            </td>
                          ) : item.pmnf_title6 === "0" ? (
                            <td key={i}> <div className="wraptdmob"></div></td>
                          ) : (
                            <td key={i}> <div className="wraptdmob">{item.pmnf_title6}</div></td>
                          )}
                        </>
                      ))}
                    </tr>
                    <tr>

                      {selectedItemsMob.map((item, i) => (
                        <>
                          {i === 1 ? (

                            <td className="fixed-cell">
                              <div className="fixed-cell-heading">
                                Imprint® Smart Mapping— Targeted Room Cleaning
                              </div>
                              Cleans specific rooms you
                              choose
                            </td>
                          ) : (
                            ''
                          )}
                          {item.pmnf_title7 === "1" ? (
                            <td key={i}>
                              <span className="tick"></span>
                            </td>
                          ) : item.pmnf_title7 === "0" ? (
                            <td key={i}> <div className="wraptdmob"></div></td>
                          ) : (
                            <td key={i}> <div className="wraptdmob">{item.pmnf_title7}</div></td>
                          )}
                        </>
                      ))}
                    </tr>
                    <tr>

                      {selectedItemsMob.map((item, i) => (
                        <>
                          {i === 1 ? (

                            <td className="fixed-cell">
                              Suggests personalised schedules by room
                            </td>
                          ) : (
                            ''
                          )}
                          {item.pmnf_title8 === "1" ? (
                            <td key={i}>
                              <span className="tick"></span>
                            </td>
                          ) : item.pmnf_title8 === "0" ? (
                            <td key={i}> <div className="wraptdmob"></div></td>
                          ) : (
                            <td key={i}> <div className="wraptdmob">{item.pmnf_title8}</div></td>
                          )}
                        </>
                      ))}
                    </tr>
                    <tr>

                      {selectedItemsMob.map((item, i) => (
                        <>
                          {i === 1 ? (

                            <td className="fixed-cell">
                              Cleans specific rooms at your voice command
                            </td>
                          ) : (
                            ''
                          )}
                          {item.pmnf_title9 === "1" ? (
                            <td key={i}>
                              <span className="tick"></span>
                            </td>
                          ) : item.pmnf_title9 === "0" ? (
                            <td key={i}> <div className="wraptdmob"></div></td>
                          ) : (
                            <td key={i}> <div className="wraptdmob">{item.pmnf_title9}</div></td>
                          )}
                        </>
                      ))}
                    </tr>
                    <tr>

                      {selectedItemsMob.map((item, i) => (
                        <>
                          {i === 1 ? (
                            <td className="fixed-cell">
                              <div className="fixed-cell-heading">
                                Navigation
                              </div>
                              Avoids obstacles like cords and pet waste
                              with PrecisionVision Navigation
                            </td>
                          ) : (
                            ''
                          )}
                          {item.pmnf_title10 === "1" ? (
                            <td key={i}>
                              <span className="tick"></span>
                            </td>
                          ) : item.pmnf_title10 === "0" ? (
                            <td key={i}> <div className="wraptdmob"></div></td>
                          ) : (
                            <td key={i}> <div className="wraptdmob">{item.pmnf_title10}</div></td>
                          )}
                        </>
                      ))}
                    </tr>
                    <tr>
                      {selectedItemsMob.map((item, i) => (
                        <>
                          {i === 1 ? (
                            <td className="fixed-cell">
                              Navigates in neat rows
                            </td>
                          ) : (
                            ''
                          )}
                          {item.pmnf_title11 === "1" ? (
                            <td key={i}>
                              <span className="tick"></span>
                            </td>
                          ) : item.pmnf_title11 === "0" ? (
                            <td key={i}> <div className="wraptdmob"></div></td>
                          ) : (
                            <td key={i}> <div className="wraptdmob">{item.pmnf_title11}</div></td>
                          )}
                        </>
                      ))}
                    </tr>
                    <tr>

                      {selectedItemsMob.map((item, i) => (
                        <>
                          {i === 1 ? (
                            <td className="fixed-cell">
                              Navigates under and around furniture
                            </td>
                          ) : (
                            ''
                          )}
                          {item.pmnf_title12 === "1" ? (
                            <td key={i}>
                              <span className="tick"></span>
                            </td>
                          ) : item.pmnf_title12 === "0" ? (
                            <td key={i}> <div className="wraptdmob"></div></td>
                          ) : (
                            <td key={i}> <div className="wraptdmob">{item.pmnf_title12}</div></td>
                          )}
                        </>
                      ))}
                    </tr>
                    <tr>

                      {selectedItemsMob.map((item, i) => (
                        <>
                          {i === 1 ? (
                            <td className="fixed-cell">
                              Cliff Detect: Avoids stairs
                            </td>
                          ) : (
                            ''
                          )}
                          {item.pmnf_title13 === "1" ? (
                            <td key={i}>
                              <span className="tick"></span>
                            </td>
                          ) : item.pmnf_title13 === "0" ? (
                            <td key={i}> <div className="wraptdmob"></div></td>
                          ) : (
                            <td key={i}> <div className="wraptdmob">{item.pmnf_title13}</div></td>
                          )}
                        </>
                      ))}
                    </tr>
                    <tr className="cell-heading">
                      <td className="subheading" style={{ margin: 0 }}>
                      </td>
                      <td className="subheading" style={{ margin: 0 }}>
                        Cleaning Features
                      </td>
                    </tr>
                    <tr>

                      {selectedItemsMob.map((item, i) => (
                        <>
                          {i === 1 ? (
                            <td className="fixed-cell">Suction power</td>
                          ) : (
                            ''
                          )}
                          {item.pcf_title1 === "1" ? (
                            <td key={i}>
                              <span className="tick"></span>
                            </td>
                          ) : item.pcf_title1 === "0" ? (
                            <td key={i}> <div className="wraptdmob"></div></td>
                          ) : (
                            <td key={i}> <div className="wraptdmob">{item.pcf_title1}</div></td>
                          )}
                        </>
                      ))}
                    </tr>
                    <tr>

                      {selectedItemsMob.map((item, i) => (
                        <>
                          {i === 1 ? (
                            <td className="fixed-cell">Specialised corner clean</td>
                          ) : (
                            ''
                          )}
                          {item.pcf_title2 === "1" ? (
                            <td key={i}>
                              <span className="tick"></span>
                            </td>
                          ) : item.pcf_title2 === "0" ? (
                            <td key={i}> <div className="wraptdmob"></div></td>
                          ) : (
                            <td key={i}> <div className="wraptdmob">{item.pcf_title2}</div></td>
                          )}
                        </>
                      ))}
                    </tr>
                    <tr>

                      {selectedItemsMob.map((item, i) => (
                        <>
                          {i === 1 ? (
                            <td className="fixed-cell">Deeper carpet clean</td>
                          ) : (
                            ''
                          )}
                          {item.pcf_title3 === "1" ? (
                            <td key={i}>
                              <span className="tick"></span>
                            </td>
                          ) : item.pcf_title3 === "0" ? (
                            <td key={i}> <div className="wraptdmob"></div></td>
                          ) : (
                            <td key={i}> <div className="wraptdmob">{item.pcf_title3}</div></td>
                          )}
                        </>
                      ))}
                    </tr>
                    <tr>

                      {selectedItemsMob.map((item, i) => (
                        <>
                          {i === 1 ? (
                            <td className="fixed-cell">Dual Multi-Surface Brushes</td>
                          ) : (
                            ''
                          )}
                          {item.pcf_title4 === "1" ? (
                            <td key={i}>
                              <span className="tick"></span>
                            </td>
                          ) : item.pcf_title4 === "0" ? (
                            <td key={i}> <div className="wraptdmob"></div></td>
                          ) : (
                            <td key={i}> <div className="wraptdmob">{item.pcf_title4}</div></td>
                          )}
                        </>
                      ))}
                    </tr>
                    <tr>

                      {selectedItemsMob.map((item, i) => (
                        <>
                          {i === 1 ? (
                            <td className="fixed-cell">
                              Dirt Detect™ Technology: cleans dirtier spots more
                              thoroughly
                            </td>
                          ) : (
                            ''
                          )}
                          {item.pcf_title5 === "1" ? (
                            <td key={i}>
                              <span className="tick"></span>
                            </td>
                          ) : item.pcf_title5 === "0" ? (
                            <td key={i}> <div className="wraptdmob"></div></td>
                          ) : (
                            <td key={i}> <div className="wraptdmob">{item.pcf_title5}</div></td>
                          )}
                        </>
                      ))}
                    </tr>
                    <tr>
                      {selectedItemsMob.map((item, i) => (
                        <>
                          {i === 1 ? (
                            <td className="fixed-cell">Low Profile Design</td>
                          ) : (
                            ''
                          )}
                          {item.pcf_title6 === "1" ? (
                            <td key={i}>
                              <span className="tick"></span>
                            </td>
                          ) : item.pcf_title6 === "0" ? (
                            <td key={i}> <div className="wraptdmob"></div></td>
                          ) : (
                            <td key={i}> <div className="wraptdmob">{item.pcf_title6}</div></td>
                          )}
                        </>
                      ))}
                    </tr>
                    <tr>

                      {selectedItemsMob.map((item, i) => (
                        <>
                          {i === 1 ? (
                            <td className="fixed-cell">
                              Adapts to clean carpets and hard floors
                            </td>
                          ) : (
                            ''
                          )}
                          {item.pcf_title7 === "1" ? (
                            <td key={i}>
                              <span className="tick"></span>
                            </td>
                          ) : item.pcf_title7 === "0" ? (
                            <td key={i}> <div className="wraptdmob"></div></td>
                          ) : (
                            <td key={i}> <div className="wraptdmob">{item.pcf_title7}</div></td>
                          )}
                        </>
                      ))}
                    </tr>
                    <tr>

                      {selectedItemsMob.map((item, i) => (
                        <>
                          {i === 1 ? (
                            <td className="fixed-cell">
                              Clean Base® Automatic Dirt Disposal
                            </td>
                          ) : (
                            ''
                          )}
                          {item.pcf_title8 === "1" ? (
                            <td key={i}>
                              <span className="tick"></span>
                            </td>
                          ) : item.pcf_title8 === "0" ? (
                            <td key={i}> <div className="wraptdmob"></div></td>
                          ) : (
                            <td key={i}> <div className="wraptdmob">{item.pcf_title8}</div></td>
                          )}
                        </>
                      ))}
                    </tr>
                    <tr>

                      {selectedItemsMob.map((item, i) => (
                        <>
                          {i === 1 ? (
                            <td className="fixed-cell">High-Efficiency Filter</td>
                          ) : (
                            ''
                          )}
                          {item.pcf_title9 === "1" ? (
                            <td key={i}>
                              <span className="tick"></span>
                            </td>
                          ) : item.pcf_title9 === "0" ? (
                            <td key={i}> <div className="wraptdmob"></div></td>
                          ) : (
                            <td key={i}> <div className="wraptdmob">{item.pcf_title9}</div></td>
                          )}
                        </>
                      ))}
                    </tr>
                    <tr>
                      {selectedItemsMob.map((item, i) => (
                        <>
                          {i === 1 ? (
                            <td className="fixed-cell">Recharge and resume</td>
                          ) : (
                            ''
                          )}
                          {item.pcf_title10 === "1" ? (
                            <td key={i}>
                              <span className="tick"></span>
                            </td>
                          ) : item.pcf_title10 === "0" ? (
                            <td key={i}> <div className="wraptdmob"></div></td>
                          ) : (
                            <td key={i}> <div className="wraptdmob">{item.pcf_title10}</div></td>
                          )}
                        </>
                      ))}
                    </tr>
                    <tr className="cell-heading">
                      <td className="subheading" style={{ margin: 0 }}>
                      </td>
                      <td className="subheading" style={{ margin: 0 }}>
                        Smart Features
                      </td>
                    </tr>
                    <tr>

                      {selectedItemsMob.map((item, i) => (
                        <>
                          {i === 1 ? (
                            <td className="fixed-cell">
                              Starts cleaning at your voice command
                            </td>
                          ) : (
                            ''
                          )}

                          {item.psf_title1 === "1" ? (
                            <td key={i}>
                              <span className="tick"></span>
                            </td>
                          ) : item.psf_title1 === "0" ? (
                            <td key={i}> <div className="wraptdmob"></div></td>
                          ) : (
                            <td key={i}> <div className="wraptdmob">{item.psf_title1}</div></td>
                          )}
                        </>
                      ))}
                    </tr>
                    <tr>

                      {selectedItemsMob.map((item, i) => (
                        <>
                          {i === 1 ? (
                            <td className="fixed-cell">
                              Compatible with Alexa/Google/AliGenie
                            </td>
                          ) : (
                            ''
                          )}
                          {item.psf_title2 === "1" ? (
                            <td key={i}>
                              <span className="tick"></span>
                            </td>
                          ) : item.psf_title2 === "0" ? (
                            <td key={i}> <div className="wraptdmob"></div></td>
                          ) : (
                            <td key={i}> <div className="wraptdmob">{item.psf_title2}</div></td>
                          )}
                        </>
                      ))}
                    </tr>
                    <tr>

                      {selectedItemsMob.map((item, i) => (
                        <>
                          {i === 1 ? (
                            <td className="fixed-cell">Cleans when you are away</td>
                          ) : (
                            ''
                          )}
                          {item.psf_title3 === "1" ? (
                            <td key={i}>
                              <span className="tick"></span>
                            </td>
                          ) : item.psf_title3 === "0" ? (
                            <td key={i}> <div className="wraptdmob"></div></td>
                          ) : (
                            <td key={i}> <div className="wraptdmob">{item.psf_title3}</div></td>
                          )}
                        </>
                      ))}
                    </tr>
                    <tr>

                      {selectedItemsMob.map((item, i) => (
                        <>
                          {i === 1 ? (
                            <td className="fixed-cell">
                              Seasonal cleaning recommendations
                            </td>
                          ) : (
                            ''
                          )}
                          {item.psf_title4 === "1" ? (
                            <td key={i}>
                              <span className="tick"></span>
                            </td>
                          ) : item.psf_title4 === "0" ? (
                            <td key={i}> <div className="wraptdmob"></div></td>
                          ) : (
                            <td key={i}> <div className="wraptdmob">{item.psf_title4}</div></td>
                          )}
                        </>
                      ))}
                    </tr>
                    <tr className="cell-heading">
                      <td className="subheading" style={{ margin: 0 }}>
                      </td>
                      <td className="subheading" style={{ margin: 0 }}>
                        Customisation Features
                      </td>
                    </tr>
                    <tr>

                      {selectedItemsMob.map((item, i) => (
                        <>
                          {i === 1 ? (
                            <td className="fixed-cell">
                              Personalised schedule recommendations
                            </td>
                          ) : (
                            ''
                          )}
                          {item.pcuf_title1 === "1" ? (
                            <td key={i}>
                              <span className="tick"></span>
                            </td>
                          ) : item.pcuf_title1 === "0" ? (
                            <td key={i}> <div className="wraptdmob"></div></td>
                          ) : (
                            <td key={i}> <div className="wraptdmob">{item.pcuf_title1}</div></td>
                          )}
                        </>
                      ))}
                    </tr>
                    <tr>

                      {selectedItemsMob.map((item, i) => (
                        <>
                          {i === 1 ? (
                            <td className="fixed-cell">
                              Creates Favourite cleaning routines
                            </td>
                          ) : (
                            ''
                          )}
                          {item.pcuf_title2 === "1" ? (
                            <td key={i}>
                              <span className="tick"></span>
                            </td>
                          ) : item.pcuf_title2 === "0" ? (
                            <td key={i}> <div className="wraptdmob"></div></td>
                          ) : (
                            <td key={i}> <div className="wraptdmob">{item.pcuf_title2}</div></td>
                          )}
                        </>
                      ))}
                    </tr>
                    <tr>

                      {selectedItemsMob.map((item, i) => (
                        <>
                          {i === 1 ? (
                            <td className="fixed-cell">
                              Pet-moulting cleaning suggestions
                            </td>
                          ) : (
                            ''
                          )}
                          {item.pcuf_title3 === "1" ? (
                            <td key={i}>
                              <span className="tick"></span>
                            </td>
                          ) : item.pcuf_title3 === "0" ? (
                            <td key={i}> <div className="wraptdmob"></div></td>
                          ) : (
                            <td key={i}> <div className="wraptdmob">{item.pcuf_title3}</div></td>
                          )}
                        </>
                      ))}
                    </tr>
                    <tr className="cell-heading">
                      <td className="subheading" style={{ margin: 0 }}>
                      </td>
                      <td className="subheading" style={{ margin: 0 }}>
                        Mopping Features
                      </td>
                    </tr>
                    <tr>

                      {selectedItemsMob.map((item, i) => (
                        <>
                          {i === 1 ? (
                            <td className="fixed-cell">
                              Vacuums then mops in sequence
                            </td>
                          ) : (
                            ''
                          )}
                          {item.pmf_title1 === "1" ? (
                            <td key={i}>
                              <span className="tick"></span>
                            </td>
                          ) : item.pmf_title1 === "0" ? (
                            <td key={i}> <div className="wraptdmob"></div></td>
                          ) : (
                            <td key={i}> <div className="wraptdmob">{item.pmf_title1}</div></td>
                          )}
                        </>
                      ))}
                    </tr>
                    <tr>

                      {selectedItemsMob.map((item, i) => (
                        <>
                          {i === 1 ? (
                            <td className="fixed-cell">Precision Jet Spray</td>
                          ) : (
                            ''
                          )}
                          {item.pmf_title2 === "1" ? (
                            <td key={i}>
                              <span className="tick"></span>
                            </td>
                          ) : item.pmf_title2 === "0" ? (
                            <td key={i}> <div className="wraptdmob"></div></td>
                          ) : (
                            <td key={i}> <div className="wraptdmob">{item.pmf_title2}</div></td>
                          )}
                        </>
                      ))}
                    </tr>
                    <tr>

                      {selectedItemsMob.map((item, i) => (
                        <>
                          {i === 1 ? (
                            <td className="fixed-cell">Adjustable spray level</td>
                          ) : (
                            ''
                          )}
                          {item.pmf_title3 === "1" ? (
                            <td key={i}>
                              <span className="tick"></span>
                            </td>
                          ) : item.pmf_title3 === "0" ? (
                            <td key={i}> <div className="wraptdmob"></div></td>
                          ) : (
                            <td key={i}> <div className="wraptdmob">{item.pmf_title3}</div></td>
                          )}
                        </>
                      ))}
                    </tr>
                    <tr>

                      {selectedItemsMob.map((item, i) => (
                        <>
                          {i === 1 ? (
                            <td className="fixed-cell">Mopping modes</td>
                          ) : (
                            ''
                          )}
                          {item.pmf_title4 === "1" ? (
                            <td key={i}>
                              <span className="tick"></span>
                            </td>
                          ) : item.pmf_title4 === "0" ? (
                            <td key={i}> <div className="wraptdmob"></div></td>
                          ) : (
                            <td key={i}> <div className="wraptdmob">{item.pmf_title4}</div></td>
                          )}
                        </>
                      ))}
                    </tr>
                    <tr>

                      {selectedItemsMob.map((item, i) => (
                        <>
                          {i === 1 ? (
                            <td className="fixed-cell">
                              Mops back and forth like you would
                            </td>
                          ) : (
                            ''
                          )}
                          {item.pmf_title5 === "1" ? (
                            <td key={i}>
                              <span className="tick"></span>
                            </td>
                          ) : item.pmf_title5 === "0" ? (
                            <td key={i}> <div className="wraptdmob"></div></td>
                          ) : (
                            <td key={i}> <div className="wraptdmob">{item.pmf_title5}</div></td>
                          )}
                        </>
                      ))}
                    </tr>
                    <tr>

                      {selectedItemsMob.map((item, i) => (
                        <>
                          {i === 1 ? (
                            <td className="fixed-cell">
                              Works with Single Use and Washable Pads
                            </td>
                          ) : (
                            ''
                          )}
                          {item.pmf_title6 === "1" ? (
                            <td key={i}>
                              <span className="tick"></span>
                            </td>
                          ) : item.pmf_title6 === "0" ? (
                            <td key={i}> <div className="wraptdmob"></div></td>
                          ) : (
                            <td key={i}> <div className="wraptdmob">{item.pmf_title6}</div></td>
                          )}
                        </>
                      ))}
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </div>
        )}
      </section>

      <section className="section compare-all comparedesk comparedesk-padding">
        <h2 className="compare_heading">
          Choose products to see how the features stack up
        </h2>
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : (
          <div className="container size_small container-compare">
            <ul className="list-all-prod thumbnail">
              <Slider {...settings}>
                {objs &&
                  objs.map((item, i) => {


                    return <li
                      id={item.id}
                      key={i}
                      style={{ borderBlockColor: color, cursor: "pointer" }}


                    >
                      <button
                        className={item.id}
                        style={{ display: "none" }}
                        onClick={() => removeFromCompare(item)}
                        title="remove"
                      >
                        X
                      </button>
                      <div className="inner-compare-product" onClick={() => addToCompare(item)}>
                        <img
                          src={process.env.REACT_APP_API_PATH + "/media/" + item.image}
                          alt=""

                        />

                        <h2>{item.name}</h2>
                        {/*
                        <h3>{item.alias}</h3>
                        */}
                        {/* <button onClick={() => addToCompare(item)}>
                          Compare
                        </button> */}
                      </div>
                    </li>

                  })}
              </Slider>
            </ul>
          </div>
        )}
      </section>
      <section className="compare-product section comparedesk">

        <div
          ref={ref}
          className={
            scrollPosition > 750 && scrollPosition < 3295
              ? "active-fixed-compare"
              : "fixed-compare-wrap"
          }
        >
          {selectedItems.length > 0 && (
            <><div className="compare-tabel" >
              <table>
                <thead>
                  <tr>
                    <th></th>


                    {selectedItems.map((item, i) => (
                      <>
                        <th key={i}>
                          <div className="wrapthhead">

                            <img
                              src={process.env.REACT_APP_API_PATH + "/media/" + item.image}
                              alt="" />
                            <h2>{item.name}</h2>
                            <Link to={`/product/${item.slug}`}>Shop Now</Link>
                          </div>

                        </th>
                      </>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr className="cell-heading">
                    <td className="subheading" style={{ margin: 0 }}>
                      Mapping and Navigation Features
                    </td>

                  </tr>
                  <tr>
                    <td className="fixed-cell">
                      <div className="fixed-cell-heading">
                        Imprint® Smart Mapping– Hazard Detection & Avoidance
                      </div>
                      Careful Drive
                    </td>

                    {selectedItems.map((item, i) => (
                      <>
                        {item.pmnf_title1 === "1" ? (
                          <td key={i}>
                            <div className="wraptd">
                              <span className="tick"></span>
                            </div>
                          </td>
                        ) : item.pmnf_title1 === "0" ? (
                          <td key={i}> <div className="wraptd"></div></td>
                        ) : (
                          <td key={i}> <div className="wraptd">{item.pmnf_title1}</div></td>
                        )}
                      </>
                    ))}
                  </tr>
                  <tr>
                    <td className="fixed-cell">
                      Detects and avoids objects, then adds them to its map
                    </td>
                    {selectedItems.map((item, i) => (
                      <>
                        {item.pmnf_title2 === "1" ? (
                          <td key={i}>
                            <div className="wraptd">
                              <span className="tick"></span>
                            </div>
                          </td>
                        ) : item.pmnf_title2 === "0" ? (
                          <td key={i}> <div className="wraptd"></div></td>
                        ) : (
                          <td key={i}> <div className="wraptd">{item.pmnf_title2}</div></td>
                        )}
                      </>
                    ))}
                  </tr>
                  <tr>
                    <td className="fixed-cell">
                      Notifies you about hazards in your home and remembers
                      your input
                    </td>
                    {selectedItems.map((item, i) => (
                      <>
                        {item.pmnf_title3 === "1" ? (
                          <td key={i}>
                            <div className="wraptd">
                              <span className="tick"></span>
                            </div>
                          </td>
                        ) : item.pmnf_title3 === "0" ? (
                          <td key={i}> <div className="wraptd"></div></td>
                        ) : (
                          <td key={i}> <div className="wraptd">{item.pmnf_title3}</div></td>
                        )}
                      </>
                    ))}
                  </tr>
                  <tr>
                    <td className="fixed-cell">

                      <div className="fixed-cell-heading">
                        Imprint® Smart Mapping— Targeted Area Cleaning
                      </div>
                      Creates Clean Zones and Keep Out
                      Zones
                    </td>
                    {selectedItems.map((item, i) => (
                      <>
                        {item.pmnf_title4 === "1" ? (
                          <td key={i}>
                            <div className="wraptd">
                              <span className="tick"></span>
                            </div>
                          </td>
                        ) : item.pmnf_title4 === "0" ? (
                          <td key={i}> <div className="wraptd"></div></td>
                        ) : (
                          <td key={i}> <div className="wraptd">{item.pmnf_title4}</div></td>
                        )}
                      </>
                    ))}
                  </tr>

                  <tr>
                    <td className="fixed-cell">
                      Customisable Clean Zones within rooms
                    </td>
                    {selectedItems.map((item, i) => (
                      <>
                        {item.pmnf_title5 === "1" ? (
                          <td key={i}>
                            <div className="wraptd">
                              <span className="tick"></span>
                            </div>
                          </td>
                        ) : item.pmnf_title5 === "0" ? (
                          <td key={i}> <div className="wraptd"></div></td>
                        ) : (
                          <td key={i}> <div className="wraptd">{item.pmnf_title5}</div></td>
                        )}
                      </>
                    ))}
                  </tr>
                  <tr>
                    <td className="fixed-cell">
                      Cleans small areas within rooms at your voice command*
                    </td>
                    {selectedItems.map((item, i) => (
                      <>
                        {item.pmnf_title6 === "1" ? (
                          <td key={i}>
                            <div className="wraptd">
                              <span className="tick"></span>
                            </div>
                          </td>
                        ) : item.pmnf_title6 === "0" ? (
                          <td key={i}> <div className="wraptd"></div></td>
                        ) : (
                          <td key={i}> <div className="wraptd">{item.pmnf_title6}</div></td>
                        )}
                      </>
                    ))}
                  </tr>
                  <tr>
                    <td className="fixed-cell">
                      <div className="fixed-cell-heading">
                        Imprint® Smart Mapping— Targeted Room Cleaning
                      </div>
                      Cleans specific rooms you
                      choose
                    </td>
                    {selectedItems.map((item, i) => (
                      <>
                        {item.pmnf_title7 === "1" ? (
                          <td key={i}>
                            <div className="wraptd">
                              <span className="tick"></span>
                            </div>
                          </td>
                        ) : item.pmnf_title7 === "0" ? (
                          <td key={i}> <div className="wraptd"></div></td>
                        ) : (
                          <td key={i}> <div className="wraptd">{item.pmnf_title7}</div></td>
                        )}
                      </>
                    ))}
                  </tr>
                  <tr>
                    <td className="fixed-cell">
                      Suggests personalised schedules by room
                    </td>
                    {selectedItems.map((item, i) => (
                      <>
                        {item.pmnf_title8 === "1" ? (
                          <td key={i}>
                            <div className="wraptd">
                              <span className="tick"></span>
                            </div>
                          </td>
                        ) : item.pmnf_title8 === "0" ? (
                          <td key={i}> <div className="wraptd"></div></td>
                        ) : (
                          <td key={i}> <div className="wraptd">{item.pmnf_title8}</div></td>
                        )}
                      </>
                    ))}
                  </tr>
                  <tr>
                    <td className="fixed-cell">
                      Cleans specific rooms at your voice command
                    </td>
                    {selectedItems.map((item, i) => (
                      <>
                        {item.pmnf_title9 === "1" ? (
                          <td key={i}>
                            <div className="wraptd">
                              <span className="tick"></span>
                            </div>
                          </td>
                        ) : item.pmnf_title9 === "0" ? (
                          <td key={i}> <div className="wraptd"></div></td>
                        ) : (
                          <td key={i}> <div className="wraptd">{item.pmnf_title9}</div></td>
                        )}
                      </>
                    ))}
                  </tr>
                  <tr>
                    <td className="fixed-cell">
                      <div className="fixed-cell-heading">
                        Navigation
                      </div>
                      Avoids obstacles like cords and pet waste
                      with PrecisionVision Navigation
                    </td>
                    {selectedItems.map((item, i) => (
                      <>
                        {item.pmnf_title10 === "1" ? (
                          <td key={i}>
                            <div className="wraptd">
                              <span className="tick"></span>
                            </div>
                          </td>
                        ) : item.pmnf_title10 === "0" ? (
                          <td key={i}> <div className="wraptd"></div></td>
                        ) : (
                          <td key={i}> <div className="wraptd">{item.pmnf_title10}</div></td>
                        )}
                      </>
                    ))}
                  </tr>
                  <tr>
                    <td className="fixed-cell">Navigates in neat rows</td>
                    {selectedItems.map((item, i) => (
                      <>
                        {item.pmnf_title11 === "1" ? (
                          <td key={i}>
                            <div className="wraptd">
                              <span className="tick"></span>
                            </div>
                          </td>
                        ) : item.pmnf_title11 === "0" ? (
                          <td key={i}> <div className="wraptd"></div></td>
                        ) : (
                          <td key={i}> <div className="wraptd">{item.pmnf_title11}</div></td>
                        )}
                      </>
                    ))}
                  </tr>
                  <tr>
                    <td className="fixed-cell">
                      Navigates under and around furniture
                    </td>
                    {selectedItems.map((item, i) => (
                      <>
                        {item.pmnf_title12 === "1" ? (
                          <td key={i}>
                            <div className="wraptd">
                              <span className="tick"></span>
                            </div>
                          </td>
                        ) : item.pmnf_title12 === "0" ? (
                          <td key={i}> <div className="wraptd"></div></td>
                        ) : (
                          <td key={i}> <div className="wraptd">{item.pmnf_title12}</div></td>
                        )}
                      </>
                    ))}
                  </tr>
                  <tr>
                    <td className="fixed-cell">
                      Cliff Detect: Avoids stairs
                    </td>
                    {selectedItems.map((item, i) => (
                      <>
                        {item.pmnf_title13 === "1" ? (
                          <td key={i}>
                            <div className="wraptd">
                              <span className="tick"></span>
                            </div>
                          </td>
                        ) : item.pmnf_title13 === "0" ? (
                          <td key={i}> <div className="wraptd"></div></td>
                        ) : (
                          <td key={i}> <div className="wraptd">{item.pmnf_title13}</div></td>
                        )}
                      </>
                    ))}
                  </tr>
                  <tr className="cell-heading">
                    <td className="subheading" style={{ margin: 0 }}>
                      Cleaning Features
                    </td>


                  </tr>
                  <tr>
                    <td className="fixed-cell">Suction power</td>
                    {selectedItems.map((item, i) => (
                      <>
                        {item.pcf_title1 === "1" ? (
                          <td key={i}>
                            <div className="wraptd">
                              <span className="tick"></span>
                            </div>
                          </td>
                        ) : item.pcf_title1 === "0" ? (
                          <td key={i}> <div className="wraptd"></div></td>
                        ) : (
                          <td key={i}> <div className="wraptd">{item.pcf_title1}</div></td>
                        )}
                      </>
                    ))}
                  </tr>
                  <tr>
                    <td className="fixed-cell">Specialised corner clean</td>
                    {selectedItems.map((item, i) => (
                      <>
                        {item.pcf_title2 === "1" ? (
                          <td key={i}>
                            <div className="wraptd">
                              <span className="tick"></span>
                            </div>
                          </td>
                        ) : item.pcf_title2 === "0" ? (
                          <td key={i}> <div className="wraptd"></div></td>
                        ) : (
                          <td key={i}> <div className="wraptd">{item.pcf_title2}</div></td>
                        )}
                      </>
                    ))}
                  </tr>
                  <tr>
                    <td className="fixed-cell">Deeper carpet clean</td>
                    {selectedItems.map((item, i) => (
                      <>
                        {item.pcf_title3 === "1" ? (
                          <td key={i}>
                            <div className="wraptd">
                              <span className="tick"></span>
                            </div>
                          </td>
                        ) : item.pcf_title3 === "0" ? (
                          <td key={i}> <div className="wraptd"></div></td>
                        ) : (
                          <td key={i}> <div className="wraptd">{item.pcf_title3}</div></td>
                        )}
                      </>
                    ))}
                  </tr>
                  <tr>
                    <td className="fixed-cell">Dual Multi-Surface Brushes</td>
                    {selectedItems.map((item, i) => (
                      <>
                        {item.pcf_title4 === "1" ? (
                          <td key={i}>
                            <div className="wraptd">
                              <span className="tick"></span>
                            </div>
                          </td>
                        ) : item.pcf_title4 === "0" ? (
                          <td key={i}> <div className="wraptd"></div></td>
                        ) : (
                          <td key={i}> <div className="wraptd">{item.pcf_title4}</div></td>
                        )}
                      </>
                    ))}
                  </tr>
                  <tr>
                    <td className="fixed-cell">
                      Dirt Detect™ Technology: cleans dirtier spots more
                      thoroughly
                    </td>
                    {selectedItems.map((item, i) => (
                      <>
                        {item.pcf_title5 === "1" ? (
                          <td key={i}>
                            <div className="wraptd">
                              <span className="tick"></span>
                            </div>
                          </td>
                        ) : item.pcf_title5 === "0" ? (
                          <td key={i}> <div className="wraptd"></div></td>
                        ) : (
                          <td key={i}> <div className="wraptd">{item.pcf_title5}</div></td>
                        )}
                      </>
                    ))}
                  </tr>
                  <tr>
                    <td className="fixed-cell">Low Profile Design</td>
                    {selectedItems.map((item, i) => (
                      <>
                        {item.pcf_title6 === "1" ? (
                          <td key={i}>
                            <div className="wraptd">
                              <span className="tick"></span>
                            </div>
                          </td>
                        ) : item.pcf_title6 === "0" ? (
                          <td key={i}> <div className="wraptd"></div></td>
                        ) : (
                          <td key={i}> <div className="wraptd">{item.pcf_title6}</div></td>
                        )}
                      </>
                    ))}
                  </tr>
                  <tr>
                    <td className="fixed-cell">
                      Adapts to clean carpets and hard floors
                    </td>
                    {selectedItems.map((item, i) => (
                      <>
                        {item.pcf_title7 === "1" ? (
                          <td key={i}>
                            <div className="wraptd">
                              <span className="tick"></span>
                            </div>
                          </td>
                        ) : item.pcf_title7 === "0" ? (
                          <td key={i}> <div className="wraptd"></div></td>
                        ) : (
                          <td key={i}> <div className="wraptd">{item.pcf_title7}</div></td>
                        )}
                      </>
                    ))}
                  </tr>
                  <tr>
                    <td className="fixed-cell">
                      Clean Base® Automatic Dirt Disposal
                    </td>
                    {selectedItems.map((item, i) => (
                      <>
                        {item.pcf_title8 === "1" ? (
                          <td key={i}>
                            <div className="wraptd">
                              <span className="tick"></span>
                            </div>
                          </td>
                        ) : item.pcf_title8 === "0" ? (
                          <td key={i}> <div className="wraptd"></div></td>
                        ) : (
                          <td key={i}> <div className="wraptd">{item.pcf_title8}</div></td>
                        )}
                      </>
                    ))}
                  </tr>
                  <tr>
                    <td className="fixed-cell">High-Efficiency Filter</td>
                    {selectedItems.map((item, i) => (
                      <>
                        {item.pcf_title9 === "1" ? (
                          <td key={i}>
                            <div className="wraptd">
                              <span className="tick"></span>
                            </div>
                          </td>
                        ) : item.pcf_title9 === "0" ? (
                          <td key={i}> <div className="wraptd"></div></td>
                        ) : (
                          <td key={i}> <div className="wraptd">{item.pcf_title9}</div></td>
                        )}
                      </>
                    ))}
                  </tr>
                  <tr>
                    <td className="fixed-cell">Recharge and resume</td>
                    {selectedItems.map((item, i) => (
                      <>
                        {item.pcf_title10 === "1" ? (
                          <td key={i}>
                            <div className="wraptd">
                              <span className="tick"></span>
                            </div>
                          </td>
                        ) : item.pcf_title10 === "0" ? (
                          <td key={i}> <div className="wraptd"></div></td>
                        ) : (
                          <td key={i}> <div className="wraptd">{item.pcf_title10}</div></td>
                        )}
                      </>
                    ))}
                  </tr>
                  <tr className="cell-heading">
                    <td className="subheading" style={{ margin: 0 }}>
                      Smart Features
                    </td>
                  </tr>
                  <tr>
                    <td className="fixed-cell">
                      Starts cleaning at your voice command
                    </td>
                    {selectedItems.map((item, i) => (
                      <>
                        {item.psf_title1 === "1" ? (
                          <td key={i}>
                            <div className="wraptd">
                              <span className="tick"></span>
                            </div>
                          </td>
                        ) : item.psf_title1 === "0" ? (
                          <td key={i}> <div className="wraptd"></div></td>
                        ) : (
                          <td key={i}> <div className="wraptd">{item.psf_title1}</div></td>
                        )}
                      </>
                    ))}
                  </tr>
                  <tr>
                    <td className="fixed-cell">
                      Compatible with Alexa/Google/AliGenie
                    </td>
                    {selectedItems.map((item, i) => (
                      <>
                        {item.psf_title2 === "1" ? (
                          <td key={i}>
                            <div className="wraptd">
                              <span className="tick"></span>
                            </div>
                          </td>
                        ) : item.psf_title2 === "0" ? (
                          <td key={i}> <div className="wraptd"></div></td>
                        ) : (
                          <td key={i}> <div className="wraptd">{item.psf_title2}</div></td>
                        )}
                      </>
                    ))}
                  </tr>
                  <tr>
                    <td className="fixed-cell">Cleans when you are away</td>
                    {selectedItems.map((item, i) => (
                      <>
                        {item.psf_title3 === "1" ? (
                          <td key={i}>
                            <div className="wraptd">
                              <span className="tick"></span>
                            </div>
                          </td>
                        ) : item.psf_title3 === "0" ? (
                          <td key={i}> <div className="wraptd"></div></td>
                        ) : (
                          <td key={i}> <div className="wraptd">{item.psf_title3}</div></td>
                        )}
                      </>
                    ))}
                  </tr>
                  <tr>
                    <td className="fixed-cell">
                      Seasonal cleaning recommendations
                    </td>
                    {selectedItems.map((item, i) => (
                      <>
                        {item.psf_title4 === "1" ? (
                          <td key={i}>
                            <div className="wraptd">
                              <span className="tick"></span>
                            </div>
                          </td>
                        ) : item.psf_title4 === "0" ? (
                          <td key={i}> <div className="wraptd"></div></td>
                        ) : (
                          <td key={i}> <div className="wraptd">{item.psf_title4}</div></td>
                        )}
                      </>
                    ))}
                  </tr>
                  <tr className="cell-heading">
                    <td className="subheading" style={{ margin: 0 }}>
                      Customisation Features
                    </td>
                  </tr>
                  <tr>
                    <td className="fixed-cell">
                      Personalised schedule recommendations
                    </td>
                    {selectedItems.map((item, i) => (
                      <>
                        {item.pcuf_title1 === "1" ? (
                          <td key={i}>
                            <div className="wraptd">
                              <span className="tick"></span>
                            </div>
                          </td>
                        ) : item.pcuf_title1 === "0" ? (
                          <td key={i}> <div className="wraptd"></div></td>
                        ) : (
                          <td key={i}> <div className="wraptd">{item.pcuf_title1}</div></td>
                        )}
                      </>
                    ))}
                  </tr>
                  <tr>
                    <td className="fixed-cell">
                      Creates Favourite cleaning routines
                    </td>
                    {selectedItems.map((item, i) => (
                      <>
                        {item.pcuf_title2 === "1" ? (
                          <td key={i}>
                            <div className="wraptd">
                              <span className="tick"></span>
                            </div>
                          </td>
                        ) : item.pcuf_title2 === "0" ? (
                          <td key={i}> <div className="wraptd"></div></td>
                        ) : (
                          <td key={i}> <div className="wraptd">{item.pcuf_title2}</div></td>
                        )}
                      </>
                    ))}
                  </tr>
                  <tr>
                    <td className="fixed-cell">
                      Pet-moulting cleaning suggestions
                    </td>
                    {selectedItems.map((item, i) => (
                      <>
                        {item.pcuf_title3 === "1" ? (
                          <td key={i}>
                            <div className="wraptd">
                              <span className="tick"></span>
                            </div>
                          </td>
                        ) : item.pcuf_title3 === "0" ? (
                          <td key={i}> <div className="wraptd"></div></td>
                        ) : (
                          <td key={i}> <div className="wraptd">{item.pcuf_title3}</div></td>
                        )}
                      </>
                    ))}
                  </tr>
                  <tr className="cell-heading">
                    <td className="subheading" style={{ margin: 0 }}>
                      Mopping Features
                    </td>

                  </tr>
                  <tr>
                    <td className="fixed-cell">
                      Vacuums then mops in sequence
                    </td>
                    {selectedItems.map((item, i) => (
                      <>
                        {item.pmf_title1 === "1" ? (
                          <td key={i}>
                            <div className="wraptd">
                              <span className="tick"></span>
                            </div>
                          </td>
                        ) : item.pmf_title1 === "0" ? (
                          <td key={i}> <div className="wraptd"></div></td>
                        ) : (
                          <td key={i}> <div className="wraptd">{item.pmf_title1}</div></td>
                        )}
                      </>
                    ))}
                  </tr>
                  <tr>
                    <td className="fixed-cell">Precision Jet Spray</td>
                    {selectedItems.map((item, i) => (
                      <>
                        {item.pmf_title2 === "1" ? (
                          <td key={i}>
                            <div className="wraptd">
                              <span className="tick"></span>
                            </div>
                          </td>
                        ) : item.pmf_title2 === "0" ? (
                          <td key={i}> <div className="wraptd"></div></td>
                        ) : (
                          <td key={i}> <div className="wraptd">{item.pmf_title2}</div></td>
                        )}
                      </>
                    ))}
                  </tr>
                  <tr>
                    <td className="fixed-cell">Adjustable spray level</td>
                    {selectedItems.map((item, i) => (
                      <>
                        {item.pmf_title3 === "1" ? (
                          <td key={i}>
                            <div className="wraptd">
                              <span className="tick"></span>
                            </div>
                          </td>
                        ) : item.pmf_title3 === "0" ? (
                          <td key={i}> <div className="wraptd"></div></td>
                        ) : (
                          <td key={i}> <div className="wraptd">{item.pmf_title3}</div></td>
                        )}
                      </>
                    ))}
                  </tr>
                  <tr>
                    <td className="fixed-cell">Mopping modes</td>
                    {selectedItems.map((item, i) => (
                      <>
                        {item.pmf_title4 === "1" ? (
                          <td key={i}>
                            <div className="wraptd">
                              <span className="tick"></span>
                            </div>
                          </td>
                        ) : item.pmf_title4 === "0" ? (
                          <td key={i}> <div className="wraptd"></div></td>
                        ) : (
                          <td key={i}> <div className="wraptd">{item.pmf_title4}</div></td>
                        )}
                      </>
                    ))}
                  </tr>
                  <tr>
                    <td className="fixed-cell">
                      Mops back and forth like you would
                    </td>
                    {selectedItems.map((item, i) => (
                      <>
                        {item.pmf_title5 === "1" ? (
                          <td key={i}>
                            <div className="wraptd">
                              <span className="tick"></span>
                            </div>
                          </td>
                        ) : item.pmf_title5 === "0" ? (
                          <td key={i}> <div className="wraptd"></div></td>
                        ) : (
                          <td key={i}> <div className="wraptd">{item.pmf_title5}</div></td>
                        )}
                      </>
                    ))}
                  </tr>
                  <tr>
                    <td className="fixed-cell">
                      Works with Single Use and Washable Pads
                    </td>
                    {selectedItems.map((item, i) => (
                      <>
                        {item.pmf_title6 === "1" ? (
                          <td key={i}>
                            <div className="wraptd">
                              <span className="tick"></span>
                            </div>
                          </td>
                        ) : item.pmf_title6 === "0" ? (
                          <td key={i}> <div className="wraptd"></div></td>
                        ) : (
                          <td key={i}> <div className="wraptd">{item.pmf_title6}</div></td>
                        )}
                      </>
                    ))}
                  </tr>
                </tbody>
              </table>

              <div className="compare-height">
              </div>

            </div>
            </>
          )}

        </div>


      </section>

    </>
  );
}
