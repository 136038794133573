import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { loadAllTopHeaderMessage } from "../../redux/action/AdditionalAction";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Loader from "./Loader";
import Message from "./Message";



var settings = {
  dots: false,
  arrows: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  fade: true,
  cssEase: "linear",
  autoplay: true,
  autoplaySpeed: 2000,
};

export default function Top_header() {
  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(window.location.search);
  var utm_sourcercv = queryParams.get('utm_source');
  var utm_campaignrcv = queryParams.get('utm_campaign');

  useEffect(() => {
    if (utm_sourcercv != null && utm_sourcercv != '' && utm_sourcercv != undefined) {

      localStorage.setItem('utm_source', utm_sourcercv);
      localStorage.setItem('utm_campaign', utm_campaignrcv);
    }

    dispatch(loadAllTopHeaderMessage());
  }, [dispatch]);

  const msg = useSelector((state) => state.Additional);
  const { loading, error, menuMessage } = msg;



  return (
    <section className="announecemt_bar">
      <Slider {...settings}>

        {menuMessage.map((slider) => {
          return (
            <div className="announcent" key={slider}>
              <p >

                {slider.message}
                <Link to={slider.button_url} className="btn top">
                  {slider.button_name}
                </Link>
              </p>
            </div>
          )

        })
        }
      </Slider>
      {/* {loading ? (
        <Loader />
      ) : error ? (
        <Message />
      ) : (
        <>
          {menuMessage.slice(1, 2).map((item, i) => (
            <Slider {...settings}>
              <div className="announcent">
                <p key={i}>
                  {item.message}
                  <Link to={item.button_url} className="btn top">
                    {item.button_name}
                  </Link>
                </p>
              </div>
            </Slider>
          ))}
          {menuMessage.slice(2, 1).map((item, i) => (
            <Slider {...settings}>
              <div className="announcent">
                <p key={i}>
                  {item.message}
                  <Link to={item.button_url} className="btn top">
                    {item.button_name}
                  </Link>
                </p>
              </div>
            </Slider>
          ))}
        </>
      )} */}
    </section>
  );
}

//  <section className="announecemt_bar">
//    <Slider {...settings}>
//      {loading ? (
//        <Loader />
//      ) : error ? (
//        <Message />
//      ) : (
//        <div className="announcent">
//          {menuMessage &&
//            menuMessage.map((item, i) => (
//              <p key={i}>
//                {item.message}.
//                <Link to={item.button_url} className="btn top">
//                  Shop Now
//                </Link>
//              </p>
//            ))}
//        </div>
//      )}
//    </Slider>
//  </section>;
