import { combineReducers } from "redux";
import CategoryReducer from "./CategoryReducer";
import SubCategoryReducer from "./SubCategoryReducer";
import AccessoriesReducer from "./AccessoriesReducer";
import SubAccessoriesReducer from "./SubAccessoriesReducer";
import ProductListingReducer from "./ProductListingReducer";
import ProductReducer from "./ProductReducer";
import AuthReducer from "./AuthReducer";
import CartReducer from "./CartReducer";
import DeliveryAddressReduser from "./deliveryAddressReducer";
import OrderReducer from "./OrderReducer";
import ProductReviewReducer from "./productReviewReducer";
import StoreListReducer from "./storeRducer";
import AdditionalReducer from "./AdditionalReducer";
import LoyaltyPointsReducer from "./LoyaltyPointsReducer";
import HomeReducer from "./HomeReducer";
import StaticReducer from "./StaticReducer";
import RegisterRobotReducer from "./RegisterRobotReducer";
import VideodemoReducer from "./VideodemoReducer";


const rootReduser = combineReducers({
  AuthReducer: AuthReducer,
  Cart: CartReducer,
  MainCategory: CategoryReducer,
  SubCategory: SubCategoryReducer,
  MainAccessories: AccessoriesReducer,
  SubAccessories: SubAccessoriesReducer,
  ProdutcListing: ProductListingReducer,
  MainProduct: ProductReducer,
  DeliveryAddress: DeliveryAddressReduser,
  Order: OrderReducer,
  ProductReview: ProductReviewReducer,
  StoreList: StoreListReducer,
  Additional: AdditionalReducer,
  LoyaltyPoints: LoyaltyPointsReducer,
  Home: HomeReducer,
  Static: StaticReducer,
  RegisterRobot: RegisterRobotReducer,
  Videodemo: VideodemoReducer,

  
});

export default rootReduser;
