// import React from "react";
// // import offer_image from "../../image/offer-image.jpg"
// import { Link } from "react-router-dom";
// import offer from "../../image/p_2.png";

// export default function OfferSection() {
//   return (
//     <section className="section offer-section">
//       <div className="container">
//         <Link to="#!">
//           <div className="offerbanners">
//             <div className="divs40">
//               <div className="lefttext">
//                 <h5>iRobot Select with Roomba ® j7 + Robot Vacuum</h5>
//               </div>
//               <div className="offerimage">
//                 <img src={offer} alt="" />
//               </div>
//             </div>
//             <div className="divs60">
//               <div className="righttext">
//                 <h4> iRobot Select with Roomba</h4>
//                 <h2>
//                   <span>iRobot</span> Select
//                 </h2>
//                 <p>
//                   iRobot Select with Roomba iRobot Select with Roomba iRobot
//                   Select with Roomba
//                 </p>
//                 <div className="offbutton">
//                   <button>$29/mo* Shop now</button>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </Link>
//       </div>
//     </section>
//   );
// }
import React, { useEffect } from 'react'
import offer_image from "../../image/offer-image.jpg"
import { Link } from "react-router-dom"
import { loadOfferBanner } from "../../redux/action/HomeAction"
import { useDispatch, useSelector } from 'react-redux'


export default function OfferSection() {
    const dispatch = useDispatch();
    useEffect((() => {
        dispatch(loadOfferBanner())
    }), [])

    const offerBanner = useSelector((state) => state.Home.offer)
    console.log("offerBanner", offerBanner);

    return (
        <section className='section offer-section'>
            <div className='container'>
                {offerBanner && offerBanner.slice(0, 1).map((offer) => {
                    return (
                        <Link to={offer.button1_url} key={offer}>
                            <img src={`${process.env.REACT_APP_API_PATH}${offer.image}`} alt="" />
                        </Link>
                    )
                })}

            </div>
            
        </section>
    )
}
