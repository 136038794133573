import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadAllSubAccesssories } from "../../redux/action/SubAccessoriesAction";
import { Link, useParams } from "react-router-dom";

export default function MainAccesories() {
  const [acces, setAcces] = useState("");
  const [categoryname, setcategoryname] = useState("");

  const dispatch = useDispatch();

  const SubAccessories = useSelector(
    (state) => state.SubAccessories.result.data
  );
  console.log("SubAccessories", SubAccessories);
  let { id } = useParams();

  useEffect(() => {
    console.log(id, "loadAllSubAccesssories");

    dispatch(loadAllSubAccesssories(id));
    setAcces(SubAccessories?.id);
  }, [id]);
  //[SubAccessories, id]);

  useEffect(() => {


    if (SubAccessories != undefined && SubAccessories != null) {
      console.log("maintittle", SubAccessories[0].catid.seo_tittle);
      var categoryname = SubAccessories[0].catid.name;
      setcategoryname(categoryname)

      //console.log("maintittle", SubAccessories[0].subcategory.seo_tittle);
      document.getElementById("maintittle").innerHTML = SubAccessories[0].catid.seo_tittle;

    }
    // document.getElementById("maintittle").innerHTML = bannerData.subcat_id.seo_tittle;



  }, [SubAccessories]);

  return (
    <section className="section accesories">
      {categoryname != "" ? <h2 className="acces_hed">{categoryname} and spare parts</h2> : ""}
      <div className="container">
        <div className="acces">
          <ul>
            {SubAccessories &&
              SubAccessories.map((accee, i) => {
                return (
                  <li key={accee.id}>
                    <div className="inner_acce">
                      <div className="acces_iamge">
                        <Link to={`/category/${accee.slug}`}>
                          <img
                            src={`${process.env.REACT_APP_API_PATH}${accee.image}`}
                          />
                        </Link>
                      </div>
                      <div className="acces_contennt">
                        <h3>{accee.name}</h3>
                        <Link
                          to={`/category/${accee.slug}`}
                          className="btn acessdd"
                        >
                          Shop Now
                        </Link>
                      </div>
                    </div>
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
    </section>
  );
}
