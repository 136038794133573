import {
  ADD_PRODUCT_REVIEW_FAIL,
  ADD_PRODUCT_REVIEW_REQUEST,
  ADD_PRODUCT_REVIEW_SUCCESS,
  GET_ALL_PRODUCT_REVIEW_FAIL,
  GET_ALL_PRODUCT_REVIEW_REQUEST,
  GET_ALL_PRODUCT_REVIEW_SUCCESS,
  GET_ALL_PRODUCT_REVIEW_IMAGE_FAIL,
  GET_ALL_PRODUCT_REVIEW_IMAGE_REQUEST,
  GET_ALL_PRODUCT_REVIEW_IMAGE_SUCCESS,
} from "../constants/ProductReviewConstant";

const initialState = {
  loading: false,
  action: "Main Product",
  result: [],
  response: {},
  singledata: [],
  productImages: [],
  reviewImages: [],
  productAddDetails: [],
  productSpec: [],
  msg: "",
  error: "",
};

const ProductReviewReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_PRODUCT_REVIEW_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case GET_ALL_PRODUCT_REVIEW_SUCCESS:
      return {
        ...state,
        result: action.result.data,
        loading: action.payload,
        msg: action.msg,
      };
    case GET_ALL_PRODUCT_REVIEW_FAIL:
      return {
        ...state,
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };

      case GET_ALL_PRODUCT_REVIEW_IMAGE_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case GET_ALL_PRODUCT_REVIEW_IMAGE_SUCCESS:
      return {
        ...state,
        reviewImages: action.result.data,
        loading: action.payload,
        msg: action.msg,
      };
    case GET_ALL_PRODUCT_REVIEW_IMAGE_FAIL:
      return {
        ...state,
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };


    case ADD_PRODUCT_REVIEW_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case ADD_PRODUCT_REVIEW_SUCCESS:
      return {
        ...state,
        result: state.result.concat(action.result.data),
        response: action.result.msg,
        loading: action.payload,
        msg: action.msg,
      };
    case ADD_PRODUCT_REVIEW_FAIL:
      return {
        ...state,
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };
    default:
      return state;
  }
};

export default ProductReviewReducer;
