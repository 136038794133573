import React from "react";
import { Spinner } from "react-bootstrap";
import spinner1 from "../../image/spinner1.gif";


const Loader = () => {
  return (

    // <div style={{
    //   width: "100px",
    //   height: "100px",
    //   margin: "auto",
    //   display: "block",
    // }}>
    //   <img id="loading-image" src={spinner1} alt="Loading..." />

    // </div >

    <Spinner
      animation="border"
      role="status"
      style={{
        width: "100px",
        height: "100px",
        margin: "auto",
        display: "block",
      }}
    >
      <span className="sr-only">Loading...</span>
    </Spinner>
  );
};

export default Loader;
