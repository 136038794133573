import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom"
import LoginBanner from "../component/share/LoginBanner";
import { forgetPasswordsend } from "../redux/action/AuthAction";



export default function ForgotPassword() {

  const [email, setEmail] = useState("");

  const dispatch = useDispatch();
  const submitHandler = (e) => {
    e.preventDefault();
    const d1 = new Date();
    console.log(d1);

    // converting to number
    const code = d1.getTime();
    console.log(code);

    dispatch(forgetPasswordsend(email, code));
    setTimeout(() => {

      setEmail('');
      alert("Password reset link has been sent to email");


    }, 500);
  };

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  //   <Link to="/" className="btn home">
  //   Home
  // </Link>
  return (
    <>
      {/* <LoginBanner /> */}
      <section className="section " >

        <div className="container">

          <div className="account_form login account_formforgot">
            <div className="account_bt">
              <h2 className="account_title">Forget Password</h2>


            </div>
            <form onSubmit={submitHandler}>
              <div className="acc_form">
                <div className="input_field">
                  <label>
                    Email address <span>*</span>
                  </label>

                  <label className='forgettext'>Forgot password? Please enter your email address and we'll email you a link to a page where you can reset your password.</label>

                  <input
                    type="email"
                    required
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <p className="fosgget">
                    <Link to="/login">Back to Login</Link>
                  </p>
                </div>

                <div className="input_field_btn">
                  <input type="submit" value="Send" />
                </div>
              </div>
            </form>
            <div className="acc_form login_register newrigforgot">
              <p>If you don't have an account, it's easy to register!</p>
              <Link to="/register" className="newrigforgotbtn">Account Registraton</Link>
            </div>
          </div>

        </div>
        <div className="res" style={{ paddingBottom: "70px" }}></div>
      </section>
    </>
  );
}
