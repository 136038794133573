import React, { useEffect } from "react";
import Contact from "../component/contactus/Contact";

export default function ContactUs() {
  useEffect(() => {
    document.getElementById("maintittle").innerHTML = "iRobot India Customer Service - 1800-200-3355 - Email Inquiry";
    document.getElementById("description").setAttribute("content", "Contact iRobot India customer service desk. Dial our toll-free number 1800-200-3355 and speak with our representatives. Register your complaints and queries with us.");

    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Contact />
    </div>
  );
}
