import {
  SIGNIN_USER_SUCCESS,
  SIGNIN_USER_REQUEST,
  SET_CURRENT_USER,
  SIGNIN_USER_ERROR,
  SIGNOUT_USER,
  USER_PASSWORD_UPDATE_FAIL,
  USER_PASSWORD_UPDATE_REQUEST,
  USER_PASSWORD_UPDATE_SUCCESS,
  USER_PROFILE_UPDATE_FAIL,
  USER_PROFILE_UPDATE_REQUEST,
  USER_PROFILE_UPDATE_SUCCESS,
  FETCH_USER_FAIL,
  FETCH_USER_REQUEST,
  FETCH_USER_SUCCESS,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAIL,
} from "../constants/AuthConstant";
const intialState = {
  isSigninIn: false,
  loading: false,
  email: "",
  password: "",
  action: "Signin",
  result: [],
  userResult: {},
  response: [],
  singledata: [],
  msg: "",
  error: "",
  isSignUp: false,


};

const AuthReducer = (state = intialState, action) => {
  switch (action.type) {

    case USER_REGISTER_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case USER_REGISTER_SUCCESS:
      return {
        ...state,
        isSignUp: action.isSignUp,
        loading: action.payload,
        msg: action.msg,
      };
    case USER_REGISTER_FAIL:
      return {
        ...state,
        isSignUp: false,
        msg: action.msg,
        loading: action.payload,
      };


    case SIGNIN_USER_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case SIGNIN_USER_SUCCESS:
      return {
        ...state,
        isSigninIn: action.isSigninIn,
        loading: action.payload,
        msg: action.msg,
      };
    case SIGNIN_USER_ERROR:
      return {
        ...state,
        isSigninIn: false,
        msg: action.msg,
        loading: action.payload,
      };

    case SET_CURRENT_USER:
      return {
        ...state,
        userResult: action.result,
        loading: action.payload,
        isSigninIn: true,
      };
    case SIGNOUT_USER:
      return {
        ...state,
        isSigninIn: action.payload,
      };

    case USER_PASSWORD_UPDATE_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case USER_PASSWORD_UPDATE_SUCCESS:
      return {
        ...state,
        result: state.result.map((item) =>
          item.id === action.result.data.id ? action.result.data : item
        ),
        response: action.result.msg,
        singledata: [],
        loading: action.payload,
        msg: action.msg,
      };
    case USER_PASSWORD_UPDATE_FAIL:
      return {
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };

    case USER_PROFILE_UPDATE_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case USER_PROFILE_UPDATE_SUCCESS:
      return {
        ...state,
        result: state.result,
        response: action.result.msg,
        loading: action.payload,
        msg: action.msg,
      };
    case USER_PROFILE_UPDATE_FAIL:
      return {
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };

    case FETCH_USER_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case FETCH_USER_SUCCESS:
      return {
        ...state,
        result: action.result.data,
        loading: action.payload,
        msg: action.msg,
      };
    case FETCH_USER_FAIL:
      return {
        ...state,
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };

    default:
      return state;
  }
};

export default AuthReducer;
