import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { loadSubCategoryTab } from "../../redux/action/StaticAction";
import LeftZigzag11 from "./Left_zigzag11";
import LeftZigzag22 from "./Left_zigzag22";
import LeftZigzag33 from "./Left_zigzag33";
import LeftZigzag44 from "./Left_zigzag44";
import Zigzag from "./Zigzag";
import { useParams } from "react-router-dom";
import $ from "jquery";

export default function Series_tab1(props) {

  $(function () {
    $(".tabbing li").click(function () {
      $(this).addClass("active");
      $(this).siblings().removeClass("active");
    });
  });

  const [tab, settab] = useState([]);

  const [tab1, setTab1] = useState(true);
  const [tab2, setTab2] = useState(false);
  const [tab3, setTab3] = useState(false);
  const [tab4, setTab4] = useState(false);

  const tabhander1 = () => {
    if (tab1 !== true) {
      setTab1(!tab1);
      setTab2(false);
      setTab3(false);
      setTab4(false);
    }
  };
  const tabhander2 = () => {
    if (tab2 !== true) {
      setTab2(!tab2);
      setTab1(false);
      setTab3(false);
      setTab4(false);
    }
  };
  const tabhander3 = () => {
    if (tab3 !== true) {
      setTab3(!tab3);
      setTab2(false);
      setTab1(false);
      setTab4(false);
    }
  };
  const tabhander4 = () => {
    if (tab4 !== true) {
      setTab4(!tab4);
      setTab2(false);
      setTab3(false);
      setTab1(false);
    }
  };



  const dispatch = useDispatch();

  let tabrcv = useSelector((state) => state.Static.subCategoriesTab);;
  console.log("tab_main", tab);

  useEffect(() => {
    if (props.banneridglobe != undefined && props.banneridglobe != 0) {
      dispatch(loadSubCategoryTab(props.banneridglobe));
      console.log("...banner in Series_tab1", props.banneridglobe);
    }
    else {
      tabrcv = [];
      console.log("...banner in Series_tab1 tabrcv", tabrcv);
      settab([]);
    }

  }, [props.banneridglobe]);

  useEffect(() => {

    settab(tabrcv);

  }, [tabrcv]);



  return (
    <>
      {tab.length > 0 ? (
        <>
          {tab1 ? <LeftZigzag11 banner_id={props.banneridglobe} /> : ""}
          {tab2 ? <LeftZigzag22 banner_id={props.banneridglobe} /> : ""}
          {tab3 ? <LeftZigzag33 banner_id={props.banneridglobe} /> : ""}
          {tab4 ? <LeftZigzag44 banner_id={props.banneridglobe} /> : ""}

          <section className="section tabbing">
            <div className="container">
              {tab.map((da) => {
                return (
                  <>
                    <div key={da.id}>
                      {da.status !== 1 ? "" :
                        <ul>
                          <li className="active" onClick={tabhander1}>
                            {da.tab1_title == null ? (
                              <h3></h3>
                            ) : (
                              <h3>{da.tab1_title}</h3>
                            )}
                            {da.tab1_description == null ? (
                              <p></p>
                            ) : (
                              <div className="tabing_inner">
                                <p>{da.tab1_description}</p>
                              </div>
                            )}
                          </li>
                          <li onClick={tabhander2}>
                            {da.tab2_title == null ? (
                              <h3></h3>
                            ) : (
                              <h3>{da.tab2_title}</h3>
                            )}
                            {da.tab2_description == null ? (
                              <p></p>
                            ) : (
                              <div className="tabing_inner">
                                <p>{da.tab2_description}</p>
                              </div>
                            )}
                          </li>
                          <li onClick={tabhander3}>
                            {da.tab3_title == null ? (
                              <h3></h3>
                            ) : (
                              <h3>{da.tab3_title}</h3>
                            )}
                            {da.tab3_description == null ? (
                              <p></p>
                            ) : (
                              <div className="tabing_inner">
                                <p>{da.tab3_description}</p>
                              </div>
                            )}
                          </li>
                          <li onClick={tabhander4}>
                            {da.tab4_title == null ? (
                              <h3></h3>
                            ) : (
                              <h3>{da.tab4_title}</h3>
                            )}
                            {da.tab4_description == null ? (
                              <p></p>
                            ) : (
                              <div className="tabing_inner">
                                <p>{da.tab4_description}</p>
                              </div>
                            )}
                          </li>
                        </ul>}
                    </div>
                  </>
                );
              })}
            </div>
          </section>
        </>
      ) : (
        <></>
      )}
    </>
  );
}
