import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import offer from "../../image/offer.png"
import { loadAllOfferContent } from "../../redux/action/StaticAction";

export default function InnerOfferBanner() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadAllOfferContent());
  }, []);
  const banners = useSelector((state) => state.Static.offerContent);
  // console.log("banners", banners);
  return (
    <>
      {banners.slice(0, 1).map((e) => {
        return (
          <section className="zigzag" key={e.id}>
            <div className="zigzag_content">
              <div className="rightt">
                {e.banner_image == null ?
                  <img src={offer} /> :
                  <img src={`${process.env.REACT_APP_API_PATH}${e.banner_image}`} />

                }
              </div>
              <div className="leftt">
                <div className="container">
                  {e.banner_tagline == null ?
                    <span className='savving'>SAVE UP TO £440</span> :
                    <span className='savving'>{e.banner_tagline}</span>}
                  {e.banner_heading == null ?
                    <h3 className="title"><span>Savings On Our Best Sellers</span></h3> :
                    <h3 className="title"><span>{e.banner_heading}</span></h3>}
                  {e.banner_content == null ? <>
                    <p>Explore our 2021 Best Sellers and find your match</p>
                    <p>Save up to £440 on selected robot models.</p>
                  </>
                    :
                    <p>{e.banner_content}</p>}
                  {e.banner_description == null ?
                    <p><small>Limited time offer on selected items. While stocks last.</small></p> :
                    <p><small>{e.banner_description}</small></p>
                  }

                </div>
              </div>

            </div>

          </section>
        );
      })}
    </>
  )
}
