import {
  GET_ALL_STORE_FAIL,
  GET_ALL_STORE_REQUEST,
  GET_ALL_STORE_SUCCESS,
  GET_SINGLE_STORE_FAIL,
  GET_SINGLE_STORE_REQUEST,
  GET_SINGLE_STORE_SUCCESS,
  GET_SINGLE_STORE_FILTER_FAIL,
  GET_SINGLE_STORE_FILTER_REQUEST,
  GET_SINGLE_STORE_FILTER_SUCCESS,
} from "../constants/StoreConstant";
import axios from "axios";
import { toast } from "react-toastify";

const loadAllStore = () => {
  return function (dispatch) {
    dispatch({
      type: GET_ALL_STORE_REQUEST,
      payload: true,
    });
    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/store/`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    axios(OPTION)
      .then((res) => {
        // console.log("res", res.data);
        dispatch(loadAllStorePre(res.data));
      })
      .catch((error) => {
        // console.log("error", error);
        dispatch({
          type: GET_ALL_STORE_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
        // toast.error(error.msg);
      });
  };
};
export const loadAllStorePre = (data) => {
  // toast.success(data.msg);
  return {
    type: GET_ALL_STORE_SUCCESS,
    result: data,
    payload: false,
    mas: "SUCCESS",
  };
};

const loadSingleStore = (id) => {
  return function (dispatch) {
    dispatch({
      type: GET_SINGLE_STORE_REQUEST,
      payload: true,
    });
    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/store/${id}/`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    axios(OPTION)
      .then((res) => {
        // console.log("res", res.data);
        dispatch(loadSingleStorePre(res.data));
      })
      .catch((error) => {
        // console.log("error", error);
        dispatch({
          type: GET_SINGLE_STORE_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
        // toast.error(error.msg);
      });
  };
};
export const loadSingleStorePre = (data) => {
  // toast.success(data.msg);
  return {
    type: GET_SINGLE_STORE_SUCCESS,
    result: data,
    payload: false,
    mas: "SUCCESS",
  };
};

const StoreFilter = (data) => {
  return function (dispatch) {
    dispatch({
      type: GET_SINGLE_STORE_FILTER_REQUEST,
      payload: true,
    });
    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/store-filter/`,
      method: "POST",
      data: data,
      headers: {
        "content-type": "application/json",
      },
    };
    axios(OPTION)
      .then((res) => {
        // console.log("res", res.data);
        dispatch(StoreFilterPre(res.data));
      })
      .catch((error) => {
        // console.log("error", error);
        dispatch({
          type: GET_SINGLE_STORE_FILTER_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
        // toast.error(error.msg);
      });
  };
};
export const StoreFilterPre = (data) => {
  // toast.success(data.msg);
  return {
    type: GET_SINGLE_STORE_FILTER_SUCCESS,
    result: data,
    payload: false,
    mas: "SUCCESS",
  };
};

export { loadAllStore, loadSingleStore, StoreFilter };
