import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadAllGeniusContent } from "../../redux/action/StaticAction";

export default function ContentOnly() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadAllGeniusContent());
  }, []);
  const banners = useSelector((state) => state.Static.genius);

  return (
    <>
      {banners.slice(0, 1).map((e) => {
        return (
          <section className="section content_only" key={e.id}>
            <div className="container">
              {e.banner_bottom_heading == null ?
                <h3 className="title">The mastermind <span>behind a tailored experience. </span></h3> :
                <h3 className="title">{e.banner_bottom_heading}</h3>}
              {e.banner_bottom_content == null ?
                <p className="subheading">Based on your habits, home and even the seasons, iRobot Genius combines insights and technology to create a personalised cleaning experience—while giving you control, straight from the app. Now that’s Genius.</p> :
                <p className="subheading">{e.banner_bottom_content}</p>}
            </div>
          </section>
        );
      })}
    </>
  )
}
