import React, { useEffect } from 'react';
import SingleBlog from "../component/blog/SingleBlog";
import RightSidebar from "../component/blog/RightSidebar";


export default function SinglePost() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <section className="blog_page single_post" style={{ background: "#fff" }}>
      <div className="container">
        <SingleBlog />
        <RightSidebar />

        {/* 
        <RightSidebar />
        */}

      </div>
    </section>
  )
}
