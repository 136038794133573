import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { loadGeniusTab } from "../../redux/action/StaticAction";
import zig_zag1 from '../../image/zig_zag1.jpg'
// import { useParams } from 'react-router-dom';

export default function Left_zigzag110({ banner_id }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadGeniusTab(banner_id));
  }, [])

  const tab = useSelector((state) => state.Static.geniusTab)

  return (
    <>
      {tab.map((da) => {
        return (
          <section className="section zigzag" key={da.id}>

            <div className="zigzag_content">
              <div className="leftt">
                <div className="container">
                  <h3 className="title"><span>{da.tab1_heading}</span></h3>
                </div>
              </div>
              <div className="rightt">
                <img src={`${process.env.REACT_APP_API_PATH}${da.tab1_image}`} alt="" />
              </div>
            </div>

          </section>
        )
      })}
    </>


  )
}
