export const GET_ALL_STORE_REQUEST = "GET_ALL_STORE_REQUEST";
export const GET_ALL_STORE_SUCCESS = "GET_ALL_STORE_SUCCESS";
export const GET_ALL_STORE_FAIL = "GET_ALL_STORE_FAIL";

export const GET_SINGLE_STORE_REQUEST = "GET_SINGLE_STORE_REQUEST";
export const GET_SINGLE_STORE_SUCCESS = "GET_SINGLE_STORE_SUCCESS";
export const GET_SINGLE_STORE_FAIL = "GET_SINGLE_STORE_FAIL";

export const GET_SINGLE_STORE_FILTER_REQUEST =
  "GET_SINGLE_STORE_FILTER_REQUEST";
export const GET_SINGLE_STORE_FILTER_SUCCESS =
  "GET_SINGLE_STORE_FILTER_SUCCESS";
export const GET_SINGLE_STORE_FILTER_FAIL = "GET_SINGLE_STORE_FILTER_FAIL";
