import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../Shared/Loader";
import { searchOrdertract } from "../../redux/action/AdditionalAction";

export default function Ordertract() {


    const [order_id, setOrder_id] = useState("");
    const [awbnumber, setawbnumber] = useState("");

    const ordertrack = useSelector((state) => state.Additional.searchOrderTract);
    const dispatch = useDispatch();


    const handleawbInvoice = () => {
        dispatch(searchOrdertract(awbnumber, ""));
    };
    useEffect(() => {
        console.log("ordertrack..", ordertrack);
    }, [ordertrack]);


    return (




        <div className="orderHistory">
            <div className="user_profile">
                <h2 className="title">
                    Track Orders & Shipments
                </h2>
            </div>

            <div className="check-status">
                <form className="tractclass" >
                    <div className="input_field">
                        <label>
                            AWB Number <span>*</span>
                        </label>
                        <input
                            required
                            type="text"
                            value={awbnumber}
                            name="awbnumber"
                            onChange={(e) => setawbnumber(e.target.value)} />
                    </div>


                    <div className="div_field_tract">
                        <input
                            type="button"
                            value="Check Status"
                            className="btn btntract"
                            onClick={() => handleawbInvoice()} />




                    </div>
                </form>



            </div>

            {ordertrack != null & ordertrack != undefined && ordertrack != '' ? <>
                <div className="recentPurchase">
                    <div
                        dangerouslySetInnerHTML={{
                            __html: ordertrack,
                        }}
                    ></div>

                </div></> : ""}

        </div >
    );
}
