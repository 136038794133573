import React, { useState, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
// import { useNavigate, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  loadAllPoints,
  loadPointsHistory,
  loadAllEarnPoints,
  loadAllSpendPoints,
} from "../../redux/action/LoyaltyPointsAction";
import Loader from "../Shared/Loader";

const RewardPoints = () => {
  const [currentPage, setcurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [pageNumberLimit] = useState(5);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0);

  const [currentPage1, setcurrentPage1] = useState(1);
  const [itemsPerPage1] = useState(10);
  const [pageNumberLimit1] = useState(5);
  const [maxPageNumberLimit1, setmaxPageNumberLimit1] = useState(5);
  const [minPageNumberLimit1, setminPageNumberLimit1] = useState(0);

  const [currentPage2, setcurrentPage2] = useState(1);
  const [itemsPerPage2] = useState(10);
  const [pageNumberLimit2] = useState(5);
  const [maxPageNumberLimit2, setmaxPageNumberLimit2] = useState(5);
  const [minPageNumberLimit2, setminPageNumberLimit2] = useState(0);

  const dispatch = useDispatch();

  const userInfo = useSelector((state) => state.AuthReducer);

  const { userResult } = userInfo;

  let id = userResult.id;

  useEffect(() => {
    if (id) {
      dispatch(loadAllPoints(id));
      dispatch(loadPointsHistory(id));
      dispatch(loadAllEarnPoints(id));
      dispatch(loadAllSpendPoints(id));
    }
  }, []);

  let pointsData = useSelector((state) => state.LoyaltyPoints);

  let { loading, loyaltyPoints, pointsHistory, earnHistory, spendHistory } =
    pointsData;

  let arr = [];

  arr.push(loyaltyPoints);

  // // console.log("arr", arr);

  // if (loyaltyPoints !== null && loyaltyPoints !== undefined) {
  //   if (loyaltyPoints.cr !== null && loyaltyPoints.cr !== undefined) {
  //     // console.log("loyaltyPoints2", loyaltyPoints.cr.points__sum);
  //   }
  // }

  const handleClick = (e) => {
    setcurrentPage(Number(e.target.id));
  };

  const pages = [];
  for (let i = 1; i <= Math.ceil(pointsHistory.length / itemsPerPage); i++) {
    pages.push(i);
  }

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = pointsHistory.slice(indexOfFirstItem, indexOfLastItem);

  const renderPageNumbers = pages.map((number) => {
    if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
      return (
        <li
          key={number}
          id={number}
          onClick={handleClick}
          className={currentPage === number ? "active" : null}
        >
          {number}
        </li>
      );
    } else {
      return null;
    }
  });

  const handleNextbtn = () => {
    setcurrentPage(currentPage + 1);

    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };

  const handlePrevbtn = () => {
    setcurrentPage(currentPage - 1);

    if ((currentPage - 1) % pageNumberLimit === 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };

  let pageIncrementBtn = null;
  if (pages.length > maxPageNumberLimit) {
    pageIncrementBtn = <li onClick={handleNextbtn}> &hellip; </li>;
  }

  let pageDecrementBtn = null;
  if (minPageNumberLimit >= 1) {
    pageDecrementBtn = <li onClick={handlePrevbtn}> &hellip; </li>;
  }

  let j = currentPage * 10 + 1 - 10;

  // const handleClick1 = (e) => {
  //   setcurrentPage1(Number(e.target.id));
  // };

  // const pages1 = [];
  // for (let i = 1; i <= Math.ceil(earnHistory.length / itemsPerPage1); i++) {
  //   pages1.push(i);
  // }

  // const indexOfLastItem1 = currentPage1 * itemsPerPage1;
  // const indexOfFirstItem1 = indexOfLastItem1 - itemsPerPage1;
  // const currentItems1 = earnHistory.slice(indexOfFirstItem1, indexOfLastItem1);

  // const renderPageNumbers1 = pages1.map((number) => {
  //   if (number < maxPageNumberLimit1 + 1 && number > minPageNumberLimit1) {
  //     return (
  //       <li
  //         key={number}
  //         id={number}
  //         onClick={handleClick1}
  //         className={currentPage1 === number ? "active" : null}
  //       >
  //         {number}
  //       </li>
  //     );
  //   } else {
  //     return null;
  //   }
  // });

  // const handleNextbtn1 = () => {
  //   setcurrentPage1(currentPage1 + 1);

  //   if (currentPage1 + 1 > maxPageNumberLimit1) {
  //     setmaxPageNumberLimit1(maxPageNumberLimit1 + pageNumberLimit1);
  //     setminPageNumberLimit1(minPageNumberLimit1 + pageNumberLimit1);
  //   }
  // };

  // const handlePrevbtn1 = () => {
  //   setcurrentPage1(currentPage1 - 1);

  //   if ((currentPage1 - 1) % pageNumberLimit1 === 0) {
  //     setmaxPageNumberLimit1(maxPageNumberLimit1 - pageNumberLimit1);
  //     setminPageNumberLimit1(minPageNumberLimit1 - pageNumberLimit1);
  //   }
  // };

  // let pageIncrementBtn1 = null;
  // if (pages1.length > maxPageNumberLimit1) {
  //   pageIncrementBtn1 = <li onClick={handleNextbtn1}> &hellip; </li>;
  // }

  // let pageDecrementBtn1 = null;
  // if (minPageNumberLimit1 >= 1) {
  //   pageDecrementBtn1 = <li onClick={handlePrevbtn1}> &hellip; </li>;
  // }

  // let k = currentPage * 10 + 1 - 10;

  // const handleClick2 = (e) => {
  //   setcurrentPage2(Number(e.target.id));
  // };

  // const pages2 = [];
  // for (let i = 1; i <= Math.ceil(spendHistory.length / itemsPerPage2); i++) {
  //   pages2.push(i);
  // }

  // const indexOfLastItem2 = currentPage2 * itemsPerPage2;
  // const indexOfFirstItem2 = indexOfLastItem2 - itemsPerPage2;
  // const currentItems2 = spendHistory.slice(indexOfFirstItem2, indexOfLastItem2);

  // const renderPageNumbers2 = pages2.map((number) => {
  //   if (number < maxPageNumberLimit2 + 1 && number > minPageNumberLimit2) {
  //     return (
  //       <li
  //         key={number}
  //         id={number}
  //         onClick={handleClick2}
  //         className={currentPage2 === number ? "active" : null}
  //       >
  //         {number}
  //       </li>
  //     );
  //   } else {
  //     return null;
  //   }
  // });

  // const handleNextbtn2 = () => {
  //   setcurrentPage2(currentPage2 + 1);

  //   if (currentPage2 + 1 > maxPageNumberLimit2) {
  //     setmaxPageNumberLimit2(maxPageNumberLimit2 + pageNumberLimit2);
  //     setminPageNumberLimit2(minPageNumberLimit2 + pageNumberLimit2);
  //   }
  // };

  // const handlePrevbtn2 = () => {
  //   setcurrentPage2(currentPage2 - 1);

  //   if ((currentPage2 - 1) % pageNumberLimit2 === 0) {
  //     setmaxPageNumberLimit2(maxPageNumberLimit2 - pageNumberLimit2);
  //     setminPageNumberLimit2(minPageNumberLimit2 - pageNumberLimit2);
  //   }
  // };

  // let pageIncrementBtn2 = null;
  // if (pages2.length > maxPageNumberLimit2) {
  //   pageIncrementBtn2 = <li onClick={handleNextbtn2}> &hellip; </li>;
  // }

  // let pageDecrementBtn2 = null;
  // if (minPageNumberLimit2 >= 1) {
  //   pageDecrementBtn2 = <li onClick={handlePrevbtn2}> &hellip; </li>;
  // }

  // let l = currentPage * 10 + 1 - 10;

  let formatter = new Intl.DateTimeFormat("en-GB", {
    year: "numeric",
    month: "numeric",
    day: "2-digit",
  });

  return (
    <Tabs className="reward-points-tabs">
      <ToastContainer />
      <TabList>
        <Tab>Total Reward</Tab>
        <Tab>Reward History</Tab>
        {/* <Tab>Reward Earn History</Tab> */}
        {/* <Tab>Reward Spend History</Tab> */}
      </TabList>

      <div className="content_area rewards-content">
        <TabPanel>
          <ul>
            {loading === true ? (
              <>
                <Loader />
              </>
            ) : (
              <li key="">
                <div className="addressBox">
                  <div className="top_title">
                    <h3>
                      <strong>Reward Points</strong>
                    </h3>
                  </div>
                  <div className="bottom_address">
                    <ul className="inner-rewards">
                      {loyaltyPoints !== null && loyaltyPoints !== undefined ? (
                        <>
                          <>
                            {loyaltyPoints.cr !== null &&
                              loyaltyPoints.cr !== undefined &&
                              loyaltyPoints.db !== null &&
                              loyaltyPoints.db !== undefined ? (
                              <>
                                <li>
                                  <p className="reward-name">
                                    Available Points
                                  </p>
                                  <p className="reward-value">
                                    <strong>
                                      {loyaltyPoints.cr.points__sum -
                                        loyaltyPoints.db.points__sum}
                                    </strong>
                                  </p>
                                </li>
                              </>
                            ) : (
                              <></>
                            )}
                          </>
                          <>
                            {loyaltyPoints.cr !== null &&
                              loyaltyPoints.cr !== undefined ? (
                              <>
                                <li>
                                  <p className="reward-name">
                                    Total Earn Points
                                  </p>
                                  <p className="reward-value">
                                    <strong>
                                      {loyaltyPoints.cr.points__sum}
                                    </strong>
                                  </p>
                                </li>
                              </>
                            ) : (
                              <></>
                            )}
                          </>
                          <>
                            {loyaltyPoints.db !== null &&
                              loyaltyPoints.db !== undefined ? (
                              <>
                                <li>
                                  <p className="reward-name">
                                    Total Spend Points
                                  </p>
                                  <p className="reward-value">
                                    <strong>
                                      {loyaltyPoints.db.points__sum}
                                    </strong>
                                  </p>
                                </li>
                              </>
                            ) : (
                              <></>
                            )}
                          </>
                          <>
                            {loyaltyPoints.purchasePoints !== null &&
                              loyaltyPoints.purchasePoints !== undefined ? (
                              <>
                                <li>
                                  <p className="reward-name">
                                    Points Earn By Refral
                                  </p>
                                  <p className="reward-value">
                                    <strong>
                                      {loyaltyPoints.purchasePoints.points__sum}
                                    </strong>
                                  </p>
                                </li>
                              </>
                            ) : (
                              <></>
                            )}
                          </>
                          <>
                            {loyaltyPoints.refralPoints !== null &&
                              loyaltyPoints.refralPoints !== undefined ? (
                              <>
                                <li>
                                  <p className="reward-name">
                                    Points Earn By Purchase
                                  </p>
                                  <p className="reward-value">
                                    <strong>
                                      {loyaltyPoints.refralPoints.points__sum}
                                    </strong>
                                  </p>
                                </li>
                              </>
                            ) : (
                              <></>
                            )}
                          </>
                        </>
                      ) : (
                        <></>
                      )}
                    </ul>
                  </div>
                </div>
              </li>
            )}
          </ul>
        </TabPanel>

        <TabPanel>
          <div className="orderHistory">
            <h4 className="title">Reward History</h4>
            <div className="product_cart">
              <div className="">
                <div className="cart_tabel">
                  {loading === true ? (
                    <>
                      <Loader />
                    </>
                  ) : (
                    <>
                      {pointsHistory.length > 0 ?
                        <>
                          <table>
                            <thead>
                              <tr>
                                <th className="section-header">Sr.no</th>
                                <th className="section-header">Points</th>
                                <th className="section-header">Points-Type</th>
                                <th className="section-header">Transition Type</th>
                                <th className="section-header Purchase">Date</th>
                              </tr>
                            </thead>
                            <tbody>
                              {currentItems &&
                                currentItems.map((item, i) => (
                                  <tr key={i}>
                                    <td>
                                      <strong>{j++}</strong>
                                    </td>
                                    <td>{item.points}</td>
                                    <td>
                                      <h3>
                                        {item.point_type === 2 ? (
                                          <>Refral Reward</>
                                        ) : (
                                          <>Purchase Reward</>
                                        )}
                                      </h3>
                                    </td>
                                    <td>
                                      <div className="series_price">
                                        <p className="regular">
                                          <span className="sale">
                                            {item.type2 === "cr" ? (
                                              <>Credit</>
                                            ) : (
                                              <>Debit</>
                                            )}
                                          </span>
                                        </p>
                                      </div>
                                    </td>

                                    <td className="delivery_date">
                                      <strong>
                                        {formatter.format(
                                          Date.parse(item.created_at)
                                        )}
                                      </strong>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                          {/* {pointsHistory.length} */}
                          {pointsHistory.length >= 5 ? <div className="mt-4">
                            <ul className="pageNumbers">
                              <li>
                                <button
                                  onClick={handlePrevbtn}
                                  disabled={currentPage === pages[0] ? true : false}
                                >
                                  Prev
                                </button>
                              </li>
                              {pageDecrementBtn}
                              {renderPageNumbers}
                              {pageIncrementBtn}

                              <li>
                                <button
                                  onClick={handleNextbtn}
                                  disabled={
                                    currentPage === pages[pages.length - 1]
                                      ? true
                                      : false
                                  }
                                >
                                  Next
                                </button>
                              </li>
                            </ul>
                          </div> : ""}

                        </>
                        : "No Reward History"}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
      </div>
    </Tabs>
  );
};

export default RewardPoints;
