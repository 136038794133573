import React, { useEffect } from "react";
import InnerBanner from "../component/applynow/InnerBanner";
import ApplyNowForm from "../component/applynow/ApplyNowForm";

export default function ApplyNow() {
  useEffect(() => {
    document.getElementById("maintittle").innerHTML = "iRobot India Jobs - Submit updated Resume - Career Opportunities";
    document.getElementById("description").setAttribute("content", "Check careers opportunities at iRobot India and join the growing team. Puresight Systems Pvt Ltd invites CVs for relevant job openings for its Bangalore office.");
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <InnerBanner />
      <div className="divApplyNowForm">
        <ApplyNowForm />

      </div>
    </>
  );
}
