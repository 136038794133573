import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";

import { useParams, Link } from "react-router-dom";
import LoginBanner from "../component/share/LoginBanner";
import { changexPasswordsend } from "../redux/action/AuthAction";



export default function ChangenewPassword() {

  const [confirmpassword, setConfirmpassword] = useState("");
  const [newpassword, setNewpassword] = useState("");
  let { id } = useParams();


  const dispatch = useDispatch();
  const submitHandler = (e) => {
    e.preventDefault();
    const d1 = new Date();
    console.log(d1);

    // converting to number
    const code = d1.getTime();
    console.log(code);
    if (id) {
      dispatch(changexPasswordsend(newpassword, id));

    }
    setTimeout(() => {

      setConfirmpassword('');
      setNewpassword('');

      // alert("Password changed successfully");


    }, 500);
  };

  useEffect(() => {

    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <LoginBanner />
      <section className="section " style={{ background: "#fff" }}>
        <div className="container">
          <div className="account_form login">

            <form onSubmit={submitHandler}>
              <div className="updatePassword">
                <h2 className="title">Change Password</h2>
                <div className="update_pass">

                  <div className="input_field">
                    <label>
                      New Password <span>*</span>
                    </label>
                    <input
                      type="text"
                      required
                      name="newpassword"
                      value={newpassword}
                      onChange={(e) => setNewpassword(e.target.value)}
                    />
                  </div>
                  <div className="input_field">
                    <label>
                      Confirm Password <span>*</span>
                    </label>
                    <input
                      type="password"
                      required
                      name="confirmpassword"
                      value={confirmpassword}
                      onChange={(e) => setConfirmpassword(e.target.value)}
                    />
                  </div>

                  <div className="input_field_btn">
                    <button type="submit">Change Password</button>

                  </div>

                </div>
              </div>
            </form>
            <Link to="/login" className="backtologin">
              <p style={{ color: "black" }}>Back to Login</p>
            </Link>
          </div>

        </div>
        <div className="res" style={{ paddingBottom: "70px" }}>

        </div>
      </section>
    </>
  );
}
