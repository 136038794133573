import React, { useEffect } from "react";
import FaqTop from "../component/faq/FaqTop";
import FaqText from "../component/faq/FaqText";

export default function Faq() {
  useEffect(() => {
    document.getElementById("maintittle").innerHTML = "FAQ - Frequently Asked Questions - iRobot India";
    document.getElementById("description").setAttribute("content", "Get online support on Roomba and Braava. Read FAQ and get answers to the most common questions on iRobot products in India.");

    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <FaqTop />
      {/* <FaqText /> */}
    </>
  );
}
