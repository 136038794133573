import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadAllRegisterRobots } from "../../redux/action/RegisterRobotAction";
import Loader from "../Shared/Loader";
import Message from "../Shared/Message";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const RegisteredRobots = () => {
  const [currentPage, setcurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [pageNumberLimit] = useState(5);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0);

  const dispatch = useDispatch();

  let user = useSelector((state) => state.AuthReducer);
  let { userResult } = user;
  let user_id = userResult.id;

  useEffect(() => {
    dispatch(loadAllRegisterRobots(user_id));
  }, [dispatch, user_id]);

  const RegisterRobot = useSelector((state) => state.RegisterRobot);

  let { loading, error, result } = RegisterRobot;

  const handleClick = (e) => {
    setcurrentPage(Number(e.target.id));
  };

  const pages = [];
  for (let i = 1; i <= Math.ceil(result.length / itemsPerPage); i++) {
    pages.push(i);
  }

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = result.slice(indexOfFirstItem, indexOfLastItem);

  const renderPageNumbers = pages.map((number) => {
    if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
      return (
        <li
          key={number}
          id={number}
          onClick={handleClick}
          className={currentPage === number ? "active" : null}
        >
          {number}
        </li>
      );
    } else {
      return null;
    }
  });

  const handleNextbtn = () => {
    setcurrentPage(currentPage + 1);

    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };

  const handlePrevbtn = () => {
    setcurrentPage(currentPage - 1);

    if ((currentPage - 1) % pageNumberLimit === 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };

  let pageIncrementBtn = null;
  if (pages.length > maxPageNumberLimit) {
    pageIncrementBtn = <li onClick={handleNextbtn}> &hellip; </li>;
  }

  let pageDecrementBtn = null;
  if (minPageNumberLimit >= 1) {
    pageDecrementBtn = <li onClick={handlePrevbtn}> &hellip; </li>;
  }

  let j = currentPage * 10 + 1 - 10;

  return (
    <div className="orderHistory">
      <ToastContainer />
      <h2 className="title">Registered Robots</h2>
      <div className="product_cart">
        <div className="">
          <div className="regrobot">
            {loading ? (
              <Loader />
            ) : error ? (
              <Message />
            ) : (
              <>
                {result.length > 0 ? (
                  <>
                    <table>
                      <thead>
                        <tr>
                          <th className="section-header">Sr.No</th>
                          <th className="section-header">Robot name</th>
                          <th className="section-header">Serial No</th>
                          <th className="section-header">Seller</th>
                          <th className="section-header Purchase">
                            Purchase Date
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentItems &&
                          currentItems.map((item, i) => (
                            <tr key={i}>
                              <td>{j++}</td>
                              <td>{item.productId.name}</td>
                              <td>{item.serial_no}</td>
                              <td>{item.seller_name}</td>
                              <td>{item.purchase_date}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                    {result.length > 10 ? (
                      <div className="mt-4">
                        <ul className="pageNumbers">
                          <li>
                            <button
                              onClick={handlePrevbtn}
                              disabled={currentPage === pages[0] ? true : false}
                            >
                              Prev
                            </button>
                          </li>
                          {pageDecrementBtn}
                          {renderPageNumbers}
                          {pageIncrementBtn}

                          <li>
                            <button
                              onClick={handleNextbtn}
                              disabled={
                                currentPage === pages[pages.length - 1]
                                  ? true
                                  : false
                              }
                            >
                              Next
                            </button>
                          </li>
                        </ul>
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  "Robot Not Registered"
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisteredRobots;
