import React, { useState, useRef, useEffect, useLayoutEffect } from "react";
import { useLocation } from 'react-router-dom';

import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
//import logo from "../../image/logo.png";
import logo from "../../image/iRobot_Logo_Animation.gif";
import logomob from "../../image/logo.png";

import InquireForm from "./../../component/home/InquireForm"
import { isMobile } from 'react-device-detect';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faUser,
  faShoppingCart,
  faBars,
  faAngleDown,
} from "@fortawesome/fontawesome-free-solid";
import SubMenuAccesories from "./SubMenuAccesories";
import SubMenuAbout from "./SubMenuAbout";
import SubMenuContact from "./SubMenuContact";
import SubMenuProduct from "./SubMenuProduct";
import { signOut } from "../../redux/action/AuthAction";
import { loadAllCart } from "../../redux/action/CartAction";
import HeaderSearch from "./HeaderSearch";
import { loadAllMenuTitle } from "../../redux/action/AdditionalAction";
import { FaWindowClose, FaTimes } from "react-icons/fa";
import CartPopup from "./cartpopup";



function Header() {

  const location = useLocation();

  const queryParams = new URLSearchParams(window.location.search);

  var utm_sourcercv = queryParams.get('utm_source');
  var utm_campaignrcv = queryParams.get('utm_campaign');

  useEffect(() => {
    console.log('utm_sourcercv...');

    if (utm_sourcercv != null && utm_sourcercv != '' && utm_sourcercv != undefined) {
      localStorage.setItem('utm_source', utm_sourcercv);
      localStorage.setItem('utm_campaign', utm_campaignrcv);
    }
    var item1 = localStorage.getItem('utm_source');
    console.log('utm_source...', item1);

    var item2 = localStorage.getItem('utm_campaign');
    console.log('utm_campaign...', item2);

  }, []);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);

    console.log('Location changed');
    showChange();
  }, [location]);




  // const ref = useRef(null);
  function handleClickOutside(event) {
    // if (ref.current && !ref.current.contains(event.target)) {
    //alert("You clicked outside of me!");
    if (!isMobile) {
      //showChange();
    }
    // }
  }
  useLayoutEffect(() => {

    // alert("Location useLayoutEffect.");

  });



  const [expended1, setExpended1] = useState(false);
  const [expended2, setExpended2] = useState(false);
  const [expended3, setExpended3] = useState(false);
  const [expended4, setExpended4] = useState(false);
  const [search, setSearch] = useState(false);
  const [mobile, setMobile] = useState(false);
  const [cartpopup, setcartpopup] = useState(false);




  const showChange = () => {
    
    console.log(expended1, expended2, expended3, expended4, search, mobile, cartpopup);

    setcartpopup(false);
    setExpended1(false);
    setExpended2(false);
    setExpended3(false);
    setExpended4(false);
    setSearch(false);
    setMobile(false);

  };



const gotocart = (e) => {
  e.preventDefault();
  e.stopPropagation();

  // Check if cart has items and if it's the first time clicking the cart icon
  const isFirstCartClick = quantity > 0 && !localStorage.getItem('firstCartClicked');

  // if (isFirstCartClick) {
  //   // Set flag that cart has been clicked
  //   localStorage.setItem('firstCartClicked', 'true');
  //   // Redirect to home
  //   window.location.href = "/";
  //   return;
  // }

  // For subsequent cart views or when adding items
  if (!isMobile) {
    if (quantity > 0) {
      setcartpopup(true);
    }
  } else {
    window.location.href = "/cart";
  }

  setcartpopup(!cartpopup);
  setExpended1(false);
  setExpended2(false);
  setExpended3(false);
  setExpended4(false);
};

//   const gotocart = (e) => {
//     e.preventDefault();
//     console.log(expended1, expended2, expended3, expended4, search, mobile, cartpopup);
//     setcartpopup(true);
//     // showChange();
//     /*
//     if (quantity > 0) {
//       window.location.href = "/cart";
//     }
// */


//     if (cartpopup !== true) {
//       setcartpopup(!cartpopup);
//       setExpended1(false);
//       setExpended2(false);
//       setExpended3(false);
//       setExpended4(false);

//       if (!isMobile) {

//         if (quantity > 0) {
//           setcartpopup(true);

//         }
//       }
//       else {
//         if (quantity > 0) {
//           window.location.href = "/cart";
//         }
//       }



//     } else {
//       setcartpopup(false);

//     }


//   };

  const handleChange1 = () => {
    if (expended1 !== true) {
      setExpended1(!expended1);
      setExpended2(false);
      setExpended3(false);
      setExpended4(false);
      setcartpopup(false);

    } else {
      setExpended1(false);
    }
  };

  const handleChange2 = () => {
    if (expended2 !== true) {
      setExpended2(!expended2);
      setExpended1(false);
      setExpended3(false);
      setExpended4(false);
      setcartpopup(false);

    } else {
      setExpended2(false);
    }
  };
  const handleChange3 = () => {
    if (expended3 !== true) {
      setExpended3(!expended3);
      setExpended1(false);
      setExpended2(false);
      setExpended4(false);
      setcartpopup(false);

    } else {
      setExpended3(false);
    }
  };
  const handleChange4 = () => {
    if (expended4 !== true) {
      setExpended4(!expended4);
      setExpended1(false);
      setExpended2(false);
      setExpended3(false);
      setcartpopup(false);

    } else {
      setExpended4(false);
    }
  };

  const [quantity, setQuantity] = useState(0);
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.AuthReducer);

  const { isSigninIn, userResult } = userInfo;

  /*
  useEffect(() => {
    if (userResult) {
      dispatch(loadAllCart(userResult.id));
      dispatch(loadAllMenuTitle());
    }
  }, [dispatch, userResult]);

  */

  let cartData = useSelector((state) => state.Cart.result);
  let menuData = useSelector((state) => state.Additional.menuTitle);


  useEffect(() => {

    console.log("firstt-----",expended1, expended2, expended3, expended4, search, mobile, cartpopup);



    if (userResult) {
      //dispatch(loadAllMenuTitle());
      if (userResult.id != null && userResult.id != undefined) {

        dispatch(loadAllCart(userResult.id));
      }
      else {
        var idrcv = localStorage.getItem("tempuser");
        dispatch(loadAllCart(idrcv));
      }

    }
    else {
      var idrcv = localStorage.getItem("tempuser");
      dispatch(loadAllCart(idrcv));

    }
  }, [userResult]);

  useEffect(() => {

    dispatch(loadAllMenuTitle());
    console.log("loadAllMenuTitle..", menuData)

  }, []);


  useEffect(() => {
    if (cartData) {
      setQuantity(cartData.reduce((acc, item) => acc + item.quantity, 0));
    }
  }, [cartData]);

  const logoutHandler = () => {
    dispatch(signOut());
    window.location.href = "/";

  };

  return (
    
    <>
      <header >
        {mobile ? (
          <div className="main_menu mobile">
            <div className="offerwheel">
              {menuData &&
                menuData.map((item, i) => (
                  <div className="offers" key={i}>
                    <Link
                      to={item.url}
                      className="btnoffer offers"
                      onClick={showChange}
                    >
                      {item.title}
                    </Link>
                  </div>
                ))}
            </div>
            <nav>

              <ul>
                <li>
                  <button
                    className={`product_child ${expended4 ? "active" : ""}`}
                    onClick={handleChange4}
                  >
                    Products
                    <FontAwesomeIcon icon={faAngleDown} />
                  </button>
                  {expended4 ? (
                    <SubMenuProduct showChange={showChange} />
                  ) : null}
                </li>
                <li>
                  <Link to="/irobot-os" onClick={showChange}>
                    iRobot OS
                  </Link>
                </li>
                <li>
                  <button
                    className={`acces_child ${expended1 ? "active" : ""}`}
                    onClick={handleChange1}
                  >
                    Accessories
                    <FontAwesomeIcon icon={faAngleDown} />
                  </button>
                  {expended1 ? (
                    <SubMenuAccesories showChange={showChange} />
                  ) : null}
                </li>
                <li>
                  <button
                    className={`support_child ${expended2 ? "active" : ""}`}
                    onClick={handleChange2}
                  >
                    Support
                    <FontAwesomeIcon icon={faAngleDown} />
                  </button>
                  {expended2 ? (
                    <SubMenuContact showChange={showChange} />
                  ) : null}
                </li>
                <li>
                  <button
                    className={`about_child ${expended3 ? "active" : ""}`}
                    onClick={handleChange3}
                  >
                    About
                    <FontAwesomeIcon icon={faAngleDown} />
                  </button>
                  {expended3 ? <SubMenuAbout showChange={showChange} /> : null}
                </li>
                <li>
                  <Link to="/video-demo" onClick={showChange}>
                    Video Demo
                  </Link>
                </li>
              </ul>
            </nav>

          </div>
        ) : (
          ""
        )}
        <div className="container headerhome">
          <div className="header">
            <div className="mobile-menu">
              {mobile ? (


                <button onClick={() => setMobile(!mobile)}>
                  <FaTimes />

                </button>
              ) : (
                <button onClick={() => setMobile(!mobile)}>
                  <FontAwesomeIcon icon={faBars} />
                </button>
              )}
            </div>
            <div className="logomobile">
              <Link to="/" onClick={showChange}>
                <img src={logomob} alt="" />
              </Link>
            </div>
            <div className="logo">
              <Link to="/" onClick={showChange}>
                <img src={logo} alt="" />
              </Link>
            </div>
            <div className="main_menu">
              <nav>
                <ul>
                  <li>
                    <button className="product_child" onClick={handleChange4}>
                      Products
                    </button>
                    {expended4 ? (
                      <SubMenuProduct showChange={showChange} />
                    ) : null}
                  </li>
                  <li>
                    <Link to="/irobot-os" onClick={showChange}>
                      iRobot OS
                    </Link>
                  </li>
                  <li>
                    <button className="acces_child" onClick={handleChange1}>
                      Accessories
                    </button>
                    {expended1 ? (
                      <SubMenuAccesories showChange={showChange} />
                    ) : null}
                  </li>
                  <li>
                    <button className="support_child" onClick={handleChange2}>
                      Support
                    </button>
                    {expended2 ? (
                      <SubMenuContact showChange={showChange} />
                    ) : null}
                  </li>
                  <li>
                    <button className="about_child" onClick={handleChange3}>
                      About
                    </button>
                    {expended3 ? (
                      <SubMenuAbout showChange={showChange} />
                    ) : null}
                  </li>
                  <li>
                    <Link to="/video-demo" onClick={showChange}>
                      Video Demo
                    </Link>
                  </li>
                </ul>
              </nav>

              <div className="offers-right">
                {menuData &&
                  menuData.map((item, i) => (
                    <div className="offers" key={i}>
                      <Link
                        to={item.url}
                        className="btnoffer offers"
                        onClick={showChange}
                      >
                        {item.title}
                      </Link>
                    </div>
                  ))}
              </div>
            </div>

            <div className="header_account">
              <ul>
                <li>
                  {/* <Link to="" onClick={() => setSearch(!search)}>
                  <FontAwesomeIcon icon={faSearch} />
                </Link> */}
                  <button onClick={() => setSearch(!search)}>
                    <FontAwesomeIcon icon={faSearch} />
                  </button>
                </li>

                <li style={{ cursor: "pointer" }}>
                  {isSigninIn === true ? (
                    <div className="dropdown">
                      <button className="">
                        <FontAwesomeIcon icon={faUser} />
                      </button>
                      <div id="myDropdown" className="dropdown-content">
                        <Link to="/dashboard">My Account</Link>
                        <Link to="/dashboard" onClick={logoutHandler}>Logout</Link>

                      </div>
                    </div>
                  ) : (
                    <div className="dropdown">
                      <button className="dropbtn">
                        <Link to="/login">
                          <FontAwesomeIcon icon={faUser} />
                        </Link>
                      </button>
                      {/* <div id="myDropdown" className="dropdown-content">
                        <Link to="/login">Login</Link>
                      </div> */}
                    </div>
                  )}
                </li>

                <li>
                <button
                    className="count-icon"
                    onClick={(e) => gotocart(e)}
                    // onClick= {{gotocart}}
                    style={{ cursor: "pointer", background: "none", border: "none" }}
                  >
                    <FontAwesomeIcon icon={faShoppingCart} />
                  {/* <a className="count-icon" onClick={(e) => gotocart(e)} style={{ cursor: "pointer" }}>
                    <FontAwesomeIcon icon={faShoppingCart} /> */}
                    <span>
                      {quantity === 0 ? (
                        <> </>
                      ) : (
                        <span className="count">{quantity}</span>
                      )}
                    </span>
                    </button>
                  {/* </a> */}
                </li>
              </ul>
            </div>
          </div>
        </div>




        {search ? <HeaderSearch showChange={showChange} /> : null}
        {expended1 ? <SubMenuAccesories showChange={showChange} /> : null}
        {expended3 ? <SubMenuAbout showChange={showChange} /> : null}
        {expended2 ? <SubMenuContact showChange={showChange} /> : null}
        {expended4 ? <SubMenuProduct showChange={showChange} /> : null}
      </header>
      {cartpopup ? <CartPopup showChange={showChange} /> : null}

      <InquireForm />
    </>
  );
}

export default Header;
