import {
  GET_ALL_STORE_FAIL,
  GET_ALL_STORE_REQUEST,
  GET_ALL_STORE_SUCCESS,
  GET_SINGLE_STORE_FAIL,
  GET_SINGLE_STORE_REQUEST,
  GET_SINGLE_STORE_SUCCESS,
  GET_SINGLE_STORE_FILTER_FAIL,
  GET_SINGLE_STORE_FILTER_REQUEST,
  GET_SINGLE_STORE_FILTER_SUCCESS,
} from "../constants/StoreConstant";

const initialState = {
  loading: false,
  action: "Store-List",
  result: [],
  response: {},
  singledataStore: [],
  filter: [],
  msg: "",
  error: "",
};

const StoreListReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_STORE_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case GET_ALL_STORE_SUCCESS:
      return {
        ...state,
        result: action.result.data,
        loading: action.payload,
        msg: action.msg,
      };
    case GET_ALL_STORE_FAIL:
      return {
        ...state,
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };

    case GET_SINGLE_STORE_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case GET_SINGLE_STORE_SUCCESS:
      return {
        ...state,
        singledataStore: action.result.data[0],
        loading: action.payload,
        msg: action.msg,
      };
    case GET_SINGLE_STORE_FAIL:
      return {
        ...state,
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };

    case GET_SINGLE_STORE_FILTER_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case GET_SINGLE_STORE_FILTER_SUCCESS:
      return {
        ...state,
        filter: action.result.data,
        loading: action.payload,
        msg: action.msg,
      };
    case GET_SINGLE_STORE_FILTER_FAIL:
      return {
        ...state,
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };

    default:
      return state;
  }
};

export default StoreListReducer;
