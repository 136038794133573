import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { loadAllProductImage } from "../../redux/action/ProductAction";

import Loader from "../Shared/Loader";
import Message from "../Shared/Message";

import { Link, useParams } from "react-router-dom";
// import ReactImageZoom from 'react-image-zoom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import $ from "jquery";


import p1 from "../../image/p1.jpg";
import p2 from "../../image/p2.jpg";
import p3 from "../../image/p3.jpg";
import p4 from "../../image/p4.jpg";
import p5 from "../../image/p5.jpg";
import p6 from "../../image/p6.jpg";

// const [activeThumb, setActiveThumb] = useState()

export default function ProductSlider() {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [slider1, setSlider1] = useState(null);
  const [slider2, setSlider2] = useState(null);

  useEffect(() => {
    setNav1(slider1);
    setNav2(slider2);
    console.log("useEffect...ProductSlider");

  }, []);

  // const props = {width: 400, height: 250, zoomWidth: 500, img: p1};

  var settings = {
    // dots: {dots:activeThumb},
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    adaptiveHeight: true,
    autoplaySpeed: 2000,
    asNavFor: ".slider-nav",

  };





  var thumb = {
    dots: false,
    arrows: true,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
    swipeToSlide: false,
    focusOnSelect: true,
    asNavFor: ".slider-for",

  };

  /* slick-slide slick-active slick-current*/

  const dispatch = useDispatch();

  const { id } = useParams();

  useEffect(() => {
    dispatch(loadAllProductImage(id));
  }, [id]);

  const clickthumb = (idrcv) => {

    console.log('idrcv', idrcv)

  }
  let productData = useSelector((state) => state.MainProduct);

  let { loading, error, productImages } = productData;

  const onSwipeEvent = (swipeDirection) => {
    console.log('onSwipeEvent', swipeDirection);
  }
  const afterChangeEvent = (currentSlide) => {
    console.log('afterChangeEvent', currentSlide);
    slider1.slickGoTo(currentSlide);

  }
  const beforeChangeEvent = (currentSlide) => {
    console.log('beforeChangeEvent', currentSlide);
  }

  //console.log(productImages, "aajkaimage");
  return (
    <>
      <div className="product_slider_container product_sliderImgMain">
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : (
          <>
            <Slider
              {...settings}
              asNavFor={nav2}
              ref={(slider) => setSlider1(slider)}
            >
              {productImages.map((image) => {
                return (
                  <div
                    className="product_slider product_sliderImg"
                    key={image.id}
                  >
                    <img src={`${process.env.REACT_APP_API_PATH}${image.image}`} className="zoomimg" />
                  </div>
                );
              })}
            </Slider>
            <Slider
              {...thumb}
              asNavFor={nav1}
              ref={(slider) => setSlider2(slider)}
              className="thumb-slider"
              onSwipe={onSwipeEvent}
              afterChange={afterChangeEvent}
              beforeChange={beforeChangeEvent}

            >
              {productImages.map((image) => {
                return (
                  <div className="product_slider-thumb" key={image.id} >
                    <img src={`${process.env.REACT_APP_API_PATH}${image.image}`} />
                  </div>
                );
              })}
            </Slider>
          </>
        )}
      </div>
    </>

    // <div className="product_slider_container">
    //    <Slider {...settings}  asNavFor={nav2} ref={slider => (setSlider1(slider))} >
    //    <div className="product_slider" >

    //    {/* <ReactImageZoom {...props} /> */}
    //    {/* <ReactImageZoom {...{width: 400, height: 400, zoomWidth: 900, img: p1}} /> */}

    //             <img src={p1} />
    //           </div>
    //           <div className="product_slider" >
    //           {/* <ReactImageZoom {...{width: 400, height: 400, zoomWidth: 900, img: p2}} /> */}
    //             <img src={p2} />
    //           </div>
    //           <div className="product_slider" >
    //           {/* <ReactImageZoom {...{width: 400, height: 400, zoomWidth: 900, img: p3}} /> */}
    //             <img src={p3} />
    //           </div>
    //           <div className="product_slider" >
    //           {/* <ReactImageZoom {...{width: 400, height: 400, zoomWidth: 900, img: p4}} /> */}
    //             <img src={p4} />
    //           </div>
    //           <div className="product_slider" >
    //           {/* <ReactImageZoom {...{width: 400, height: 400, zoomWidth: 900, img: p5}} /> */}
    //             <img src={p5} />
    //           </div>
    //           <div className="product_slider" >
    //           {/* <ReactImageZoom {...{width: 400, height: 400, zoomWidth: 900, img: p6}} /> */}
    //             <img src={p6} />
    //           </div>

    //    </Slider>
    //    <Slider {...thumb} asNavFor={nav1} ref={slider => (setSlider2(slider))} className="thumb-slider">
    //    <div className="product_slider-thumb" >
    //             <img src={p1} />
    //           </div>
    //           <div className="product_slider-thumb" >
    //             <img src={p2} />
    //           </div>
    //           <div className="product_slider-thumb" >
    //             <img src={p3} />
    //           </div>
    //           <div className="product_slider-thumb" >
    //             <img src={p4} />
    //           </div>
    //           <div className="product_slider-thumb" >
    //             <img src={p5} />
    //           </div>
    //           <div className="product_slider-thumb" >
    //             <img src={p6} />
    //           </div>

    //    </Slider>

    // </div>
  );
}
