import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import imagenocost from "../images/No-Cost-EMI.png";


export default function NoCostEmi() {

    useEffect(() => {
        document.getElementById("maintittle").innerHTML = "No Cost EMI - Terms and conditions";
        document.getElementById("description").setAttribute("content", "The Nashtronics Trading Pvt. Ltd., as a seller, would bear the cost of No Cost EMI. It will be given as an upfront discount on the product cost to the end consumer");


    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <section classNameName="section">
            <img src={imagenocost} alt="" />





            <div className="col-sm-10 px-5 m-auto">
                <div id="ContentPlaceHolder1_lblhead" className="font-weight-bolder text-left h2-large pl-0">No Cost EMI on iRobot products</div>


                <div className="para-average">
                    <span id="ContentPlaceHolder1_lblcontent"><span className="font14">No-Cost EMI is an offer by which you pay your EMI provider only the product price, equally divided over your repayment timeline.<br></br>
                        Let us consider the example of buying a robot worth ₹ 50,000 on No-Cost EMI on a 6-month EMI period. The bank charges 13% interest per annum.</span><br></br>
                        <br></br>
                        <table className="nocosttable">
                            <thead>
                                <tr>
                                    <th scope="col"><span className="font14"><strong>Amount (A)</strong></span></th>
                                    <th scope="col"><span className="font14"><strong>₹&nbsp;</strong><strong>50,000</strong></span></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><span className="font14">No Cost EMI interest discount</span></td>
                                    <td><span className="font14">-1,913</span></td>
                                </tr>
                                <tr>
                                    <td><span className="font14">Final Amount (P)</span></td>
                                    <td><span className="font14">₹ 48,087</span></td>
                                </tr>
                                <tr>
                                    <td><span className="font14">Interest charged by bank at 13%</span></td>
                                    <td><span className="font14">₹ 1,913</span></td>
                                </tr>
                                <tr>
                                    <td><span className="font14">Total Amount payable to the bank</span></td>
                                    <td><span className="font14">₹ 50,000</span></td>
                                </tr>
                                <tr>
                                    <td><span className="font14">EMI</span></td>
                                    <td><span className="font14">₹ 8,333.33</span></td>
                                </tr>
                            </tbody>
                        </table>
                        <br></br>
                        <span className="font14">In the above example, the original price is discounted by ₹1913. This cost is borne by Nashtronics Trading Pvt. Ltd. For a bank charging 13% of interest per annum with an EMI period of 6 months, the discount is 3.8%.<br></br>
                            <br></br>
                            <strong>Calculation of EMI</strong><br></br>
                            <br></br>
                            EMI is generally calculated using the below formula:<br></br>
                            EMI = [P x R x (1+R)^N]/[(1+R)^N-1]<br></br>
                            <br></br>
                            where<br></br>
                            <strong>P</strong> = Principal<br></br>
                            <strong>R</strong> = Interest rate per month<br></br>
                            <strong>N</strong> = Number of installations of the EMI<br></br>
                            <br></br>
                            For the case of No-Cost EMI, the EMI value is calculated as A/N, where A is the price of the product.<br></br>
                            <br></br>
                            <strong>Frequently asked questions</strong><br></br>
                            <br></br>
                            WHO BEARS THE COST OF BANK INTEREST CHARGES IN NO-COST EMI?<br></br>
                            <em>The Nashtronics Trading Pvt. Ltd., as a seller, would bear the cost of No Cost EMI. It will be given as an upfront discount on the product cost to the end consumer. The discount percentage will vary by the bank and period of EMI.</em><br></br>
                            <br></br>
                            WHAT IS CANCELLATION T&amp;C UNDER THIS OFFER?</span>
                        <br></br>
                        <ul className="nocostli">
                            <li><span className="font14"><em>Cancellation is allowed as per the general cancellation policy of iRobot India. Please <u><a href="terms-condition" target="_blank"><span className="color0000FF">click here</span></a></u> for the iRobot India Terms and conditions and <u><a href="return-warranty" target="_blank"><span className="color0000FF">click here</span></a></u> for The iRobot India Returns and Warranty policy.</em></span></li>
                            <li><span className="font14"><em>Refund against cancellation of the orders under this offer will attract a deduction of applied bank interest charges discounted amount and also 5% as Payment gateway charges amount.</em></span></li>
                        </ul>
                        <br></br>
                        <span className="font14"> WHICH PAYMENT METHODS CAN BE USED FOR NO-COST EMI?<br></br>
                            <em>No-Cost EMI is available on credit cards from all major banks – Kotak, Axis, Indusind, RBL, ICICI, Standard Chartered, YesBank, and more. Please check the payment page for more details.</em><br></br>
                            <br></br>
                            WILL THE CUSTOMERS' BANKS CONTINUE TO CHARGE THEM INTEREST?<br></br>
                            <em>Yes, the customers’ banks will continue to charge them interest. However, this interest charge has been provided to the customer as an upfront discount at the time of purchase, effectively giving them the benefit of a No-Cost EMI.</em><br></br>
                            <br></br>
                            IS GST CHARGED OVER AND ABOVE THIS AMOUNT?<br></br>
                            <em>Yes, banks charge GST to end consumers on the interest paid over and above this amount. Please check with your bank for charges related to interest, processing fee, refund, or pre-closure. The Nashtronics Tradings Pvt. Ltd. does not levy any charges on the availing EMI.</em><br></br>
                            <br></br>
                            CAN YOU SHARE A TABLE WITH THE CREDIT CARD INTEREST RATES CHARGED BY THE BANKS?<br></br>
                            <em>The interest rates charged by various banks for each of the tenures are provided for your reference on the payment page upon selection of issuing bank name. The minimum transaction amount for which EMIs are applicable can vary for each bank. The maximum amount is dependent on the card limit set by the issuing bank.</em></span></span>

                </div>
                <br></br>
                <br></br>

            </div>
        </section>
    );
}
