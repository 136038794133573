import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadAllProdtList } from "../../redux/action/StaticAction";
import { useParams } from "react-router-dom";
import series_banner from "../../image/series_banner.jpg";

export default function Inner_banner(props) {
  const [Prodctslist, setProdctslist] = useState("");
  const banner = useSelector((state) => state.Static.prodlist);

  const dispatch = useDispatch();
  let { id } = useParams();

  useEffect(() => {
    dispatch(loadAllProdtList(id));
    // console.log("inner banner", props.banneridglobe);
    //setProdctslist(banner[0]?.id);
  }, [id]);




  useEffect(() => {
    if (banner) {
      console.log("....banner", banner);
      console.log("....banner id inn", props.banneridglobe);
      console.log("....banner banner[0]?.id", banner[0]?.id);
      // if (banner[0]?.id != undefined) {
      props.setbanneridglobe(banner[0]?.id);
      // }
      //props.banneridglobe = banner[0]?.id;

    }
    //setProdctslist(banner[0]?.id);
  }, [banner]);


  let bannerData = banner[0];

  if (bannerData) {
    var banner_id = bannerData.id;
    var banner_image = bannerData.top_banner;
    var banner_heading = bannerData.top_banner_heading;
    var banner_content = bannerData.top_banner_content

    console.log("maintittle..", bannerData.subcat_id.seo_tittle);
    //document.getElementById("maintittle").innerHTML = bannerData.subcat_id.seo_tittle;

  }
  return (
    <>
      {banner.length > 0 ? (
        <>
          <section className="inner_banner series" key={banner_id}>
            {banner_image == null ? (
              <img src={series_banner} alt="" />
            ) : (
              <img src={`${process.env.REACT_APP_API_PATH}${banner_image}`} />
            )}
            <div className="container">
              <div className="inner">
                <h2 className="title">{banner_heading}</h2>
                <p className="inner_p">{banner_content}</p>
              </div>
            </div>
          </section>
        </>
      ) : (
        ""
      )}
    </>
  );
}
